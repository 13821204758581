<div class="myHeader">
    Change Password
  </div>
<div fxFlex fxLayoutAlign="center center" fxLayout="column">

    <mat-card class="mat-elevation-z16" style="width: 90vw; height: 75vh; border: 4px solid rgba(146, 148, 248, 1);" fxLayout="column" fxLayoutAlign="space-evenly none">
        <!-- <button mat-mini-fab style="position: absolute;top: 0px;left: 0px;" (click)="closing()"><mat-icon>arrow_back</mat-icon></button> -->
        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="password" type="password">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input matInput [(ngModel)]="passwordConfirm" type="password">
        </mat-form-field>

        <div class="btn valid" *ngIf="password == passwordConfirm && password != ''" (click)="updatePassword()" ><mat-icon>key</mat-icon> Reset Password </div>
        <div class="btn invalid" *ngIf="password != passwordConfirm || password == ''"><mat-icon>key</mat-icon> Reset Password </div>
        <!-- <a (click)="openHelp('4.2.1')" style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a> -->

    </mat-card>

</div>