import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ElementRef, NgZone, HostListener } from '@angular/core';
import * as jquery from 'jquery';


@Component({
  selector: 'app-carousel-menu',
  templateUrl: './carousel-menu.component.html',
  styleUrls: ['./carousel-menu.component.scss'],
})
export class CarouselMenuComponent implements OnInit, AfterViewInit {
  // @ViewChild('paddingLeft') paddingLeft: ElementRef;
  // @ViewChild('paddingRight') paddingRight: ElementRef;
  // @ViewChild('mainContainer') mainContainer: ElementRef;
  // @ViewChild('menuContainer') menuContainer: ElementRef;
  // @ViewChild('mainContainer') mainContainer: ElementRef;

  // scrollDirection = 0;
  // scrollSpeed = 10;

  selectedIndex = 0;

  menuItems = [
    { label: 'Dashboard', icon: 'dashboard', route: '/dashboard' },
    { label: 'Alarms', icon: 'notification_important', route: '/alarms' },
    { label: 'v-Keys', icon: 'shield', route: '/token-tools' },
    { label: 'Manage Sites', icon: 'domain', route: '/site-management' },
    { label: 'Manage Users', icon: 'person', route: '/user-management' },
    { label: 'Subcontractors', icon: 'group', route: '/subcontractor-management' },
    { label: 'Handovers', icon: 'handshake', route: '/handover-management' },
    { label: 'Handover Requests', icon: 'handshake', route: '/handover-requests' },
    { label: 'Schedules', icon: 'schedule', route: '/schedules' },
    // Add more menu items as needed
  ];

  constructor(private renderer: Renderer2, private ngZone: NgZone) { }

  ngOnInit(): void {
    
  }

  

  navigate(route: string): void {
    // Implement your navigation logic here
    
  }

  // Other methods and properties...
  openMenu() {

  }
  ngAfterViewInit(): void {
    this.setStartPos();
  }

  selectItem(index: number): void {
    this.selectedIndex = index;

    let cts = document.querySelectorAll(".carousel-container > .container");
    for (let i = 0; i <= cts.length; i++) {
      if(cts[i] as HTMLElement == undefined) {
        console.log("undefined element: \n" + cts[i])
      } else {
        (cts[i] as HTMLElement).style.transition = "all 1s linear";
        if(index== i) {
          // (cts[i] as HTMLElement).style.left = "0";
          // (cts[i] as HTMLElement).style.left = "0";
          cts[i].parentElement.append(cts[i] as HTMLElement)
          // document.insertBefore(cts[i].cloneNode(), cts[i])
        } else if(index < this.selectedIndex) {
          (cts[i] as HTMLElement).style.translate = "-400px 0px";
        } else if(index > this.selectedIndex) {
          (cts[i] as HTMLElement).style.translate = "50vw, 50vh";
        }
      }
      // console.log(cts[i])
      // cts[i] == undefined ? alert(i + ' Is undefined') : null;

      
      
    }
  }

  setStartPos() {
    jquery.$(".container:nth-child(n + 2)").css("margin","4px");    
  }
  
}



// menuItems = [
//   { label: 'Dashboard', icon: 'dashboard', route: '/dashboard' },
//   { label: 'Alarms', icon: 'notification_important', route: '/alarms' },
//   { label: 'v-Keys', icon: 'shield', route: '/token-tools' },
//   { label: 'Manage Sites', icon: 'domain', route: '/site-management' },
//   { label: 'Manage Users', icon: 'person', route: '/user-management' },
//   { label: 'Subcontractors', icon: 'group', route: '/subcontractor-management' },
//   { label: 'Handovers', icon: 'handshake', route: '/handover-management' },
//   { label: 'Handover Requests', icon: 'handshake', route: '/handover-requests' },
//   { label: 'Schedules', icon: 'schedule', route: '/schedules' },
//   // Add more menu items as needed
// ];