<mat-card style="padding: 8px; border: 1px solid white; max-height: 90vh; overflow-y: auto; overflow-x: hidden;" #myTemplateCard cdkDrag >
    <mat-card-header cdkDragHandle>
        <mat-card-title>{{isAdd ? 'Add Template' : 'Edit Template'}}</mat-card-title>
    </mat-card-header>

    <mat-card-content>

        <div fxLayout="column">
            <mat-form-field appearance="outline">
                <mat-label>Template Name</mat-label>
                <input matInput [(ngModel)]="mytemplate.name">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Template Description</mat-label>
                <textarea matInput [(ngModel)]="mytemplate.description"> </textarea>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Template Color</mat-label>
                <input matInput [(ngModel)]="mytemplate.color" type="color">
            </mat-form-field>

            <div fxLayout="row">
                <div fxLayout="column" style="border: 2px solid #573dee; border-radius: 5px; margin: 4px; padding:8px;">
                    1. Actuator
                    <mat-slide-toggle color="primary" [(ngModel)]="mytemplate.door1AutoLock" cdkDragHandle>Door 1 Auto Lock</mat-slide-toggle>
                    <mat-slide-toggle color="primary" [(ngModel)]="mytemplate.door2AutoLock" cdkDragHandle>Door 2 Auto Lock</mat-slide-toggle>
                    <mat-slide-toggle color="primary" [(ngModel)]="mytemplate.useTimer" cdkDragHandle>Use Timer</mat-slide-toggle>
                    <mat-slide-toggle color="primary" [(ngModel)]="mytemplate.invertActuator" cdkDragHandle>Invert Actuator</mat-slide-toggle>
                    <mat-slide-toggle color="primary" [(ngModel)]="mytemplate.maglockToggle" cdkDragHandle>Use Magnetic Lock</mat-slide-toggle>
        
                    <div style="border: 1px solid #f37321; border-radius: 5px; margin: 4px; padding: 4px;">
                        <div>
                            <mat-label>Actuator Timeout </mat-label><strong style="color:limegreen">
                                {{mytemplate.actuatorTimeout}}</strong>s
                        </div>
                        <mat-slider color="primary" [(ngModel)]="mytemplate.actuatorTimeout" min="1" max="20" step="1"
                            thumbLabel></mat-slider>
                    </div>
        
                    <div style="border: 1px solid #f37321; border-radius: 5px; margin: 4px; padding: 4px;">
                        <div>
                            <mat-label>Max Current </mat-label><strong style="color:limegreen">
                                {{mytemplate.maxCurrent}}</strong>A
                        </div>
                        <mat-slider color="primary" [(ngModel)]="mytemplate.maxCurrent" min="0.25" max="6" step="0.01"
                            thumbLabel></mat-slider>
                    </div>
        
                    <div style="border: 1px solid #f37321; border-radius: 5px; margin: 4px; padding: 4px;">
                        <div>
                            <mat-label>Actuator Speed </mat-label><strong style="color:limegreen">
                                {{mytemplate.actSpeed}}</strong>mm/s
                        </div>
                        <mat-slider color="primary" [(ngModel)]="mytemplate.actSpeed" min="0" max="15" step="1"
                            thumbLabel></mat-slider>
                    </div>
        
                    <div style="border: 1px solid #f37321; border-radius: 5px; margin: 4px; padding: 4px;">
                        <div>
                            <mat-label>Actuator Length </mat-label><strong style="color:limegreen">
                                {{mytemplate.actLength}}</strong>mm
                        </div>
                        <mat-slider color="primary" [(ngModel)]="mytemplate.actLength" min="0" max="150" step="1"
                            thumbLabel></mat-slider>
                    </div>
                </div>

                <div fxLayout="column" style="border: 2px solid #573dee; border-radius: 5px; margin: 4px; padding:8px;">
                    2. Sensors
                    <mat-form-field appearance="outline">
                        <mat-label>Sensor Type</mat-label>
                        <mat-select [(ngModel)]="mytemplate.sensorType">
                            <mat-option [value]="0">Magnetic Contacts</mat-option>
                            <mat-option [value]="1">PRISM Smart IR</mat-option>
                            <mat-option [value]="2">Induction Sensor</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="mytemplate.useDoorContact">Use door contact w/o door sensor</mat-slide-toggle>

                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="mytemplate.use2Sensors"
                        [disabled]="mytemplate.sensorType != 2">Use 2 sensors</mat-slide-toggle>

                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="mytemplate.useTamperRelay">Use Tamper Relay</mat-slide-toggle>
                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="mytemplate.useDCtoInhibit">Use Door Contact & Door Sensor</mat-slide-toggle>
                    <mat-slide-toggle style="margin: 8px;" color="primary" [(ngModel)]="mytemplate.dcIsNO">Door Contact Normally Open</mat-slide-toggle>


                    <!--  -->
                    <!-- <div style="border: 1px solid gray; border-radius: 5px; margin: 4px; padding: 4px; width: 100%">
                        <label>Tamper Relay</label>
                        <div fxLayout="row" fxLayoutAlign="space-evenly center">
                            <div class="ioBtn" fxLayout="column" fxLayoutAlign="center center"
                                style="border-color: lime; color: lime; font-weight: bold;"
                                (click)="setTamperRelay(true)">
                                I
                            </div>
                            <div class="ioBtn" fxLayout="column" fxLayoutAlign="center center"
                                style="border-color: red; color: red; font-weight: bold;"
                                (click)="setTamperRelay(false)">
                                O
                            </div>
                        </div>
                    </div> -->
                    <!-- <div style="border: 1px solid gray; border-radius: 5px; margin: 4px; padding: 4px; width: 100%"
                        *ngIf="version < 'PRO-S3-O'" [hidden]="!gotConfig || !gotVersion">
                        <label>Use door contact w/ door sensor</label>
                        <div fxLayout="row" fxLayoutAlign="space-evenly center">
                            <div class="ioBtn" fxLayout="column" fxLayoutAlign="center center"
                                style="border-color: lime; color: lime; font-weight: bold;"
                                (click)="setInhibitSD(true)">
                                I
                            </div>
                            <div class="ioBtn" fxLayout="column" fxLayoutAlign="center center"
                                style="border-color: red; color: red; font-weight: bold;" (click)="setInhibitSD(false)">
                                O
                            </div>
                        </div>

                    </div> -->
                </div>

                <div fxLayout="column" style="border: 2px solid #573dee; border-radius: 5px; margin: 4px; padding:8px;">
                    3.WiFi
                    <mat-form-field appearance="outline">
                        <mat-label>SSID/Network Name</mat-label>
                        <input matInput [(ngModel)]="mytemplate.ssid">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput [(ngModel)]="mytemplate.password">
                    </mat-form-field>
                    <mat-slide-toggle  color="primary" [(ngModel)]="mytemplate.currentWifiState">WiFi On</mat-slide-toggle>
                </div>

                <div fxLayout="column" style="border: 2px solid #573dee; border-radius: 5px; margin: 4px; padding:8px;">
                    4. Secondary Controller
                    <mat-slide-toggle color="primary" [(ngModel)]="mytemplate.secondaryPresent">Secondary Controller Present</mat-slide-toggle>
                    <mat-slide-toggle color="primary" [(ngModel)]="mytemplate.tpmPresent" *ngIf="mytemplate.secondaryPresent">TPM Present</mat-slide-toggle>
                    <mat-form-field appearance="outline" *ngIf="mytemplate.secondaryPresent">
                        <mat-label>Sensor Type</mat-label>
                        <mat-select [(ngModel)]="mytemplate.secondarySensorType">
                            <mat-option [value]="0">Magnetic Contacts</mat-option>
                            <mat-option [value]="1">PRISM Smart IR</mat-option>
                            <mat-option [value]="2">Induction Sensor</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-slide-toggle color="primary" [(ngModel)]="mytemplate.secondaryUse2Sensors" *ngIf="mytemplate.secondaryPresent">Secondary Uses 2 Sensors</mat-slide-toggle>
                </div>
            </div>
           
        </div>
    </mat-card-content>

    <mat-card-footer>
        <div fxLayoutAlign="space-evenly center" fxLayout="row" style="margin: 16px;">
            <button mat-raised-button color="primary" style="width:20%" (click)="saveConfig()"><mat-icon>save</mat-icon> Save</button>
            <!-- <button mat-raised-button color="warn" style="width:20%" (click)="closing()"><mat-icon>cancel</mat-icon> Cancel</button> -->
            <button mat-raised-button color="warn" style="width:20%" (click)="deleteConfig()"><mat-icon>cancel</mat-icon> Delete</button>
        </div>
        
    </mat-card-footer>
</mat-card>