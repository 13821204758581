import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-key-requests',
  templateUrl: './key-requests.component.html',
  styleUrls: ['./key-requests.component.scss']
})
export class KeyRequestsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  currentRequests = new MatTableDataSource();

  displayedColumnsCurrent = ['name', 'surname', 'id', 'faceImg', 'idImg', 'refImg', 'cell', 'email', 'siteID', 'siteName','support' , 'from', 'to', 'accept', 'delete']

  pageSize = 5;
  previousPageIndex = 0;
  pageIndex = 0;
  length = 5;

  selectedItem = null;
  isAccept = false;
  isDelete = false;

  enlargedImageUrl: string | null = null;
  enlargedImagePositionX = 0;
  enlargedImagePositionY = 0;

  constructor(private webreq: WebRequestsService, public loader: LoaderService, public notify: NotificationsService) { }

  ngOnInit(): void {
    this.loader.isLoading = true;
    this.currentRequests.paginator = this.paginator;
    this.getKeyRequests();
  }
  showEnlargedImage(event: MouseEvent, imageUrl: string): void {
    this.enlargedImageUrl = imageUrl;
    this.updateEnlargedImagePosition(event);
  }
  hideEnlargedImage(): void {
    this.enlargedImageUrl = null;
  }

  updateEnlargedImagePosition(event: MouseEvent): void {
    if (event.clientY < 500) {
      this.enlargedImagePositionY = event.clientY + 10;
      this.enlargedImagePositionX = event.clientX + 10;
    } else {
      this.enlargedImagePositionY = event.clientY - 300;
      this.enlargedImagePositionX = event.clientX + 10;
    }
  }
  getKeyRequests() {
    this.loader.isLoading = true;
    this.webreq.getKeyRequests(this.pageSize, this.pageIndex).subscribe(results => {
      let tempData = results.data;
      this.length = results.count[0]['COUNT(*)'];
      tempData.forEach(element => {
        element.img_face = this.getImgUrl(element.img_face.data);
        element.img_ID = this.getImgUrl(element.img_ID.data);
        element.img_REF = this.getImgUrl(element.img_REF.data);
      });
      this.currentRequests.data = results.data;
      // this.currentRequests.paginator = this.paginator;
      this.currentRequests.sort = this.sort;
      this.loader.isLoading = false;
      console.log(this.length)
    }, err => {
      this.loader.isLoading = false;
    })
  }

  logPageEvent(evt) {
    console.log(evt)
    this.previousPageIndex = evt.previousPageIndex;
    this.pageIndex = evt.pageIndex;
    this.pageSize = evt.pageSize;
    this.length = evt.length;
    this.getKeyRequests();
    
  }
   
  async accept() {
    this.loader.isLoading = true;
    let userObjList = await this.webreq.getUsers().toPromise();
    let userObj = userObjList.filter(x => x.username == this.selectedItem.username && x.email == this.selectedItem.email && x.clientdb.includes(this.selectedItem.clientdb))[0];
    let siteList = await this.webreq.getSitesAll().toPromise();
    let siteObj = siteList.filter(x => x.siteID == this.selectedItem.siteID && x.siteName == this.selectedItem.siteName)[0];

    let result: any = {};
    if (this.selectedItem.door2 == true && (this.selectedItem.door1 == undefined || this.selectedItem.door1 == false)) {
      result = await this.webreq.generateSystemTokensNew(siteObj, [[1, 0], [5, 0], [5, 1], [5, 2], [3, 0], [2, 0], [4, 0]], new Date(this.selectedItem.fromDate).getTime(), new Date(this.selectedItem.toDate).getTime(), userObj.username, userObj._id, this.selectedItem.support).toPromise();

    } else if (this.selectedItem.door1 == true && this.selectedItem.door2 == true) {
      result = await this.webreq.generateSystemTokensNew(siteObj, [[1, 0], [0, 0], [0, 1], [0, 2], [5, 0], [5, 1], [5, 2], [3, 0], [2, 0], [4, 0]], new Date(this.selectedItem.fromDate).getTime(), new Date(this.selectedItem.toDate).getTime(), userObj.username, userObj._id, this.selectedItem.support).toPromise();
    }
    else {
      result = await this.webreq.generateSystemTokensNew(siteObj, [[1, 0], [0, 0], [0, 1], [0, 2], [3, 0], [2, 0], [4, 0]], new Date(this.selectedItem.fromDate).getTime(), new Date(this.selectedItem.toDate).getTime(), userObj.username, userObj._id, this.selectedItem.support).toPromise();
    }
    if (result.success == true) {
      this.isAccept = false;
      alert('v-Keys Generated');
      this.notify.openMessage(result.message, 'success', 5);
      this.acceptKeyRequest();

    }
    else {
      alert('Failed To Generate v-Keys');
      this.notify.openMessage(result.message, 'error', 5);
      this.loader.isLoading = false;
    }
    return result;
  }

  async deleteKeyRequest(item: any) {
    // let result = await this.webreq.deleteKeyRequest(this.selectedItem).toPromise();
    this.selectedItem.access = 'denied';
    let result = await this.webreq.updateKeyRequest(this.selectedItem).toPromise();
    this.isDelete = false;
    this.selectedItem = null;
    this.getKeyRequests();
  }

  async acceptKeyRequest() {
    
    this.selectedItem.access = 'granted';
    let result = await this.webreq.updateKeyRequest(this.selectedItem).toPromise();
    this.selectedItem = null;
    this.loader.isLoading = false;

    this.getKeyRequests();
  }

  getImgUrl(elementBuffer) {
    let binary = '';
    const bytes = new Uint8Array(elementBuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return binary;
  }

}
