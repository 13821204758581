import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-alarm-history',
  templateUrl: './alarm-history.component.html',
  styleUrls: ['./alarm-history.component.scss']
})
export class AlarmHistoryComponent implements OnInit, AfterViewInit {

  alarmList: any = [];
  dataSource = new MatTableDataSource<any>();
  dataSourceCurrent = new MatTableDataSource<any>();
  displayedColumns = ['id', 'type', 'siteID', 'siteName', 'time']
  displayedColumnsCurrent = ['time', 'type', 'siteID', 'siteName', 'acknowledgedBy', 'acknowledgedTime', 'resolvedBy', 'resolvedComment', 'resolvedTime']
  wsConnected: boolean = false;

  startResolve: boolean = false;
  resolveComment: string = "";
  selectedAlarm: any = {};
  reportUrl:any = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(public webReq: WebRequestsService,private sanitizer: DomSanitizer) { }

  
  ngAfterViewInit() {
    this.dataSourceCurrent.paginator = this.paginator;
    this.dataSourceCurrent.sort = this.sort;
  }

  ngOnInit(): void {
    this.getReportUrl(localStorage.getItem('clientdb'));
    this.webReq.getAllAlarms().subscribe(alarms => {
      this.alarmList = alarms;
      this.dataSourceCurrent = new MatTableDataSource<any>(this.alarmList);
      this.dataSourceCurrent.paginator = this.paginator;
      this.dataSourceCurrent.sort = this.sort;
    });
  }

  sortData($event) {
    console.log("Sort clicked")
  }

  getPriorityColor(priority) {
    if(priority == 1) {
      return 'red';
    } else if(priority == 2) {
      return 'orange';
    } else if(priority ==3 ) {
      return 'green';
    } else return 'white'
  }

  getReportUrl(client:any) {
    var initUrl = "https://lookerstudio.google.com/embed/reporting/75054db0-9dee-4532-8938-206925b913cd/page/WTUCD";
    console.log("Initial report url")
    console.log(initUrl)
    var params = {
      "ds2.clientdb": client
    };
    var paramsAsString = JSON.stringify(params);
    var encodedParams = encodeURIComponent(paramsAsString)
    console.log("Encoded params:")
    console.log(encodedParams);

    this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(initUrl+"?params="+encodedParams);
    console.log("Report url final")
    console.log(this.reportUrl.toString())
  }

}
