<div fxFlex fxLayoutAlign="center center" fxLayout="column">



    <mat-card *ngIf="!tokensSet && !cameraOpen" fxLayout="column" fxLayoutAlign="space-around center"
        style="width: 80vw; height: 70vh;">
    <button mat-mini-fab (click)="closing()" style="position: absolute;top: 8px;left: 8px; background-color: transparent;"><mat-icon>arrow_back</mat-icon></button>    
            <button mat-raised-button color="primary" style="width: 90%;" (click)="cameraOpen = true">
                <mat-icon>photo_camera</mat-icon>
                <mat-label>Camera</mat-label>
            </button>
            
        <div style="width: 90%;">
            <button mat-raised-button color="primary" style="width: 100%;" (click)="tryOpenFile()">
                <mat-icon>folder_open</mat-icon>
                <mat-label>File</mat-label>
            </button>
            <input id="fileInput" type="file" (change)="processFile($event)">
        </div>
    </mat-card>


    <div class="scanner-container" style="height: 80vh;" *ngIf="!tokensSet && cameraOpen">
        <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)"></zxing-scanner>
        <button mat-raised-button (click)="cameraOpen = false;"><mat-icon color="warn">close</mat-icon> Close Camera</button>
    </div>

    <mat-card *ngIf="tokensSet">
        <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon style="color: lightgreen">check_circle</mat-icon>
            <div>Tokens Stored</div>
        </div>
    </mat-card>
</div>