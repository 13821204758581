import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsComponent } from '../components/notifications/notifications.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public currentAlarms = 0;
  public currentHandovers = 0;
  public currentKeyRequests = 0;

  constructor(private snackBar: MatSnackBar) { }

  openMessage(message:string,icon:string, duration:number = 3) {
    this.snackBar.openFromComponent(NotificationsComponent,{
      duration: duration*1000,
      data:{
      message: message,
      icon: icon,
      preClose: ()=>{this.snackBar.dismiss()}
    }
  });  }


}
