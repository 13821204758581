import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { BluetoothComponent } from './pages/bluetooth/bluetooth.component';
import { HomeComponent } from './pages/home/home.component';
// import { LoginComponent } from './login/loginOld.component';
import { SidenavnewComponent} from './components/sidenavnew/sidenavnew.component';
import { LoginComponent } from './pages/login/login.component';
import { SidenavnewModule } from './components/sidenavnew/sidenavnew.module';
import { TestingComponent } from './pages/testing/testing.component';
import { TokensComponent } from './pages/token-tools/tokens/tokens.component';
import { GuardsGuard } from './guards.guard';
import { AdminhomeComponent } from './pages/admin/adminhome/adminhome.component';
import { LogsComponent } from './pages/logs/logs.component';
import { LoginModule } from './pages/login/login.module';
import { BypassComponent } from './pages/bypass/bypass.component';
import { GetSystemTokensComponent } from './pages/token-tools/get-system-tokens/get-system-tokens.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import {DiagnosticsComponent} from './pages/diagnostics/diagnostics.component';
import { TokenToolsComponent } from './pages/token-tools/token-tools.component';
import { MyTokensComponent } from './pages/my-tokens/my-tokens.component';
import { TokenAdminComponent } from './pages/token-admin/token-admin.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MapSelectorComponent } from './components/map-selector/map-selector.component';
import { AlarmsComponent } from './pages/alarms/alarms.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SiteManagementComponent } from './pages/site-management/site-management.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { PasswordUpdateComponent } from './components/password-update/password-update.component';
import { ManageSubcontractorsComponent } from './pages/manage-subcontractors/manage-subcontractors.component';
import { ManageHandoversComponent } from './pages/manage-handovers/manage-handovers.component';
import { AltDashboardComponent } from './pages/alt-dashboard/alt-dashboard.component';
import { SchedulesComponent } from './components/schedules/schedules.component';
import { AlarmHistoryComponent } from './pages/alarm-history/alarm-history.component';
import { ConfigTemplatesComponent } from './pages/config-templates/config-templates.component';
import { HandoverRequestsComponent } from './pages/handover-requests/handover-requests.component';
import { SiteReportsComponent } from './pages/site-reports/site-reports.component';
import { CoverageMapComponent } from './components/coverage-map/coverage-map.component';
import { KeyRequestsComponent } from './pages/key-requests/key-requests.component';
import { BlockedUsersComponent } from './pages/blocked-users/blocked-users.component';
import { MegaMenuComponent } from './landing-page/mega-menu/mega-menu.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m=>m.LoginModule)
  },
  
  {
    path: '',
    component: SidenavnewComponent,
    // loadChildren: () => import('./components/sidenavnew/sidenavnew.module').then(m=>m.SidenavnewModule)
    children: [
      // {
      //   path: '',
      //   component: HomeComponent
      // },
      // {
      //   path: '',
      //   component: LandingPageComponent
      // },
      {
        path: '',
        component: MegaMenuComponent
      },
      {
        path: 'page-one',
        component: HomeComponent
      },
      {
        path: 'page-two',
        component: BluetoothComponent
      },
      {
        path: 'page-three',
        component: LoginComponent
      },
      {
        path: 'map',
        component: MapSelectorComponent
      },
      {
        path: 'coverage-map',
        component: CoverageMapComponent
      },
      {
        path: 'testing',
        component: TestingComponent,
        loadChildren: () => import('./pages/testing/testing.module').then(m=>m.TestingModule)
      },
      {
        path: 'generate-tokens',
        component: TokensComponent,
        loadChildren: () => import('./pages/token-tools/tokens/tokens.module').then(m=>m.TokensModule)
      },
      {
        path: 'qr-scanner',
        component: QrScannerComponent
      },
      {
        path: 'get-system-tokens',
        component: GetSystemTokensComponent,
        loadChildren: () => import('./pages/token-tools/get-system-tokens/get-system-tokens.module').then(m=>m.GetSystemTokensModule)
      },
      {
        path: 'admin-home',
        loadChildren:()=>import('./pages/admin/adminhome/adminhome.module').then(m=>m.AdminhomeModule),
        component: AdminhomeComponent,
        // canActivate:[GuardsGuard]
      },
      {
        path: 'logs',
        component: LogsComponent,
        loadChildren:()=>import('./pages/logs/logs.module').then(m=>m.LogsModule)
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        // loadChildren:()=>import('./pages/logs/logs.module').then(m=>m.LogsModule)
      },
      {
        path: 'bypass',
        component: BypassComponent,
        loadChildren:()=>import('./pages/bypass/bypass.module').then(m=>m.BypassModule)
      },
      {
        path: 'diagnostic',
        component:DiagnosticsComponent,
        loadChildren:()=>import('./pages/diagnostics/diagnostics.module').then(m=>m.DiagnosticsModule)
      },
      {
        path: 'token-tools',
        // component: TokenToolsComponent,
        component: TokenAdminComponent,
        loadChildren:()=>import('./pages/token-tools/token-tools.module').then(m=>m.TokenToolsModule)
      },
      {
        path: 'my-tokens',
        component: MyTokensComponent,
      },
      {
        path: 'alarms',
        component: AlarmsComponent,
        loadChildren:()=>import('./pages/alarms/alarms.module').then(m=>m.AlarmsModule)
      },
      {
        path: 'alarms-history',
        component: AlarmHistoryComponent,
        // loadChildren:()=>import('./pages/alarms/alarms.module').then(m=>m.AlarmsModule)
      },
      {
        path: 'site-management',
        component: SiteManagementComponent
      },
      {
        path: 'user-management',
        component: UserManagementComponent
      },
      {
        path:'change-password',
        component: PasswordUpdateComponent
      },
      {
        path: 'subcontractor-management',
        component: ManageSubcontractorsComponent
      },
      {
        path: 'handover-management',
        component: ManageHandoversComponent
      },
      {
        path: 'prism-dashboard',
        component: AltDashboardComponent
      },
      {
        path: 'schedules',
        component: SchedulesComponent
      },
      {
        path: 'config-templates',
        component: ConfigTemplatesComponent
      },
      {
        path:'handover-requests',
        component: HandoverRequestsComponent
      },
      {
        path: 'site-reports',
        component: SiteReportsComponent
      },
      {
        path: 'key-requests',
        component: KeyRequestsComponent
      },
      {
        path: 'blocked-users',
        component: BlockedUsersComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), SidenavnewModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
