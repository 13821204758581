import { TemplateBindingParseResult } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-site-users',
  templateUrl: './site-users.component.html',
  styleUrls: ['./site-users.component.scss']
})
export class SiteUsersComponent implements OnInit {

  @Input() site = null;
  @Input() allUsers: any[] = [];
  @Output() close = new EventEmitter();
  usersOfSite = [];
  filteredUsersOfSite = [];
  notUsersOfSite = [];
  filteredNotUsersOfSite = [];
  usersFilter ="";
  nonUsersFilter = "";

  constructor(private webReq: WebRequestsService) { }

  ngOnInit(): void {
    this.getUsersOfSite()
  }

  userOfSite(user: any) {
    if (user.sites != null && user.sites != undefined) {
      if (user.sites?.filter((x: any) => x._id == this.site._id && x._id != undefined).length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getUsersOfSite() {
    // console.log("")
    let tempList = [];
    let nonList = [];
    tempList = this.allUsers.filter(user =>  user.sites?.some(site => site._id == this.site._id))
    nonList = this.allUsers.filter(user =>  !user.sites?.some(site => site._id == this.site._id))
    this.usersOfSite = tempList;
    this.filteredUsersOfSite = tempList;
    this.notUsersOfSite = nonList;
    this.filteredNotUsersOfSite = nonList;
    // console.log(this.usersOfSite)
  }

  removeUserFromSite(user:any) {
    this.webReq.removeUserFromSite(this.site.siteID, this.site.siteName, user.email).subscribe(result => {
      if(result.success == true) {
        this.notUsersOfSite.push(user);
        this.filteredNotUsersOfSite = this.notUsersOfSite;
        this.usersOfSite = this.usersOfSite.filter(myuser => myuser._id != user._id);
        this.filteredUsersOfSite = this.usersOfSite;
      }
    })
  }

  addUserToSite(user:any) {
    this.webReq.addUserToSite(user.email,this.site).subscribe(result => {
      if(result.success == true) {
        this.usersOfSite.push(user);
        this.filteredUsersOfSite = this.usersOfSite;
        this.notUsersOfSite = this.notUsersOfSite.filter(myuser => myuser._id != user._id);
        this.filteredNotUsersOfSite = this.notUsersOfSite;
      }
    })
  }

  closing() {
    this.close.emit();
  }

  applyUserFilter(event: Event) {
    const usersFilter = (event.target as HTMLInputElement).value;
    this.filteredUsersOfSite = this.usersOfSite.filter(user => user.username.toLowerCase().includes(usersFilter.toLowerCase()) || user.email.toLowerCase().includes(usersFilter.toLocaleLowerCase()));
  }

  applyNonUserFilter(event: Event) {
    const usersFilter = (event.target as HTMLInputElement).value;
    this.filteredNotUsersOfSite = this.notUsersOfSite.filter(user => user.username.toLowerCase().includes(usersFilter.toLowerCase()) || user.email.toLowerCase().includes(usersFilter.toLocaleLowerCase()));
  }

}
