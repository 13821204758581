import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import *  as XLSX_Node from 'node-xlsx';
// import * as XLSX from 'xlsx';
// import * as XLSX from 'ts-xlsx';
// import * as fs from 'fs';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-batch-imports',
  templateUrl: './batch-imports.component.html',
  styleUrls: ['./batch-imports.component.scss']
})
export class BatchImportsComponent implements OnInit {
  // @Input() selectedUserGroup: any = {};
  // @Input() allUsers: any = [];
  @Output() close = new EventEmitter();

  arrayBuffer: any;
  file: any;
  fileName: any;
  usersToAdd: any = [];
  userObjectsToAdd: any = [];
  addingComplete: boolean = false;

  firstRow = 0;
  nameIndex = 0;
  surnameIndex = 1;
  cellphoneIndex = 2;
  emailIndex = 3;
  contractorIndex = 4;


  successes = 0;
  failures = 0;

  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
    console.log(this.usersToAdd)
  }

  closing() {
    this.close.emit();
  }

  checkEvent(event: any) {
    console.log(event.target.files[0].name);
    this.fileName = event.target.files[0].name;
    let fileReader = new FileReader();

    fileReader.readAsText(event.target.files[0]);
    fileReader.onload = (e) => {
      // debugger
      let csv: any = fileReader.result;
      let allTextLines = [];
      allTextLines = csv.split(/\r|\n|\r/);
      console.log(allTextLines)
      for (let i = this.firstRow; i <= allTextLines.length; i++) {

        if (allTextLines[i] != undefined) {
          if (allTextLines[i].length > 0) {
            let userObjArr = allTextLines[i].split(';');
            if (userObjArr[this.emailIndex] != '' && userObjArr[this.emailIndex] != undefined) {
              this.usersToAdd.push({
                username: userObjArr[this.emailIndex],
                name: userObjArr[this.nameIndex],
                surname: userObjArr[this.surnameIndex],
                email: userObjArr[this.emailIndex],
                subcontractor: userObjArr[this.contractorIndex],
                // isAdmin: userObjArr[2] == 'y' ? true : false,
                // generateTokens: userObjArr[3],
                cellphone: userObjArr[this.cellphoneIndex],
              });
            }
          }
        }


      }
    }

  }

  AddUsers() {

    this.usersToAdd.forEach((user: any) => {
      var userObj = {
        username: user.username,
        email: user.email,
        isAdmin: user.admin == 'y' ? true : false,
        generateTokens: user.generate_tokens,
        cellphone: user.cellphone,
        clientdb: localStorage.getItem('clientdb')
      }
      this.userObjectsToAdd.push(userObj);
    });
    console.log(this.userObjectsToAdd)

    var addPromises: Promise<any>[] = [];
    debugger
    this.userObjectsToAdd.forEach((userObj: any) => {
      addPromises.push(this.webReq.addUser(userObj).toPromise());
    });

    Promise.all(addPromises).then((values: any) => {
      console.log(values);
      values.forEach((result: any) => {
        if (result.success == true) {
          this.successes++;
        } else {
          this.failures++;
        }
      });
      this.addingComplete = true;
    });

  }

}
