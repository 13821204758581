import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit {

  public canApprove = false;
  public isAdmin = false;

  constructor(public notifications: NotificationsService) { }

  ngOnInit(): void {
    let canApproveObj = localStorage.getItem('canApproveUsers');
    let isAdminObj = localStorage.getItem('admin');
    if(canApproveObj == 'true') {
      this.canApprove = true
    }
    if(isAdminObj == 'true') {
      this.isAdmin = true;
    }

  }

}
