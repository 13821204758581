import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebRequestsService } from 'src/app/services/web-requests.service';
// import * as ftpClient from 'ftp-client';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-site-reports',
  templateUrl: './site-reports.component.html',
  styleUrls: ['./site-reports.component.scss']
})
export class SiteReportsComponent implements OnInit {

  directories: any[] = [];
  directoriesMain: any[] =[];
  selectedPDF: any;
  myBlob: Blob;
  pdfUrl: any;
  pdfSet = false;
  iframeSrc:any = "";
  showIframe = false;
  isLoading = false;

  sites:any[] = [];

  siteFilter = "";
  fromFilter: any = new Date(null);
  toFilter:any = new Date();

  constructor(public webReq: WebRequestsService, private sanitizer: DomSanitizer) { }

  async ngOnInit() {
    this.isLoading=true;
    await this.getReports();
    await this.getSites();
  }

  applyFilter(event:any) {
    this.directories = this.directoriesMain.filter(x => x.folder.toLowerCase().includes(this.siteFilter.toLowerCase()) || x.siteName.toLowerCase().includes(this.siteFilter.toLocaleLowerCase()))
  }

  applyDateFilter(event:any) {
    // console.log("Apply Date Filter now")
  }

  searchByDates() {
    let newDirList = [];
    
    this.directories.forEach(element => {
      let addObj:any = {};
        
      addObj.folder = element.folder;
        addObj.files = [];
        addObj.siteName = element.siteName;
        element.files.forEach(filename => {
    
          let fileDate = new Date(filename.substring(0, filename.length - 4))
          if(fileDate >= new Date(this.fromFilter) && fileDate <= new Date(this.toFilter)) {
            addObj.files.push(filename);
          }
        });        
        if(addObj.files.length > 0) {
          newDirList.push(addObj)
        }
    });

    this.directories = newDirList;
  }

  async resetData() {
    await this.getReports();
    await this.getSites();
  }

  async getReports() {
    try{
      this.directories = await this.webReq.getSiteReports().toPromise();
      this.directoriesMain = this.directories;
      // console.log(this.directories)
      this.isLoading = false;
      return;
    }
    catch(err) {
      this.isLoading =false;
      return;
    }
  }

  async getSites() {
    try {
      console.log("Directories to work through: " + this.directories.length)
      this.sites = await this.webReq.getSitesAll().toPromise();
      console.log("Sites to scan: " + this.sites.length)
      this.directories.forEach(dir => {
        // debugger
        dir.siteName = this.sites.filter(x => x.siteID == dir.folder)[0].siteName;
      
       })
       console.log(this.directories);
       return
    }
    catch(err) {
      return
    }
  //  this.sites = await this.webReq.getSitesAll().toPromise();
  //  this.directories.forEach(dir => {
  //   dir.sitename = this.sites.filter(x => x.siteID == dir.siteID)[0].siteID;
  //  })
  //  console.log(this.directories)
  }
  async viewReport(path) {
    this.isLoading=true;
    // console.log(path);
    this.webReq.downloadReport(localStorage.getItem('clientdb') +'/'+ path).subscribe(reports => {
      const blob = new Blob([reports], { type: 'application/pdf' });
      this.myBlob = blob;
      let blobUrl: string = window.URL.createObjectURL(blob);
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      this.showIframe = true
      this.isLoading = false;          
    })
  }
  async downloadReport(path) {
    console.warn("Called Download")
    console.log(path);
    this.webReq.downloadReport(localStorage.getItem('clientdb') +'/'+ path).subscribe(reports => {
      const blob = new Blob([reports], { type: 'application/octet-stream' });
      this.myBlob = blob;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      let fileName = path.split('/')[1].replaceAll(":", "_")
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    })
  }
}
