import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-blocked-users',
  templateUrl: './blocked-users.component.html',
  styleUrls: ['./blocked-users.component.scss']
})
export class BlockedUsersComponent implements OnInit {

  dataSource = new MatTableDataSource();
  displayedColumns = ['id','email','username','name','surname', 'idNumber', 'cellphone', 'blockedReason', 'details','unlock', 'reject']
  users:any[] = [];
  selectedUser = null;
  rejectingUser = null;
  rejectReason = "";
  viewUser = null;
  addingComment = false;
  currentComment = "";

  public canApprove = false;

  filterValue = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
    let canApproveObj = localStorage.getItem('canApproveUsers');
    if(canApproveObj == 'true') {
      this.canApprove = true
    }
    if(this.canApprove) {
      this.displayedColumns = ['email','username','name','surname', 'idNumber', 'cellphone', 'blockedReason', 'details','unlock', 'reject']
    } else {
      this.displayedColumns = ['email','username','name','surname', 'idNumber', 'cellphone', 'blockedReason', 'details']

    }


    this.getBlockedUsers();
  }

  getBlockedUsers() {
    this.webReq.getBlockedUsers().subscribe(res => {
      console.log(res)
      this.users = res;
      this.dataSource.data = this.users;
      this.dataSource.paginator = this.paginator;
      if(this.viewUser) {
        this.viewUser = this.users.filter(x => x.email == this.viewUser.email)[0];
      }
    })
  }

  
  unblockUser() {
    this.webReq.unblockUser(this.selectedUser).subscribe(res => {
      if(res.success != undefined && res.success == false) {
        this.selectedUser = null;
        this.notify.openMessage(res.message, 'error', 5);
      } else {
        this.selectedUser = null;
        this.getBlockedUsers();
        this.notify.openMessage(res.message, 'success', 5);
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.data = this.users.filter((x: any) => x.username.toLowerCase().includes(filterValue.toLowerCase()) || 
    x.email.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.cellphone.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.subcontractor.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.name.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.surname.toLowerCase().includes(filterValue.toLowerCase()) );
  }

  addComment() {
    this.webReq.addBlockedComment(this.viewUser, this.currentComment).subscribe(result => {
      this.notify.openMessage(result.success == true ? 'Comment Added' : result.message, result.success == true ? 'success' : 'error', 3);
      if(result.success) {
        this.getBlockedUsers()
        this.addingComment = false;
      }
    })
  }

  revokeUser() {
    this.rejectingUser.rejectReason = this.rejectReason;
    this.webReq.revokeUser(this.rejectingUser).subscribe(res => {
      console.log(res)
      this.notify.openMessage(res.success == true ? 'User Revoked' : res.message, res.success == true ? 'success' : 'error', 3);
      if(res.success) {
        this.getBlockedUsers();
        this.rejectingUser = null;
      }
    })
  }
}
