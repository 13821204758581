<!-- <div class="myHeader">
    <mat-icon>notification_important</mat-icon> Alarm History
  </div> -->
<div fxLayout="row" fxLayoutAlign="space-between start" style="height: 80vh">
<!-- 
    <div style="position: absolute; top: 15vh; width: 100vw; height: 80vh; max-height: 72vh; overflow-y: scroll" >
        <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSourceCurrent" class="mat-elevation-z8" style="width: 100%;">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>
                    ID
                </th>
                <td mat-cell *matCellDef="let element">{{element.alarmUUID}}</td>
            </ng-container>

            <ng-container matColumnDef="priority">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by alarm priority"> Priority </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': getPriorityColor(element.priority), 'border':'1px solid white'}" >
                    {{element.priority}}
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by alarm type"> Type </th>
                <td mat-cell *matCellDef="let element">{{element.alarmType}}</td>
            </ng-container>

            <ng-container matColumnDef="siteID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by site ID"> Site ID </th>
                <td mat-cell *matCellDef="let element">{{element.siteID}}</td>
            </ng-container>

            <ng-container matColumnDef="siteName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by site name"> Site Name </th>
                <td mat-cell *matCellDef="let element">{{element.siteName}}</td>
            </ng-container>

            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by time"> Time </th>
                <td mat-cell *matCellDef="let element">{{element.alarmTime | date:"dd/MM/yy HH:mm"}}</td>
            </ng-container>

            <ng-container matColumnDef="acknowledged">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by acknowledged"> Acknowledged </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="element.acknowledged == 1" style="color: lime">check</mat-icon>
                    <mat-icon *ngIf="element.acknowledged == 0" style="color: red;">close</mat-icon>
               
                </td>
            </ng-container>

            <ng-container matColumnDef="acknowledgedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by acknowledgedby"> Acknowledged By </th>
                <td mat-cell *matCellDef="let element">{{element.acknowledgedBy}}</td>
            </ng-container>

            <ng-container matColumnDef="acknowledgedTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by acknowledged time"> Acknowledged Time </th>
                <td mat-cell *matCellDef="let element">{{element.acknowledgedTime | date:"dd/MM/yy HH:mm"}}</td>
            </ng-container>


            <ng-container matColumnDef="resolvedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by resolvedby"> Resolved By </th>
                <td mat-cell *matCellDef="let element">{{element.resolvedBy}}</td>
            </ng-container>

            
            <ng-container matColumnDef="resolvedComment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by resolveComment"> Comment </th>
                <td mat-cell *matCellDef="let element">{{element.resolvedComment}}</td>
            </ng-container>


            <ng-container matColumnDef="resolvedTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by resolved time"> Resolved Time </th>
                <td mat-cell *matCellDef="let element">{{element.resolvedTime | date:"dd/MM/yy HH:mm"}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsCurrent"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCurrent;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20, 30, 50]" [pageSize]="10" showFirstLastButtons aria-label="Select page of alarms" style="position:fixed;bottom: 6vh; width: 100%;">
        </mat-paginator>

    </div>
         -->
   
         <iframe width="100%" height="100%" [src]="reportUrl" frameborder="1" style="border:1" allowfullscreen></iframe>
</div>