import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-add-user-group',
  templateUrl: './add-user-group.component.html',
  styleUrls: ['./add-user-group.component.scss']
})
export class AddUserGroupComponent implements OnInit {
  @Input() selectedUserGroup: any = {};
  @Output() close = new EventEmitter();
  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
  }

  addUserGroup() {
    this.webReq.addUserGroup(this.selectedUserGroup.groupName).subscribe((result:any) => {
      this.notify.openMessage(result.message, result.success == true ? 'success' : 'error', 3000);
      this.close.emit();
    });
  }

  closing() {
    this.close.emit();
  }

}
