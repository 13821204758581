import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-user-sites',
  templateUrl: './edit-user-sites.component.html',
  styleUrls: ['./edit-user-sites.component.scss']
})
export class EditUserSitesComponent implements OnInit {

  @Output() close = new EventEmitter();

  @Output() savedSites = new EventEmitter();
  @Input() siteList: any = [];
  @Input() activeSites: any = [];

  selectedActive:any[] = [];
  selectedInactive:any[] = [];

  constructor() { }

  ngOnInit(): void {
    let tempList:any[] = [];
    this.siteList.forEach((allSite:any) => {
      let found = false;
      if(this.activeSites != undefined){
        this.activeSites.forEach((site:any) => {
          if(site._id==allSite._id) {
            found = true;
          }
        })
      } else {
        this.activeSites = [];
      }
      
      if(!found) {
        tempList.push(allSite);
      }
    });
    this.siteList = tempList;
  }

  addSites() {

  }

  checkCurrentSite(mySite: any, value: any) {
    if(value == true) { 
      this.selectedActive.push(mySite)
    }
    if(value == false) {
      let tempList:any[] = [];
      this.selectedActive.forEach(site => {
        if (site != mySite) {
          tempList.push(site);
        }
      });
      this.selectedActive = tempList;
    }

    console.log(this.selectedActive)
  }

  checkNonCurrentSite(mySite:any, value:any) {
    if(value == true) { 
      this.selectedInactive.push(mySite)
    }
    if(value == false) {
      let tempList:any[] = [];
      this.selectedInactive.forEach(site => {
        if (site != mySite) {
          tempList.push(site);
        }
      });
      this.selectedInactive = tempList;
    }

    console.log(this.selectedInactive)
  }

  addActiveSites() {
    this.selectedInactive.forEach((site:any)=> {
      this.activeSites.push(site)
    })
    // this.activeSites.push(this.selectedInactive);
    let tempInactive:any[] = [];
    this.siteList.forEach((allSite:any) => {
      let found = false;
      this.activeSites.forEach((site:any) => {
        if(site._id==allSite._id) {
          found = true
        }
      });
      if(!found) {
        tempInactive.push(allSite);
      }
    });
    this.siteList = tempInactive;
    this.selectedInactive = [];
  }

  removeActiveSites() {
    this.selectedActive.forEach((site:any) => {
      this.siteList.push(site)
    })
    // this.siteList.push(this.selectedActive);
    let tempActive:any[]=[];
    this.activeSites.forEach((aSite:any) => {
      let found = false;
      this.siteList.forEach((iSite:any) => {
        if(aSite._id == iSite._id) {
          found = true;
        }
      });
      if(!found) {
        tempActive.push(aSite);
      }
    });
    this.activeSites = tempActive;
    this.selectedActive = []
  }

  save() {
    this.savedSites.emit(this.activeSites);
  }

  closing() {
    this.close.emit();
  }
}
