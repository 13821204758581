<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <app-loader></app-loader>
    <div style="height: 75vh; width: 100vw;" fxLayoutAlign="start center" fxLayout="column">
        <button mat-mini-fab (click)="closeGenerator()" color="accent" style="position: absolute; top: 8vh; left: 8px;">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-stepper orientation="horizontal" linear=true style="background-color: transparent;">

            <mat-step *ngIf="!isLoggedIn">
                <ng-template matStepLabel>Enter user details</ng-template>
                <div fxLayout="column" fxLayoutAlign="space-evenly center">
                    <div *ngIf="!isLoggedIn">
                        <mat-form-field appearance="outline">
                            <mat-label>Username</mat-label>
                            <input [(ngModel)]="email" matInput placeholder="username" required>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Password</mat-label>
                            <input matInput [(ngModel)]="password" placeholder="password" type="password" required>
                        </mat-form-field>
                        <div>
                            <button mat-raised-button (click)="login(email, password)" color="primary">Confirm</button>
                        </div>
                    </div>
                    <div *ngIf="isLoggedIn">
                        <button mat-raised-button class="nextButton" matStepperNext (click)="step = 2;" color="primary">Next</button>
                    </div>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Select Site</ng-template>
                <div fxLayout="column" fxLayoutAlign="space-evenly center">
                    <mat-form-field appearance="outline" *ngIf="sites.length > 0">
                        <mat-label>Site</mat-label>
                        <mat-select [(ngModel)]="selectedSite">
                            <mat-option *ngFor="let site of sites" [value]="site">
                                {{site.siteName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <button mat-raised-button class="nextButton" matStepperNext (click)="step = 3" color="primary">Next</button>
                    </div>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Valid From</ng-template>
                <div fxLayout="column" fxLayoutAlign="space-evenly center">
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="fromDate" [min]="minDate" type="datetime-local">
                    </mat-form-field>
                    <div>
                        <button mat-raised-button class="nextButton" matStepperNext color="primary">Next</button>
                    </div>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Valid To</ng-template>
                <div fxLayout="column" fxLayoutAlign="space-evenly center">
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="toDate" [min]="fromDate" type="datetime-local">
                    </mat-form-field>
                    <div>
                        <button mat-raised-button matStepperNext class="nextButton" (click)="getTokensNew()" color="primary">
                            <mat-icon>sms</mat-icon> Generate
                        </button>
                    </div>
                </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Confirm Tokens</ng-template>
                <!-- <div fxLayout="column" fxLayoutAlign="space-evenly center"> -->
                    <div style="top: 5vh; width: 100%;">
                        <div *ngIf="tokensSet" fxLayout="column" fxLayoutAlign="center center">
                            <mat-icon style="color: lightgreen; margin: 8px; ">check_circle</mat-icon>
                            <label style="margin: 8px;">Tokens loaded for sites: </label>
                            <ul>
                                <li>
                                    <label style="font-size:small">{{mySite}}</label>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="!tokensSet" fxLayout="column" fxLayoutAlign="center center">
                            <mat-icon style="color: red; margin: 8px;">highlight_off</mat-icon>
                            <label style="margin: 8px;">No new tokens loaded...</label>
                        </div>
                    </div>
                <!-- </div> -->
            </mat-step>

        </mat-stepper>
    </div>
</div>