<div class="myHeader">
    <mat-icon>lock</mat-icon> Locked Users
</div>
<div>

    <div class="filter-container">
        <mat-form-field>
            <mat-label>
                Filter <mat-icon>search</mat-icon>
            </mat-label>
            <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)">
        </mat-form-field>
    </div>

    <div style="position: absolute; top: 15vh; width: 100vw; height: 80vh; max-height: 72vh; overflow-y: scroll">
        <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>
                    ID
                </th>
                <td mat-cell *matCellDef="let element">{{element._id}}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>
                    Email
                </th>
                <td mat-cell *matCellDef="let element">{{element.email}}</td>
            </ng-container>

            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef>
                    Username
                </th>
                <td mat-cell *matCellDef="let element">{{element.username}}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    Name
                </th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef>
                    Surname
                </th>
                <td mat-cell *matCellDef="let element">{{element.surname}}</td>
            </ng-container>

            <ng-container matColumnDef="cellphone">
                <th mat-header-cell *matHeaderCellDef>
                    Cellphone
                </th>
                <td mat-cell *matCellDef="let element">{{element.cellphone}}</td>
            </ng-container>

            <ng-container matColumnDef="idNumber">
                <th mat-header-cell *matHeaderCellDef>
                    ID No.
                </th>
                <td mat-cell *matCellDef="let element">{{element.idNumber}}</td>
            </ng-container>

            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef>
                    View Details
                </th>
                <td mat-cell *matCellDef="let element"><mat-icon style="color: orange" (click)="viewUser = element">visibility</mat-icon></td>
            </ng-container>

            <ng-container matColumnDef="blockedReason">
                <th mat-header-cell *matHeaderCellDef>
                    Lock Reason
                </th>
                <td mat-cell *matCellDef="let element">{{element.blockedReason}}</td>
            </ng-container>

            <ng-container matColumnDef="reject">
                <th mat-header-cell *matHeaderCellDef>
                    Reject
                </th>
                <td mat-cell *matCellDef="let element"><button mat-mini-fab (click)="rejectingUser = element"><mat-icon
                            style="color: red">front_hand</mat-icon></button></td>
            </ng-container>

            <ng-container matColumnDef="unlock">
                <th mat-header-cell *matHeaderCellDef>
                    Unlock
                </th>
                <td mat-cell *matCellDef="let element" (click)="selectedUser = element"><button mat-mini-fab><mat-icon
                            style="color: lime">lock_open</mat-icon></button></td>
            </ng-container>




            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20, 30, 50]" [pageSize]="10" showFirstLastButtons
            aria-label="Select page of alarms" style="position:fixed;bottom: 6vh; width: 100%;">
        </mat-paginator>
    </div>

</div>

<!-- TODO -->

<!-- 
    Add Columns for user details, and button to unblock users if user canApproveUsers!!!
 -->
<div class="backdrop" *ngIf="selectedUser != null || rejectingUser != null || viewUser != null"></div>
<mat-card *ngIf="selectedUser != null"
    style="width: fit-content; height: fit-content; z-index: 3011; position: absolute; left:0; right: 0;top:0;bottom:0;margin:auto;border-radius:5px;border: 1px solid white">
    <h1>Unlock User</h1>
    <mat-card-content>
        <h2>Do you want to unlock the below user?</h2>
        <p>({{selectedUser.email}}) {{selectedUser.name}} {{selectedUser.surname}}</p>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="unblockUser()"><mat-icon>lock_open</mat-icon>Unlock</button>
        <button mat-raised-button color="warn" (click)="selectedUser = null;"><mat-icon>cancel</mat-icon>Cancel</button>
    </mat-card-actions>
</mat-card>

<mat-card *ngIf="rejectingUser != null"
    style="width: fit-content; height: fit-content; z-index: 3011; position: absolute; left:0; right: 0;top:0;bottom:0;margin:auto;border-radius:5px;border: 1px solid white">
    <h1>Reject User</h1>
    <mat-card-content>
        <h2>Please supply a reason for rejecting the following user:</h2>
        <p>({{rejectingUser.email}}) {{rejectingUser.name}} {{rejectingUser.surname}}</p>

        <mat-form-field appearance="outline">
            <mat-label>Reason</mat-label>
            <input matInput [(ngModel)]="rejectReason">
        </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="revokeUser()"><mat-icon>front_hand</mat-icon>Reject</button>
        <button mat-raised-button color="warn"
            (click)="rejectingUser = null;"><mat-icon>cancel</mat-icon>Cancel</button>
    </mat-card-actions>
</mat-card>

<mat-card *ngIf="viewUser != null"
    style="width: 50vw; height: fit-content; z-index: 3011; position: absolute; left:0; right: 0;top:0;bottom:0;margin:auto;border-radius:5px;border: 1px solid white">
    <h2>Locked User Details:</h2>
    <div fxLayout="row">
        <div fxLayout="column" style="margin: 8px; width: 45%" class="valuesContainer">
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Email:</mat-label>
                <mat-label class="labelValue">{{viewUser.email}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Name:</mat-label>
                <mat-label class="labelValue">{{viewUser.name}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Surname:</mat-label>
                <mat-label class="labelValue">{{viewUser.surname}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Cell No.:</mat-label>
                <mat-label class="labelValue">{{viewUser.cellphone}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>ID Number:</mat-label>
                <mat-label class="labelValue">{{viewUser.idNumber}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Sub Contractor:</mat-label>
                <mat-label class="labelValue">{{viewUser.subcontractor}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Can Configure Locks:</mat-label>
                <mat-label class="labelValue" >{{viewUser.canConfigure ? 'Yes' : 'No'}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Can Commission Sites:</mat-label>
                <mat-label class="labelValue">{{viewUser.canCommission ? 'Yes' : 'No'}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Can Perform Handovers:</mat-label>
                <mat-label class="labelValue">{{viewUser.canHandover ? 'Yes' : 'No'}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Can Generate Keys:</mat-label>
                <mat-label class="labelValue">{{viewUser.generateTokens ? 'Yes' : 'No'}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Locked Reason:</mat-label>
                <mat-label class="labelValue">{{viewUser.blockedReason}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Locked Date:</mat-label>
                <mat-label class="labelValue">{{viewUser.blockedDate | date: 'dd/MM/yyyy HH:mm:ss'}}</mat-label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label>Locked By:</mat-label>
                <mat-label class="labelValue">{{viewUser.blockedBy}}</mat-label>
            </div>
        </div>
        <div fxLayout="column" style="margin: 8px; width: 45%">
         
            <div class="commentsContainer" fxLayout="column" fxLayoutAlign="space-between start">
                <mat-label>Comments:</mat-label>
                <div *ngFor="let comment of viewUser.blockedComments">
                    <section>{{comment.commentDate | date: 'dd/MM/yyyy HH:mm:ss'}} - {{comment.commentBy}} - {{comment.comment}}</section>
                    <section> ========== </section>
                </div>
                <button mat-mini-fab (click)="addingComment = true" color="primary" *ngIf="canApprove"><mat-icon>add</mat-icon></button>

            </div>
        </div>
    </div>
    <button mat-mini-fab (click)="viewUser = null" color="warn" style="position: absolute; right: 8px; top: 8px;"><mat-icon>close</mat-icon></button>
   
</mat-card>

<mat-card cdkDrag *ngIf="addingComment" style="width: 40vw; height: fit-content; z-index: 3011; position: absolute; left:0; right: 0;top:0;bottom:0;margin:auto;border-radius:5px;border: 1px solid white">
    <h2>Add Comment</h2>
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Comment</mat-label>
        <textarea matInput [(ngModel)]="currentComment"></textarea>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <button mat-raised-button color="primary" (click)="addComment()"><mat-icon>add</mat-icon>Add Comment</button>
    <button mat-raised-button color="warn" (click)="addingComment = false;currentComment = ''"><mat-icon>cancel</mat-icon>Cancel</button>
    </div>
</mat-card>