<div class="myHeader">
  Site Management
</div>


<div class="reportButton" fxLayout="column" fxLayoutAlign="center center" matTooltip="Click to go to site reports"
  (click)="navToSiteReports()">
  <mat-icon>report</mat-icon>
</div>
<div class="historyButton" [ngClass]="checkDeletedOn()" fxLayout="column" fxLayoutAlign="center center"
  matTooltip="Click to show deleted sites" (click)="toggleDeleted()">
  <mat-icon>history</mat-icon>
</div>

<div class="filter-container">
  <mat-form-field>
    <mat-label>
      Filter <mat-icon>search</mat-icon>
    </mat-label>
    <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)">
  </mat-form-field>
</div>

<div class="table-container">

  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
    class="mat-elevation-z8" style="width: 100%">

    <ng-container matColumnDef="siteID">
      <th mat-header-cell mat-sort-header sortActionDescription="Sort by device ID" *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element"> {{element.siteID}} </td>
    </ng-container>

    <ng-container matColumnDef="serialnumber">
      <th mat-header-cell mat-sort-header sortActionDescription="Sort by serial number" *matHeaderCellDef> S/N </th>
      <td mat-cell *matCellDef="let element"> {{element.device.serialnumber}} </td>
    </ng-container>

    <ng-container matColumnDef="siteName">
      <th mat-header-cell mat-sort-header sortActionDescription="Sort by site name" *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.siteName}} </td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell mat-sort-header sortActionDescription="Sort by customer" *matHeaderCellDef> Customer </th>
      <td mat-cell *matCellDef="let element"> {{element.customer}} </td>
    </ng-container>


    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let element"> <mat-icon (click)="selectedSite = element">edit</mat-icon> </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef> Delete Forever </th>
      <td mat-cell *matCellDef="let element"> <mat-icon style="color: red;"
          (click)="tryDeletePerm(element)">delete</mat-icon> </td>
    </ng-container>

    <ng-container matColumnDef="restore">
      <th mat-header-cell *matHeaderCellDef> Restore Site </th>
      <td mat-cell *matCellDef="let element"> <mat-icon style="color: lime;"
          (click)="restoreSite(element)">settings_backup_restore</mat-icon> </td>
    </ng-container>

    <ng-container matColumnDef="linked">
      <th mat-header-cell mat-sort-header sortActionDescription="Sort by linked" *matHeaderCellDef> Linked </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.device != null && element.device != undefined && element.device.linked == true"
          style="color: lime;">radio_button_checked</mat-icon>
        <mat-icon *ngIf="element.device == null || element.device == undefined || element.device.linked != true"
          style="color: red;">radio_button_checked</mat-icon>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="getdisplayedColumns(); sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: getdisplayedColumns();" (click)="selectedSite = row"></tr>
  </table>


</div>

<button class="btn"
  style="position: absolute; bottom: 7vh; z-index: 3001; color: black; background-color: lime; left: 8px;"
  (click)="addingSite = true"><mat-icon>domain_add</mat-icon> Add Site</button>
<mat-paginator #myPaginator [length]="dataSource.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
  aria-label="Select page" style="position: absolute; bottom: 6vh; width: 100vw;">
</mat-paginator>

<div *ngIf="addingSite"
  style="position: absolute; width:fit-content; left: 0; right:0; margin-left:auto; margin-right: auto; top: 20vh; border:2px solid white; z-index: 3011;"
  cdkDrag>
  <app-add-site (close)="addingSite = false; getSites(); completeAddSite($event);"
    [configTemplates]="configTemplates"></app-add-site>
</div>


<!-- Edit Site Card -->

<div class="backdrop"
  *ngIf="selectedSite != null || addingSite || showDelPerm || selectedDelSite != null || editingSiteUsers"
  style="position: fixed; width: 100vw; height: 100vh; top: 0; z-index: 3004; background-color: rgba(100,100,100,0.5);">
</div>

<!-- <div class="backdrop" (click)="selectedSite = null; addingSite = false;" *ngIf="selectedSite != null || addingSite || showDelPerm || selectedDelSite != null || editingSiteUsers"
  style="position: fixed; width: 100vw; height: 100vh; top: 0; z-index: 3004; background-color: rgba(100,100,100,0.5);">
</div> -->

<mat-card class="editPanel" *ngIf="selectedSite != null" cdkDrag>
  <button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;" (click)="selectedSite = null">
    <mat-icon>close</mat-icon>
  </button>

  <mat-card-header cdkDragHandle>
    <mat-card-title>
      Site
    </mat-card-title>
  </mat-card-header>

  <div fxLayout="row" style="width: fit-content;">
    <div style="width: 300px; margin:4px">
      <div>
        <label>Details for Site:</label>
        <div fxLayout="column" *ngIf="selectedSite != {}">
          <!-- ID -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field style="width:90%" appearance="outline">
              <mat-label>ID</mat-label>
              <input matInput [(ngModel)]="selectedSite._id" disabled>
            </mat-form-field>
          </div>
          <!-- Site Name -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field style="width:90%" appearance="outline">
              <mat-label>Site Name</mat-label>
              <input matInput [(ngModel)]="selectedSite.siteName">
            </mat-form-field>
          </div>
          <!-- Site ID -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field style="width:90%" appearance="outline">
              <mat-label>Site ID</mat-label>
              <input matInput [(ngModel)]="selectedSite.siteID" disabled>
            </mat-form-field>
          </div>
          <!-- Config Template -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field style="width:90%" appearance="outline">
              <mat-label>Config Template</mat-label>
              <mat-select [(ngModel)]="selectedSite.configTemplate">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let template of configTemplates" [value]="template._id">{{template.name}} <mat-icon
                    style="position: absolute; right: 0;"
                    [ngStyle]="{'color': template.color}">trip_origin</mat-icon></mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- Secondary Controller -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-slide-toggle [(ngModel)]="selectedSite.secondaryController">Has Multiple Doors</mat-slide-toggle>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" *ngIf="selectedSite.secondaryController">
            <mat-form-field style="width:90%" appearance="outline">
              <mat-label>Secondary Controller Name(If Applicable)</mat-label>
              <input matInput [(ngModel)]="selectedSite.secondaryName">
            </mat-form-field>
          </div>
          <!-- Customer -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field style="width:90%" appearance="outline">
              <mat-label>Customer</mat-label>
              <input matInput [(ngModel)]="selectedSite.customer">
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field style="width:90%" appearance="outline"
              *ngIf="selectedSite.device != null && selectedSite.device != undefined && selectedSite.device.linked == true">
              <mat-label>Linked Device S/N</mat-label>
              <input matInput [(ngModel)]="selectedSite.device.serialnumber">
            </mat-form-field>
            <a style="color: blue; cursor: pointer;" (click)="isUnlinking = false; manualLinking = true"
              *ngIf="selectedSite.device == null || selectedSite.device == undefined || selectedSite.device.linked == false"><mat-icon>add_link</mat-icon>
              Link Device</a>
            <a style="color: blue; cursor: pointer;" (click)="isUnlinking = true; manualLinking = true"
              *ngIf="selectedSite.device != null && selectedSite.device != undefined && selectedSite.device.linked == true"><mat-icon>add_link</mat-icon>
              Unlink Device</a>
          </div>
        </div>


      </div>
    </div>

    <div fxLayout="column" style="margin:4px">
      <div>
        <label>Location Information:</label>

        <!-- Location -->
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field style="width:90%" appearance="outline" style="width: 100%">
            <mat-label>Location</mat-label>
            <input matInput [(ngModel)]="selectedSite.lat + ' ' + selectedSite.lon" disabled>
            <mat-icon (click)="editSiteLocation=true; confirmEditLoc();" matSuffix>edit</mat-icon>
          </mat-form-field>
        </div>
  
        <app-map-selector *ngIf="!editSiteLocation" [site]="selectedSite" (close)="editSiteLocation = false"
          [width]="'10vw'" [height]="'15vw'" [showButtons]="false"></app-map-selector>
      </div>
      

    </div>


    <div fxLayout="column" style="margin: 4px;">
     
      <!-- <h2 style="color: transparent">.</h2> -->
      <label>Users assigned to site:</label>
      

      <div fxLayout="row">

        <div class="editPanelMini" style="height: 20rem; overflow: auto; width: 250px">
          <div *ngFor="let user of users">
            <div *ngIf="userOfSite(user)">
              <div>{{user.username}}</div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>

        <button mat-mini-fab style="background-color: lime; color: black"
      (click)="editingSiteUsers = true"><mat-icon>edit</mat-icon></button>

      </div>
    



      <div fxLayout="column">
        <mat-checkbox [(ngModel)]="selectedSite.blocked"><mat-icon style="font-size: 24px">block</mat-icon> Block Site
          Access</mat-checkbox>
        <mat-form-field appearance="outline" *ngIf="selectedSite.blocked">
          <mat-label>Block Reason:</mat-label>
          <input matInput [(ngModel)]="selectedSite.blockReason">
        </mat-form-field>
      </div>

    </div>

  </div>
  <mat-card-actions fxLayout="row" fxLayoutAlign="end end">
    <button style="margin: 4px;" mat-raised-button color="warn" (click)="deleteSite()"
    [disabled]="selectedSite.siteID == 10000">
    <mat-icon>delete</mat-icon> Delete site
  </button>
  <button style="margin: 4px;" mat-raised-button color="primary" (click)="updateSite()">
    <mat-icon>save</mat-icon> Update Site
  </button>
  </mat-card-actions>
</mat-card>


<!-- Ask Manual Link -->

<div *ngIf="manualLinking"
  style="position: absolute; width:auto; left: 45vw; top: 40vh; width: 20vw; border:2px solid white; z-index: 3012;">
  <mat-card *ngIf="manualLinkingStep == 1">
    <button style="position: absolute; right: 4px; top: 4px" mat-mini-fab
      (click)="manualLinkingStep = 1; manualLinking = false"><mat-icon>close</mat-icon></button>
    <h2>Linking Process</h2>
    <p *ngIf="!isUnlinking">Do you wish to link device automatically?</p>
    <p *ngIf="isUnlinking">Do you wish to unlink device automatically?</p>
    <div fxLayout="row" fxLayoutAlign="space-evenly center">
      <button mat-raised-button color="primary" *ngIf="!isUnlinking"
        (click)="startLinkingDevice()"><mat-icon>bluetooth</mat-icon> Yes</button>
      <button mat-raised-button color="primary" *ngIf="isUnlinking"
        (click)="startUnlinkingDevice()"><mat-icon>bluetooth</mat-icon> Yes</button>
      <button mat-raised-button color="accent" (click)="manualLinkingStep = 2"><mat-icon>bluetooth_disabled</mat-icon>
        No</button>
    </div>
  </mat-card>

  <mat-card *ngIf="manualLinkingStep == 2">
    <button style="position: absolute; right: 4px; top: 4px;margin: 4px;" mat-mini-fab
      (click)="manualLinkingStep=1; manualLinking = false"><mat-icon>close</mat-icon></button>
    <h2>Device</h2>
    <div fxLayout="column" style="top: 24px">
      <mat-form-field appearance="outline" *ngIf="!isUnlinking">
        <mat-label>Linked Device S/N</mat-label>
        <input matInput [(ngModel)]="selectedSite.device.serialnumber">
      </mat-form-field>
      <button mat-raised-button (click)="manualLink()" *ngIf="!isUnlinking"
        color="primary"><mat-icon>link</mat-icon>Link</button>
      <button mat-raised-button (click)="manualLink()" *ngIf="isUnlinking"
        color="primary"><mat-icon>link_off</mat-icon>Unlink</button>
    </div>

  </mat-card>

</div>

<!-- Edit Site Location -->
<div *ngIf="editSiteLocation" class="mat-elevation-z16"
  style="z-index: 3012; position: absolute; left: 0; top: 10vh; right: 0; margin-left: auto; margin-right:auto;">
  <app-map-selector [site]="selectedSite" (close)="editSiteLocation = false"></app-map-selector>
</div>

<!-- Permanent Site Delete -->
<mat-card *ngIf="showDelPerm"
  style="width:30vw; height: fit-content; left:0; right: 0; top: 0; bottom: 0; margin:auto; border: 1px solid white; z-index: 3011"
  fxLayout="column" fxLayoutAlign="space-evenly center">
  <mat-card-header>
    <mat-card-title>
      Permanently Delete
    </mat-card-title>
  </mat-card-header>
  <div>
    <h2>Do you want to delete <strong style="color: lime">({{selectedDelSite.siteID}})
        {{selectedDelSite.siteName}}</strong> forever?</h2>
    <div fxLayout="row" fxLayoutAlign="space-evenly center">
      <button mat-raised-button color="warn" (click)="permDeleteSite()"
        style="width: 40%"><mat-icon>delete</mat-icon>Delete</button>
      <button mat-raised-button color="accent"
        (click)="showDelPerm = false; selectedSite = null; selectedDelSite = null"
        style="width: 40%"><mat-icon>cancel</mat-icon>Cancel</button>
    </div>
  </div>
</mat-card>

<div *ngIf="addedSite">
  <mat-card
    style="width:fit-content; height: fit-content; left:0; right: 0; top: 0; bottom: 0; margin:auto; border: 1px solid white; z-index: 3011">
    <mat-card-header>
      <mat-card-title>
        New Site Added
      </mat-card-title>
      <mat-card-subtitle>
        Add users to site?
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>Do you want to add users to the newly added site?</p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="addedSite = false; addUsersToNewSite();">Yes</button>
      <button mat-raised-button color="warn" (click)="addedSite = false; addedSiteDocID = null">No</button>
    </mat-card-actions>
  </mat-card>
</div>

<div *ngIf="editingSiteUsers">
  <mat-card
    style="width:fit-content; height: fit-content; left:0; right: 0; top: 0; bottom: 0; margin:auto; border: 1px solid white; z-index: 3011">
    <app-site-users [allUsers]="users" [site]="selectedSite" (close)="refreshSite()"></app-site-users>
  </mat-card>
</div>