import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-get-system-tokens',
  templateUrl: './get-system-tokens.component.html',
  styleUrls: ['./get-system-tokens.component.scss']
})
export class GetSystemTokensComponent implements OnInit {

  @Output() close = new EventEmitter();
  public user = {}
  public email = ""
  public password = ""

  public isLoggedIn: boolean = false;
  public tokensSet: boolean = false;
  public tokenSites: string[] = [];

  constructor(private webReq: WebRequestsService,
    private auth: AuthService,
    public loader: LoaderService,
    public snackBar: MatSnackBar,
    public notifier: NotificationsService) { }

  ngOnInit(): void {
    if (localStorage.getItem('isLoggedIn') == "true") {
      this.isLoggedIn = true;
    }
  }

  login(email: string, password: string) {
    this.loader.isLoading = true;
    this.user = {
      'username': this.email.toLowerCase(),
      'password': this.password
    }
    this.webReq.login(this.user).subscribe(data => {
      // console.log(data)
      if (data.loggedIn == true) {
        localStorage.setItem('username', this.email);
        // localStorage.setItem('password', this.password);
        localStorage.setItem('isLoggedIn', 'true');
        this.auth.isLoggedIn = true;
        this.isLoggedIn = true;
        this.loader.isLoading = false;
        // this.router.navigate(['sidenavnew/page-one'], {replaceUrl: true});
      }
    }, err => {
      this.loader.isLoading = false;
      this.snackBar.open('Invalid Credentials', 'Ok', { duration: 3000 })
    });
    // this.router.navigate(['sidenavnew'], {replaceUrl: true});
  }

  retrieveTokens() {
    // this.loader.isLoading = true;
    let tokenList;
    this.webReq.getSystemTokens(localStorage.getItem('username')).subscribe((res: any) => {
      tokenList = res.tokens;
      console.log(tokenList);
      tokenList.forEach((token:any) => {
        if(token.functionCode == 1) {
          localStorage.setItem("t_" + token.sitename + "_time",token.token);
        }
        if(token.functionCode == 0) {
          localStorage.setItem("t_" + token.sitename + "_" + token.opCode, token.token);
          console.log("Set token for " + token.sitename)
          this.tokenSites.push(token.sitename);
        }
        if(token.functionCode == 3) {
          localStorage.setItem("t_" + token.sitename + "_diag",token.token);
        }
        if(token.functionCode == 2) {
          localStorage.setItem("t_" + token.sitename + "_conf",token.token);
        }
      });
      localStorage.setItem("t_" + tokenList[0].sitename+"_from",tokenList[0].fromDate)
      localStorage.setItem("t_" + tokenList[0].sitename+"_to",tokenList[0].toDate)
      this.tokenSites = Array.from(new Set(this.tokenSites));
      console.log(this.tokenSites)
      if(tokenList.length > 0) {
        this.tokensSet = true;
      } else {
        this.tokensSet = false;
      }
      this.loader.isLoading = false;
    }, err => {
      this.notifier.openMessage('Connection error', 'error')
      this.loader.isLoading = false;
    });
  }

  closing() {
    this.close.emit();
  }
}

