<div id="container" fxFlex fxLayoutAlign="space-evenly center" fxLayout="column">


    <div>
        <!-- <button mat-raised-button color="primary" (click)="setMyLocation()">
            <mat-icon>my_location</mat-icon> My Location
        </button> -->
        <button *ngIf="showButtons" mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;"
        (click)="closing()">
        <mat-icon>close</mat-icon>
      </button>
        <div *ngIf="showButtons" fxLayout="column" class="mat-elevation-z16"
            style="border: 1px solid white; border-radius: 5px; padding: 8px; margin: 8px; width: 20vw;">

            <mat-form-field appearance="outline">
                <mat-label>Latitude</mat-label>
                <input matInput [(ngModel)]="selectedLocation.lat">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Longitude</mat-label>
                <input matInput [(ngModel)]="selectedLocation.lon">
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="space-evenly center">
                <button mat-mini-fab color="primary" (click)="setMyLocation()">
                    <mat-icon>my_location</mat-icon>
                </button>
                <button mat-raised-button color="primary" (click)="setChosenLocation()">
                    <mat-icon>where_to_vote</mat-icon>Set
                </button>
                <button mat-raised-button style="background-color: lightgreen; color:black" (click)="save()">
                    <mat-icon>save</mat-icon>Save
                </button>
            </div>
        </div>

        <div id="map" class="map" [style.width]="width" [style.height]="height"></div>

    </div>


</div>