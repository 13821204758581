<div class="myHeader">
    Handover Requests
  </div>

  <div class="historyButton" [ngClass]="checkHistoryOn()" fxLayout="column" fxLayoutAlign="center center" matTooltip="Click to show handover history" (click)="toggleHistory()">
    <mat-icon>history</mat-icon>
  </div>

  <div class="filter-container">
    <mat-form-field>
      <mat-label>
        Filter <mat-icon>search</mat-icon>
      </mat-label>
      <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>

<div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%" multiTemplateDataRows>
        <ng-container matColumnDef="siteID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by siteID">
                Site ID </th>
            <td mat-cell *matCellDef="let element">
                {{element.siteID}}
                <!-- {{element.email}} -->
            </td>
        </ng-container>
        <ng-container matColumnDef="siteName">
            <th mat-header-cell *matHeaderCellDef> Site Name </th>
            <td mat-cell *matCellDef="let element">
                {{element.siteName}}
                <!-- <mat-icon *ngIf="element.isAdmin" style="color: lime">check</mat-icon> -->
                <!-- <mat-icon *ngIf="!element.isAdmin" style="color: red">close</mat-icon> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="_requestedBy">
            <th mat-header-cell *matHeaderCellDef> Requested </th>
            <td mat-cell *matCellDef="let element">
                {{element._requestedBy}}
            </td>
        </ng-container>

        <ng-container matColumnDef="_requestedByEmail">
            <th mat-header-cell *matHeaderCellDef> Requested (Email) </th>
            <td mat-cell *matCellDef="let element">
                {{element._requestedByEmail}}
            </td>
        </ng-container>

        <ng-container matColumnDef="_handoverBy">
            <th mat-header-cell *matHeaderCellDef> Handover By </th>
            <td mat-cell *matCellDef="let element">
                {{element._handoverBy}}
            </td>
        </ng-container>

        <ng-container matColumnDef="_handoverByEmail">
            <th mat-header-cell *matHeaderCellDef> Handover By (Email)</th>
            <td mat-cell *matCellDef="let element">
                {{element._handoverByEmail}}
            </td>
        </ng-container>

        <ng-container matColumnDef="requestTime">
            <th mat-header-cell *matHeaderCellDef> Time </th>
            <td mat-cell *matCellDef="let element">
                {{element.requestTime | date:'dd/MM/yyyy-HH:mm'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="standdown">
            <th mat-header-cell *matHeaderCellDef> Stand Down Success </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.standdown == 1" style="color:lime">check</mat-icon>
                <mat-icon *ngIf="element.standdown == 0" style="color:red" class="closeFlash">close</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="sensor1">
            <th mat-header-cell *matHeaderCellDef> Sensor1 </th>
            <td mat-cell *matCellDef="let element">
                {{element.sensor1 == 'not connected' ? 'n/a' : element.sensor1}}
            </td>
        </ng-container>

        <ng-container matColumnDef="sensor2">
            <th mat-header-cell *matHeaderCellDef> Sensor2 </th>
            <td mat-cell *matCellDef="let element">
                {{element.sensor2 == 'not connected' ? 'n/a' : element.sensor2}}
            </td>
        </ng-container>

        <ng-container matColumnDef="sensor3">
            <th mat-header-cell *matHeaderCellDef> Sensor3 </th>
            <td mat-cell *matCellDef="let element">
                {{element.sensor3 == 'not connected' ? 'n/a' : element.sensor3}}
            </td>
        </ng-container>

        <ng-container matColumnDef="sensor4">
            <th mat-header-cell *matHeaderCellDef> Sensor4 </th>
            <td mat-cell *matCellDef="let element">
                {{element.sensor4 == 'not connected' ? 'n/a' : element.sensor4}}
            </td>
        </ng-container>

        <ng-container matColumnDef="actuator1">
            <th mat-header-cell *matHeaderCellDef> Actuator1 </th>
            <td mat-cell *matCellDef="let element">
                {{element.actuator1 == 'not connected' ? 'n/a' : element.actuator1}}
            </td>
        </ng-container>

        <ng-container matColumnDef="actuator2">
            <th mat-header-cell *matHeaderCellDef> Actuator2 </th>
            <td mat-cell *matCellDef="let element">
                {{element.actuator2 == 'not connected' ? 'n/a' : element.actuator2}}
            </td>
        </ng-container>

        <ng-container matColumnDef="report">
            <th mat-header-cell *matHeaderCellDef> Report </th>
            <td mat-cell *matCellDef="let element">
                <a (click)="viewReport(element)" style="color: lime; cursor: pointer;">View</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="handover">
            <th mat-header-cell *matHeaderCellDef> Handover </th>
            <td mat-cell *matCellDef="let element">
                <button mat-mini-fab
                    (click)="selectedHandover = element; isAdd=true; checkHO();"><mat-icon>handshake</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getdisplayedColumns(); sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: getdisplayedColumns();" [class]="getRowClass(row)" [class.example-expanded-row]="expandedElement === row"></tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->

    </table>



</div>
<mat-icon style="color:lime; position: fixed; bottom: 8vh; left: 1vw; z-index: 2002;" *ngIf="wsConnected == true">swap_horiz</mat-icon>
<mat-icon style="color:red; position: fixed; bottom: 8vh; left: 1vw; z-index: 2002;" *ngIf="wsConnected == false">swap_horiz</mat-icon>
<mat-paginator #myPaginator [length]="dataSource.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
aria-label="Select page" style="position: absolute; bottom: 6vh; width: 100vw;">
</mat-paginator>

<div class="backdrop" *ngIf="isAdd || isEdit"></div>

<mat-card
style="z-index:3011; position:absolute;left:0;right:0;top:0;bottom:0;margin:auto; height:fit-content; width: fit-content; border: 1px solid white;"
*ngIf="isAdd" fxLayout="column" fxLayoutAlign="space-evenly center">
<h2>Handover ({{selectedHandover.siteID}}) {{selectedHandover.siteName}} device to:</h2>

<div fxLayout="row" fxLayoutAlign="space-evenly start" style="width: 100%">
    <mat-form-field appearance="outline">
        <mat-label>Site Group</mat-label>
        <mat-select #mySelect (openedChange)="onSelectOpen($event)" [(ngModel)]="selectedSiteGroup">
            <mat-form-field>
                <input matInput placeholder="Filter" (keyup)="applyHOFilter($event)" [(ngModel)]="handoverFilter" style="color: white">
            </mat-form-field>
            <mat-option [value]="undefined">None</mat-option>
            <mat-option *ngFor="let group of siteGroups" [value]="group">({{group.groupID}})
                {{group.groupName}}</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-mini-fab style="background-color: lime" (click)="isEdit = true"><mat-icon>add</mat-icon></button>
</div>

<div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 100%;">
    <button mat-raised-button color="primary" style="width: 40%;" (click)="handover()" [disabled]="checkHOReady()">Handover</button>
    <button mat-raised-button color="warn" style="width: 40%;" (click)="isAdd = false;">Cancel</button>
</div>
</mat-card>


<mat-card
style="z-index:3011; position:absolute;left:0;right:0;top:0;bottom:0;margin:auto; height:fit-content; width: fit-content; border: 1px solid white;"
*ngIf="isEdit" fxLayout="column" fxLayoutAlign="space-evenly center">
<h2>Enter site group details</h2>

   <mat-form-field appearance="outline">
        <mat-label>Group ID (T-Number)</mat-label>
        <input matInput [(ngModel)]="newSiteGroupID" pattern="[0-9]">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Group Name</mat-label>
        <input matInput [(ngModel)]="newSiteGroupName">
    </mat-form-field>


<div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 100%">
    <button mat-raised-button color="primary" (click)="addSiteGroup();">Add</button>
    <button mat-raised-button color="warn" (click)="isEdit = false">Cancel</button>
</div>
</mat-card>
<app-loader *ngIf="loader.isLoading || isLoading"></app-loader>

<div class="backdrop" *ngIf="showIframe || isLoading"></div>
<iframe id="pdfViewer" [src]="iframeSrc" style="width: 70vw; height: 90vh;" zindex="3011" *ngIf="showIframe" ></iframe>
<button mat-mini-fab id="closeiFrame" style="z-index: 3012; position: absolute; right:10vw; top: 5vh" color="warn" *ngIf="showIframe" (click)="showIframe = false"><mat-icon>close</mat-icon></button>
