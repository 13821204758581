import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BrowserQRCodeReader } from '@zxing/browser';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})


export class QrScannerComponent implements OnInit, AfterViewInit {

  @ViewChild('scanner') scanner =  document.getElementById('scanner');
  @Output() close = new EventEmitter();
  public tokenSites: string[] = [];
  public tokensSet: boolean = false;
  public cameraOpen: boolean = false;

  constructor() {
    
   }

  ngOnInit(): void {
    
  }
  ngAfterViewInit(): void {
      
  }

  report(res: Event) {
    alert(res);
  }

  getBackCamera() {
  }

  scanSuccessHandler(event:any) {
    // console.log(event);
    let obj = JSON.parse(event)
    let tokenList;
    tokenList = obj.tokens;
    // console.log(tokenList);
    tokenList.forEach((token:any) => {
      if(token[2] == '1' && token[3] == '0'){
        // console.log("Setting time token: " + token[6])
        localStorage.setItem("t_" + token[5] + "_time", token[6]);
      } else if (token[2] == '3') {
        localStorage.setItem("t_" + token[5] + "_diag", token[6]);
      } else {
        // console.log("Setting function " + token[3] + " token: " + token[6])
        localStorage.setItem("t_" + token[5] + "_" + token[3], token[6]);
        this.tokenSites.push(token[5]);
      }
    });
    this.tokenSites = Array.from(new Set(this.tokenSites));

    if(tokenList.length > 0) {
      this.tokensSet = true;
    } else {
      this.tokensSet = false;
    }
    this.cameraOpen = false;
  }

  tryOpenFile() {
    var myFile = document.getElementById('fileInput')?.click();
  }

  processFile(file: any) {
    let fileReader = new FileReader();
    let imgUrl = fileReader.readAsDataURL(file.target.files[0]);
    let objUrl = URL.createObjectURL(file.target.files[0]);
    console.log(imgUrl)
    let codeReader = new BrowserQRCodeReader();
    codeReader.decodeFromImageUrl(objUrl).then(res => {
      // console.log(res);
    let obj = JSON.parse(res.getText())
    let tokenList;
    tokenList = obj.tokens;
    tokenList.forEach((token:any) => {
      if(token[2] == '1' && token[3] == '0'){
        // console.log("Setting time token: " + token[6])
        localStorage.setItem("t_" + token[5] + "_time", token[6]);
      }else if (token[2] == '3') {
        localStorage.setItem("t_" + token[5] + "_diag", token[6]);
      } else {
        // console.log("Setting function " + token[3] + " token: " + token[6])
        localStorage.setItem("t_" + token[5] + "_" + token[3], token[6]);
        this.tokenSites.push(token[5]);
      }
    });
    this.tokenSites = Array.from(new Set(this.tokenSites));

    if(tokenList.length > 0) {
      this.tokensSet = true;
    } else {
      this.tokensSet = false;
    }
    this.cameraOpen = false;
    });
  }

  closing() {
    this.close.emit();
  }

}
