<div fxLayout="column" fxLayoutAlign="center space-evenly" id="bigBox"
    style="width: 100vw; height: 84vh; position: absolute; top:8vh; z-index: 0;">
    
    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-bottom: auto; margin-top:auto">

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToDashboard()">
                <mat-icon fxLayoutAlign="center center">dashboard</mat-icon>
                Dashboard
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToAlarms()">
                <mat-icon fxLayoutAlign="center center" [matBadge]="notifications.currentAlarms" matBadgeColor="warn" [matBadgeHidden]="notifications.currentAlarms == 0">notification_important</mat-icon>
                Alarms
            </div>
        </div>


        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToVKeys()">
                <mat-icon fxLayoutAlign="center center">shield</mat-icon>
                v-Keys
            </div>
        </div>


       


    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:auto; margin-bottom:auto">

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToSites()">
                <mat-icon fxLayoutAlign="center center">domain</mat-icon>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <label>Manage</label><label>Sites</label>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToUsers()">
                <mat-icon fxLayoutAlign="center center">person</mat-icon>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <label>Manage</label><label>Users</label>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToKeyRequests()">
                <mat-icon fxLayoutAlign="center center">shield</mat-icon>

                <div fxLayout="column" fxLayoutAlign="center center">
                    <label>v-Key</label><label>Requests</label>
                </div>
            </div>
        </div>

   
    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:auto; margin-bottom:auto">
        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToHandovers()">
                <mat-icon fxLayoutAlign="center center">handshake</mat-icon>
                Handovers
            </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToHandoverReqs()">
              <mat-icon fxLayoutAlign="center center" [matBadge]="notifications.currentHandovers" matBadgeColor="warn" [matBadgeHidden]="notifications.currentHandovers == 0">handshake</mat-icon>
              <div fxLayout="column" fxLayoutAlign="center center">
              <label>Handover</label><label>Requests</label>
            </div>
          </div>
        </div>

        <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToSchedules()">
                  <mat-icon fxLayoutAlign="center center">schedule</mat-icon>
                  Schedules
                </div>
          </div>
   
    </div>

</div>

        <img class="bg-img" src="../../assets/prism.png">