import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-item-main',
  templateUrl: './menu-item-main.component.html',
  styleUrls: ['./menu-item-main.component.scss']
})
export class MenuItemMainComponent implements OnInit {
  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() description = '';
  @Input() link: string = '';

  @Output() close = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  navToLink() {
    if (this.link.includes('https://myprism.co.za')) {
      this.close.emit('');
      window.open(this.link, "_blank");
    } else {
      this.close.emit('');
      this.router.navigate(['/' + this.link]);
    }
  }



}
