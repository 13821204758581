import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLoggedIn = false;
  constructor() { }

  public isAuthenticatedAdmin() {
    var loggedin = localStorage.getItem('isLoggedIn')
    var admin = localStorage.getItem('admin')

    if(loggedin == 'true' && admin == 'true') {
      return true
    } else {
      return false
    }
  }
}
