import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CanvasJS } from '@canvasjs/angular-charts';
import Integer from '@zxing/library/esm/core/util/Integer';
import { CombineLatestSubscriber } from 'rxjs/internal/observable/combineLatest';
import { ExportServiceService } from 'src/app/services/export-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-alt-dashboard',
  templateUrl: './alt-dashboard.component.html',
  styleUrls: ['./alt-dashboard.component.scss']
})
export class AltDashboardComponent implements OnInit, AfterViewInit {

  sites = [];
  allTokens = [];
  systemTokens = [];
  filteredTokens = [];
  users = [];
  hoReady = false;
  rcReady = false;
  sitesStatus = [];
  reasonsData = [];
  schedulesData = [];
  opTimes = [];
  sacReady = false;
  optReady = false;
  busiestHours:any = "";
  busiestDay:any = "";
  statusList = [];
  sitesOpen = 0;
  sitesClosed = 0;
  sitesStoodDown = 0;
  siteStatusList = [];
  errorStates = 0;
  handedOverUnlinked = 0;
  linkedNotHandedOver = 0;
  unlinkedNotHandedOver = 0;

  currentStateSites = {
    state: "",
    sites: []
  }


  maintenanceChartOptions = {
    animationEnabled: true,
    title: {
      text: "Maintenance Schedules"
    },
  };
  handedOverChartOptions = {
    animationEnabled: true,
    backgroundColor: "transparent",
    title: {
      text: "Sites Handed Over",
      fontColor: "orange",
      fontFamily: "audiowide"
    },
    data: []
  };
  schedulesChartOptions = {
    animationEnabled: true,
    backgroundColor: "transparent",
    title: {
      text: "Scheduled Keys/Reasons",
      fontColor: "orange",
      fontFamily: "audiowide"
    },
    data: []
  };
  reasonsChartOptions = {
    animationEnabled: true,
    backgroundColor: "transparent",
    title: {
      text: "Site Access Reasons",
      fontColor: "orange",
      fontFamily: "audiowide"
    },
    axisY: {
      title: "Count",
      labelFontColor: "white"
    },
    axisX: {
      labelFontColor: "white"
    },
    data: []
  };

  siteAccessCountChartOptions = {
    animationEnabled: true,
    backgroundColor: "transparent",
    title: {
      text: "Site Access Count",
      fontColor: "orange",
      fontFamily: "audiowide"
    },
    data: []
  };

  showStatusTable = false;
  dataSourceCurrent =  new MatTableDataSource<any>();
  displayedColumns = ['id', 'type', 'siteID', 'siteName', 'time']
  displayedColumnsCurrent = ['id', 'siteID','site', 'action', 'time', 'user']

  @ViewChild('myPaginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('myPaginator') set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  
  constructor(public webReq: WebRequestsService, public loader: LoaderService, public exportService: ExportServiceService) { }

  ngAfterViewInit() {
    this.dataSourceCurrent.paginator = this.paginator;
    this.dataSourceCurrent.sort = this.sort;
  }

  ngOnInit(): void {
    this.loader.isLoading = true;
    this.getSites();
    this.getUsers();
    this.getAllTokens();
    // this.getReports();
    // this.getSiteStatus();
    this.dataSourceCurrent.paginator = this.paginator;
    this.dataSourceCurrent.sort = this.sort;
  }

  setDataSourceAttributes() {
    this.dataSourceCurrent.paginator = this.paginator;
    // this.dataSource.sort = this.sort;

    if (this.paginator) {
    }
  }

  getSites() {
    this.webReq.getSitesAll().subscribe(sites => {
      this.sites = sites;
      this.getReports();
      this.makeHandoverData();
      this.getSiteStatus();
      
      // this.loader.isLoading = false;
    })
  }


  filterTokens(tokens: any) {
    var tempArr: any = [];
    var tempUserList: any = [];
    tokens.forEach((token: any) => {
      tempArr.push({ username: token.username, fromDate: token.fromDate, toDate: token.toDate, sitename: token.sitename, reason: token.reason });
    });
    tempArr.forEach((item: any) => {
      let count = tempUserList.filter((x: any) => x.username == item.username && x.sitename == item.sitename && x.fromDate == item.fromDate && x.toDate == item.toDate).length;
      if (count == 0) {
        tempUserList.push(item);
      }
    });
    return tempUserList;
  }

  getUsers() {
    // this.loader.isLoading = true;
    this.webReq.getUsers().subscribe(users => {
      this.users = users;
    })
  }

  getAllTokens() {
    this.webReq.getSystemTokensByClient().subscribe(res => {
      this.allTokens = res.tokens;
      this.filteredTokens = this.filterTokens(this.allTokens);
    });
  }

  checkValidity(row: any): string {
    var currentTime = new Date().getTime();
    if (row.fromDate <= currentTime && row.toDate >= currentTime) {
      return "current";
    } else if (row.fromDate < currentTime && row.toDate < currentTime) {
      return "past";
    } else if (row.fromDate > currentTime) {
      return "future";
    } else {
      return "";
    }
  }

  makeHandoverData() {

    this.sites.forEach(site => {
      if(site.handedOver == true && site.device.linked != true) {
        this.handedOverUnlinked++;
      } else if(site.handedOver != true && site.device.linked == true) {
        this.linkedNotHandedOver++;
      } else if(site.handedOver != true && site.device.linked != true) {
        this.unlinkedNotHandedOver++;
      }
    })

    let dataIndex = [
      { y: 0, name: "Handed Over" },
      { y: 0, name: "Not Handed Over" },
      // { y: 0, name: "Handed Over - Not linked"},
      // { y: 0, name: "Not Handed Over - Not linked"},
      // { y: 0, name: "Not Handed Over - Linked"}
    ]
    this.sites.forEach(site => {
      if (site.handedOver == true) {
        dataIndex[0].y += 1;
      } else {
        dataIndex[1].y += 1;
      }
    })
    this.handedOverChartOptions.data = [{
      type: "pie",
      startAngle: -90,
      indexLabel: "{name}: {y}",
      indexLabelFontColor: "white",
      dataPoints: dataIndex
    }];
    this.hoReady = true;
  }

  getSiteStatus() {
    this.webReq.getSiteStatus(this.sites).subscribe(statusses => {
      this.sitesStatus = statusses;
    })
  }

  getReports() {
    this.webReq.getReports().subscribe(reports => {
      this.reasonsData = reports.reasons[0]
      this.schedulesData = reports.schedules[0]
      this.schedulesData = reports.siteAccessCount[0]
      this.opTimes = reports.operationalTimes[0]
      this.statusList = reports.statusses[0]
      this.getLastStatus();
      this.getBusiestDay();
      this.getBusiestHours();
      this.setReasonsData();
      this.setSchedulesData();
      this.setSiteAccessCountData();
      // this.setOpTimesData();
      this.loader.isLoading = false;
    })
  }

  setReasonsData() {
    let indexData = [];
    let i = 0;
    this.reasonsData.forEach(rd => {
      indexData.push({ y: rd.count, x: i, label: rd.reason });
      i++;
    });
    this.reasonsChartOptions.data = [{
      type: "column",
      showInLegend: true,
      legendMarkerType:"none",
      legendText: "Reason",
      indexLabel: "{y}",
      indexLabelFontColor: "white",
      labelFontColor: "white",
      dataPoints: indexData
    }]
    this.rcReady = true;
  }

  setSchedulesData() {
    let indexData = [];
    this.schedulesData.forEach(rd => {
      indexData.push({y:rd.count, name: rd.reason})
    });
    this.schedulesChartOptions.data = [{
      type: "pie",
      startAngle: -90,
      indexLabel: "{name}: {y}",
      indexLabelFontColor: "white",
      dataPoints: indexData
    }]
    this.rcReady = true;
  }

  setSiteAccessCountData() {
    let indexData = [];
    if(this.schedulesData != undefined) {
      this.schedulesData.forEach(rd => {
        indexData.push({ y: rd.count, name: "(" + rd.siteID + ") " })
      });
    }
    this.siteAccessCountChartOptions.data = [{
      type: "pie",
      startAngle: -90,
      indexLabel: "{name}",
      indexLabelFontColor: "white",
      dataPoints: indexData
    }]

    this.sacReady = true;
  }

  getBusiestDay() {
    let days = [{day: "Monday", count: "0"}, {day: "Tuesday", count: "0"}, {day: "Wednesday", count: "0"}, {day: "Thursday", count: "0"}, {day: "Friday", count: "0"},{day: "Saturday", count: "0"},{day: "Sunday", count: "0"}]
    this.opTimes.forEach(opTime => {
      days.forEach(day => {
        if(opTime.Day == day.day) {
          day.count += 1;
        }
      });
    });
    let highest = days[0];
    days.forEach(day => {
      if(day.count > highest.count) {
        highest = day;
      }
    })
    this.busiestDay = highest.day;
  }

  getBusiestHours() {
    let hourList = [];
    for(let i=1;i<=24;i++) {
      hourList.push({hour: i, count: 0})
    };
    this.opTimes.forEach(opTime => {
      hourList.forEach(hour => {
        if(new Date(opTime.time).getHours() == hour.hour) {
          hour.count++;
        }
      });
    });
    let busyHour = hourList[0];
    hourList.forEach(hour => {
      if(hour.count> busyHour.count) {
        busyHour = hour
      }
    })
    this.busiestHours = busyHour;
  }

  getLastStatus() {
    let sites = [];
    this.statusList.forEach(status => {
      let found = false;
      sites.forEach(site => {
        if (site.siteID == status.siteID) {
          found =true;
        }
      });
      if(!found) {
        this.sites.forEach(mySite => {
          if(mySite.siteID == status.siteID) {
            sites.push({siteID: status.siteID, statusList: []})

          }  
        })
      }
    });
    
    this.statusList.forEach(status => {
      sites.forEach(site => {
        if (site.siteID == status.siteID) {
          site.statusList.push(status);
        }
      });
    });
    
    let open = 0;
    let closed = 0;
    let stoodDown = 0;
    let errorStates = 0;
    sites.forEach(site => {
      if (site.statusList[0].action == "Stand down success") {
        stoodDown++;
      } else if (site.statusList[0].action == "Open Lock") {
        open++;
      } else if (site.statusList[0].action == "Close Lock") {
        closed++;
      } else if (site.statusList[0].action.includes("Error:")) {
        errorStates++;
      }
    });
    this.siteStatusList = sites;
    console.log("Open: " + open)
    this.sitesOpen = open;
    console.log("Closed: " + closed)
    this.sitesClosed = closed;
    console.log("Stood Down: " + stoodDown)
    this.sitesStoodDown = stoodDown;
    console.log("Error States: " + errorStates)
    this.errorStates = errorStates;
  }

  getStatusSites(statusCode) {
    let siteList = [];
    this.siteStatusList.forEach(site => {
      
      if(!statusCode.includes("Error:")) {
        if(site.statusList[0].action == statusCode) {
          siteList.push(site.statusList[0].siteID + " " + site.statusList[0].site)
        }
      } else {
        if(site.statusList[0].action.includes("Error:")) {
          siteList.push(site.statusList[0].siteID + " " + site.statusList[0].site)
        }
      }
      
    });
    let mystring = "";
    siteList.forEach(item => {
      mystring = mystring + `\n` + item;
    })
    return mystring
  }

  showStatusSites(statusCode) {
    let siteList = [];
    this.currentStateSites.state = statusCode;
    this.currentStateSites.sites = [];
    this.siteStatusList.forEach(site => {
      
      if(!statusCode.includes("Error:")) {
        if(site.statusList[0].action == statusCode) {
          siteList.push(site.statusList[0].siteID + " " + site.statusList[0].site)
          this.currentStateSites.sites.push(site.statusList[0])
        }
      } else {
        if(site.statusList[0].action.includes("Error:")) {
          siteList.push(site.statusList[0].siteID + " " + site.statusList[0].site)
          this.currentStateSites.sites.push(site.statusList[0])
        }
      }
      
    });
    let mystring = "";
    siteList.forEach(item => {
      mystring = mystring + `\n` + item;
      // this.currentStateSites.sites.push(item);
    })
    this.showStatusTable = true;
    this.dataSourceCurrent = new MatTableDataSource<any>(this.currentStateSites.sites);
    this.dataSourceCurrent.data = this.currentStateSites.sites;
    this.dataSourceCurrent.paginator = this.paginator;
    console.log(this.currentStateSites.sites)
    return mystring
  }

  exportDataSet(){
    let copyList = this.dataSourceCurrent.data;
    // let newList:any = [];
    // copyList.forEach((site:any) => {
    //   delete site['_id'];
    //   site.serial = site.device.serialnumber;
    //   site.linked = site.device.linked;
    //   newList.push(site);
    // });
    this.exportService.exportToExcel(copyList, "Sites_Export_" + new Date());
  }

}
