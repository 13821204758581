import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-handover-requests',
  templateUrl: './handover-requests.component.html',
  styleUrls: ['./handover-requests.component.scss']
})
export class HandoverRequestsComponent implements OnInit {

  isTryingHandover = false;

  isEdit = false;
  isAdd = false;
  isDeleting = false;
  selectedHandover: any;
  handoverList: any[] = [];
  siteGroups: any[] = [];
  siteGroupsFiltered: any[] = [];
  selectedSiteGroup: any;
  newSiteGroupID: any;
  newSiteGroupName: any;
  handoverFilter: any = "";
  siteList: any[] = [];
  handoverHistoryList: any[] = [];
  showHistory = false;
  wsConnected: boolean = false;
  public socket;

  isLoading = false;
  iframeSrc: any = "";
  myBlob: Blob;
  showIframe = false;


  dataSource = new MatTableDataSource<any>(this.handoverList);
  displayedColumns: string[] = ['report', 'siteID', 'siteName', '_requestedBy', '_requestedByEmail', 'standdown','sensor1', 'sensor2', 'sensor3', 'sensor4', 'actuator1', 'actuator2', 'requestTime',  'handover'];
  displayedColumnsAlt: string[] = ['siteID', 'siteName', '_requestedBy', '_requestedByEmail', '_handoverBy', '_handoverByEmail','standdown', 'sensor1', 'sensor2', 'sensor3', 'sensor4', 'actuator1', 'actuator2', 'requestTime'];
  filterValue = "";




  @ViewChild('mySelect') mySelect;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public webReq: WebRequestsService, public ws: WebsocketService, public notifications: NotificationsService, public loader: LoaderService, private sanitizer: DomSanitizer) { }

  onSelectOpen(event: Event) {
    // console.log("Called onSelectOpen")
    this.handoverFilter = ""
    this.siteGroups = this.siteGroupsFiltered;
  }

  ngOnInit() {
    this.loader.isLoading = false;
    this.socket = this.ws.connectWS()
    // console.log(this.socket)
    this.socket.onopen = (event) => {
      this.wsConnected = true
    }

    this.socket.onmessage = (event) => {
      this.doUpdateList(event.data)
    }

    this.socket.onerror = (event) => {
      this.wsConnected = false;
    }

    this.socket.onclose = (event) => {
      this.wsConnected = false;
    }

    this.getHandoverRequests();
    this.getHandoverHistory()
    this.getSiteGroups();
    this.getSites();

  }

  getRowClass(row:any) {
    if(row.standdown == 1) {
      return 'good'
    } else {
      return 'bad'
    }
  }

  getdisplayedColumns() {
    if (this.showHistory == false) {
      return this.displayedColumns
    } else {
      return this.displayedColumnsAlt
    }
  }
  getHandoverRequests() {
    this.loader.isLoading = true;
    this.webReq.getHandoverRequests().subscribe(handovers => {
      this.handoverList = handovers;
      this.dataSource.data = handovers;
      this.dataSource.paginator = this.paginator;
      this.notifications.currentHandovers = handovers.length;
      this.loader.isLoading = false;
    })
  }

  doUpdateList(data: any) {
    this.getHandoverRequests();
  }
  getHandoverHistory() {
    this.webReq.getHandoverHistory().subscribe(handovers => {
      this.handoverHistoryList = handovers;
    })
  }

  toggleHistory() {
    if (this.showHistory == false) {
      this.showHistory = true;
      this.dataSource.data = this.handoverHistoryList;
      this.dataSource.paginator = this.paginator;
    } else {
      this.showHistory = false;
      this.dataSource.data = this.handoverList;
      this.dataSource.paginator = this.paginator;
    }
  }

  checkHistoryOn() {
    if (this.showHistory == false) {
      return 'historyButton'
    } else {
      return 'historyButton on'
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyHOFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.siteGroups = this.siteGroupsFiltered.filter(x => x.groupID.includes(filterValue) || x.groupName.toLowerCase().includes(filterValue.toLocaleLowerCase()))
  }

  goToEdit(item: any) {

  }

  doDeleting(item: any) {

  }
  deleteHandoverRequest(item: any) {

  }

  getSiteGroups() {
    this.webReq.getSiteGroups().subscribe(sitegroups => {
      this.siteGroups = sitegroups.siteGroups;
      this.siteGroupsFiltered = sitegroups.siteGroups;
    })
  }

  getSites() {
    this.webReq.getSitesAll().subscribe(sites => {
      this.siteList = sites;
    })
  }

  addSiteGroup() {
    this.webReq.addSiteGroup({ groupName: this.newSiteGroupName, groupID: this.newSiteGroupID }).subscribe(result => {
      // console.log(result)
      if (result.success == true) {
        this.getSiteGroups();
        this.isEdit = false;
      } else {
        alert(result.message)
      }
    })
  }

  handover() {

    this.isTryingHandover = true;
    this.loader.isLoading = true;
    this.webReq.iCryptoAddDevice({ DeviceID: this.selectedHandover.siteID, DeviceName: this.selectedHandover.siteName, SiteID: this.selectedSiteGroup.groupID, SiteName: this.selectedSiteGroup.groupName }).subscribe(result => {
      if (result.success == true) {
        let mySite = this.siteList.filter(x => x.siteID == this.selectedHandover.siteID)[0];
        this.selectedSiteGroup.sites.push(mySite);
        this.webReq.updateSiteGroup(this.selectedSiteGroup).subscribe(result => {
          // console.log(result)
          this.selectedHandover._handoverBy = localStorage.getItem('username')
          this.selectedHandover._handoverByEmail = localStorage.getItem('email')
          this.webReq.acceptHandover(this.selectedHandover).subscribe(results => {
            // console.log(results)
            this.isTryingHandover = false;
            this.getHandoverRequests();
            this.getHandoverHistory();
            this.isAdd = false;
            this.loader.isLoading = false;

          })
        })
      } else {
        alert("Failed to handover");
        this.loader.isLoading = false;
        this.isTryingHandover = false;
      }

    }, err => {
      alert("Handover Failed")
    })
  }

  checkHO() {
    // console.log(this.selectedHandover)
  }

  checkHOReady() {
    if (this.isTryingHandover == true || this.selectedSiteGroup == undefined) {
      return true
    } else {
      return false
    }
  }

  getLink(reqObj: any) {
    let filename = reqObj.filename.substring(0, reqObj.filename.length - 5);
  }

  async viewReport(path) {
    this.isLoading = true;
    if(path.filename.includes('Invalid') || path.filename.includes('undefined')) {
      console.log(path.filename);
      this.isLoading = false;
      alert("The file name could not be found");
    } else {
      this.webReq.downloadReport(localStorage.getItem('clientdb') + '/'+ path.siteID + '/' + path.filename+'.pdf').subscribe(reports => {
        const blob = new Blob([reports], { type: 'application/pdf' });
        this.myBlob = blob;
        let blobUrl: string = window.URL.createObjectURL(blob);
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
        this.showIframe = true
        this.isLoading = false;
      })
    }    
  }



}
