<div fxLayout="column" fxLayoutAlign="space-evenly center">
    <div class="myHeader">
        <mat-icon>extension</mat-icon> Config Templates
      </div>

      <div class="backdrop" *ngIf="showAddTemplate" (click)="showAddTemplate=false; selectedConfig = null;"></div>
    <app-add-edit-config class="addCard" *ngIf="showAddTemplate" [isAdd]="isAdding" [mytemplate]="selectedConfig" (close)="isAdding = false; showAddTemplate = false; selectedConfig = null; getConfigTemplates()"></app-add-edit-config>

    <div fxLayout="row wrap" fxLayoutAlign="start space-evenly" style="max-height: 70vh; height: 70vh; overflow-y: auto; padding: 14px;">
        <mat-card  class="templateCard" *ngFor="let template of myTemplates" (click)="isAdding = false; selectedConfig = template; showAddTemplate = true" style="border: 1px solid white; border-radius: 5px; width: 40vw; margin: 4px;">
            <div style="position: absolute; right: 4px; top: 4px; width: 45px; height: 45px; border-radius:50%;" [ngStyle]="{'background-color': checkBG(template)}"></div>
            <mat-card-title>
                {{template.name}}
            </mat-card-title>
            <p>{{template.description}}</p>
            <div fxLayout="row">
                <div fxLayout="column"  style="border: 2px solid #573dee; border-radius: 5px; margin: 4px; padding:8px; background-color:black">
                    <div><strong>1. Actuator</strong></div>
    
                    <div>
                        Door 1 Auto Lock: <strong style="color: lime">{{template.door1AutoLock}}</strong>
                    </div>

                    <div>
                        Door 2 Auto Lock: <strong style="color: lime">{{template.door2AutoLock}}</strong>
                    </div>

                    <div>
                        Use Timer: <strong style="color: lime">{{template.useTimer}}</strong>
                    </div>
    
                    <div>
                        Invert Actuator: <strong style="color: lime">{{template.invertActuator}}</strong>
                    </div>
                    <div>
                        Use Mag Lock: <strong style="color: lime">{{template.maglockToggle}}</strong>
                    </div>
                    <div>
                        Actuator Timeout: <strong style="color: lime">{{template.actuatorTimeout}}</strong>
                    </div>
                    <div>
                        Max Current: <strong style="color: lime">{{template.maxCurrent}}</strong>
                    </div>
                    <div>
                        Actuator Speed: <strong style="color: lime">{{template.actSpeed}}</strong>
                    </div>
                    <div>
                        Actuator Length: <strong style="color: lime">{{template.actLength}}</strong>
                    </div>
                </div>

                <div fxLayout="column"  style="border: 2px solid #573dee; border-radius: 5px; margin: 4px; padding:8px; background-color:black">
                    <div><strong>2. Sensors</strong></div>

                    <div>
                        Sensor Type: <strong style="color: lime">{{template.sensorType}}</strong>
                    </div>
                    <div>
                        Use door contact w/o door sensor: <strong style="color: lime">{{template.useDoorContact}}</strong>
                    </div>
                    <div>
                        Use 2 sensors: <strong style="color: lime">{{template.use2Sensors}}</strong>
                    </div>
                    <div>
                        Use Tamper Relay: <strong style="color: lime">{{template.useTamperRelay}}</strong>
                    </div>
                    <div>
                        Use Door Contact & Door Sensor: <strong style="color: lime">{{template.useDCtoInhibit}}</strong>
                    </div>
                    <div>
                        Door Contact Normally Open: <strong style="color: lime">{{template.dcIsNO}}</strong>
                    </div>
                </div>

                <div fxLayout="column"  style="border: 2px solid #573dee; border-radius: 5px; margin: 4px; padding:8px; background-color:black">
                    <div><strong>3. WiFi</strong></div>
                    <div>
                        WiFi On: <strong style="color: lime">{{template.currentWifiState}}</strong>
                    </div>
                    <div>
                        SSID/Network Name: <strong style="color: lime">{{template.wifiDetails.ssid}}</strong>
                    </div>
                    <div>
                        WiFi Password: <strong style="color: lime">{{template.wifiDetails.password}}</strong>
                    </div>
                </div>

                <div fxLayout="column"  style="border: 2px solid #573dee; border-radius: 5px; margin: 4px; padding:8px; background-color:black">
                    <div><strong>4. Secondary Controller</strong></div>
                    <div>
                        Secondary Controller Present: <strong style="color: lime">{{template.secondaryPresent}}</strong>
                    </div>
                    <div>
                        TPM Present: <strong style="color: lime">{{template.tpmPresent}}</strong>
                    </div>
                    <div>
                        Secondary Sensor Type: <strong style="color: lime">{{template.secondarySensorType}}</strong>
                    </div>
                    <div>
                        Secondary Uses 2 Sensors: <strong style="color: lime">{{template.secondaryUse2Sensors}}</strong>
                    </div>
                </div>
            </div>
          
        </mat-card>

    </div>

    <div>
        <button mat-raised-button class="btn"
            style="position: absolute; bottom: 7vh; z-index: 3001; color: black; background-color: lime; left: 14px;"
            (click)="isAdding = true; showAddTemplate = true;">Add Template</button>
    </div>
</div>