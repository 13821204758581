<div fxFlex fxLayoutAlign="center center" fxLayout="column" style="overflow: hidden;">
    <!-- <mat-progress-bar style="position: absolute; top: 10vh;" mode="indeterminate"></mat-progress-bar> -->

    <!-- Diagnostics Card -->
    <mat-card class="mat-elevation-z16" style="height: 75vh; width: 85vw;" fxLayout="column"
        fxLayoutAlign="space-evenly none" [ngClass]="{ 'nodisplay': showWebcam}">
        <button mat-mini-fab style="position: absolute;top: 8px;left: 8px; background-color: transparent;" (click)="close()">
            <mat-icon>arrow_back</mat-icon>
        </button>
       

        <mat-accordion>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                     Tests
                    </mat-panel-title>
                    <mat-panel-description>
                      Device Tests
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
                    <div (click)="testDoorSensor()" class="block-button mat-elevation-z16" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner>
                        <mat-icon *ngIf="doorSensor.state != 3" [style.color]="stateStyle(doorSensor.state)">sensor_door
                        </mat-icon>
                        <label>Door Sensor</label>
                    </div>
        
        
        
                    <div (click)="testTamper()" class="block-button mat-elevation-z16" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-spinner *ngIf="tamper.state == 3" class="button-spinner" diameter="25"></mat-spinner>
                        <mat-icon *ngIf="tamper.state != 3" [style.color]="stateStyle(tamper.state)">do_not_touch</mat-icon>
                        <label>Tamper</label>
                    </div>
                </div>
        
                <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
                    <div (click)="testActuator()" class="block-button mat-elevation-z16" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-spinner *ngIf="actuator.state == 3" class="button-spinner" diameter="25"></mat-spinner>
                        <mat-icon *ngIf="actuator.state != 3" [style.color]="stateStyle(actuator.state)">settings</mat-icon>
                        <label>Actuator</label>
                    </div>
                    <div (click)="testPowerSupply()" class="block-button mat-elevation-z16" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-spinner *ngIf="powerSupply.state == 3" class="button-spinner" diameter="25"></mat-spinner>
                        <mat-icon *ngIf="powerSupply.state != 3" [style.color]="stateStyle(powerSupply.state)">
                            electrical_services</mat-icon>
                        <label>Power Supply</label>
                    </div>
                </div>

            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Images
                    </mat-panel-title>
                    <mat-panel-description>
                        Site Images
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
                    <div (click)="showWebcam = true; imageSelection = 'dsImg'" class="block-button mat-elevation-z16" style="border: 2px dashed white;" fxLayout="column"
                        fxLayoutAlign="center center">
                        <label class="imgLabel">Door Sensor</label>
                        <img #dsImg id="dsImg" style="width: 98px; height: 98px; border-radius: 5%; margin: 1px">
                    </div>
                    <div  (click)="showWebcam = true; imageSelection = 'tamperImg'" class="block-button mat-elevation-z16" style="border: 2px dashed white;" fxLayout="column"
                        fxLayoutAlign="center center">
                        <!-- <label>Tamper Picture</label> -->
                        <label class="imgLabel">Tamper</label>
                        <img #tamperImg id="tamperImg" style="width: 98px; height: 98px; border-radius: 5%; margin: 1px">
                    </div>
                </div>
        
                <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
                    <div (click)="showWebcam = true; imageSelection = 'actImg'" class="block-button mat-elevation-z16" style="border: 2px dashed white;" fxLayout="column"
                        fxLayoutAlign="center center">
                        <!-- <label>Actuator Picture</label> -->
                        <label class="imgLabel">Actuator</label>
                        <img #actImg id="actImg" style="width: 98px; height: 98px; border-radius: 5%; margin: 1px">
                    </div>
                    <div (click)="showWebcam = true; imageSelection = 'psImg'" class="block-button mat-elevation-z16" style="border: 2px dashed white;" fxLayout="column"
                        fxLayoutAlign="center center">
                        <!-- <label>Power Supply Picture</label> -->
                        <label class="imgLabel">Power Supply</label>
                        <img #psImg id="psImg" style="width: 98px; height: 98px; border-radius: 5%; margin: 1px">

                    </div>
                </div>
        
                <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
                    <!-- <div  class="block-button mat-elevation-z16" style="border: 2px dashed white;" fxLayout="column"
                        fxLayoutAlign="center center">
                        <mat-spinner *ngIf="actuator.state == 3" class="button-spinner" diameter="25"></mat-spinner>
                        <mat-icon *ngIf="actuator.state != 3" [style.color]="stateStyle(actuator.state)">help</mat-icon>
                        <label>Technician Picture</label>
                    </div> -->
                    <div (click)="showWebcam = true; imageSelection = 'siteImg'" class="block-button mat-elevation-z16" style="border: 2px dashed white;" fxLayout="column"
                        fxLayoutAlign="center center">
                        <!-- <label>Site Picture</label> -->
                        <label class="imgLabel">Site</label>
                        <img #siteImg id="siteImg" style="width: 98px; height: 98px; border-radius: 5%; margin: 1px">

                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        

        <div fxLayoutAlign="center center">
            <button mat-raised-button color="primary" style="width: 80%;" (click)="generateReport()"><mat-icon>picture_as_pdf</mat-icon>Generate Report</button>
        </div>

        <a (click)="openHelp()" style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a>



    </mat-card>

    <!-- Camera Component -->

    <div [ngClass]="{ 'nodisplay': !showWebcam}" style="height: 300px; width: 350px; border: 2px solid orange; overflow: hidden;">
        <button mat-mini-fab style="position: absolute;top: 15vh;left: 8px;" (click)="showWebcam = false">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <webcam   [trigger]="triggerObservable" (imageCapture)="handleImage($event)"></webcam>
        <button mat-mini-fab style="position: absolute; bottom: 10vh; left: 50vw" (click)="takeSnapshot()"><mat-icon>camera</mat-icon></button>
        <button mat-mini-fab style="position: absolute; bottom: 10vh; right: 10vw" (click)="onOffWebCame()"><mat-icon>flip_camera_android</mat-icon></button>
    </div>
</div>