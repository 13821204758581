<div class="full-page custom-bg" fxLayoutAlign="center center">
    <mat-card class="login-card mat-elevation-z16"  *ngIf="!showDBSelect && !resetPassword">
        <mat-card-title>
            Login to App
        </mat-card-title>
        <mat-card-content>
          
            <mat-form-field class="login-field" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" [(ngModel)]="email" type="email" autocomplete="username" required/>
            </mat-form-field>
            <mat-form-field class="login-field" appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput placeholder="Password" [(ngModel)]="password" [type]="inputType" autocomplete="new-password" required/>
                <mat-icon matSuffix *ngIf="inputType == 'password'" (click)="toggleInputType()">visibility</mat-icon>
                <mat-icon matSuffix *ngIf="inputType != 'password'" (click)="toggleInputType()">visibility_off</mat-icon>
            </mat-form-field>
        
            
        </mat-card-content>
        <p><a (click)="resetPassword = true" style="color: blue; text-decoration: underline;">Forgot Password?</a></p>
        <!-- <p><a (click)="requestAccount = true" style="color: white; text-decoration: underline;">Don't have an account?</a></p> -->

        <mat-card-actions>
            <button mat-raised-button color="primary" class="login-field" id="login-btn" (click)="mongoLogin()" type="submit">
                Login
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card class="login-card mat-elevation-z16" *ngIf="showDBSelect" >
        <mat-card-title>
            Select Client
        </mat-card-title>
        <mat-card-content>
            <mat-form-field class="login-field" appearance="outline">
                <mat-label>Client</mat-label>
                <mat-select [(ngModel)]="selectedClient">
                    <mat-option *ngFor="let client of clients" [value]="client">{{client}}</mat-option>
                </mat-select>
            </mat-form-field>     
        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button color="primary" class="login-field" id="login-btn" (click)="continue()" type="submit">
                Login
            </button>
        </mat-card-actions>
    </mat-card>
    <app-loader></app-loader>

    <app-password-reset (close)="resetPassword = false" *ngIf="resetPassword"></app-password-reset>
    
    <mat-card class="login-card mat-elevation-z16" *ngIf="requestAccount" >
        <mat-card-title>
            Please enter details to request an account
        </mat-card-title>
        <mat-card-content>
          
            <mat-form-field class="login-field" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" [(ngModel)]="newEmail" type="email" required/>
            </mat-form-field>
            <mat-form-field class="login-field" appearance="outline">
                <mat-label>Position/Type of Access Required</mat-label>
                <input matInput placeholder="Admin/Technician/Maintenance" [(ngModel)]="requirements" type="password" autocomplete="new-password" required/>
            </mat-form-field>
        
            
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" class="login-field" (click)="sendRequestAccount()" >Request</button>
            <button mat-raised-button color="warn" class="login-field" (click)="requestAccount = false">
                Cancel
            </button>
        </mat-card-actions>
    </mat-card>

    </div>