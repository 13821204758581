<div fxFlex fxLayoutAlign="center center" fxLayout="column">

    <div fxFlex fxLayoutAlign="center center" fxLayout="column">

        <app-user-admin (close)="userAdmin = false" *ngIf="userAdmin"></app-user-admin>
        <app-site-admin (close)="siteAdmin = false" *ngIf="siteAdmin"></app-site-admin>
        <app-logs (close)="showLogs = false" *ngIf="showLogs"></app-logs>
        <app-dashboard (close)="showDashboard = false" *ngIf="showDashboard"></app-dashboard>
        <app-password-update (close)="changePassword = false" *ngIf="changePassword"></app-password-update>

        <div *ngIf="!userAdmin && !siteAdmin && !changePassword && !showLogs && !showDashboard" style="height: 75vh; width: 85vw;"
            fxLayoutAlign="space-evenly none" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-evenly center">
                <!-- <div (click)="userAdmin = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow">person</mat-icon>
                    <label>Users</label>
                </div> -->

                <!-- <div (click)="siteAdmin = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow">domain</mat-icon>
                    <label>Sites</label>
                </div> -->

                <div (click)="showDashboard = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow; overflow: visible;">line_axis</mat-icon>
                    <label>Dashboard</label>
                </div>

                <div *ngIf="isAdmin" (click)="showLogs = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow; overflow: visible; ">format_list_numbered_rtl</mat-icon>
                    <label>Logs</label>
                </div>

                <div (click)="changePassword = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow; overflow: visible;">key</mat-icon>
                    <label>Change Pwd</label>
                </div>
            </div>
            <!-- <div (click)="siteAdmin = true" class="block-button mat-elevation-z16" fxLayout="column"
                    fxLayoutAlign="center center">
                    <mat-icon style="color: greenyellow">domain</mat-icon>
                    <label>Sites</label>
                </div> -->
            <a (click)="openHelp('admin')" style="position: absolute; bottom: 6vh; right: 0px; margin: 4px;">
                <mat-icon>help_outline</mat-icon>
            </a>
        </div>
    </div>
</div>