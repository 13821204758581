<mat-card class="mat-elevation-z8">
    <div fxLayout="column" style="width: 70vw; height: 80vh;">
        <button mat-mini-fab style="position: absolute; right: 4px; top:4px;" (click)="closing()"><mat-icon>close</mat-icon></button>

        <mat-stepper linear="true" #stepper>

            <mat-step>

                <div fxLayout="column" fxLayoutAlign="space-evenly center">
                    <h2>CSV File Config</h2>
                    <mat-form-field appearance="outline">
                        <mat-label>First Row</mat-label>
                        <input matInput [(ngModel)]="firstRow" type="number">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Name Index</mat-label>
                        <input matInput [(ngModel)]="nameIndex" type="number">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Surname Index</mat-label>
                        <input matInput [(ngModel)]="surnameIndex" type="number">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Cellphone Index</mat-label>
                        <input matInput [(ngModel)]="cellphoneIndex" type="number">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Email Index</mat-label>
                        <input matInput [(ngModel)]="emailIndex" type="number">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Contractor Index</mat-label>
                        <input matInput [(ngModel)]="contractorIndex" type="number">
                    </mat-form-field>


                    <h2>Select a file</h2>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <input type="file" accept=".csv" (change)="checkEvent($event)" class="custom-file-input" #fileUpload class="file-input">
                        {{fileName || "No file uploaded yet."}}
                        <button mat-mini-fab (click)="fileUpload.click()" style="margin: 4px; background-color: blue;" matTooltip="Add File"><mat-icon>attach_file</mat-icon></button>
                    </div>
                    <button mat-raised-button [disabled]="usersToAdd.length == 0" matStepperNext color="primary" style="position: absolute; bottom: 8px;"><mat-icon>check_circle</mat-icon> Continue</button>
                </div>
            </mat-step>

            <mat-step>
                <div fxLayout="column" fxLayoutAlign="space-evenly center">
                    <h2>Users for Import ({{usersToAdd.length}})</h2>
                    <div style="max-height: 50vh; overflow-y: auto; border: 1px solid lightgray; border-radius: 5px; background-color: black;">
                        <mat-list>
                            <mat-list-item *ngFor="let user of usersToAdd"><mat-icon>pending</mat-icon> {{user.username}} - {{user.email}}</mat-list-item>
                        </mat-list>
                    </div>
                    
                    <button mat-raised-button (click)="AddUsers()" color="primary" style="position: absolute; bottom: 8px;" matStepperNext><mat-icon>check_circle</mat-icon> Add</button>
                </div>
            </mat-step>

            <mat-step>
                <div fxLayout="column" fxLayoutAlign="space-evenly center">
                    <h2>Summary</h2>

                    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!addingComplete">
                        <mat-spinner diameter="50"></mat-spinner>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="addingComplete">
                        <mat-icon style="color: lightgreen" >check</mat-icon> Upload Complete
                    </div>
                    <div fxLayout="column" *ngIf="addingComplete">
                        <label>
                            Successes: {{successes}}
                        </label>
                        <label>
                            Failures: {{failures}}
                        </label>
                    </div>

                    <button mat-raised-button style="background-color: limegreen; position: absolute; bottom: 8px;" [disabled]="!addingComplete" (click)="closing()"><mat-icon>check_circle</mat-icon> Finish</button>
                </div>
            </mat-step>

        </mat-stepper>
        
    </div>
</mat-card>