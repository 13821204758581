import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { write } from 'fs';
import { Observable, Subject } from 'rxjs';
import { throwIfEmpty } from 'rxjs/operators';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';

import { PDF417DecodedBitStreamParser } from '@zxing/library';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'app-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss']
})
export class DiagnosticsComponent implements OnInit {

  public actuator: Actuator = new Actuator();
  public doorSensor: DoorSensor = new DoorSensor();
  public tamper: Tamper = new Tamper();
  public powerSupply: PowerSupply = new PowerSupply();
  public isBusy: boolean = true;
  public isTestAll: boolean = false;
  public testCounter = 0;
  public resultCounter = 0;
  public currentTest: any;
  public responseRecieved: boolean = false;

  public testArray: any[] = [];

  @Input() myCharacteristic: any;
  @Input() testSubject: Subject<any> = new Subject<any>();

  @Output() closed = new EventEmitter();
  @Output() action = new EventEmitter();

  public images: any[] = [];
  public imageSelection = "Door Sensor";
  @Output() getPicture = new EventEmitter<WebcamImage>();
  showWebcam = false;
  isCameraExist = true;
  errors: WebcamInitError[] = [];
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(public loader: LoaderService, public conn: ConnectivityService, public btService: BluetoothService, public logger: LoggingService) { }
  // Return ok or err
  ngOnInit(): void {
    this.testSubject.subscribe(res => {
      console.log("Got event from bt component: " + res)
      if (this.currentTest == undefined) {
        this.currentTest = new Actuator();
      }
      if (res == true) {
        this.currentTest.state = 0;
        this.isBusy = false;

      }
      else if (res == false) {
        this.currentTest.state = 1;
        this.isBusy = false;
      }
    });

    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.isCameraExist = mediaDevices && mediaDevices.length > 0;
    })
  }

  ngOnDestroy() {
    // needed if child gets re-created (eg on some model changes)
    // note that subsequent subscriptions on the same subject will fail
    // so the parent has to re-create parentSubject on changes
    // this.testSubject.unsubscribe();
  }

  testActuator() {
    if (this.isBusy == false) {
      this.isBusy = true;
      this.actuator.state = 3;
      this.currentTest = this.actuator;
      this.action.emit('*;2');
    }
  }

  testDoorSensor() {
    if (this.isBusy == false) {
      this.isBusy = true;
      this.doorSensor.state = 3;
      this.currentTest = this.doorSensor;
      this.action.emit('*;0');
    }
  }

  testTamper() {
    if (this.isBusy == false) {
      this.isBusy = true;
      this.tamper.state = 3;
      this.currentTest = this.tamper;
      this.action.emit('*;1')
    }
  }

  testPowerSupply() {
    if (this.isBusy == false) {
      this.isBusy = true;
      this.powerSupply.state = 3;
      this.currentTest = this.powerSupply;
      this.action.emit('*;3')
    }
  }

  testAll() {
    // this.testArray = [this.testDoorSensor,this.testTamper,this.testPowerSupply];

    // this.isTestAll = true;
    // this.testCounter = 3;
    // this.testActuator();
    // // this.testDoorSensor();
    // // this.testPowerSupply();
    // // this.testTamper();
  }

  generateReport() {
    let actRes: string = "";
    let dsRes: string = "";
    let tmpRes: string = "";
    let psRes: string = "";
    this.actuator.state == 0 ? actRes = 'OK' : actRes = 'FAULT';
    this.doorSensor.state == 0 ? dsRes = 'OK' : dsRes = 'FAULT';
    this.tamper.state == 0 ? tmpRes = 'OK' : tmpRes = 'FAULT';
    this.powerSupply.state == 0 ? psRes = 'OK' : psRes = 'FAULT';
    var imgData = new Image();
    var imgData1 = new Image();
    var DoorSensorImg = new Image();
    var TamperImg = new Image();
    var PowerSupplyImg = new Image();
    var SiteImg = new Image();
    var ActuatorImg = new Image();

    DoorSensorImg.src = this.images.filter(x => x.component == 'dsImg')[0].image.imageAsDataUrl;
    TamperImg.src = this.images.filter(x => x.component == 'tamperImg')[0].image.imageAsDataUrl;
    SiteImg.src = this.images.filter(x => x.component == 'siteImg')[0].image.imageAsDataUrl;
    ActuatorImg.src = this.images.filter(x => x.component == 'actImg')[0].image.imageAsDataUrl;
    PowerSupplyImg.src = this.images.filter(x => x.component == 'psImg')[0].image.imageAsDataUrl;


    imgData.src = 'assets/ethele.png'
    imgData1.src = 'assets/prism.png'
    const doc = new jsPDF() as jsPDFWithPlugin;
    // doc.setFont('courier')
    // doc.addFont('zapfdingbats',)
    doc.addImage(imgData1, 'png', 160, 10, 25, 25);
    doc.addImage(imgData, 'png', 162, 35, 20, 10);
    doc.line(10, 75, 200, 75)
    doc.setFontSize(32);
    doc.text("Prism Test Report", 10, 30);
    doc.setFontSize(16);
    doc.text("Site: " + localStorage.selectedBTSite, 10, 50);
    doc.text("Date: " + new Date().toLocaleString(), 10, 40);
    // doc.text("site ID: " + localStorage.selectedBTSite,10,50);
    doc.text("This report was generated by " + localStorage.username, 10, 85);
    doc.line(10, 290, 200, 290)

    // doc.table(10, 135, [
    //   { "Component": "Actuator", "Result": actRes },
    //   { "Component": "Door Sensor", "Result": dsRes },
    //   { "Component": "Tamper", "Result": tmpRes },
    //   { "Component": "Power Supply", "Result": psRes }
    // ], ["Component", "Result"], { autoSize: true });

    var mytb = '<table id="mypdftable"><thead><tr><th>Component</th><th>Result</th><th>Image</th></tr></thead><tbody><tr><td>Actuator</td><td>'+ actRes +'</td><td><img src="'+ ActuatorImg.src +'" /></td></tr><tr><td>Door Sensor</td><td>'+ dsRes +'</td><td><img src="'+ DoorSensorImg.src +'" /></td></tr><tr><td>Tamper</td><td>'+ tmpRes +'</td><td><img src="'+ TamperImg.src +'" /></td></tr><tr><td>Power Supply</td><td>'+ psRes +'</td><td><img src="'+ PowerSupplyImg.src +'" /></td></tr></tbody></table>';

    var parser = new DOMParser();
    var oldBody = document.body;
    var myTBL = parser.parseFromString(mytb,'text/html');
    // document.body.append(myTBL.body)
    document.body = myTBL.body

    doc.autoTable({
      html: '#mypdftable',
      bodyStyles: {minCellHeight: 30},
      startY: 90,
      didDrawCell: function(data) {
        if (data.column.index === 2 && data.cell.section === 'body') {
           var td = data.cell.raw?.valueOf();
           console.log(typeof(data.cell.raw?.valueOf()));
           var img = td?.valueOf();
           var dim = data.cell.height - data.cell.padding('vertical');
           var textPos = data.cell.getTextPos();
           var imgSrc = "";
           switch(data.row.index) {
             case 0 : imgSrc = ActuatorImg.src; break;
             case 1 : imgSrc = DoorSensorImg.src; break;
             case 2 : imgSrc = TamperImg.src; break;
             case 3 : imgSrc = PowerSupplyImg.src; break;
             default: break;
           }
           doc.addImage(imgSrc, textPos.x,  textPos.y, dim, dim);
        }
      }
    });

    // doc.addImage(ActuatorImg, 'imageAsDataUrl', 65, 120, 25, 25);
    // doc.text("Actuator", 65, 115);
    // doc.addImage(DoorSensorImg, 'imageAsDataUrl', 65, 155, 25, 25);
    // doc.text("Door Sensor", 65, 150);
    // doc.addImage(TamperImg, 'imageAsDataUrl', 100, 120, 25, 25);
    // doc.text("Tamper", 100, 115);
    // doc.addImage(PowerSupplyImg, 'imageAsDataUrl', 100, 155, 25, 25);
    // doc.text("Power Supply", 100, 150);
    doc.addImage(SiteImg, 'imageAsDataUrl', 75, 45, 25, 25);
    // doc.text("Site", 65, 185);

    doc.text("* This report serves as indication that the above components marked", 10, 235);
    doc.text(" as 'OK' are in a working condition, conforming to ", 10, 245);
    doc.text(" Prism™ flow standards.", 10, 255)
    doc.text(" Please check any components marked with 'FAULT' for any issues", 10, 265)
    doc.text(" pertaining to the installation of the Prism™ unit", 10, 275)
    doc.save("prismPDF " + new Date().toISOString().replace('.', ':') + ".pdf");
    document.getElementById('mypdftable')?.remove();
    document.body.remove();
    document.body = oldBody;
  }

  stateStyle(state: any) {
    switch (state) {
      case 0: return '#50ff4a';
        break;
      case 1: return 'red';
        break;
      case 2: return 'white';
        break;
      default: return 'white';
        break;
    }
  }

  writeAction(tokenID: string) {
    this.loader.isLoading = true;

    var enc = new TextEncoder();
    var myValue;
    myValue = tokenID;

    if (myValue != null) {
      var myValue1 = enc.encode(myValue + "\n");
      // this.clickable = true;
      this.logger.addLog(tokenID, this.conn.online);
      // console.log("about to write: " + myValue1);
      setTimeout(() => {
        return this.btService.myWriteCharacteristic.writeValue(myValue1);
      }, 500);
    }
    else {
      console.log("about to write: Nothing");
      alert("Will send: Nothing")
      return this.btService.myWriteCharacteristic.writeValue(enc.encode(""));
    }
  }

  close() {
    this.closed.emit();
  }

  openHelp() {
    window.open('https://wernerg123.github.io/prism-v2-docs/#3.3', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }


  takeSnapshot(): void {
    this.trigger.next();
  }

  onOffWebCame() {
    this.showWebcam = !this.showWebcam;
  }

  handleInitError(error: WebcamInitError) {
    this.errors.push(error);
  }

  changeWebCame(directionOrDeviceId: boolean | string) {
    this.nextWebcam.next(directionOrDeviceId);
  }

  handleImage(webcamImage: WebcamImage) {
    this.getPicture.emit(webcamImage);
    if (this.images.filter(x => x.component == this.imageSelection).length > 0) {
      this.images = this.images.filter(x => x.component != this.imageSelection);
    }
    this.images.push({ "image": webcamImage, "component": this.imageSelection });
    console.log(this.images);
    var myElement = document.getElementById(this.imageSelection);
    myElement?.setAttribute('src', webcamImage.imageAsDataUrl);
    this.showWebcam = false;
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  findImage(component: string) {
    // console.log('Called findImage');
    // return this.imgUrl;
    if (this.images.length > 0) {
      var myImg = this.images.filter(x => x.component == component)[0];
      console.log(myImg)
      if (myImg) {
        console.log(myImg.image.imageAsDataUrl)
        return myImg._imageAsDataUrl;
      } else {
        return "https://image.shutterstock.com/image-vector/add-button-260nw-506458858.jpg";
      }
    }
    else {
      return "https://image.shutterstock.com/image-vector/add-button-260nw-506458858.jpg";
    }
  }

}

enum CheckState {
  ok, // (0)
  err, // (1)
  unknown,
  checking,
  checked,
}

class Actuator {
  state: CheckState = 2;
}

class Tamper {
  state: CheckState = 2;
}

class DoorSensor {
  state: CheckState = 2;
}

class PowerSupply {
  state: CheckState = 2;
}