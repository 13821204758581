import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
// import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LoginRoutingModule } from '../login/login-routing.module';
import { closeSync } from 'fs';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  // @ViewChild(BaseChartDirective) workingTimesChart: BaseChartDirective | undefined;


  displayedColumns: string[] = ['siteID', 'sitename', 'linked', 'status'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  allSites: any = [];
  allUsers: any = [];
  allUserGroups: any = [];
  allSiteGroups: any = [];

  totalWorkingTime:number = 0;
  sitesAndTimes = [];
  totalTokensGenerated: number = 0;

  reportUrl:any = "";

  constructor(private router: Router,
    private webReq: WebRequestsService,
    public loader: LoaderService,
    public snackBar: MatSnackBar,
    public notify: NotificationsService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loader.isLoading = false;
    this.getSitesAndUsers();
    this.getTotalTokensGenerated();
    this.getReportUrl(localStorage.getItem('clientdb'));
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getSitesAndUsers() {
    // this.loader.isLoading = true;
    this.webReq.getSitesAll().subscribe(sites => {
      this.allSites = sites.filter((x:any)=>x.siteID != '10000');
      let statusSites: any = [];
      this.allSites.forEach((site:any) => {
        statusSites.push(site.siteID);
      });
      this.webReq.getSiteStatus(statusSites).subscribe((statusRes:any) => {
        this.allSites.forEach((site:any) => {
          statusRes.forEach((status:any) => {
            if(status.siteID == site.siteID) {
              if(status.action != null && status.action != undefined){
                if(status.action == "Close Lock") {
                  site.status = "Locked";
                } else if(status.action == "Open Lock") {
                  site.status = "Unlocked";
                } else if(status.action == "Stand Down") {
                  site.status = "Secured";
                } else if(status.action.includes("Error")) {
                  site.status = "Fault";
                }else {
                  site.status = "Unknown";
                }
              } else {
                site.status = "Unknown";
              }
            }
          });
        });

        this.allSites.forEach(site => {
          if(site.status == null || site.status == undefined) {
            site.status = "Unknown"
          }
        });

        this.getLinkedData();

        this.dataSource = new MatTableDataSource(this.allSites);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.webReq.getUsers().subscribe(users => {
          this.allUsers = users;
          this.webReq.getUserGroups().subscribe(userGroups => {
            this.allUserGroups = userGroups.userGroups;
            this.webReq.getSiteGroups().subscribe(siteGroups => {
              this.allSiteGroups = siteGroups.siteGroups;
              this.loader.isLoading = false
            });
          });
        });
      });
      this.getOpensAndStanddowns();
      // this.getWorkingTimes(this.allSites);
    });
  }

  getLinkedData() {
    var total = this.allSites.length;
    var linked = 0
    var unlinked = 0;

    this.allSites.forEach((site:any) => {
      if(site.device != null && site.device != undefined && site.device.linked == true) {
        linked++;
      } else {
        unlinked++;
      }
    });

    this.pieChartData = {
      labels: [ [ 'Linked Sites' ], [ 'Unlinked Sites' ] ],
    datasets: [ {
      data: [ linked, unlinked ],
      backgroundColor: [
        'limegreen','rgb(153, 26, 17)'
      ],
      hoverBackgroundColor: [
        'limegreen','rgb(153, 26, 17)'
      ]
    } ]
    }

    return {linked: linked, unlinked: unlinked, total: total};
  }

  getWorkingTimes(siteIDS) {
    let sites = [];
    siteIDS.forEach(site => {
      sites.push(site.siteID);
    });
    this.webReq.getSiteWorkingTimes(sites).subscribe(workingTimes => {
      console.log(workingTimes);
      workingTimes.forEach(workItem => {
        let siteTotalTime:number = 0;
        workItem.logs.forEach(logItem => {
          this.totalWorkingTime += (logItem.open + logItem.standdown)
          siteTotalTime += (logItem.open + logItem.standdown);
        });
        debugger
        this.sitesAndTimes.push({sitename:workItem.sitename, time: siteTotalTime, siteID: workItem.siteID});
      });
      
      console.log("Sites and times \n" + JSON.stringify(this.sitesAndTimes))

      console.log("Total working time: " + this.totalWorkingTime)
      let myLabels = [];
      let myData:number[] = [];

      this.sitesAndTimes.forEach(siteTime => {
        myLabels.push(siteTime.sitename + " " + siteTime.siteID)
        myData.push(siteTime.time);
      });

      // this.workingTimesChartData = {
      //   labels:  myLabels,
      // datasets: [ {
      //   data: myData 
      // } ]
      // }

    });
  }

  getOpensAndStanddowns() {
    let siteIDS = [];
    this.allSites.forEach(site => {
      siteIDS.push(site.siteID);
    });

    this.webReq.getOpensAndStanddowns(siteIDS).subscribe(res => {
      console.log(res);
      let labels = [];
      let opens:number[] = [];
      let sds:number[] = [];
      let closes: number[] = [];

      res.forEach(item => {
        labels.push(item.siteID)
        opens.push(item.opens)
        sds.push(item.standdowns)
        closes.push(item.closes);
      });
      // this.openSDChartData.datasets: res;
      this.openSDChartData = {
        labels: labels,
      datasets: [ 
        {data: opens, label:'Open Locks',backgroundColor: 'orange' }, 
        {data:closes, label: 'Close Locks' ,backgroundColor: 'blue'}, 
        {data:sds, label: 'Stand Downs',backgroundColor: 'green'}
      ],
      
      // datasets: [ {data: opens }, {data:sds}]
      }
    })
  }

  getTotalTokensGenerated() {
    this.webReq.getTotalTokensGenerated().subscribe(res => {
      this.totalTokensGenerated = res.message.length;
    }, err => {
      console.log(err);
    })
  }

  getReportUrl(client:any) {
    var initUrl = "https://datastudio.google.com/embed/u/0/reporting/8394e230-7a17-438e-bebd-3b1841f61925/page/WTUCD";
    console.log("Initial report url")
    console.log(initUrl)
    var params = {
      "ds2.clientdb": client
    };
    var paramsAsString = JSON.stringify(params);
    var encodedParams = encodeURIComponent(paramsAsString)
    console.log("Encoded params:")
    console.log(encodedParams);

    this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(initUrl+"?params="+encodedParams);
    console.log("Report url final")
    console.log(this.reportUrl.toString())
  }

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      
    }
  };
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [ [ 'Linked', 'Sites' ], [ 'Unlinked', 'Sites' ] ],
    datasets: [ {
      data: [ 0, 0 ]
    } ]
  };
  public pieChartType: ChartType = 'pie';

  // public workingTimesChartOptions: ChartConfiguration['options'] = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: true,
  //       position: 'top',
  //     },
      
  //   }
  // };
  // public workingTimesChartData: ChartData<'pie', number[], string | string[]> = {
  //   labels: [ [ 'Linked', 'Sites' ], [ 'Unlinked', 'Sites' ] ],
  //   datasets: [ {
  //     data: [ 0, 0 ]
  //   } ]
  // };
  // public workingTimesChartType: ChartType = 'pie';

  public openSDChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      
    }
  };
  public openSDChartData: ChartData<'bar', number[], string | string[]> = {
    labels: [ [ 'Open', 'Locks' ], [ 'Stand', 'Downs' ] ],
    datasets: [ {
      data: [ 0, 0 ]
    } ]
  };
  public openSDChartType: ChartType = 'bar';
}
