import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable, of, timer, from as fromPromise } from 'rxjs';
import { catchError, first, mapTo, switchMap, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
  ) {
    if (this.swUpdate.isEnabled) {
      this.appRef.isStable.pipe(
        first(isStable => isStable === true),
        switchMap(() => this.swUpdate.versionUpdates),
      ).subscribe(() => {
        this.swUpdate.activateUpdate().then(() => {
          // document.location.reload()
          console.info('SW Updated')
        });
      });
    }
  }

  checkForUpdate(): Observable<boolean> {
    const waitFor = 1000;

    console.log("Inside checkForUpdate()");
    console.log("swUpdate isEnabled:" + this.swUpdate.isEnabled);

    if (this.swUpdate.isEnabled) {
      const available$ = this.swUpdate.versionUpdates.pipe(
        mapTo(true),
        timeout(waitFor),
        catchError(() => of(false)),
      );
      console.log("mapped and timeout complete");
      return fromPromise(this.swUpdate.checkForUpdate()).pipe(
        switchMap(() => available$),
      );
    }

    return timer(waitFor).pipe(mapTo(false));

  }

  async checkForUpdateNew() {
    try {
      let res = await this.swUpdate.checkForUpdate();
      console.log("Completed check for updates");
      return (res);
    }
    catch(e) {
      console.warn(e.message);
      return null;
    }
    
  }

  async activateUpdate() {
    let result = await this.swUpdate.activateUpdate();
    if(result) {
      return true
    } else {
      return false
    }
  }
}
