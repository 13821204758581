import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavnewComponent } from './sidenavnew.component';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import {MatBadgeModule} from '@angular/material/badge';
import { CarouselMenuComponent } from '../carousel-menu/carousel-menu.component';
import { MenuItemMainComponent } from './menu-item-main/menu-item-main.component';

// import { HoverMenuComponent } from '../hover-menu/hover-menu.component';

@NgModule({
  declarations: [
    SidenavnewComponent,
    CarouselMenuComponent,
    MenuItemMainComponent,
    // HoverMenuComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatSidenavModule,RouterModule,MatListModule, MatToolbarModule, MatIconModule, FlexLayoutModule, MatButtonModule, MatSnackBarModule,MatTooltipModule, MatBadgeModule
  ]
})
export class SidenavnewModule { }
