<mat-card *ngIf="!noTokens" class="mat-elevation-z16" style="height: 65vh; width: 85vw;" fxLayoutAlign="center center">
    <div fxLayout="column">
        <mat-form-field appearance="outline">
            <mat-label>Site</mat-label>
            <mat-select [(ngModel)]="selectedSite">
                <mat-option *ngFor="let site of availableSites" [value]="site">{{site}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="confirmSite(selectedSite)">
            <mat-icon>done</mat-icon> Confirm Site
        </button>
    </div>
</mat-card>

<mat-card *ngIf="noTokens" class="mat-elevation-z16" style="height: 65vh; width: 85vw;">
    <div fxLayout="column" style="height: 100%;" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="center center" style="position: absolute; top: 35% !important">
            <mat-icon style="color: red; font-size: xx-large; margin: 4px;">highlight_off</mat-icon>
            <span style="color: white; font-size: large; margin: 4px">No available tokens, please get new tokens</span>
        </div>
        <button mat-raised-button color="primary" (click)="goToGetTokens()" style="position: absolute; top: 80%; width: 70%;">
            <mat-icon>lock</mat-icon> Get Tokens
        </button>

    </div>
</mat-card>