import { HttpClient } from '@angular/common/http';
// import { templateJitUrl } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BluetoothAction } from '../models/bluetooth-action';
import { WebRequestsService } from './web-requests.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  public btLog: BluetoothAction[] = [];
  public logItem: BluetoothAction = {};
  constructor(public http: HttpClient, public webReq: WebRequestsService) { }
// Random comment
  addLog(action: any, online: boolean) {
    this.logItem.time = new Date().getTime().toString();
    this.logItem.user = localStorage.getItem('username');
    this.logItem.userID = localStorage.getItem('userID');
    this.logItem.client = localStorage.getItem('clientdb');
    this.logItem.action = this.getAction(action);
    this.logItem.device = navigator.userAgent;
    this.btLog.push(this.logItem);
    if (online) {
      this.sendLogItem(this.logItem);
    } else {
      if(localStorage.getItem('btlog') != null && localStorage.getItem('btlog') != undefined) {
        let tempLog = JSON.parse(localStorage.btlog);
        console.log("Logs from localStorage: " + tempLog);
        tempLog.push(this.logItem);
        localStorage.btlog = JSON.stringify(tempLog);
      } else {
        let tempLog = [];
        tempLog.push(this.logItem);
        localStorage.btlog = JSON.stringify(tempLog);
      }
    }
  }

  addResponseLog() {
    // TODO - add response logs for upload...
  }

  getAction(action: any) {
    switch (action) {
      case '0': return 'Open Lock';
        break;
      case '1': return 'Close Lock';
        break;
      case '2': return 'Stand Down';
        break;
      case '3': return 'Set Time';
        break;
      case 'time': return 'Set Time';
        break;
      default: return 'Unknown Action'; break;
    }
  }

  sendLogItem(logItem: any) {
    let myEvent = [];
    myEvent.push(logItem);

    this.webReq.pushBTEvents(myEvent).subscribe(res => {
      console.log(res);
    });
  }

  sendAllLogs() {
    if (localStorage.getItem('btlog') != null && localStorage.getItem('btlog') != undefined) {
      let logs = JSON.parse(localStorage.btlog);
      if (logs.length > 0) {
        this.webReq.pushBTEvents(logs).subscribe(res => {
          console.log(res);
          localStorage.btlog = JSON.stringify([]);
        });
      }
    }
  }
}
