import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OptionalBreakPoint } from '@angular/flex-layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss']
})
export class TokensComponent implements OnInit {

  @Output() close = new EventEmitter();

  public user = {}
  public email = ""
  public password = ""

  selectedSite: any;
  sites: any[] = [];
  fromTime: any="";
  toTime: Date= new Date();
  fromDate: any = new Date(Date.now() + 119*60000).toISOString().slice(0, 16); // toISO returns GMT Time (-2 hours)
  toDate: any= new Date(Date.now() + 150*60000).toISOString().slice(0, 16);
  minDate: any = new Date(Date.now() + 118*60000).toISOString().slice(0, 16);

  gotTokens = false;
  tempToken0 = "";
  tempToken1 = "";
  tempToken2 = "";
  tokensSet: boolean = false;
  step: number = 1;
  edit: boolean = false;

  mySite = "";

  public isLoggedIn: boolean = false;

  constructor(private router: Router,
    private webReq: WebRequestsService,
    private auth: AuthService,
    public loader: LoaderService,
    public snackBar: MatSnackBar,
    public notifier: NotificationsService) { }

  ngOnInit() {
    if (localStorage.getItem('isLoggedIn') == "true") {
      this.isLoggedIn = true;
      var temp = localStorage.getItem('username')
      if (temp) {
        this.email = temp
      }
      this.getSites();
    }
  }

  login(email: string, password: string) {
    this.loader.isLoading = true;
    this.user = {
      'username': this.email.toLowerCase(),
      'password': this.password
    }
    this.webReq.login(this.user).subscribe(data => {
      // console.log(data)
      if (data.loggedIn == true) {
        localStorage.setItem('username', this.email);
        // localStorage.setItem('password', this.password);
        localStorage.setItem('isLoggedIn', 'true');
        this.auth.isLoggedIn = true;
        this.isLoggedIn = true;
        this.loader.isLoading = false;
        this.getSites();
        // this.router.navigate(['sidenavnew/page-one'], {replaceUrl: true});
      }
    }, err => {
      this.loader.isLoading = false;
      this.snackBar.open('Invalid Credentials', 'Ok', { duration: 3000 })
    });
    // this.router.navigate(['sidenavnew'], {replaceUrl: true});
  }

  getSites() {
    this.loader.isLoading = true;
    this.webReq.getSites(this.email).subscribe((data: any) => {
      this.sites = data
      this.loader.isLoading = false;
    }, err => {
      this.loader.isLoading = false;
      this.snackBar.open('Failed to get sites', 'Ok', { duration: 3000 })
    });
  }


  getTokens() {
    this.loader.isLoading = true;
    //Time Token:
    this.webReq.generateToken(this.selectedSite.id, 1, [0]).subscribe((tokensA: { "tokens": any[] }) => {

      let timeToken = tokensA.tokens[0];
      localStorage.setItem("t_" + this.selectedSite.siteID + "_" +'time', timeToken[2]);
      //Function Codes:
      this.webReq.generateToken(this.selectedSite.id, [0,3], [0, 1, 2]).subscribe((tokens: { "tokens": [] }) => {
        let tokensList: [] = tokens.tokens;
        // console.log(tokensList);
        tokensList.forEach(token => {
          localStorage.setItem("t_" + this.selectedSite.siteID + "_" + token[1], token[2])
        });

        let t0 = localStorage.getItem("t_" + this.selectedSite.siteID + '_0');
        let t1 = localStorage.getItem("t_" + this.selectedSite.siteID +'_1');
        let t2 = localStorage.getItem("t_" + this.selectedSite.siteID +'_2');

        t0 != null ? this.tempToken0 = t0 : t0 = "";
        t1 != null ? this.tempToken1 = t1 : t1 = "";
        t2 != null ? this.tempToken2 = t2 : t2 = "";

        this.tokensSet = true;
        this.loader.isLoading = false;
      }, err => {
        this.loader.isLoading = false;
        this.notifier.openMessage('Failed to generate tokens', 'error');
      });
    }, err => {
      this.loader.isLoading = false;
      this.notifier.openMessage('Failed to generate tokens', 'error');
    });
  }

  getTokensNew() {
    this.loader.isLoading = true;
    this.webReq.generateTokenNew(this.selectedSite, [[1,0],[0,0], [0,1],[0,2],[3,0],[2,0],[4,0]], new Date(this.fromDate).getTime(), new Date(this.toDate).getTime()).subscribe((tokens: {"tokens":[],"from":any,"to":any}) => {
      console.log(tokens.tokens);
      tokens.tokens.forEach(tokenObj => {
        if(tokenObj[0] == 3) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_diag",tokenObj[2]);
        }
        if(tokenObj[0] == 0) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_" + tokenObj[1], tokenObj[2]);
        }
        if(tokenObj[0] == 1) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_time",tokenObj[2]);
        }
        if(tokenObj[0]==2) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_conf", tokenObj[2])
        }
        if(tokenObj[0]==4) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_reset", tokenObj[2])
        }
      });
      localStorage.setItem("t_" + this.selectedSite.siteName + "_from", tokens.from);
      localStorage.setItem("t_" + this.selectedSite.siteName + "_to", tokens.to);
      this.mySite = this.selectedSite.siteName;      
      this.loader.isLoading = false;
      this.tokensSet = true;
    }, err => {
      console.log(err);
      this.loader.isLoading = false;
    });
  }

  showDetails() {
    console.log("Selected site ID: "+this.selectedSite.id + "\nFrom date: " + this.fromDate+ " type: " + new Date(this.fromDate).getTime() +"\nFrom time: "+this.fromTime+" type: " + typeof(this.fromTime))
  }

  closeGenerator() {
    this.close.emit();
  }

  defaultToTime() {
    return new Date().toISOString();
  }

  defaultFromTime() {
    return new Date().toISOString();
  }
}

