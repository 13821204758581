import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-generate-system-tokens',
  templateUrl: './generate-system-tokens.component.html',
  styleUrls: ['./generate-system-tokens.component.scss']
})
export class GenerateSystemTokensComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Input() users: any;
  @Input() filteredUsers: any;
  // @Input() siteGroups: any;
  @Input() reasonList: any[] = ["None"];

  public reason:any;

  userNameFilter="";
  siteFilter = "";
  public userList: any;
  // public filteredUsers: any = [];
  public allUsers:any;
  public userSites: any;
  public filteredSites:any;
  public siteGroups: any;
  public filteredSiteGroups:any;
  public siteID: any;
  public siteGroup: any;
  public username: any;
  public tokensSet = false;
  public fromDate:any  = new Date(Date.now() + 119*60000).toISOString().slice(0, 16); // toISO returns GMT Time (-2 hours)
  public toDate:any = new Date(Date.now() + 150*60000).toISOString().slice(0, 16);
  public minDate: any = new Date(Date.now() + 118*60000).toISOString().slice(0, 16);

  public isSiteGroup: boolean = false;
  // public 

  constructor(public webReq: WebRequestsService, public loader: LoaderService, public notifier: NotificationsService) {
    // this.filteredUsers = this.users;
   }

  ngOnInit(): void {
    this.allUsers = this.users
    // console.log("init generate-system-tokens, users: " + this.users)
    this.webReq.getSiteGroups().subscribe((res:any) => {
      this.siteGroups = res.siteGroups;
      this.filteredSiteGroups = this.siteGroups;
    });
  }

  filterUsers(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    // console.log("filtering: " + filterValue);
    this.filteredUsers = this.users.filter(x => x.username.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredUsers = this.users;
    }
  }

  filterSites(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    // console.log("filtering: " + filterValue);
    this.filteredSites = this.userSites.filter(x => x.siteName.toLowerCase().includes(filterValue) || x.siteID.toString().includes(filterValue));
    if(filterValue == "") {
      this.filteredSites = this.userSites;
    }
  }

  filterSiteGroups(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    // console.log("filtering: " + filterValue);
    this.filteredSiteGroups = this.siteGroups.filter(x => x.groupName.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredSiteGroups = this.siteGroups;
    }
  }

  generateSystemTokens(site:any) {
    this.getTokensNew(site);
  }

  getUserSites(user: any) {
      this.userSites = user.sites.filter((x:any)=> x != null && x.clientdb == localStorage.getItem('clientdb'));
      this.filteredSites = this.userSites;
      console.log(this.userSites)
  }


  getTokensNew(site:any) {
    
    this.loader.isLoading = true;
    let pArray = [];

    if(this.isSiteGroup) {
      this.siteGroup.forEach((group:any) => {
        group.sites.forEach((site:any) => {
          pArray.push(this.webReq.generateSystemTokensNew(site,[[1,0],[0,0],[0,1],[0,2],[3,0],[2,0], [4,0]],new Date(this.fromDate).getTime(), new Date(this.toDate).getTime(),this.username.username, this.username._id, this.reason).toPromise());          
        });
      });
    } else {
      site.forEach(siteID => {

        if(siteID.door2 == true && (siteID.door1 == undefined || siteID.door1 == false)) {
          pArray.push(this.webReq.generateSystemTokensNew(siteID,[[1,0],[5,0],[5,1],[5,2],[3,0],[2,0], [4,0]],new Date(this.fromDate).getTime(), new Date(this.toDate).getTime(),this.username.username, this.username._id, this.reason).toPromise()); 
  
           } else if(siteID.door1 == true && siteID.door2 == true) {
          pArray.push(this.webReq.generateSystemTokensNew(siteID,[[1,0],[0,0],[0,1],[0,2], [5,0], [5,1], [5,2],[3,0],[2,0], [4,0]],new Date(this.fromDate).getTime(), new Date(this.toDate).getTime(),this.username.username, this.username._id, this.reason).toPromise()); 
           }
            else {
            pArray.push(this.webReq.generateSystemTokensNew(siteID,[[1,0],[0,0],[0,1],[0,2],[3,0],[2,0], [4,0]],new Date(this.fromDate).getTime(), new Date(this.toDate).getTime(),this.username.username, this.username._id, this.reason).toPromise()); 
           }

        // pArray.push(this.webReq.generateSystemTokensNew(siteID,[[1,0],[0,0],[0,1],[0,2],[3,0],[2,0],[4,0]],new Date(this.fromDate).getTime(), new Date(this.toDate).getTime(),this.username.username, this.username._id, this.reason).toPromise());
      });
    }

    Promise.all(pArray).then(values => {  // This allows for multiple sites for token generation
      // console.log(values);
      this.tokensSet = true;
      this.loader.isLoading = false;
      console.log(values)
      values.forEach(value => {
        if(value.success == false) {
          this.notifier.openMessage(value.message, 'error');
        } else {
          this.notifier.openMessage('v-Keys generated', 'success');
        }
      })
      
    }).catch(err => {
      this.loader.isLoading = false;
      this.notifier.openMessage('Failed to generate v-Keys', 'error');
    })
  }

  closing() {
    this.close.emit();
  }

}

