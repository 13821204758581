<div class="myHeader">
    Site Reports
</div>
<!-- <app-loader ></app-loader> -->



<div class="explorer" fxLayout="column" fxLayoutAlign="start start">
       <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
        
        <div>
            <mat-form-field appearance="outline">
                <mat-label>Filter</mat-label>
                <input matInput [(ngModel)]="siteFilter" (keyup)="applyFilter($event)">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 40vw">
            <mat-form-field appearance="outline">
                <mat-label>From</mat-label>
                <input matInput [(ngModel)]="fromFilter" (keyup)="applyDateFilter($event)" type="datetime-local">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>To</mat-label>
                <input matInput [(ngModel)]="toFilter" (keyup)="applyDateFilter($event)" type="datetime-local">
            </mat-form-field>
            <button mat-raised-button (click)="searchByDates()" color="primary"><mat-icon>search</mat-icon>Search</button>
            <button mat-raised-button (click)="resetData()" color="accent"><mat-icon>cancel</mat-icon>Reset</button>
        </div>
       
        
       </div>

        <mat-accordion style="max-height: 70vh; overflow: auto; width: 100%">
            <mat-expansion-panel *ngFor="let dir of directories">
                <mat-expansion-panel-header>

                    <mat-panel-title>{{dir.folder}}</mat-panel-title>
                    <mat-panel-description>{{dir.siteName}}</mat-panel-description>
                </mat-expansion-panel-header>
                <div class="dlLink" *ngFor="let file of dir.files" (click)="viewReport(dir.folder+'/'+file)">
                     {{file}}
                </div>
            </mat-expansion-panel>
        </mat-accordion>
</div>

<div class="backdrop" *ngIf="showIframe || isLoading"></div>
<iframe id="pdfViewer" [src]="iframeSrc" style="width: 70vw; height: 90vh;" zindex="3011" *ngIf="showIframe" ></iframe>
<button mat-mini-fab id="closeiFrame" style="z-index: 3012; position: absolute; right:10vw; top: 5vh" color="warn" *ngIf="showIframe" (click)="showIframe = false"><mat-icon>close</mat-icon></button>
