import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationsService } from 'src/app/services/notifications.service';
import { error } from 'console';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss']
})
export class AlarmsComponent implements OnInit, AfterViewInit, OnDestroy {

  alarmList: any = [];
  dataSource = new MatTableDataSource<any>();
  dataSourceCurrent = new MatTableDataSource<any>();
  displayedColumns = ['id', 'type', 'siteID', 'siteName', 'time']
  displayedColumnsCurrent = ['time', 'priority', 'type', 'siteID', 'siteName', 'acknowledged', 'acknowledgedBy', 'acknowledgedTime', 'acknowledge', 'resolve']
  wsConnected: boolean = false;

  startResolve: boolean = false;
  resolveComment: string = "";
  selectedAlarm: any = {};


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public socket;
  public reconnectInterval: number = 5000;
  private healthCheckInterval: number = 10000; // Time in ms to check connection health
  private healthCheckTimer: any;

  private messageSubscription: Subscription;

  
  constructor(public ws: WebsocketService, public webReq: WebRequestsService, public notifications: NotificationsService) { }

  ngAfterViewInit() {
    this.dataSourceCurrent.paginator = this.paginator;
    this.dataSourceCurrent.sort = this.sort;
  }

  ngOnDestroy(): void {
    if(this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    // this.ws.close();
  }

  ngOnInit(): void {

    this.messageSubscription = this.ws.messages$.subscribe((message) => {
      this.doUpdateList(message);
    })
    this.webReq.getCurrentAlarms().subscribe(alarms => {
      this.alarmList = alarms;
      this.dataSourceCurrent = new MatTableDataSource<any>(this.alarmList);
      this.dataSourceCurrent.paginator = this.paginator;
      this.dataSourceCurrent.sort = this.sort;
      this.notifications.currentAlarms = alarms.length;
    });

  }

  sortData($event) {
    console.log("Sort clicked")
  }

  doUpdateList(alarm) {
    try {
      if (JSON.parse(alarm).client == localStorage.getItem('clientdb')) {
        if (JSON.parse(alarm).messageType == "new alarm") {
          this.alarmList.push(JSON.parse(alarm))
          this.dataSourceCurrent = new MatTableDataSource<any>(this.alarmList);
          this.dataSourceCurrent.paginator = this.paginator
          this.dataSourceCurrent.sort = this.sort;
          // this.dataSource = new MatTableDataSource<any>(this.alarmList);
        } else if (JSON.parse(alarm).messageType == "alarm acknowledged") {
          this.alarmList.forEach(alarmItem => {
            if (alarmItem.alarmUUID == JSON.parse(alarm).alarmUUID) {
              alarmItem.acknowledged = 1;
              alarmItem.acknowledgedTime = JSON.parse(alarm).time;
              alarmItem.acknowledgedBy = JSON.parse(alarm).acknowledgedBy;
            }
          });
          this.dataSourceCurrent = new MatTableDataSource<any>(this.alarmList);
          this.dataSourceCurrent.paginator = this.paginator;
          this.dataSourceCurrent.sort = this.sort;
        } else if (JSON.parse(alarm).messageType == "alarm resolved") {
          let tempAlarmList = [];
          this.alarmList.forEach(alarmItem => {
            if (alarmItem.alarmUUID != JSON.parse(alarm).alarmUUID) {
              tempAlarmList.push(alarmItem);
            }
          });
          this.alarmList = tempAlarmList;
          this.dataSourceCurrent = new MatTableDataSource<any>(this.alarmList);
          this.dataSourceCurrent.paginator = this.paginator;
          this.dataSourceCurrent.sort = this.sort;
        }
  
      }
    }
    catch(err) {
      console.info(`Didn't parse: ${alarm}`)
    }
    
  }

  acknowledgeAlarm(alarm) {
    this.webReq.acknowledgeAlarm(alarm).subscribe(result => {
      if (result.success == true) {

        console.log('Alarm Acknowledged')

      } else {
        alert('Failed to acknowledge, please try again')
      }
    })
  }

  resolveAlarm() {
    var myObj = { alarm: this.selectedAlarm, username: localStorage.getItem('username'), comment: this.resolveComment }
    console.log(myObj)
    this.webReq.resolveAlarm(myObj).subscribe(result => {
      if (result.success == true) {
        console.log("Alarm Resolved")
        this.startResolve = false;
        this.selectedAlarm = {};
        this.webReq.getCurrentAlarms().subscribe(res => {
          this.notifications.currentAlarms = res.length;
        })
      } else {
        alert("Failed to resolve alarm")
      }
    })
  }

  getPriorityColor(priority) {
    if (priority == 1) {
      return 'red';
    } else if (priority == 2) {
      return 'orange';
    } else if (priority == 3) {
      return 'green';
    } else return 'white'
  }

  private startHealthCheck() {
    this.healthCheckTimer = setInterval(() => {
      if (this.socket?.readyState !== WebSocket.OPEN) {
        console.warn('WebSocket health check failed, reconnecting...');
        this.wsConnected = false;
        this.socket?.close();
        this.socket?.connect();
      }
    }, this.healthCheckInterval);
  }

  private stopHealthCheck() {
    if (this.healthCheckTimer) {
      clearInterval(this.healthCheckTimer);
      this.healthCheckTimer = null;
    }
  }

  private tryReconnect() {
    this.socket?.close();
    this.socket?.connect();
  }
}
