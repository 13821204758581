<mat-sidenav-container>
  <mat-sidenav #drawer mode="over">
    <mat-nav-list>
      <a mat-list-item (click)="drawer.toggle()" [routerLink]="['page-one']">
        <mat-icon>home</mat-icon> Home
      </a>
      <a mat-list-item (click)="drawer.toggle()" [routerLink]="['token-tools']" *ngIf="canGenerate">
        <mat-icon>lock</mat-icon> User v-Keys
      </a>

      <!-- <a mat-list-item (click)="drawer.toggle()" [routerLink]="['page-two']">
        <mat-icon>bluetooth</mat-icon> Controller Functions
      </a> -->

      <!-- <a mat-list-item *ngIf="sysadmin" (click)="drawer.toggle()" [routerLink]="['logs']">
        <mat-icon>manage_search</mat-icon> Logs
      </a> -->
      <a mat-list-item (click)="drawer.toggle()" [routerLink]="['admin-home']">
        <mat-icon>edit</mat-icon> Admin
      </a>
      <a mat-list-item (click)="drawer.toggle()" [routerLink]="['alarms']" *ngIf="isAdmin">
        <mat-icon>alarm</mat-icon> Alarms
      </a>
      <!-- <a mat-list-item (click)="drawer.toggle()" [routerLink]="['my-tokens']">
        <mat-icon>visibility</mat-icon> My Tokens
      </a> -->
      <!-- <a mat-list-item (click)="drawer.toggle(); openManual()">
        <mat-icon>help_outline</mat-icon> Help
      </a> -->
      <a mat-list-item (click)="drawer.toggle()" [routerLink]="['login']">
        <mat-icon>logout</mat-icon> Logout
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="custom-bg" fxLayout=column fxLayoutAlign="space-between stretch" fxFlex=100
      style="background-color: white;height: 100vh;">
      <img *ngIf="clientdb == 'superfecta'" src="../../../assets/clients/sft/sft_logo_1.png"
        style="position: fixed; max-height: 6vh !important; top: 95vh; left: 40vw; width: 10vw;">

      <mat-toolbar class="mat-elevation-z8" style="height: 7vh !important; z-index: 3000"
        fxLayoutAlign="space-between center">

        <button mat-icon-button (click)="openMenu()" [matBadge]="notifications.currentAlarms+notifications.currentHandovers+notifications.currentKeyRequests" matBadgeColor="warn" [matBadgeHidden]="notifications.currentAlarms + notifications.currentHandovers + notifications.currentKeyRequests == 0">
          <mat-icon >widgets</mat-icon>
        </button>

        <span><img src="../../../assets/ethele-white.png" style="max-height: 6vh !important; margin: 4px"></span>
        <span><img src="../../../assets/prism.png" style="max-height: 6vh; margin: 4px"></span>

      </mat-toolbar>

      <router-outlet></router-outlet>

      <div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>

      <mat-toolbar class="mat-elevation-z8" style="height: 6vh !important; z-index: 3000;">
        <label style="position: absolute; right: 2vw; font-size: smaller; color:#F37321">{{username}}</label>
        <label style="position: absolute; right: 2vw; bottom: 0vh; font-size: small; color:#F37321">{{admin}}</label>

        <mat-icon *ngIf="conn.online"
          style="color: limegreen; font-size: smaller; position: absolute; left: 20px;bottom:25px;">wifi</mat-icon>
        <mat-icon *ngIf="!conn.online"
          style="color: red; font-size: smaller; position: absolute; left: 20px;bottom:25px;">wifi_off</mat-icon>
        <label style="position: absolute; left: 15px; bottom: 0vh; font-size: small; color:#F37321"><a
            (click)="checkSites()" [matTooltip]="sitesString">{{clientdb}}</a> </label>

      </mat-toolbar>

    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-card *ngIf="showSites" style="position: absolute; left: 10vw; top: 10vh; z-index: 1000; width: 80vw;">
  <mat-card-title>Site List</mat-card-title>
  <mat-card-content>
    <div *ngFor="let site of siteList" fxLayout="column">
      <label>Site: {{site[1]}}</label>
      <label>ID: {{site[0]}}</label>
      <label>--------</label>
    </div>
  </mat-card-content>
  <mat-card-actions fxLayoutAlign="center center">
    <button mat-raised-button (click)="showSites = false" color="warn">Close</button>
  </mat-card-actions>
</mat-card>

<!-- <app-hover-menu></app-hover-menu> -->

<!-- Make hover menu as part of sidenav -->
<div class="backdrop" *ngIf="showHoverMenu">
  <div id="overlay-menu">

    <div class="close-button" fxLayout="column" fxLayoutAlign="center center" (click)="openMenu()">
      <mat-icon>close</mat-icon>
    </div>
    <div class="menu-container" fxLayout="column">
      <div class="headerText">
        Dashboards
    </div>
     <div fxLayout="row">
      <app-menu-item-main (close)="showHoverMenu = false" icon="dashboard" label="System Analysis" description="Main dashboard for auditing and tracking" link="dashboard"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" icon="summarize" label="Operational Summaries" description="Operational dashboard for sites overview" link="prism-dashboard"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" icon="query_stats" label="Diagnostic Reports" description="View Diagnostic Report documents" link="site-reports"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" [matBadge]="notifications.currentAlarms" matBadgeColor="warn" [matBadgeHidden]="notifications.currentAlarms == 0" icon="notification_important" label="Alarms" description="View and handle currently active alarms" link="alarms"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" icon="notifications" label="Alarm history" description="View historical data of alarms" link="alarms-history"></app-menu-item-main>
    
     </div>
     <hr>
    
     <div class="headerText">
         Users
     </div>
     <div fxLayout="row">
      <app-menu-item-main (close)="showHoverMenu = false" icon="person" label="User Management" description="Manage User details, allowed sites and access" link="user-management"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" icon="group_add" label="Sub Contractors" description="Manage Subcontractor Details" link="subcontractor-management"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" icon="person_off" label="Locked Users" description="View and manage currently locked users" link="blocked-users" *ngIf="isAdmin"></app-menu-item-main>
     </div>
     <hr>
    
     <div class="headerText">
         Sites
     </div>
     <div fxLayout="row">
      <app-menu-item-main (close)="showHoverMenu = false" icon="domain" label="Site Management" description="Manage Site details and configurations" link="site-management"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" icon="extension" label="Config Templates" description="Manage Configuration Templates for easy config on controllers" link="config-templates"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" [matBadge]="notifications.currentHandovers" matBadgeColor="warn" [matBadgeHidden]="notifications.currentHandovers == 0"  icon="handshake" label="Handover Requests" description="Manage sites which are requested for handover" link="handover-requests"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" icon="handshake" label="Handovers" description="Manage Handover site details and groupings" link="handover-management"></app-menu-item-main>
     </div>
     <hr>
    
     <div class="headerText">
         Access
     </div>
     <div fxLayout="row">
      <app-menu-item-main (close)="showHoverMenu = false" icon="shield" label="V-Keys" description="Create V-Keys for site access" link="token-tools"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" [matBadge]="notifications.currentKeyRequests" matBadgeColor="warn" [matBadgeHidden]="notifications.currentKeyRequests == 0" icon="shield" label="V-Key Requests" description="Approve V-Key Requests for site access" link="key-requests"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" icon="schedule" label="Schedules" description="Create Scheduled access to sites" link="schedules"></app-menu-item-main>
     </div>
     <hr>
    
     <div class="headerText">
         Other Services
     </div>
     <div fxLayout="row">
      <app-menu-item-main (close)="showHoverMenu = false" icon="support_agent" label="Support" description="Customer Support link" link="https://myprism.co.za/support/"></app-menu-item-main>
      <app-menu-item-main (close)="showHoverMenu = false" icon="key" label="Change Password" description="Change your user password" link="change-password"></app-menu-item-main>
     </div>
      
  </div>
</div>
</div>


<div id="logoutButton" (click)="logout()">
  <div fxLayout="row" fxLayoutAlign="center center">
    Logout <mat-icon>logout</mat-icon>
  </div>
</div>
<div id="homeButton" (click)="navToHome()" fxLayout="column" fxLayoutAlign="center">
  <mat-icon>home</mat-icon>
</div>

<!-- sideMenu -->
<!-- <div class="side-tab"> -->
<div class="puller" hidden>
  <label id="openicon">
    <</label>
      <div class="side-tab-container" fxLayout="column" fxLayoutAlign="center center">
        <div class="container">

          <div fxLayout="row" fxLayoutAlign="space-evenly center" style="width: 100%; margin: 4px;">
            <div class="btn"
              style="width: 15vh !important; height: 15vh !important; font-size: small !important; margin-right: 8px;"
              fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToPrismDashboard()">
              <mat-icon fxLayoutAlign="center center">summarize</mat-icon>
              <div fxLayout="column" fxLayoutAlign="center center">
                <label>Operational</label><label>Summaries</label>
              </div>
            </div>

            <div class="btn"
            style="width: 15vh !important; height: 15vh !important; font-size: small !important; margin-right: 8px;"
            fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToConfigTemplates()">
            <mat-icon fxLayoutAlign="center center">extension</mat-icon>
            <div fxLayout="column" fxLayoutAlign="center center">
              <label>Config</label><label>Templates</label>
            </div>

          </div>


            <div class="btn" style="width: 15vh !important; height: 15vh !important; font-size: small !important;"
              fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToSupport()">
              <mat-icon fxLayoutAlign="center center">support_agent</mat-icon>
              Support
            </div>
          
            <div class="btn" style="width: 15vh !important; height: 15vh !important; font-size: small !important;"
            fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToSubcontractors()">
            <mat-icon fxLayoutAlign="center center">group_add</mat-icon>
            <label>Sub</label> <label>Contractors</label> 
          </div>
        

          </div>

          

          <div fxLayout="row" fxLayoutAlign="space-evenly start" style="width: 100%; margin: 4px;">
            
          

            <div class="btn"
              style="width: 15vh !important; height: 15vh !important; font-size: small !important; margin-right: 8px;"
              fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToDiagnosticReports()">
              <mat-icon fxLayoutAlign="center center">query_stats</mat-icon>
              <div fxLayout="column" fxLayoutAlign="center center">
                <label>Diagnostic</label><label>Reports</label>
              </div>              
            </div>

            <div class="btn"
            style="width: 15vh !important; height: 15vh !important; font-size: small !important; margin-right: 8px;"
            fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToAlarmHistory()">
            <mat-icon fxLayoutAlign="center center">notification_important</mat-icon>
            <div fxLayout="column" fxLayoutAlign="center center">
              <label>Alarm</label><label>History</label>
            </div> 

          </div>

          <div class="container" [hidden]="!canApprove">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToBlockedUsers()">
              <mat-icon fxLayoutAlign="center center">person_off</mat-icon>
              <div fxLayout="column" fxLayoutAlign="center center">
              <label>Locked</label><label>Users</label>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center" (click)="navToChangePwd()">
              <mat-icon fxLayoutAlign="center center">key</mat-icon>
              <div fxLayout="column" fxLayoutAlign="center center">
              <label>Change</label><label>Password</label>
              </div>
            </div>
          </div>

          </div>


        </div>
      </div>
</div>

<!-- </div> -->