import { Component, OnInit } from '@angular/core';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-config-templates',
  templateUrl: './config-templates.component.html',
  styleUrls: ['./config-templates.component.scss']
})
export class ConfigTemplatesComponent implements OnInit {

  myTemplates:any = [];
  isAdding: boolean = false;
  selectedConfig:any = null;
  showAddTemplate = false;
  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
    this.getConfigTemplates();
  }

  getConfigTemplates() {
    this.webReq.getConfigTemplate().subscribe(res => {
      console.log(res)
      this.myTemplates = res;
    })
  }

  checkBG(templ) {
    return templ.color;
  }

}
