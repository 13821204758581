<mat-card class="mat-elevation-z8" style=" border: 4px solid rgba(146, 148, 248, 1)">
    <div fxLayout="column">
        <h2>Handover Site</h2>
        <div>
            <label>Details for Site:</label>
            <div fxLayout="column">
                <!-- Site ID / iCrypto T-ID -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Site ID (iCrypto T-ID)</mat-label>
                        <input matInput [(ngModel)]="selectedSiteGroup.groupID">
                    </mat-form-field>
                </div>

                <!-- Group Name -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Group Name</mat-label>
                        <input matInput [(ngModel)]="selectedSiteGroup.groupName">
                    </mat-form-field>
                </div>

                
                <button style="margin: 4px;" mat-raised-button color="primary" (click)="addSiteGroup()">
                    <mat-icon>save</mat-icon> Create Group
                </button>
                <button style="margin: 4px;" mat-raised-button color="warn" (click)="closing()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
            </div>
        </div>
    </div>
</mat-card>