import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

const WSUrl = environment.wsUrl;



export interface Message {
  source: string;
  content: string;
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  public socket: WebSocket | null = null;
  private url: string = environment.wsUrl; // Replace with your WebSocket URL
  private reconnectInterval: number = 5000; // Time in ms to wait before reconnecting
  public isConnected: boolean = false;
  private healthCheckInterval: number = 10000; // Time in ms to check connection health
  private healthCheckTimer: any;


  private messageSubject = new Subject<string>();
  public messages$ = this.messageSubject.asObservable();

  constructor() {
    this.connect();
  }

  connectWS() {
    return new WebSocket(WSUrl, "websocket")
  }

  private connect() {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      console.log('WebSocket connected');
      this.isConnected = true;
      this.startHealthCheck();
    };

    this.socket.onmessage = (message) => {
      console.log('Received message:', message.data);
      let parsedMessage:any = {};
      try {
        parsedMessage = JSON.parse(message.data);
      } catch(err) {

      }
      if (parsedMessage.type === 'PONG' || message.data.includes('PONG')) {
        console.log('Received PONG response');
        // Reset your health check or handle pong response here

      } else {
        this.messageSubject.next(message.data);
        // Handle incoming messages here
      }
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket closed', event);
      this.isConnected = false;
      this.stopHealthCheck();
      // Attempt to reconnect
      setTimeout(() => this.connect(), this.reconnectInterval);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error', error);
      // Close the socket on error to trigger the onclose event
      this.socket?.close();
    };
  }

  private startHealthCheck() {
    this.healthCheckTimer = setInterval(() => {
      if (this.socket?.readyState !== WebSocket.OPEN) {
        console.warn('WebSocket health check failed, reconnecting...');
        this.isConnected = false;
        this.socket?.close();
        this.connect();
      } else {
        let testMsg = {
          type: "PING",
          message: "PING",
          username: localStorage.getItem('username')
        }
        this.sendMessage(JSON.stringify(testMsg))
      }
    }, this.healthCheckInterval);
  }

  private stopHealthCheck() {
    if (this.healthCheckTimer) {
      clearInterval(this.healthCheckTimer);
      this.healthCheckTimer = null;
    }
  }

  sendMessage(message: string) {
    if (this.isConnected) {
      console.log(`Will now send message: ${JSON.stringify(message)}`)
      this.socket?.send(message);
      // console.log('Socket:')
      // console.log(this.socket)
    } else {
      console.warn('WebSocket is not connected. Cannot send message:', message);
    }
  }

  close() {
    this.stopHealthCheck();
    this.socket?.close();
  }
}
