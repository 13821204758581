<div fxFlex fxLayoutAlign="space-evenly center" fxLayout="column">
  <app-loader></app-loader>
  <div class="cover"
    *ngIf="addingSite || addingUsers || selectedUser != null || selectedSite != null && loader.isLoading == false">
  </div>

  <h1>Welcome {{username}}</h1>
  
  <h2 *ngIf="!isAdmin">Please use the menu to navigate</h2>

  <div style="height: 75vh; width: 100vw; max-height: 75vh; overflow: auto;" fxLayoutAlign="space-evenly start"
    fxLayout="column">
    
    <button *ngIf="isAdmin" mat-raised-button style="position: fixed; right: 0; top: 12vh;" color="primary"
      (click)="addingBatch = true">
      <mat-icon>upload</mat-icon> Batch Add
    </button>

    <div fxLayout="row" *ngIf="isAdmin">
      <!-- Sites -->
      <div class="adminPanel">
        <div fxLayoutAlign="space-between center">
          <label>Manage Sites</label>
          <mat-form-field style="right: 4px;">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applySitesFilter($event)" placeholder="Ex. default" value="" #input>
          </mat-form-field>
          <div></div>
        </div>
        <!-- Use Tabs here for all/groups -->
        <div class="listBox">
          <div fxLayoutAlign="space-between center" style="margin: 4px;">
            <label style="font-size: large;">Sites</label>
            <button mat-mini-fab color="primary" (click)="addingSite = true"
              style="position: relative; top: 4px; right: 4px;">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>
          <div style="max-height: 25vh; overflow-y: auto;">
            <div *ngFor="let site of filteredSites" class="listItem" (click)="selectedSite = site">
              <!-- <div *ngFor="let site of allSites" class="listItem" (click)="selectedSite = site"> -->
              <div fxLayoutAlign="start center">{{site.siteName}} ({{site.siteID}})
                <mat-icon style="font-size: small; color:limegreen;"
                  *ngIf="site.device != null && site.device != undefined && site.device.linked == true">radio_button_checked</mat-icon>
                <mat-icon style="font-size: small; color:red;"
                  *ngIf="site.device == null || site.device == undefined || site.device.linked == false">radio_button_checked</mat-icon>
              </div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
      <!-- Site Groups -->
      <div class="adminPanel">
        <div fxLayoutAlign="space-between center">
          <label>Manage Site Groups</label>
          <mat-form-field style="right: 4px;">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applySiteGroupFilter($event)" placeholder="Ex. default" value="" #input>
          </mat-form-field>
          <div></div>
        </div>
        <!-- Use Tabs here for all/groups -->
        <div class="listBox">
          <div fxLayoutAlign="space-between center" style="margin: 4px;">
            <label style="font-size: large;">Site Groups</label>
            <button mat-mini-fab color="primary" (click)="addingSiteGroup = true"
              style="position: relative; top: 4px; right: 4px;">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>
          <div style="max-height: 25vh; overflow-y: auto;">
            <div *ngFor="let group of filteredSiteGroups" class="listItem"
              (click)="selectedSiteGroup = group; editingSiteGroup = true">
              <div>{{group.groupName}}</div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>

      <!-- Sub Contractors -->
      <div class="adminPanel">
        <div fxLayoutAlign="space-between center">
          <label>Manage Sub-Contractors</label>
          <mat-form-field style="right: 4px;">
            <mat-label>Filter</mat-label>
            <input matInput placeholder="Ex. default" value="" #input>
          </mat-form-field>
          <div></div>
        </div>
        <!-- Use Tabs here for all/groups -->
        <div class="listBox">
          <div fxLayoutAlign="space-between center" style="margin: 4px;">
            <label style="font-size: large;">Sub-Contractors</label>
            <button mat-mini-fab color="primary" (click)="addingSubcontractor = true"
              style="position: relative; top: 4px; right: 4px;">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>
          <div style="max-height: 25vh; overflow-y: auto;">
            <div *ngFor="let contractor of filteredSubcontractors" class="listItem"
              (click)="selectedSubcontractor = contractor; editingSubcontractor = true">
              <div>{{contractor.name}}</div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div fxLayout="row" *ngIf="isAdmin">
      <!-- Users -->
      <div class="adminPanel">
        <div fxLayoutAlign="space-between center">
          <label>Manage Users</label>
          <mat-form-field style="right: 4px;">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyUsersFilter($event)" placeholder="Ex. default" value="" #input>
          </mat-form-field>
          <div></div>
        </div>
        <!-- Use Tabs here for all/groups -->
        <div class="listBox">
          <div fxLayoutAlign="space-between center" style="margin: 4px;">
            <label style="font-size: large;">Users</label>
            <button mat-mini-fab color="primary" (click)="addingUsers = true"
              style="position: relative; top: 4px; right: 4px;">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>

          <div style="max-height: 25vh; overflow-y: auto;">
            <div *ngFor="let user of filteredUsers" class="listItem"
              (click)="selectedUser = user; setAllowedSites(user)">
              <div>{{user.username}}</div>
              <mat-divider></mat-divider>
            </div>
          </div>

        </div>
      </div>
      <!-- User Groups -->
      <div class="adminPanel">
        <div fxLayoutAlign="space-between center">
          <label>Manage User Groups</label>
          <mat-form-field style="right: 4px;">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyUserGroupFilter($event)" placeholder="Ex. default" value="" #input>
          </mat-form-field>
          <div></div>
        </div>
        <!-- Use Tabs here for all/groups -->
        <div class="listBox">
          <div fxLayoutAlign="space-between center" style="margin: 4px;">
            <label style="font-size: large;">User Groups</label>
            <button mat-mini-fab color="primary" (click)="addingUserGroup = true"
              style="position: relative; top: 4px; right: 4px;">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <mat-divider></mat-divider>

          <div style="max-height: 25vh; overflow-y: auto;">
            <div *ngFor="let group of filteredUserGroups" class="listItem"
              (click)="selectedUserGroup = group; editingUserGroup = true;">
              <div>{{group.groupName}}</div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>

  <!-- Ask Manual Link -->

  <div *ngIf="manualLinking"
    style="position: absolute; width:auto; left: 45vw; top: 40vh; width: 20vw; border:2px solid white; z-index: 2001;">
    <mat-card *ngIf="manualLinkingStep == 1">
      <button style="position: absolute; right: 4px; top: 4px" mat-mini-fab
        (click)="manualLinkingStep = 1; manualLinking = false"><mat-icon>close</mat-icon></button>
      <h2>Linking Process</h2>
      <p *ngIf="!isUnlinking">Do you wish to link device automatically?</p>
      <p *ngIf="isUnlinking">Do you wish to unlink device automatically?</p>
      <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <button mat-raised-button color="primary" *ngIf="!isUnlinking"
          (click)="startLinkingDevice()"><mat-icon>bluetooth</mat-icon> Yes</button>
        <button mat-raised-button color="primary" *ngIf="isUnlinking"
          (click)="startUnlinkingDevice()"><mat-icon>bluetooth</mat-icon> Yes</button>
        <button mat-raised-button color="accent" (click)="manualLinkingStep = 2"><mat-icon>bluetooth_disabled</mat-icon>
          No</button>
      </div>
    </mat-card>

    <mat-card *ngIf="manualLinkingStep == 2">
      <button style="position: absolute; right: 4px; top: 4px;margin: 4px;" mat-mini-fab
        (click)="manualLinkingStep=1; manualLinking = false"><mat-icon>close</mat-icon></button>
      <h2>Device</h2>
      <div fxLayout="column" style="top: 24px">
        <mat-form-field appearance="outline" *ngIf="!isUnlinking">
          <mat-label>Linked Device S/N</mat-label>
          <input matInput [(ngModel)]="selectedSite.device.serialnumber">
        </mat-form-field>
        <button mat-raised-button (click)="manualLink()" *ngIf="!isUnlinking"
          color="primary"><mat-icon>link</mat-icon>Link</button>
        <button mat-raised-button (click)="manualLink()" *ngIf="isUnlinking"
          color="primary"><mat-icon>link_off</mat-icon>Unlink</button>
      </div>

    </mat-card>
  </div>

  <!-- Add/Edit Subcontractor -->
  <div *ngIf="editingSubcontractor || addingSubcontractor"
    style="position: absolute; width:auto; left: 35vw; top: 20vh; border:2px solid white; z-index: 2001;" cdkDrag>
    <app-edit-subcontractor [contractor]="selectedSubcontractor"
      (close)="selectedSubcontractor = null; editingSubcontractor = false; addingSubcontractor = false; getSitesAndUsers();"></app-edit-subcontractor>
  </div>
  <!-- Add User -->
  <div *ngIf="addingUsers"
    style="position: absolute; width:auto; left: 35vw; top: 20vh; border:2px solid white; z-index: 2001;" cdkDrag>
    <app-add-user (close)="addingUsers = false; getSitesAndUsers()" [subcontractors]="allSubcontractors"></app-add-user>
  </div>

  <!-- Add User Group -->
  <div *ngIf="addingUserGroup"
    style="position: absolute; width:auto; left: 35vw; top: 20vh; border:2px solid white; z-index: 2001;" cdkDrag>
    <app-add-user-group (close)="addingUserGroup = false; getSitesAndUsers();"></app-add-user-group>
  </div>

  <!-- Edit User Group -->
  <div *ngIf="editingUserGroup"
    style="margin:auto; position:absolute; width: 80vw; right: 0; left: 0; border:2px solid white; z-index: 2001;"
    cdkDrag>
    <app-edit-user-group [selectedUserGroup]="selectedUserGroup" [allUsers]="allUsers"
      (close)="editingUserGroup = false; getSitesAndUsers();"></app-edit-user-group>
  </div>

  <!-- Add Site -->
  <div *ngIf="addingSite"
    style="position: absolute; width:auto; left: 35vw; top: 20vh; border:2px solid white; z-index: 2001;" cdkDrag>
    <app-add-site (close)="addingSite = false; getSitesAndUsers();"></app-add-site>
  </div>

  <!-- Add Site Group -->
  <div *ngIf="addingSiteGroup"
    style="position: absolute; width:auto; left: 35vw; top: 20vh; border:2px solid white; z-index: 2001;" cdkDrag>
    <app-add-site-group (close)="addingSiteGroup = false; getSitesAndUsers();"></app-add-site-group>
  </div>

  <!-- Batch Imports -->
  <div *ngIf="addingBatch"
    style="margin:auto; position: absolute; width:auto; max-width: 80vw; right: 0; left: 0; border:2px solid white; z-index: 2001;"
    cdkDrag>
    <app-batch-imports (close)="addingBatch = false; getSitesAndUsers();"></app-batch-imports>
  </div>

  <!-- Edit Site Group -->
  <div *ngIf="editingSiteGroup"
    style="margin:auto; position:absolute; width: 80vw; right: 0; left: 0; border:2px solid white; z-index: 2001;"
    cdkDrag>
    <app-edit-site-group [selectedSiteGroup]="selectedSiteGroup" [allSites]="allSites"
      (close)="editingSiteGroup = false; getSitesAndUsers();"></app-edit-site-group>
  </div>

  <!-- Edit Site Location -->
  <div *ngIf="editSiteLocation"
    style="margin:auto; position:absolute; width: 80vw; right: 0; left: 0; border:2px solid white; z-index: 2001; background-color: gray;"
    cdkDrag>
    <app-map-selector [site]="selectedSite" (close)="editSiteLocation = false"></app-map-selector>
  </div>

  <!-- Edit User Panel -->
  <div class="editPanel" cdkDrag
    style="position: absolute; left: 35vw; top: 12vh; border: 1px solid white; z-index: 3000;"
    *ngIf="selectedUser != null">
    <button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;"
      (click)="selectedUser = null">
      <mat-icon>close</mat-icon>
    </button>
    <div fxLayout="column">
      <h2>Edit User</h2>
      <div fxLayout="row">
        <div>
          <label>Details for User:</label>
          <div fxLayout="column" *ngIf="selectedUser != {}">
            <!-- ID -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>ID</mat-label>
                <input matInput [(ngModel)]="selectedUser._id" disabled>
              </mat-form-field>
            </div>
            <!-- Email -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="selectedUser.email" disabled>
              </mat-form-field>
            </div>
            <!-- Username -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput [(ngModel)]="selectedUser.username">
              </mat-form-field>
            </div>
            <!-- Name -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput [(ngModel)]="selectedUser.name">
              </mat-form-field>
            </div>
            <!-- Surname -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Surname</mat-label>
                <input matInput [(ngModel)]="selectedUser.surname">
              </mat-form-field>
            </div>
            <!-- Subcontractor -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Subcontractor</mat-label>
                <mat-select [(ngModel)]="selectedUser.subcontractor">
                  <mat-option value="None">None</mat-option>
                  <mat-option *ngFor="let contractor of allSubcontractors"
                    [value]="contractor.name">{{contractor.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- Admin -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.isAdmin">Admin</mat-checkbox>
            </div>
            <!-- sysAdmin
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.sysAdmin">System Admin</mat-checkbox>
            </div> -->
            <!-- Tokens -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.generateTokens">Can Generate v-Keys
              </mat-checkbox>
            </div>
            <!-- Can Configure -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.canConfigure">Can Configure
              </mat-checkbox>
            </div>
            <!-- Cellphone -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Cellphone</mat-label>
                <input matInput [(ngModel)]="selectedUser.cellphone">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div>
          <!-- Sites -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="editPanel"
              style="height: 40vh; max-height: 40vh; overflow-y: scroll; width: 20vw; margin: 8px;">
              <div *ngFor="let site of selectedUser.sites">
                <div>
                  <div>{{site.siteName}} ({{site.siteID}})</div>
                  <mat-divider></mat-divider>
                </div>
              </div>
              <button mat-mini-fab
                style="background-color: lightgreen; color:black; position: absolute; right: 25px; top: 54px;"
                (click)="addingUserSites = !addingUserSites">
                <mat-icon>edit</mat-icon>
              </button>
            </div>

            <app-edit-user-sites *ngIf="addingUserSites" [siteList]="allSites" [activeSites]="selectedUser.sites"
              (close)="addingUserSites = false;"
              (savedSites)="selectedUser.sites=$event; addingUserSites = false;"></app-edit-user-sites>

          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <button mat-raised-button style="background-color: lightgreen; color:black" (click)="resetPassword()">
          <mat-icon>restart_alt</mat-icon>Reset Password
        </button>
        <button style="margin: 4px;" mat-raised-button color="warn" (click)="deleteUser()">
          <mat-icon>delete</mat-icon> Delete User
        </button>
        <button style="margin: 4px;" mat-raised-button color="primary" (click)="updateUser()">
          <mat-icon>save</mat-icon> Update User
        </button>
      </div>


    </div>
  </div>

  <!-- Edit Site Panel -->
  <div class="editPanel" style="position: absolute; left: 35vw; top: 20vh; border: 1px solid white; z-index: 2000;"
    *ngIf="selectedSite != null" cdkDrag>
    <button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;"
      (click)="selectedSite = null">
      <mat-icon>close</mat-icon>
    </button>

    <div fxLayout="row">
      <div>
        <h2>Sites</h2>
        <div>
          <label>Details for Site:</label>
          <div fxLayout="column" *ngIf="selectedSite != {}">
            <!-- ID -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>ID</mat-label>
                <input matInput [(ngModel)]="selectedSite._id" disabled>
              </mat-form-field>
            </div>
            <!-- Site Name -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Site Name</mat-label>
                <input matInput [(ngModel)]="selectedSite.siteName">
              </mat-form-field>
            </div>
            <!-- Site ID -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Site ID</mat-label>
                <input matInput [(ngModel)]="selectedSite.siteID" disabled>
              </mat-form-field>
            </div>
            <!-- Customer -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Customer</mat-label>
                <input matInput [(ngModel)]="selectedSite.customer">
              </mat-form-field>
            </div>
            <!-- Location -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline">
                <mat-label>Location</mat-label>
                <input matInput [(ngModel)]="selectedSite.lat + ' ' + selectedSite.lon" disabled>
                <mat-icon (click)="editSiteLocation=true" matSuffix>edit</mat-icon>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-form-field appearance="outline"
                *ngIf="selectedSite.device != null && selectedSite.device != undefined && selectedSite.device.linked == true">
                <mat-label>Linked Device S/N</mat-label>
                <input matInput [(ngModel)]="selectedSite.device.serialnumber">
              </mat-form-field>
              <a style="color: blue; cursor: pointer;" (click)="isUnlinking = false; manualLinking = true"
                *ngIf="selectedSite.device == null || selectedSite.device == undefined || selectedSite.device.linked == false"><mat-icon>add_link</mat-icon>
                Link Device</a>
              <a style="color: blue; cursor: pointer;" (click)="isUnlinking = true; manualLinking = true"
                *ngIf="selectedSite.device != null && selectedSite.device != undefined && selectedSite.device.linked == true"><mat-icon>add_link</mat-icon>
                Unlink Device</a>
            </div>



            <button style="margin: 4px;" mat-raised-button color="warn" (click)="deleteSite()">
              <mat-icon>delete</mat-icon> Delete site
            </button>
            <button style="margin: 4px;" mat-raised-button color="primary" (click)="renameSite()">
              <mat-icon>save</mat-icon> Update Site
            </button>
          </div>


        </div>




      </div>
      <div fxLayout="column" style="margin: 4px;">
        <h2 style="color: transparent">.</h2>
        <label>Users assigned to site:</label>
        <div class="editPanel" style="height: 50%;">
          <div *ngFor="let user of allUsers">
            <div *ngIf="userOfSite(user)">
              <div>{{user.username}}</div>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>