import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-token-admin',
  templateUrl: './token-admin.component.html',
  styleUrls: ['./token-admin.component.scss']
})
export class TokenAdminComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public userList: any[] = [];
  allTokens: any = [];
  filteredTokens: any = [];
  displayedColumns = ['username', 'site', 'reason', 'from', 'to']
  dataSource = new MatTableDataSource<any>(this.filteredTokens);
  reasonList: any[] = ["None"];
  isAdmin: boolean = false;
  canGenerate: boolean = false;

  constructor(public webReq: WebRequestsService, public adminService: AdminService, public loader: LoaderService) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.loader.isLoading = false;
    
    if(localStorage.getItem('generateTokens') == 'true') {
      this.canGenerate = true;
    }
    if(localStorage.getItem('admin') == 'true') {
      this.isAdmin = true;
    }
    this.getAllTokens();
    this.getUsers();
    this.getReasons();
  }

  getReasons() {
    this.webReq.getReasons().subscribe(reasons => {
      if(reasons.reasons != undefined) {
        this.reasonList = reasons.reasons;
      } else {
        this.reasonList = ["None"]
      }
    })
  }

  filterTokens(tokens:any) {
    var tempArr: any = [];
    var tempUserList: any = [];
    tokens.forEach((token:any) => {
      tempArr.push({username: token.username, fromDate: token.fromDate, toDate: token.toDate, sitename: token.sitename, reason: token.reason});
    });
    tempArr.forEach((item:any) => {
      let count = tempUserList.filter((x:any) => x.username == item.username && x.sitename == item.sitename && x.fromDate == item.fromDate && x.toDate == item.toDate).length;
      if(count == 0) {
        tempUserList.push(item);
      }
    });
    return tempUserList;
  }

  getUsers() {
    // this.loader.isLoading = true;
    this.webReq.getUsers().subscribe(users => {
      this.userList = users;
      this.adminService.allUsers = users;
      // this.loader.isLoading = false;
    })
  }

  getAllTokens() {
    this.webReq.getSystemTokensByClient().subscribe(res => {
      // console.log(res);
      this.allTokens = res.tokens;
      this.filteredTokens = this.filterTokens(this.allTokens);
      this.dataSource.data = this.filteredTokens;
    });
  }

  checkValidity(row:any):string {
    var currentTime = new Date().getTime();
    if(row.fromDate <= currentTime && row.toDate >= currentTime) {
      // console.log("Returning current");
      return "current";
    }else if (row.fromDate < currentTime && row.toDate < currentTime) {
      // console.log("Returning past");
      return "past";
    } else if (row.fromDate > currentTime) {
      // console.log("Returning future");
      return "future";
    } else {
      return "";
    }
  }

}
