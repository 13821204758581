import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {

  public iconColor: string = "black";

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.checkErrorCode(data.message);
    if (data.icon == 'error') {
      this.iconColor = 'red';
    } else if (data.icon == 'success') {
      this.iconColor = 'green'
      data.icon = 'check_circle'
    } else if (data.icon == 'info') {
      this.iconColor = 'gray'
      data.icon = 'info'
    }
  }

  dismiss() {
    // this.close();
    this.data.preClose();
  }

  checkErrorCode(errCode: string) {
    switch (errCode) {
      case '0':
        this.data.message = 'Success';
        this.iconColor = 'green';
        this.data.icon = 'success'
        break;
      case '1':
        this.data.message = 'Error';
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '2':
        this.data.message = 'Packet too long';
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '3':
        this.data.message = 'Invalid CRC';
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '4':
        this.data.message = 'Valid CRC';
        this.iconColor = 'green';
        this.data.icon = 'success'
        break;
      case '5':
        this.data.message = 'Invalid token';
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '6':
        this.data.message = 'Valid token';
        this.iconColor = 'green';
        this.data.icon = 'success'
        break;
      case '7':
        this.data.message = 'Future token';
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '8':
        this.data.message = 'Invalid site ID';
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '9':
        this.data.message = 'Over current';
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case ':':
        this.data.message = 'Door open';
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case ';':
        this.data.message = "Check power supply";
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '<':
        this.data.message = "Check actuator";
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '12':
        this.data.message = "Check actuator";
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '=':
        this.data.message = "Standdown Successful";
        this.iconColor = 'green';
        this.data.icon = 'success';
        break;
      case '11':
        this.data.message = "Check power supply";
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      case '13':
        this.data.message = "Standdown Successful";
        this.iconColor = 'green';
        this.data.icon = 'success';
        break;
      case '>':
        this.data.message = "Door Fault";
        this.iconColor = 'red';
        this.data.icon = 'error';
        break;
      default:
        break;
    }

  }
}
