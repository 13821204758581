import { Component, OnInit } from '@angular/core';
import { resolve } from 'dns';
import * as L from 'leaflet';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-coverage-map',
  templateUrl: './coverage-map.component.html',
  styleUrls: ['./coverage-map.component.scss']
})
export class CoverageMapComponent implements OnInit {
  private map: any;
  public myCoords: any;

  constructor(public webReq: WebRequestsService) { }

   ngOnInit(): void {
    // this.myCoords = await this.getCoverage();
    this.getCoverage().then(results => {
      this.myCoords = results[0];
      this.initializeMap();
    })
    // this.initializeMap();
  }

  getCoverage() {
    return new Promise((resolve) => {
      this.webReq.getCoverage().subscribe(results => {
        // this.myCoords = results;
        // results.forEach(element => {
          
        // });
        resolve(results)
      })

    })
  }

  private initializeMap() {
    // Set initial coordinates and zoom level
    const initialCoords = [0, 0];
    const initialZoom = 7;

    
    // Create the map
    // this.map = L.map('map').setView(initialCoords, initialZoom);
    this.map = L.map('map').setView([-25.8739,28.1758], initialZoom);

    // Add a tile layer (you can use other tile providers like OpenStreetMap)
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(this.map);

    // Add your coverage zones using coordinates
    // const coverageZone = [
    //   [lat1, lon1],
    //   [lat2, lon2],
    //   // Add more coordinates as needed
    // ];
    const coverageZone = this.myCoords;
    // Create a polygon and add it to the map
    const polygon = L.polygon(coverageZone, { color: 'red' }).addTo(this.map);
  }
}


