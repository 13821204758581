import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.scss']
})
export class PasswordUpdateComponent implements OnInit {


  public password: string = "";
  public passwordConfirm:string = "";

  @Output() close = new EventEmitter();

  
  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
  }

  updatePassword() {
    this.webReq.updatePassword({username: localStorage.username, email: localStorage.email, password: this.password}).subscribe(res => {
      if (res.success == true) {
        this.notify.openMessage('Password Changed','success', 3000);
        this.closing();
      } else {
        this.notify.openMessage('Contact Administrator', 'error', 3000);
      }
    });
  }

  closing() {
    this.close.emit('cancel');
  }

  validate() {
    if(this.password == this.passwordConfirm) {
      return "btn valid";
    } else {
      return "btn invalid";
    }
  }
}
