import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debug } from 'console';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import * as ol from '../../components/map-selector/ol';

@Component({
  selector: 'app-map-selector',
  templateUrl: './map-selector.component.html',
  styleUrls: ['./map-selector.component.scss']
})
export class MapSelectorComponent implements OnInit {

  @Output() close = new EventEmitter();

  @Input() site: any;

  @Input() showClose: boolean = true;
  @Input() width: any = '80vw';
  @Input() height: any = '50vh';
  @Input() showButtons = true;

  map = new ol.Map({
    target: 'map',
    layers: [
      new ol.layer.Tile({
        source: new ol.source.OSM()
      })
    ],
    view: new ol.View({
      center: [0, 0],
      zoom: 16
    })
  });
  locationString: string;
  selectedLocation = { lat: 0, lon: 0 }

  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
    // Get location here
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(res => {
        // console.log(res)
        if (this.site.lat != undefined && this.site.lon != undefined) {
          this.selectedLocation.lat = this.site.lat;
          this.selectedLocation.lon = this.site.lon;
        } else {
          this.selectedLocation.lat = res.coords.latitude;
          this.selectedLocation.lon = res.coords.longitude;
        }

        this.map = new ol.Map({
          target: 'map',
          layers: [
            new ol.layer.Tile({
              source: new ol.source.OSM()
            }),
            new ol.layer.Vector({
              source: new ol.source.Vector(),
              style: new ol.style.Style({
                image: new ol.style.Icon({
                  anchor: [0.5, 1],
                  src: 'assets/marker2.png',
                })
              })
            })
          ],
          view: new ol.View({
            center: ol.proj.fromLonLat([this.selectedLocation.lon, this.selectedLocation.lat]),
            zoom: 16
          })
        });



        this.map.on('click', (evt) => {
          if (this.selectedLocation == undefined) {
            this.selectedLocation = { lat: evt.coordinate[0], lon: evt.coordinate[1] }
          }
          this.selectedLocation.lat = evt.coordinate[0]
          this.selectedLocation.lon = evt.coordinate[1]
          this.clickedLocation(evt);
        })
        console.log(`Setting location icon at...${this.site.lat}, ${this.site.lon}`)
        // this.clickedLocation({ coordinate: [this.site.lat, this.site.lon] });
        this.setInitalLocationIcon();
      })

    }
    // =================
  }

  searchLocation() {
    console.log("Fired by click event")
  }

  setPinOnMap(evt) {
    var self = this;

    var latLong = ol.proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    var lat = latLong[1];
    var long = latLong[0];

    this.map.params.options.mapClick({ lat: lat, long: long });

    if (this.map.dinamicPinLayer !== undefined) {
      console.log("moove")
      this.map.iconGeometry.setCoordinates(evt.coordinate);
      //or create another pin  
    } else {
      this.map.iconGeometry = new ol.geom.Point(evt.coordinate);
      var iconFeature = new ol.Feature({
        geometry: this.map.iconGeometry,
        name: 'Null Island',
        population: 4000,
        rainfall: 500
      });
      var iconStyle = new ol.style.Style({
        image: new ol.style.Icon(({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          size: [48, 48],
          opacity: 1,
          src: '/resources/cloud/controls/cloudMap/img/redmarker_small.png'
        }))
      });

      iconFeature.setStyle(iconStyle);

      var vectorSource = new ol.source.Vector({
        features: [iconFeature]
      });
      this.map.dinamicPinLayer = new ol.layer.Vector({
        source: vectorSource
      });
      self.map.addLayer(this.map.dinamicPinLayer);
    }
  }

  setMyLocation() {
    navigator.geolocation.getCurrentPosition(res => {
      this.selectedLocation.lat = res.coords.latitude;
      this.selectedLocation.lon = res.coords.longitude;
      let view = this.map.getView();

      view.animate({ center: ol.proj.fromLonLat([res.coords.longitude, res.coords.latitude]) })

      this.map.getLayers().forEach(layer => {
        if (layer instanceof ol.layer.Vector) {
          layer.getSource().clear();
          var marker = new ol.Feature(new ol.geom.Point(ol.proj.fromLonLat([res.coords.longitude, res.coords.latitude])));
          layer.getSource().addFeature(marker);
        }
      });
    });
  }

  setChosenLocation() {

    let view = this.map.getView();

    view.animate({ center: ol.proj.fromLonLat([this.selectedLocation.lon, this.selectedLocation.lat]) })

    var markers = new ol.layer.Vector({
      source: new ol.source.Vector(),
      style: new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 1],
          src: 'assets/marker2.png',
        })
      })
    });

    this.map.addLayer(markers);

    var marker = new ol.Feature(new ol.geom.Point(ol.proj.fromLonLat([this.selectedLocation.lon, this.selectedLocation.lat])));
    markers.getSource().addFeature(marker);
  }

  clickedLocation(evt) {
    var lonlat = ol.proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    this.selectedLocation.lat = lonlat[1];
    this.selectedLocation.lon = lonlat[0];
    this.map.getLayers().forEach(layer => {
      if (layer instanceof ol.layer.Vector) {
        layer.getSource().clear();
        var marker = new ol.Feature(new ol.geom.Point(ol.proj.fromLonLat([this.selectedLocation.lon, this.selectedLocation.lat])));
        debugger
        layer.getSource().addFeature(marker);
      }
    });
  }

  setInitalLocationIcon() {
    // var lonlat = ol.proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    // this.selectedLocation.lat = lonlat[1];
    // this.selectedLocation.lon = lonlat[0];
    this.map.getLayers().forEach(layer => {
      if (layer instanceof ol.layer.Vector) {
        layer.getSource().clear();
        var marker = new ol.Feature(new ol.geom.Point(ol.proj.fromLonLat([this.site.lon, this.site.lat])));
        // debugger
        layer.getSource().addFeature(marker);
      }
    });
  }

  save() {
    this.site.lat = this.selectedLocation.lat;
    this.site.lon = this.selectedLocation.lon;
    this.webReq.renameSite(this.site).subscribe(result => {
      this.notify.openMessage(result.message, result.success == true ? 'success' : 'error', 3000);
      this.closing();
    })
  }

  closing() {
    this.close.emit();
  }
}
