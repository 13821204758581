import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AdminhomeComponent } from '../pages/admin/adminhome/adminhome.component';

@Injectable({
  providedIn: 'root'
})
export class WebRequestsService {

  constructor(private http: HttpClient) { }


  resetDevices(user:any) {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + "/reset_devices", user);
  }

  getCoverage() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_coverage", {clientdb:clientdb});
  }

  iCryptoAddDevice(deviceToAdd:any) {
    return this.http.post<any>(environment.baseUrl + "/add_device_icrypto", deviceToAdd);
  }
  iCryptoDeleteDevice(deviceToRemove:any) {
    return this.http.post<any>(environment.baseUrl + "/delete_device_icrypto", deviceToRemove);
  }
  handoverDevice(device:any) {
    device.username = localStorage.getItem('username');
    return this.http.post<any>(environment.baseUrl + "/handover_device", device);
  }
  takeBackDevice(device:any) {
    device.username = localStorage.getItem('username')
    return this.http.post<any>(environment.baseUrl + "/takeback_device", device);
  }

  getReasons(){
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_reasons", {clientdb: clientdb}); 
  }

  commissionSite(siteID: any, siteKey: any, customer: any, secondaryController=false, configTemplate="") {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/add_site", { "_by": _by, "_byEmail":_byEmail, "sitename": siteID, "siteKey": siteKey, "clientdb": clientdb, "customer": customer, "secondaryController": secondaryController, "configTemplate": configTemplate });
  }

  deleteSite(id: any, siteName: any) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/delete_site", { "_by": _by, "_byEmail": _byEmail, "id": id, "siteName": siteName, "clientdb": clientdb  });
  }

  permDeleteSite(site:any) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/permanent_delete_site", { site: site, _by: _by, _byEmail:_byEmail , "clientdb": clientdb  });
  }

  renameSite(site: any) {
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    site._by = _by;
    site._byEmail = _byEmail;
    return this.http.post<any>(environment.baseUrl + '/rename_site', { site: site });
  }

  getClients(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_clients", { "username": user, "clientdb": clientdb });
  }

  login(user: any) {
    let headers_node = {
      headers: new HttpHeaders(
        { 'rejectUnauthorized': 'false' }
      )
    };
    return this.http.post<any>(environment.baseUrl + "/login", user, headers_node);
  }

  getSites(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_sites", { "username": user, "clientdb": clientdb });
  }

  getSitesAll() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_sites_all", { "clientdb": clientdb });
  }

  getDeletedSites() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_deleted_sites", { "clientdb": clientdb });
  }

  restoreSite(site) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/restore_site", site);
  }

  getSiteUsers(siteID: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_site_users", { "siteID": siteID, "clientdb": clientdb });
  }

  getUserGroups() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_user_groups", { "clientdb": clientdb });
  }

  addUserGroup(groupName: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/create_user_group", { groupName: groupName, clientdb: clientdb });
  }

  deleteUserGroup(groupObj:any) {
    return this.http.post<any>(environment.baseUrl + "/delete_user_group", groupObj);
  }

  updateUserGroup(groupObj:any) {
    return this.http.post<any>(environment.baseUrl + "/update_user_group", groupObj);
  }

  getSiteGroups() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_site_groups", { "clientdb": clientdb });
  }

  getSubcontractors() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/get_subcontractors', {"clientdb": clientdb});
  }

  updateContractor(contractor) {
    let clientdb = localStorage.getItem('clientdb');
    contractor.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/update_subcontractor', contractor);
  }

  deleteContractor(contractor) {
    let clientdb = localStorage.getItem('clientdb');
    contractor.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/delete_subcontractor', contractor);
  }

  addContractor(contractor) {
    let clientdb = localStorage.getItem('clientdb');
    contractor.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/add_subcontractor', contractor);
  }

  addSiteGroup(group: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/create_site_group", { groupName: group.groupName, groupID: group.groupID, clientdb: clientdb });
  }

  deleteSiteGroup(groupObj:any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/delete_site_group",{groupID: groupObj._id, clientdb: clientdb});
  }

  updateSiteGroup(groupObj:any) {
    return this.http.post<any>(environment.baseUrl + "/update_site_group", groupObj);
  }

  generateToken(siteId: any, funcCode: any, operations: any[], username: any = "none") {
    if (username == "none") {
      localStorage.getItem('username') != null ? username = localStorage.getItem('username') : username == "none";
    }
    const options = { responseType: 'text' as 'text' };
    let clientdb = localStorage.getItem('clientdb')
    let obj = { "siteID": siteId, "funcCode": funcCode, "operations": operations, "clientdb": clientdb, "username": username }
    return this.http.post<any>(environment.baseUrl + "/get_token", obj)
  }

  // This method accepts from date and to date in milliseconds and defaults to now and tomorrow
  generateTokenNew(site: any, codes: any[], fromDate: any = new Date().getTime(), toDate: any = new Date().setDate(new Date().getDate() + 1), username: any = "none") {
    if (username == "none") {
      localStorage.getItem('username') != null ? username = localStorage.getItem('username') : username == "none";
    }
    const options = { responseType: 'text' as 'text' };
    let clientdb = localStorage.getItem('clientdb')
    let obj = { "site": site, "codes": codes, "clientdb": clientdb, "username": username, "from": fromDate, "to": toDate }
    return this.http.post<any>(environment.baseUrl + "/get_token", obj)
  }

  addUser(user:any) {
    // let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/add_user_client", {_by: _by, _byEmail: _byEmail, username: user.username, email: user.email, admin: user.isAdmin, generateTokens: user.generateTokens, cellphone: user.cellphone, clientdb: user.clientdb, name: user.name, surname:user.surname, subcontractor: user.subcontractor, canConfigure: user.canConfigure, userType: user.userType, idNumber:user.idNumber });
  }

  getUsers() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/get_users", { clientdb: clientdb });
  }

  removeUser(username: string) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/remove_user", { _by: _by, _byEmail: _byEmail, clientdb: clientdb, email: username });
  }

  removeUserFromSite(siteID: any, siteName: any, email: any) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/remove_user_from_site", { _by:_by, _byEmail: _byEmail, clientdb: clientdb, email: email, siteID: siteID, siteName: siteName });
  }

  addUserToSite(email: string, site: string) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/add_site_user", { _by: _by, _byEmail:_byEmail, email: email, site: site, clientdb: clientdb })
  }
  addUserClient(username: string, email: string, admin: any, generateTokens: any, cellphone: string) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/add_user_client", { _by:_by, _byEmail: _byEmail, username: username, email: email, admin: admin, generateTokens: generateTokens, cellphone: cellphone, clientdb: clientdb });
  }

  updateUser(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    return this.http.post<any>(environment.baseUrl + "/update_user", { user, clientdb: clientdb });
  }
  globalUpdateUser(user:any) {
    return this.http.post<any>(environment.baseUrl + "/global_update_user", user);
  }
  globalDeleteUser(user:any) {
    return this.http.post<any>(environment.baseUrl + "/global_delete_user", user);
  }



  smsTokens(cellphone: any, siteID: any, funcCode: any, operations: any[]) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + "/sms_tokens", { "cellphone": cellphone, "siteID": siteID, "funcCode": funcCode, "operations": operations, "clientdb": clientdb });
  }

  getLogs() {
    return this.http.post<any>(environment.baseUrl + "/get_log", {});
  }

  generateSystemTokens(siteId: any, sitename: any, funcCode: any, operations: any[], username: any = "none") {
    let clientdb = localStorage.getItem('clientdb')
    let obj = { "siteID": siteId, "sitename": sitename, "funcCode": funcCode, "operations": operations, "clientdb": clientdb, "username": username }
    return this.http.post<any>(environment.baseUrl + "/system_tokens", obj)
  }
  generateSystemTokensNew(site: any, codes: any, fromDate: any = new Date().getTime(), toDate: any = new Date().setDate(new Date().getDate() + 1), username: any = "none", userID: any ="", reason:any="None") {
    let clientdb = localStorage.getItem('clientdb')
    let obj = { "site": site, "codes": codes, "clientdb": clientdb, "username": username, "userID":userID,"from": fromDate, "to": toDate, "reason": reason }
    return this.http.post<any>(environment.baseUrl + "/system_tokens", obj)
  }

  addSchedule(obj) {
    
    let clientdb = localStorage.getItem('clientdb')
    obj.clientdb= clientdb
    return this.http.post<any>(environment.baseUrl + "/add_schedule", obj)
  }

  getSchedules() {
    let clientdb = localStorage.getItem('clientdb')

    let obj = {clientdb:clientdb}
    return this.http.post<any>(environment.baseUrl + "/get_schedules", obj)

  }

  removeSchedule(schedule) {
    let  clientdb = localStorage.getItem('clientdb');
    schedule.clientdb = clientdb;
    schedule.fromEpoch = new Date(schedule.from).getTime();
    schedule.toEpoch = new Date(schedule.to).getTime();
    return this.http.post<any>(environment.baseUrl + "/remove_schedule", schedule)
  }
  getSystemTokens(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    let obj = { "clientdb": clientdb, "username": user }
    return this.http.post<any>(environment.baseUrl + "/get_system_tokens", obj)
  }

  getSystemTokensByClient() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + "/check_system_tokens", {clientdb: clientdb})
  }

  pushBTEvents(eventList: any) {
    let events = { "events": eventList };
    return this.http.post<any>(environment.baseUrl + "/bluetooth_log", events);
  }

  getBTLogs(from: any, to: any, user: any) {
    return this.http.post<any>(environment.baseUrl + '/get_bt_log', { 'from': from, 'to': to, 'user': user });
  }

  getSiteStatus(sites: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + '/get_last_status', {sites:sites, clientdb:clientdb});
  }

  getSiteWorkingTimes(sites:any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + '/get_working_times', {sites:sites, clientdb:clientdb});
  }

  resetPassword(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + '/password_reset', { "username": user.username, "email": user.email, "clientdb": clientdb });
  }

  updatePassword(user: any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + '/password_update', { "username": user.username, "email": user.email, "password": user.password, "clientdb": clientdb });
  }

  requestNewAccount(email: any, type: any) {
    let obj = { "email": email, "type": type };
    return this.http.post<any>(environment.baseUrl + "/request_account", obj);
  }

  getOpensAndStanddowns(siteIDS:any) {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + '/get_opens_standdowns', {sites:siteIDS, clientdb:clientdb});
  }

  getTotalTokensGenerated() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + '/total_tokens_generated', {clientdb:clientdb});
  }

  getCurrentAlarms() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + '/get_current_alarms', {clientdb:clientdb});
  }

  getAllAlarms() {
    let clientdb = localStorage.getItem('clientdb')
    return this.http.post<any>(environment.baseUrl + '/get_all_alarms', {clientdb:clientdb});
  }

  acknowledgeAlarm(alarm) {
    let clientdb = localStorage.getItem('clientdb');
    let username = localStorage.getItem('username');
    return this.http.post<any>(environment.baseUrl + '/ack_alarm', {clientdb:clientdb, username: username, alarm});
  }

  resolveAlarm(obj) {
    return this.http.post<any>(environment.baseUrl + '/resolve_alarm', obj);
  }

  getReports() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/reports_new', {clientdb: clientdb});
  }

  saveConfigTemplate(obj) {
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    obj._by = _by;
    obj._byEmail = _byEmail;
    return this.http.post<any>(environment.baseUrl + '/save_config_template', obj);
  }

  updateConfigTemplate(obj) {
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    obj._by = _by;
    obj._byEmail = _byEmail;

    return this.http.post<any>(environment.baseUrl + '/update_config_template', obj);
  }

  deleteConfigTemplate(obj) {
    let _by = localStorage.getItem('username')
    let _byEmail = localStorage.getItem('email')
    obj._by = _by;
    obj._byEmail = _byEmail;

    return this.http.post<any>(environment.baseUrl + '/delete_config_template', obj);
  }

  getConfigTemplate() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/get_config_templates', {clientdb:clientdb})
  }

  getHandoverRequests() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/get_handovers', {clientdb: clientdb})
  }

  getHandoverHistory() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/get_handovers_history', {clientdb: clientdb})
  }

  acceptHandover(HOObj:any) {
    return this.http.post<any>(environment.baseUrl + '/accept_handover_request', HOObj)
  
  }

  getSiteReports() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/list_reports', {clientdb: clientdb})
  }

  downloadReport(path) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/download_report', {clientdb: clientdb, filepath: path},{headers, responseType:'blob' as 'json'})

  }

  getKeyRequests(pageSize, pageNumber) {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/all_requests', { pageSize: pageSize, pageNumber: pageNumber, clientdb: clientdb});
  }
  deleteKeyRequest(item:any) {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/delete_request', item);
  }
  updateKeyRequest(item:any) {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/update_request', item);
  }

  getBlockedUsers() {
    let clientdb = localStorage.getItem('clientdb');
    return this.http.post<any>(environment.baseUrl + '/get_blocked_users', {clientdb:clientdb});
  }

  unblockUser(user) {
    let clientdb = localStorage.getItem('clientdb');
    user.clientdb = clientdb;
    return this.http.post<any>(environment.baseUrl + '/unblock_user', user);
  }

  blockUser(user) {
    let clientdb = localStorage.getItem('clientdb');
    user.clientdb = clientdb
    return this.http.post<any>(environment.baseUrl + '/block_user', user);
  }
  addBlockedComment(user, comment) {
    return this.http.post<any>(environment.baseUrl + '/add_block_comment', {user: user, comment: comment});
  }

  revokeUser(user) {
    return this.http.post<any>(environment.baseUrl + '/revoke_user', user);
  }
}
