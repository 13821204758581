<div fxFlex fxLayoutAlign="center center" fxLayout="column">
  <app-loader></app-loader>

  <app-site-select (btSite)="siteSelected = true" [currentState]="siteSelected" *ngIf="!siteSelected"></app-site-select>

  <app-testing (debuggerClosed)="isDebugging = false" *ngIf="isDebugging"></app-testing>

  <app-diagnostics (action)="doEventAction($event)" [testSubject]="testSubject" (closed)="writeAction('*;9');isDiagnostics = false" *ngIf="isDiagnostics"></app-diagnostics>

  <mat-card *ngIf="siteSelected && !isDebugging && !isDiagnostics" class="mat-elevation-z16" style="height: 75vh; width: 85vw;"
    fxLayoutAlign="space-evenly none" fxLayout="column">
    <mat-form-field *ngIf="btService.tokenSet == true" appearance="outline">
      <mat-label>Write Value</mat-label>
      <input matInput placeholder="Write Value" [(ngModel)]="testValue">
    </mat-form-field>



    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);" *ngIf="!isConnected" (click)="Connect()" class="block-button mat-elevation-z16" fxLayout="column"
      fxLayoutAlign="center center">
      <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
      <mat-icon style="color: darkblue">bluetooth_connected</mat-icon>
      <label>Connect</label>
    </div>

    <!-- =============================================================================================================================================== -->

      <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <div *ngIf="isConnected" (click)="writeAction('0')" class="block-button mat-elevation-z16" fxLayout="column"
          fxLayoutAlign="center center">
          <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
          <mat-icon style="color: greenyellow">lock_open</mat-icon>
          <label>Unlock</label>
        </div>


        
          <div *ngIf="isConnected" (click)="ToggleLED()" class="block-button mat-elevation-z16" fxLayout="column"
            fxLayoutAlign="center center">
            <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
            <mat-icon>notifications_active</mat-icon>
            <label>Buzz</label>
          </div>
        
      </div>

      <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <div *ngIf="isConnected" (click)="writeAction('1')" class="block-button mat-elevation-z16" fxLayout="column"
          fxLayoutAlign="center center">
          <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
          <mat-icon style="color:red">lock</mat-icon>
          <label>Lock</label>
        </div>

        <div *ngIf="isConnected" (click)="writeAction('2')" class="block-button mat-elevation-z16" fxLayout="column"
          fxLayoutAlign="center center">
          <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
          <mat-icon style="color:yellow">gpp_good</mat-icon>
          <label>Stand Down</label>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <div *ngIf="isConnected" (click)="writeAction('1')" class="block-button mat-elevation-z16" fxLayout="column"
          fxLayoutAlign="center center">
          <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
          <mat-icon style="color:black">settings</mat-icon>
          <label>Configure</label>
        </div>

        <div *ngIf="isConnected" (click)="enterDiagnostics()" class="block-button mat-elevation-z16" fxLayout="column"
          fxLayoutAlign="center center">
          <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
          <mat-icon style="color:orange">health_and_safety</mat-icon>
          <label>Diagnostics</label>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <div *ngIf="isConnected" (click)="isDebugging = true" class="block-button mat-elevation-z16" fxLayout="column"
        fxLayoutAlign="center center">
        <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
        <mat-icon style="color:gray">bug_report</mat-icon>
        <label>Debug</label>
      </div>

        <div *ngIf="isConnected" (click)="StopBluetooth()" class="block-button mat-elevation-z16" fxLayout="column"
          fxLayoutAlign="center center">
          <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
          <mat-icon style="color:darkblue">bluetooth_disabled</mat-icon>
          <label>Disconnect</label>
        </div>
      </div>

    <!-- =============================================================================================================================================== -->

    <!-- </div> -->
    <!-- </mat-card-content> -->
    <a *ngIf="!isConnected" (click)="openHelp('3.1')" style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a>
    <a *ngIf="isConnected" (click)="openHelp('3.2')" style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a>
  </mat-card>

</div>