<div fxFlex fxLayoutAlign="center center" fxLayout="column">

    <mat-card class="mat-elevation-z16" style="width: 90vw; height: 75vh;" fxLayout="column" fxLayoutAlign="space-evenly none">
        <button mat-mini-fab style="position: absolute;top: 0px;left: 0px; background-color: transparent;" (click)="closing()"><mat-icon>arrow_back</mat-icon></button>
        <mat-card-title>
            Forgot Password
        </mat-card-title>
        <!-- <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput [(ngModel)]="username">
        </mat-form-field> -->
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="email">
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="resetPassword()"><mat-icon>key</mat-icon> Reset Password </button>
        <!-- <a (click)="openHelp('4.2.1')" style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a> -->

    </mat-card>

</div>