<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <app-loader></app-loader>
    <mat-card class="mat-elevation-z16" style="height:80vh; width: 99vw;" fxLayoutAlign="start center" fxLayout="column">
        
        <table mat-table class="mat-elevation-z16" style="width: 100%;" [dataSource]="dataSource" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Sitename Column -->
            <ng-container matColumnDef="sitename">
              <th mat-header-cell *matHeaderCellDef> Site </th>
              <td mat-cell *matCellDef="let element"> {{element.sitename}} </td>
            </ng-container>
          
            <!-- From Column -->
            <ng-container matColumnDef="from">
              <th mat-header-cell *matHeaderCellDef> From </th>
              <td mat-cell *matCellDef="let element" > {{element.from | date:"MM/dd/yy HH:mm"}} </td>
            </ng-container>
          
            <!-- To Column -->
            <ng-container matColumnDef="to">
              <th mat-header-cell *matHeaderCellDef> To </th>
              <td mat-cell *matCellDef="let element" > {{element.to | date:"MM/dd/yy HH:mm"}} </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row [ngClass]="checkValidity(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </mat-card>
</div>