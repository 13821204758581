<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <app-loader></app-loader>
    <!-- <div class="cover" *ngIf="addingSite || addingUsers || selectedUser != null || selectedSite != null"> -->
        <div class="cover" hidden>
    </div>
  
    <div class="myHeader">
      v-Keys Management
    </div>

    <h2 *ngIf="!canGenerate">Insufficient Privilege</h2>
    <div style="height: 75vh !important; width: 100vw;" fxLayoutAlign="space-evenly start" fxLayout="row" *ngIf="canGenerate">
      
      <div>
       
        <div class="listBox" style="height:70vh; width: 90vw; ">
            <mat-tab-group>
                <mat-tab label="Available v-Keys" (click)="getAllTokens()">
                    <button style="position: absolute; top: 4px;right: 4px; z-index: 2000" mat-mini-fab (click)="getAllTokens()"><mat-icon>autorenew</mat-icon></button>
                    
                    <table style="width: 100%; max-height: 50%;" mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="username">
                            <th mat-header-cell *matHeaderCellDef> Username </th>
                            <td mat-cell *matCellDef="let element"> {{element.username}} </td>
                          </ng-container>
                          <ng-container matColumnDef="site">
                            <th mat-header-cell *matHeaderCellDef> Site </th>
                            <td mat-cell *matCellDef="let element"> {{element.sitename}} </td>
                          </ng-container>
                          <ng-container matColumnDef="reason">
                            <th mat-header-cell *matHeaderCellDef> Reason </th>
                            <td mat-cell *matCellDef="let element"> {{element.reason}} </td>
                          </ng-container>
                          <ng-container matColumnDef="from">
                            <th mat-header-cell *matHeaderCellDef> From </th>
                            <td mat-cell *matCellDef="let element"> {{element.fromDate | date:"MM/dd/yy HH:mm"}} </td>
                          </ng-container>
                          <ng-container matColumnDef="to">
                            <th mat-header-cell *matHeaderCellDef> To </th>
                            <td mat-cell *matCellDef="let element"> {{element.toDate | date:"MM/dd/yy HH:mm"}} </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row [ngClass]="checkValidity(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator [length]="filteredTokens.length" [pageSizeOptions]="[5,10]" aria-label="Select page"></mat-paginator>
                </mat-tab>

                <mat-tab label="User v-Keys">
                    <app-generate-system-tokens [users]="userList" [filteredUsers]="userList" [reasonList]="reasonList" ></app-generate-system-tokens>
                </mat-tab>
                
                <!-- <mat-tab label="Site Tokens">Generate tokens for users for sites here</mat-tab> -->
            </mat-tab-group>            


        </div>
      </div>
    </div>

  </div>