<div class="myHeader">
    Subcontractor Management
  </div>

  <div class="filter-container">
    <mat-form-field>
      <mat-label>
        Filter <mat-icon>search</mat-icon>
      </mat-label>
      <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>

<div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Subcontractor Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="contactEmail">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.contactEmail}} </td>
        </ng-container>

        <ng-container matColumnDef="contactNumber">
            <th mat-header-cell *matHeaderCellDef> Contact No. </th>
            <td mat-cell *matCellDef="let element"> {{element.contactNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Adress </th>
            <td mat-cell *matCellDef="let element"> {{element.address}} </td>
        </ng-container>

<!-- 
        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element"> <mat-icon (click)="selectedSite = element" >edit</mat-icon> </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedSubcontractor = row"></tr>
    </table>


</div>

<button class="btn"
    style="position: absolute; bottom: 7vh; z-index: 3001; color: black; background-color: lime; left: 24px;"
    (click)="selectedSubcontractor = {}"><mat-icon>group_add</mat-icon> Add Subcontractor</button>
<mat-paginator #myPaginator [length]="dataSource.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page" style="position: absolute; bottom: 6vh; width: 100vw;">
</mat-paginator>


<div class="backdrop" *ngIf="selectedSubcontractor != null" (click)="selectedSubcontractor = null"></div>
<div *ngIf="selectedSubcontractor != null" style="position: absolute; width: 20vw; left:40vw; height: 60vh; top: 20vh; z-index: 3011" cdkDrag>
    <app-edit-subcontractor  [contractor]="selectedSubcontractor" (close)="selectedSubcontractor = null; getSubcontractors();"></app-edit-subcontractor>
</div>