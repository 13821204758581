<div class="myHeader">
    Handover Management
  </div>

  <div class="filter-container">
    <mat-form-field>
      <mat-label>
        Filter <mat-icon>search</mat-icon>
      </mat-label>
      <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)">
    </mat-form-field>
  </div>

<div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%" multiTemplateDataRows>
        <ng-container matColumnDef="groupID">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Site ID (T-ID) </th>
            <td mat-cell *matCellDef="let element"> {{element.groupID}} </td>
        </ng-container>

        <ng-container matColumnDef="groupName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Site Name </th>
            <td mat-cell *matCellDef="let element"> {{element.groupName}} </td>
        </ng-container>

        <ng-container matColumnDef="handoverCount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Devices Handed Over </th>
            <td mat-cell *matCellDef="let element"> {{element.sites.length}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button *ngIf="element.sites.length == 0" (click)="selectedSiteGroup=element; removingGroup = true;">
                    <mat-icon style="color: red">delete</mat-icon> 
                </button>                
                </td>
        </ng-container>

        <!-- ================================================ EXPANSION ===================================================== -->
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions"> More
            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">

                <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div *ngIf="element == expandedElement">
                        <div style="margin-bottom: 4px; border-bottom: 1px solid whitesmoke; padding: 4px;"
                            *ngFor="let site of element.sites" fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxLayout="column">
                                <div>
                                    Device ID: {{site.siteID}} 
                                </div>
                                <div>
                                    Device Name: {{site.siteName}}
                                </div>
                            </div>
                            <div>
                                <button mat-icon-button color="warn" (click)="selectedSiteGroup = element; deviceToRemove = site; removingDevice = true; deviceToRemove.reason=''">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div>
                            <button mat-mini-fab style="width: 30px; height: 30px; background-color: lime;"
                                (click)="selectedSiteGroup=element; getDataForAdd();"><mat-icon
                                    style="font-size: small; color: black">add</mat-icon></button>
                        </div>
                    </div>
                </div>

            </td>
        </ng-container>
        <!-- ================================================================================================================ -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="example-element-row" [class.example-expanded-row]="expandedElement === row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>


</div>

<button class="btn"
    style="position: absolute; bottom: 7vh; z-index: 3001; color: black; background-color: lime; left: 24px;"
    (click)="addingSiteGroup = true"><mat-icon>domain_add</mat-icon> Add Site</button>
<mat-paginator #myPaginator [length]="dataSource.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
    aria-label="Select page" style="position: absolute; bottom: 6vh; width: 100vw;">
</mat-paginator>

<div class="backdrop" *ngIf="addingSiteGroup == true" (click)="addingSiteGroup = false"></div>
<div *ngIf="addingSiteGroup == true"
    style="position: absolute; width: 20vw; left:40vw; height: 60vh; top: 20vh; z-index: 3011" cdkDrag>
    <app-add-site-group (close)="addingSiteGroup = false; getSites();"></app-add-site-group>
</div>


<div class="backdrop" *ngIf="addingDevice || removingDevice || removingGroup" (click)="addingDevice = false; removingDevice = false; removingGroup = false;"></div>

<mat-card id="addingCard" *ngIf="addingDevice" fxLayout="column" fxLayoutAlign="space-evenly center" style="border: 1px solid white">
    <h3>Add device to <strong style="color: orange">{{selectedSiteGroup.groupName}}</strong></h3>
    <mat-form-field appearance="outline">
        <mat-label>Devices</mat-label>
        <mat-select [(ngModel)]="devicesToAdd">
            <mat-form-field>
                <input matInput placeholder="Filter" (keyup)="applyDeviceFilter($event)" [(ngModel)]="deviceFilter" style="color: white">
            </mat-form-field>
            <mat-option *ngFor="let device of availableDevicesFiltered" [value]="device">({{device.siteID}}) {{device.siteName}}  </mat-option>
        </mat-select>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
        <!-- <button mat-raised-button color="primary" (click)="addDevices()"><mat-icon>check</mat-icon> Add</button> -->
        <button mat-raised-button color="primary" (click)="handoverDevice()"><mat-icon>check</mat-icon> Add</button>
        <button mat-raised-button color="warn" (click)="addingDevice = false"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>

</mat-card>


<mat-card id="removingCard" *ngIf="removingDevice" fxLayout="column" fxLayoutAlign="space-evenly center">
    <h2>Confirm:</h2>
    <p>Please supply a reason to remove <strong style="color: red;">{{deviceToRemove.siteID}} - {{deviceToRemove.siteName}}</strong> from <strong style="color: orange;">{{selectedSiteGroup.groupID}} - {{selectedSiteGroup.groupName}}</strong></p>
    <mat-form-field appearance="outline">
        <mat-label>Reason</mat-label>
        <input matInput [(ngModel)]="deviceToRemove.reason">
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
        <button mat-raised-button color="primary" (click)="removeDevices()" [disabled]="deviceToRemove.reason != undefined && deviceToRemove.reason != null && deviceToRemove.reason.length < 4"><mat-icon>check</mat-icon> Remove</button>
        <button mat-raised-button color="warn" (click)="removingDevice = false"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
</mat-card>

<mat-card id="removingGroupCard" *ngIf="removingGroup" fxLayout="column" fxLayoutAlign="space-evenly center">
    <h2>Confirm:</h2>
    <p>Do you want to remove <strong style="color: orange">{{selectedSiteGroup.groupName}}</strong>?</p>

    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
        <button mat-raised-button color="primary" (click)="removeGroup()"><mat-icon>check</mat-icon> Remove</button>
        <button mat-raised-button color="warn" (click)="removingGroup = false"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
</mat-card>