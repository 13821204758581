import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { NotificationsService } from '../services/notifications.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  isAdmin = false;
  username = "";
  user = null;

  constructor(private router: Router, public loader: LoaderService, public notifications: NotificationsService) { }

  ngOnInit(): void {
    if(localStorage.getItem('admin') == 'true') {
      this.isAdmin = true;
    }
    this.loader.isLoading = true;
    this.username = localStorage.getItem('username');
    this.user = localStorage.getItem('username');
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    if (this.username == null || isLoggedIn == null || isLoggedIn == 'false') {
      this.router.navigate(['/login']);
    }

  }

  navToDashboard() {
    this.router.navigate(['/dashboard'])
  }

  navToSites() {
    this.router.navigate(['/site-management'])
  }

  navToUsers() {
    this.router.navigate(['/user-management'])
  }

  navToVKeys() {
    this.router.navigate(['/token-tools'])
  }

  navToAlarms() {
    this.router.navigate(['/alarms'])
  }

  navToChangePwd() {
    this.router.navigate(['/change-password'])
  }

  navToSubcontractors() {
    this.router.navigate(['/subcontractor-management'])
  }

  navToHandovers() {
    this.router.navigate(['/handover-management'])
  }

  navToHandoverReqs() {
    // this.showHoverMenu = false;
    this.router.navigate(['/handover-requests'])
  }

  navToSchedules() {
    // this.showHoverMenu = false;
    this.router.navigate(['/schedules']);
  }

  navToKeyRequests() {
    this.router.navigate(['/key-requests']);

  }
}
