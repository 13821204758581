import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PwaService } from 'src/app/services/pwa.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeOut', [
      transition(':leave', [
        query(':leave', animateChild(), { optional: true }),
        animate(400, style({ opacity: 0 }))
      ]),
    ]),
  ],
  styles: [`
  .splash-screen {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9999;
      background-image: linear-gradient(to left,#747474, #F37321);
  }
`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SplashScreenComponent implements OnInit {
  show = true;

  constructor(
    private pwaService: PwaService,
    private cdr: ChangeDetectorRef,
    private appRef: ApplicationRef,
  ) {
  }

  async ngOnInit() {
    this.show = false;
    // console.log("Inside ngOnInit");
    let tempRes = await this.pwaService.checkForUpdateNew();
    // console.log("tempRes: " + tempRes);
    if(tempRes) {
      let updated = await this.pwaService.activateUpdate();
      if(updated) {
        this.show = false;
      }
    }
  }

}
