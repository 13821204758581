import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.scss']
})
export class SiteManagementComponent implements OnInit {

  displayedColumns: string[] = ['siteID', 'serialnumber', 'siteName', 'customer', 'linked'];
  displayedColumnsAlt: string[] = ['siteID', 'serialnumber', 'siteName', 'customer', 'delete', 'restore'];
  dataSource = new MatTableDataSource();
  sites = [];
  sitesFiltered = [];

  deletedSites = [];
  deletedSitesFiltered = [];
  showDeleted = false;

  editingSiteUsers = false;
  addingSite = false;
  selectedSite = null;
  selectedDelSite = null;
  manualLinking: boolean = false;
  manualLinkingStep = 1;
  isUnlinking: boolean = false;
  isConfig: boolean = false;
  deviceState = 0;
  listenType = 0; // Listen types: [0:normal,1:version,2:config]
  confSubject: Subject<any> = new Subject();
  isConnected: boolean = false;
  users = [];
  filterValue = "";
  editSiteLocation = false;
  showDelPerm = false;

  addedSite = false;
  addedSiteDocID: any = null;

  configTemplates = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public loader: LoaderService,
    public webReq: WebRequestsService,
    public notify: NotificationsService,
    public btService: BluetoothService,
    public router: Router,
    private _liveAnnouncer: LiveAnnouncer) { }

  ngOnInit(): void {
    this.loader.isLoading = true;

    let isLoggedIn = localStorage.getItem('isLoggedIn');

    this.getSites();
    this.getUsers();
    this.getConfigTemplates();
  }

  confirmEditLoc() {
    console.log("clicked edit, value of edit: " + this.editSiteLocation);
  }

  getUsers() {
    this.webReq.getUsers().subscribe(users => {
      this.users = users;
    })
  }

  getSites() {
    this.loader.isLoading = true;
    let tempSites = [];
    this.webReq.getSitesAll().subscribe(data => {
      data.forEach(site => {
        if (site.handedOver != true) {
          tempSites.push(site);
        }
      });
      this.sites = tempSites;
      if (this.showDeleted == false) {
        this.dataSource.data = this.sites;

      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.loader.isLoading = false;
    }, err => {
      this.loader.isLoading = false;
      this.notify.openMessage('Failed to get sites', 'error')
    });

    this.webReq.getDeletedSites().subscribe(data => {
      this.deletedSites = data;
      if (this.showDeleted == true) {
        this.dataSource.data = this.deletedSites;
      }
    });
  }

  restoreSite(element:any) {
    setTimeout(() => {
      this.selectedSite = null;
      this.selectedDelSite = null;
      this.webReq.restoreSite(element).subscribe(result => {
        this.notify.openMessage('Site Restored', 'success', 3);
        this.getSites();
      });
    }, 300)
    
  }

  getdisplayedColumns() {
    if (this.showDeleted == false) {
      return this.displayedColumns
    } else {
      return this.displayedColumnsAlt
    }
  }

  checkDeletedOn() {
    if (this.showDeleted == false) {
      return 'historyButton'
    } else {
      return 'historyButton on'
    }
  }

  toggleDeleted() {
    if (this.showDeleted == false) {
      this.showDeleted = true;
      this.dataSource.data = this.deletedSites;
    } else {
      this.showDeleted = false;
      this.dataSource.data = this.sites;
    }
  }

  deleteSite() {
    this.webReq.deleteSite(this.selectedSite._id, this.selectedSite.siteName).subscribe(result => {
      // this.getSites();
      // this.isDeleting = false;
      this.selectedSite = null;
      this.getSites();
      this.notify.openMessage(result.message, result.success == true ? 'success' : 'error', 3000);
    });
  }

  updateSite() {
    // this.selectedSite.configTemplate = this.selectedSite.configTemplate._id;
    this.webReq.renameSite(this.selectedSite).subscribe(result => {
      // this.getSites();
      this.selectedSite = null;
      this.notify.openMessage(result.message, result.success == true ? 'success' : 'error', 3000);
      this.getSites();
    })
  }

  manualLink() {
    if (this.isUnlinking) {
      this.selectedSite.device = { linked: false, serialnumber: "0" };
    } else {
      this.selectedSite.device = { linked: true, serialnumber: this.selectedSite.device.serialnumber };
    }
    this.updateSite();
    this.manualLinking = false;
    this.manualLinkingStep = 1;
    this.isUnlinking = false;
  }

  startLinkingDevice() {
    // show popup asking to access device

    this.loader.isLoading = true;
    this.isUnlinking = false;

    var actualID = this.selectedSite.siteID;
    this.selectedSite.siteID = 10000;
    this.webReq.generateTokenNew(this.selectedSite, [[1, 0], [0, 0], [0, 1], [0, 2], [3, 0], [2, 0]], new Date(Date.now()).getTime(), new Date(Date.now()).getTime() + 300000).subscribe((tokens: { "tokens": [], "from": any, "to": any }) => {
      console.log(tokens);
      this.selectedSite.siteID = actualID;
      tokens.tokens.forEach(tokenObj => {
        if (tokenObj[0] == 3) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_diag", tokenObj[2]);
        }
        if (tokenObj[0] == 0) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_" + tokenObj[1], tokenObj[2]);
        }
        if (tokenObj[0] == 1) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_time", tokenObj[2]);
        }
        if (tokenObj[0] == 2) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_conf", tokenObj[2])
        }
      });

      //Tokens are set...continue to connect
      this.Connect();

    });

  }

  startUnlinkingDevice() {
    this.loader.isLoading = true;
    // var actualID = this.selectedSite.siteID;
    // this.selectedSite.siteID = 10000;
    this.isUnlinking = true;
    this.webReq.generateTokenNew(this.selectedSite, [[1, 0], [0, 0], [0, 1], [0, 2], [3, 0], [2, 0]], new Date(Date.now()).getTime(), new Date(Date.now()).getTime() + 300000).subscribe((tokens: { "tokens": [], "from": any, "to": any }) => {
      console.log(tokens);
      // this.selectedSite.siteID = actualID;
      tokens.tokens.forEach(tokenObj => {
        if (tokenObj[0] == 3) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_diag", tokenObj[2]);
        }
        if (tokenObj[0] == 0) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_" + tokenObj[1], tokenObj[2]);
        }
        if (tokenObj[0] == 1) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_time", tokenObj[2]);
        }
        if (tokenObj[0] == 2) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_conf", tokenObj[2])
        }
      });

      //Tokens are set...continue to connect
      this.Connect();

    });
  }

  handleNotifications(event: any) {
    // console.log("Recieved BT Message. Listen Type: " + this.listenType)
    // console.log("Config: " + this.isConfig + "\nDiagnostics: " + this.isDiagnostics)
    let utf8decoder = new TextDecoder();
    let value = event.target.value;
    let a = [];
    let b;
    // Convert raw data bytes to hex values just for the sake of showing something.
    // In the "real" world, you'd use data.getUint8, data.getUint16 or even
    // TextDecoder to process raw data bytes.
    for (let i = 0; i < value.byteLength; i++) {
      a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
    }
    b = (utf8decoder.decode(value));
    // console.log('> ' + a.join(' '));
    // Handle Response Here
    console.log('Text Value From BT > ' + b);
    // console.log('Substring: ' + b.substring(0, 2));

    if (!this.isConfig) { // These are general action responses
      if (b == '0' || b == '1' || b == '2' || b == '3' || b == '4' || b == '5' ||
        b == '7' || b == '8' || b == '9' || b == ':' || b == ';' || b == '<' ||
        b == '=' || b == '>' || b == '13' || b == '11' || b == '10' || b == '12' || b == '14') {
        console.log()
        this.notify.openMessage(b, 'success', 3);
        // this.clickable = true;
        this.loader.isLoading = false;
      } else {
        if (b.length > 6) {
          this.notify.openMessage(b, 'info', 10);
        }
      }
      // this.counterValue = utf8decoder.decode(value);
    } else if (this.isConfig) {
      // alert(b + ' Device State: ' + this.deviceState)
      if (b == '1') {
        this.loader.isLoading = false;
        this.manualLinking = false;
        alert('Failed to ' + (this.isUnlinking == true ? 'unlink' : 'link'))
      } else if (b == '8') {
        this.loader.isLoading = false;
        this.manualLinking = false;

        alert('Failed to ' + (this.isUnlinking == true ? 'unlink' : 'link') + ", ensure site ID's are correct");
      } else if (b == '0') {
        // this.deviceState++;
        if (this.isUnlinking) {
          this.selectedSite.device = { linked: false, serialnumber: "0" };
        } else {
          this.selectedSite.device = { linked: true, serialnumber: "0" };
        }
        if (this.deviceState == 3) {
          this.webReq.renameSite(this.selectedSite).subscribe((res: any) => {
            if (res.success == true) {
              this.manualLinking = false;
              alert(this.isUnlinking == true ? 'Device Unlinked' : 'Device Linked')
              this.loader.isLoading = false;

            }
            else {
              this.manualLinking = false;
              this.loader.isLoading = false;
              alert((this.isUnlinking == true ? 'Device Unlinked' : 'Device Linked') + " but failed to update database");
            }
          });
        } else if (this.deviceState == 4) {
          this.StopBluetooth();
          alert('Device config saved');
        }

      }
      this.confSubject.next({ value: b, listenType: this.listenType });
    }

  }

  Connect() {
    // this.loader.isLoading = true;
    // this.clickable = false;
    this.btService.connectDevice().then(conn => {
      if (conn) {
        this.isConnected = true;
        this.deviceState = 1;
        this.btService.myReadCharacteristic.startNotifications().then(() => {
          console.log("Listening...")
          // this.loader.isLoading = false;
          this.btService.myReadCharacteristic.addEventListener('characteristicvaluechanged', (event: any) => this.handleNotifications(event))
          navigator.vibrate(300);
        });
        this.writeAction('time');
        setTimeout(() => {
          this.isConfig = true;
          console.log('Will now enter config')
          this.writeAction('conf');
          this.deviceState = 2;
          setTimeout(() => {
            console.log('Will now set ID')
            this.deviceState = 3;
            if (this.isUnlinking) {
              this.writeAction('3;10000');
            } else {
              this.writeAction('3;' + this.selectedSite.siteID);
            }

            setTimeout(() => {
              this.deviceState = 4;
              this.writeAction('98');
            }, 2500)
          }, 2500)
        }, 2500)
      }
    }).catch(err => {
      this.loader.isLoading = false;
      this.notify.openMessage("Connection Cancelled", "error", 10);
    }).finally(() => {
      // this.loader.isLoading = false;
    });
  }

  writeAction(tokenID: string) {
    // if (!this.isDiagnostics && !this.isConfig && tokenID != 'conf' && tokenID != '?') {
    //   this.loader.isLoading = true;
    // }
    // if (tokenID != 'time') {
    //   this.clickable = false;
    // }
    var enc = new TextEncoder();
    var myValue;
    if (tokenID == 'time') {
      let date = Math.floor(new Date().getTime() / 1000.0)
      myValue = "*;" + date;
    } else if (tokenID.includes('*;')) {
      myValue = tokenID;
    } else if (tokenID == '99') {
      myValue = tokenID;
    } else if (tokenID == '?') {
      myValue = tokenID;
    } else if (this.isConfig) {
      if (tokenID == 'conf') {
        myValue = localStorage.getItem("t_" + this.selectedSite.siteName + "_conf");
      } else {
        myValue = tokenID;
      }
    }
    else {
      myValue = localStorage.getItem("t_" + localStorage.getItem("selectedBTSite") + "_" + tokenID);
    }
    if (myValue != null) {
      var myValue1 = enc.encode(myValue + "\n");
      // this.clickable = true;
      if (myValue != '?') {
        // this.logger.addLog(tokenID, this.conn.online,localStorage.getItem("selectedBTSite")); // Don't log status check
      }
      // console.log("about to write: " + myValue1);
      setTimeout(() => {
        return this.btService.myWriteCharacteristic.writeValue(myValue1);
      }, 500);
    }
    else {
      // this.clickable = true;

      console.log("about to write: Nothing");
      this.notify.openMessage('Please get tokens', 'info', 5);
      this.router.navigate(['/token-tools'])
      // return this.btService.myWriteCharacteristic.writeValue(enc.encode(""));
    }
  }

  StopBluetooth() {
    console.log("Stopping Service ...")
    if (this.btService.myReadCharacteristic) {
      this.btService.myReadCharacteristic.stopNotifications()
        .then((data: any) => {
          console.log('> Notifications stopped');
          // this.showCounter = false;
          this.btService.myReadCharacteristic.removeEventListener('characteristicvaluechanged', this.handleNotifications);
          if (this.btService.myDevice.gatt.connected) {
            console.log("Disconnecting from:")
            console.log(this.btService.myDevice)
            this.btService.myDevice.gatt.disconnect();
            this.notify.openMessage('Disconnected...', 'success', 3);
            localStorage.removeItem("selectedBTSite");
            navigator.vibrate(200);
          }
          // this.isConnected = false;
          // this.siteSelected = false;
        })
        .catch((err: any) => {
          console.log('Argh! ' + err);
        });
    }
  }

  userOfSite(user: any) {
    if (user.sites != null && user.sites != undefined) {
      if (user.sites.filter((x: any) => x._id == this.selectedSite._id && x._id != undefined).length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.showDeleted == false) {
      this.dataSource.data = this.sites.filter((x: any) => {
        try {
          const condition = x.handedOver !== true && x.siteID != null &&
            ( x.siteName.toLowerCase().includes(filterValue.toLowerCase()) ||
              x.siteID.toString().includes(filterValue.toLowerCase()) || x.device.serialnumber.toString().includes(filterValue.toLocaleLowerCase())) ;

          if (!condition) {
            // console.log('Filtered out:', x);
          }

          return condition;
        } catch (error) {
          console.error('Error processing item:', x, error);
          return false; // or handle the error as needed
        }
      });


    } else {

      this.dataSource.data = this.deletedSites.filter((x: any) => {
        try {
          const condition = x.handedOver !== true && x.siteID != null &&
            (x.siteName.toLowerCase().includes(filterValue.toLowerCase()) ||
              x.siteID.toString().includes(filterValue.toLowerCase()) || x.device.serialnumber.toString().includes(filterValue.toLocaleLowerCase()));

          if (!condition) {
            // console.log('Filtered out:', x);
          }

          return condition;
        } catch (error) {
          console.error('Error processing item:', x, error);
          return false; // or handle the error as needed
        }
      });


    }

  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getConfigTemplates() {
    this.webReq.getConfigTemplate().subscribe(templates => {
      this.configTemplates = templates;
    })
  }

  permDeleteSite() {
    this.webReq.permDeleteSite(this.selectedDelSite).subscribe(result => {
      if (result.success == true) {
        this.showDelPerm = false;
        this.getSites();
        this.notify.openMessage('Site Deleted Forever', 'success', 3);
        // alert(`(${this.selectedDelSite.siteID}) ${this.selectedDelSite.siteName} Site Permanently Deleted`)
        this.selectedDelSite = null;


      } else {
        this.notify.openMessage('Failed to delete site', 'error', 3);

        // alert("Failed to delete site!")
      }
    })
  }

  tryDeletePerm(element) {
    setTimeout(() => {
      this.selectedSite = null;
      this.selectedDelSite = element;
      this.showDelPerm = true
    }, 10)

  }

  navToSiteReports() {
    this.router.navigate(['/site-reports'])
  }

  refreshSite() {

    this.loader.isLoading = true;
    // let selectedID = this.selectedSite._id;
    this.webReq.getUsers().subscribe(usersRes => {
      this.users = usersRes;
        this.editingSiteUsers = false;
        this.loader.isLoading = false;
    })
    
  }

  completeAddSite(evt:any) {
    if(evt != null) {
      this.addedSite = true;
      this.addedSiteDocID = evt;
      console.log("Site added: " + evt)
  
    }
    
  }

  addUsersToNewSite() {
    this.selectedSite = this.sites.filter(x => x._id == this.addedSiteDocID)[0];
    this.editingSiteUsers = true;
  }
}
