import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-tokens',
  templateUrl: './my-tokens.component.html',
  styleUrls: ['./my-tokens.component.scss']
})
export class MyTokensComponent implements OnInit {

  public availableSites: Set<string> = new Set();
  public siteAvailability : any[] = [];
  public noTokens: boolean = false;
  public dataSource = this.siteAvailability;
  public displayedColumns = ['sitename', 'from','to'] 
  constructor() { }

  ngOnInit(): void {
    this.getSites();
  }

  getSites() {
    let tempSites = new Array<string>();
    let tempDetails:any[] = [];
    for (var key in localStorage) {
      if (key.includes("t_")&& !key.includes("_time") && !key.includes("_diag") && !key.includes("_from") && !key.includes("_to")) {
        tempSites.push(key.substring(2, key.length - 2));
      }
    }

    for (var key in localStorage) {
      if (key.includes("_from")) {
        tempDetails.push({"sitename":key.substring(2, key.length - 5), "from":localStorage.getItem(key)});
      }
    }
    for (var key in localStorage) {
      if (key.includes("_to")) {
        var temp = tempDetails.filter((x:any)=> x.sitename == key.substring(2, key.length - 3))[0] 
        temp = {"sitename": temp.sitename, "from":temp.from, "to": localStorage.getItem(key)} 
        tempDetails.forEach(detail => {
          if(detail.sitename == temp.sitename && detail.from == temp.from) {
            this.siteAvailability.push(temp);
          }
        });;
      }
    }


    this.availableSites = new Set(tempSites);
    console.log(this.siteAvailability)
  }

  checkSites() {
    if (this.availableSites.size == 0) {
      this.noTokens = true;
    } else if (this.availableSites.size == 1) {
      var values = this.availableSites.values();
      var obj = values.next();
      var chosen = obj.value;
    }
  }

  checkValidity(row:any):string {
    var currentTime = new Date().getTime();
    if(row.from <= currentTime && row.to >= currentTime) {
      console.log("Returning current");
      return "current";
    }else if (row.from < currentTime && row.to < currentTime) {
      console.log("Returning past");
      return "past";
    } else if (row.from > currentTime) {
      console.log("Returning future");
      return "future";
    } else {
      return "";
    }
    
  }
}
