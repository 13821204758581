<mat-card class="mat-elevation-z8">
    <div fxLayout="column">
        <h2>User Group</h2>
        <div>
            <label>Details for Group:</label>
            <div fxLayout="column">
                <!-- Group Name -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Group Name</mat-label>
                        <input matInput [(ngModel)]="selectedUserGroup.groupName">
                    </mat-form-field>
                </div>
                <button style="margin: 4px;" mat-raised-button color="primary" (click)="addUserGroup()">
                    <mat-icon>save</mat-icon> Create Group
                </button>
                <button style="margin: 4px;" mat-raised-button color="warn" (click)="closing()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
            </div>
        </div>
    </div>
</mat-card>