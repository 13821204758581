<mat-card class="mat-elevation-z8">
  <div fxLayout="column" style="width: auto;">
    <h2>User Group</h2>
    <div>
      <label>Details for Group:</label>
      <div fxLayout="column">
        <!-- Group Name -->
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline">
            <mat-label>Group Name</mat-label>
            <input matInput [(ngModel)]="selectedUserGroup.groupName">
          </mat-form-field>
        </div>

        <button mat-mini-fab style="position: absolute; margin: 4px; top:4px; right: 4px;" mat-raised-button
          color="primary" (click)="closing()">
          <mat-icon>close</mat-icon>
        </button>

        <div fxLayout="row" fxLayoutAlign="space-evenly center">
          <div fxLayout="column">
            <mat-label>Group Users</mat-label>
            <div class="listBox">
              <div fxLayoutAlign="space-between center" style="margin: 4px;">
                <label style="font-size: large;">Users</label>
              </div>
              <mat-divider></mat-divider>
              <div *ngFor="let user of currentUsers" class="listItem">
                <div fxLayout="row">
                  <mat-checkbox (change)="checkCurrentUser(user, $event.checked)"></mat-checkbox>
                  <div>{{user.username}}</div>
                </div>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>

          <div fxLayout="column">
            <button mat-mini-fab (click)="removeCurrentUsers()">
              <mat-icon>arrow_right</mat-icon>
            </button>
            <button mat-mini-fab (click)="addCurrentUsers()">
              <mat-icon>arrow_left</mat-icon>
            </button>
          </div>

          <div fxLayout="column">
            <mat-label>None Users</mat-label>
            <div class="listBox">
              <div fxLayoutAlign="space-between center" style="margin: 4px;">
                <label style="font-size: large;">Users</label>
              </div>
              <mat-divider></mat-divider>
              <div *ngFor="let user of currentNoneUsers" class="listItem">
                <div fxLayout="row">
                  <mat-checkbox (change)="checkNoneUser(user, $event.checked)"></mat-checkbox>
                  <div style="margin-left: 8px">{{user.username}}</div>
                </div>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>

        </div>

        <div fxLayout="column" fxLayoutAlign="space-evenly center">
          <button style="margin: 4px; width: 30vw;" mat-raised-button color="primary" (click)="updateUserGroup()">
            <mat-icon>save</mat-icon> Update Group
          </button>
          <button style="margin: 4px; width: 30vw;" mat-raised-button color="warn" (click)="deleteUserGroup()">
            <mat-icon>delete</mat-icon> Delete
          </button>
        </div>
        

      </div>
    </div>
  </div>
</mat-card>