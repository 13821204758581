<div fxLayout="column" fxLayoutAlign="space-evenly center" style="width: fit-content">
    <button mat-mini-fab color="primary" (click)="closing()"
        style="position: absolute; right: 4px; top: 4px;"><mat-icon>close</mat-icon></button>
    <h1>Manage Users for site ({{site.siteID}}) {{site.siteName}}</h1>
    <div fxLayout="row">
        <div fxLayout="column">
            <label>Users of site</label>
       
            <div fxLayout="column" class="selectionBox">
                <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input matInput [(ngModel)]="usersFilter"  (keyup)="applyUserFilter($event)">
                </mat-form-field>
                <section *ngFor="let user of filteredUsersOfSite"  fxLayout="row" fxLayoutAlign="start center"><mat-icon style="color:red; cursor: pointer"
                        (click)="removeUserFromSite(user)" class="addRem">remove</mat-icon> {{user.username}} ({{user.email}})</section>
            </div>
        </div>

        <div fxLayout="column">
            <label>Not Users of site</label>
            
            <div fxLayout="column" class="selectionBox">
                <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input matInput [(ngModel)]="nonUsersFilter"  (keyup)="applyNonUserFilter($event)">
                </mat-form-field>
                <section *ngFor="let user of filteredNotUsersOfSite" fxLayout="row" fxLayoutAlign="start center"><mat-icon style="color:lime; cursor: pointer"
                        (click)="addUserToSite(user)" class="addRem">add</mat-icon> {{user.username}} ({{user.email}})</section>
            </div>
        </div>
    </div>


</div>