import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
// import * as Papa from 'papaparse';
// import * as Papa from 'papaparse';
const EXCEL_EXTENSION = '.xlsx'; 

@Injectable({
  providedIn: 'root'
})
export class ExportServiceService {

  constructor() { }

  public exportToExcel (element: any, fileName: string): void {
    // generate workbook and add the worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(element);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new(); 

    // save to file
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    XLSX.writeFile(workbook, fileName + EXCEL_EXTENSION); 
  }

  exportToCsv(data: any[], filename: string) {
    const csv = this.convertToCsv(data);
    this.downloadCsv(csv, filename);
  }
  
  public convertToCsv(data: any[]): string {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const item of data) {
      const values = headers.map(header => {
        return item[header];
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  public downloadCsv(csv: string, filename: string) {
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvUrl = window.URL.createObjectURL(csvData);

    const tempLink = document.createElement('a');
    tempLink.setAttribute('href', csvUrl);
    tempLink.setAttribute('download', filename);
    tempLink.click();
  }

}
