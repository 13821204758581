import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {

  public online: boolean = true;

  constructor(public logger: LoggingService) {
    window.addEventListener('online', () => {
      this.online = true;
      console.log("Back Online!");
      this.logger.sendAllLogs();
    });
    window.addEventListener('offline', () => {
      this.online = false;
    });
  }

  addOfflineBTLog() {

  }

  pushBTLogs() {

  }
  
}
