<div class="myHeader">
  User Management
</div>


<mat-tab-group style="position:absolute; left: 0; top:12vh;width: 100%;height: 75vh;"
  (selectedTabChange)="toggleGroups($event)">
  <mat-tab label="Users">

    <div class="filter-container">
      <mat-form-field>
        <mat-label>
          Filter <mat-icon>search</mat-icon>
        </mat-label>
        <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="locked">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Locked </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: red" *ngIf="element.blocked">lock</mat-icon>
            <mat-icon *ngIf="element.blocked == undefined || element.blocked == false">lock_open</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Username </th>
          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="surname">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Surname </th>
          <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="cellphone">
          <th mat-header-cell *matHeaderCellDef> Cellphone </th>
          <td mat-cell *matCellDef="let element"> {{element.cellphone}} </td>
        </ng-container>


        <ng-container matColumnDef="admin">
          <th mat-header-cell *matHeaderCellDef> Admin </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: limegreen" *ngIf="element.isAdmin==true">check_circle</mat-icon>
            <mat-icon style="color: red" *ngIf="element.isAdmin==false">cancel_circle</mat-icon>
          </td>
        </ng-container>


        <ng-container matColumnDef="vkeys">
          <th mat-header-cell *matHeaderCellDef> v-Keys </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: limegreen" *ngIf="element.generateTokens==true">check_circle</mat-icon>
            <mat-icon style="color: red" *ngIf="element.generateTokens==false">cancel_circle</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="canHandover">
          <th mat-header-cell *matHeaderCellDef> Handovers </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: limegreen" *ngIf="element.canHandover==true">check_circle</mat-icon>
            <mat-icon style="color: red" *ngIf="element.canHandover==false">cancel_circle</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="canCommission">
          <th mat-header-cell *matHeaderCellDef> Commissions </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: limegreen" *ngIf="element.canCommission==true">check_circle</mat-icon>
            <mat-icon style="color: red" *ngIf="element.canCommission==false">cancel_circle</mat-icon>
          </td>
        </ng-container>


        <ng-container matColumnDef="configurator">
          <th mat-header-cell *matHeaderCellDef> Configure </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: limegreen" *ngIf="element.canConfigure==true">check_circle</mat-icon>
            <mat-icon style="color: red" *ngIf="element.canConfigure==false">cancel_circle</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="subcontractor">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Subcontractor </th>
          <td mat-cell *matCellDef="let element"> {{element.subcontractor}} </td>
        </ng-container>


        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element"> <mat-icon (click)="selectedUser = element">edit</mat-icon> </td>
        </ng-container>

        <ng-container matColumnDef="linked">
          <th mat-header-cell *matHeaderCellDef> Linked </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.device != null && element.device != undefined && element.device.linked == true"
              style="color: lime;">radio_button_checked</mat-icon>
            <mat-icon *ngIf="element.device == null || element.device == undefined || element.device.linked != true"
              style="color: red;">radio_button_checked</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field>
              <mat-label>Filter <mat-icon>search</mat-icon></mat-label>
              <input matInput="filterValue">
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedUser = row"></tr>
      </table>


    </div>

  </mat-tab>
  <mat-tab label="User Groups">


    <div class="filter-container">
      <mat-form-field>
        <mat-label>
          Filter <mat-icon>search</mat-icon>
        </mat-label>
        <input matInput [(ngModel)]="filterValue" (keyup)="applyUserGroupFilter($event)">
      </mat-form-field>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="dataSourceGroups" #groupsSort matSort class="mat-elevation-z8" style="width: 100%">

        <ng-container matColumnDef="groupname">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.groupName}} </td>
        </ng-container>

        <ng-container matColumnDef="count">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Users in Group </th>
          <td mat-cell *matCellDef="let element"> {{element.users.length}} </td>
        </ng-container>


        <ng-container matColumnDef="configurator">
          <th mat-header-cell *matHeaderCellDef> Configure </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="color: limegreen" *ngIf="element.canConfigure==true">check_circle</mat-icon>
            <mat-icon style="color: red" *ngIf="element.canConfigure==false">cancel_circle</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element"> <mat-icon (click)="selectedUser = element">edit</mat-icon> </td>
        </ng-container>



        <ng-container matColumnDef="filter">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field>
              <mat-label>Filter <mat-icon>search</mat-icon></mat-label>
              <input matInput="filterValueGroups">
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsGroups; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsGroups;"
          (click)="selectedUserGroup = row; editingUserGroup = true;"></tr>
      </table>


    </div>

  </mat-tab>
</mat-tab-group>

<button class="btn" *ngIf="!showGroups"
  style="position: absolute; bottom: 7vh; z-index: 3001; color: black; background-color: lime; left: 8px;"
  (click)="addingUser = true"><mat-icon>person_add</mat-icon> Add User</button>
<mat-paginator #myPaginator *ngIf="!showGroups" [length]="dataSource.data.length" [pageSize]="10"
  [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page" style="position: absolute; bottom: 6vh; width: 100vw;">
</mat-paginator>
<button class="btn" *ngIf="showGroups"
  style="position: absolute; bottom: 7vh; z-index: 3001; color: black; background-color: lime; left: 8px;"
  (click)="addingUserGroup = true"><mat-icon>group_add</mat-icon> Add Group</button>
<mat-paginator #myPaginatorGroups *ngIf="showGroups" [length]="dataSourceGroups.data.length" [pageSize]="10"
  [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page" style="position: absolute; bottom: 6vh; width: 100vw;">
</mat-paginator>


<!-- Add User -->
<div *ngIf="addingUser || editingUserGroup || addingUserGroup" class="backdrop"
  style="position: fixed; width: 100vw; height: 100vh; top: 0; z-index: 3004; background-color: rgba(100,100,100,0.5);">
</div>

<div *ngIf="addingUser"
  style="position: absolute; width:fit-content; height: fit-content; left: 0; top: 0; bottom: 0; right: 0; margin: auto; border:2px solid white; z-index: 3005;"
  cdkDrag>
  <app-add-user (close)="addingUser = false; getUsers(); completeAddUser($event)" [subcontractors]="subcontractors"></app-add-user>
</div>

<!-- Add new User to sites -->
<div *ngIf="addedUser">
  <mat-card style="width:fit-content; height: fit-content; left:0; right: 0; top: 0; bottom: 0; margin:auto; border: 1px solid white; z-index: 3011">
    <mat-card-header>
      <mat-card-title>
        New User Added
      </mat-card-title>
      <mat-card-subtitle>
        Add user to sites?
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>Do you want to add the newly created user to sites?</p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="addedUser = false; addNewUserToSites();">Yes</button>
      <button mat-raised-button color="warn" (click)="addedUser = false; addedEmail = null">No</button>
    </mat-card-actions>
  </mat-card>
</div>

<!-- Edit User Panel -->
<div *ngIf="selectedUser != null" class="backdrop" 
  style="position: fixed; width: 100vw; height: 100vh; top: 0; z-index: 3004; background-color: rgba(100,100,100,0.5);">
</div>

<div class="editPanel" cdkDrag
  style="position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; border: 1px solid white; z-index: 3005; width: 50vw; height: 90vh; max-height: 90vh; overflow: auto;"
  *ngIf="selectedUser != null">
  <button mat-mini-fab color="primary" style="position: absolute; top: 4px; right: 4px;" (click)="selectedUser = null">
    <mat-icon>close</mat-icon>
  </button>
  <div fxLayout="column">
    <h2>Edit User</h2>
    <div fxLayout="row" fxLayoutAlign="space-evenly stretch">
      <div>
        <label>Details for User:</label>
        <div fxLayout="column" *ngIf="selectedUser != {}">
          <!-- ID -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <mat-label>ID</mat-label>
              <input matInput [(ngModel)]="selectedUser._id" disabled>
            </mat-form-field>
          </div>
          <!-- Email -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="selectedUser.email" disabled>
            </mat-form-field>
          </div>
          <!-- Username -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput [(ngModel)]="selectedUser.username">
            </mat-form-field>
          </div>
          <!-- Name -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput [(ngModel)]="selectedUser.name">
            </mat-form-field>
          </div>
          <!-- Surname -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <mat-label>Surname</mat-label>
              <input matInput [(ngModel)]="selectedUser.surname">
            </mat-form-field>
          </div>
          <!-- ID -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <mat-label>ID Number</mat-label>
              <input matInput [(ngModel)]="selectedUser.idNumber">
            </mat-form-field>
          </div>
          <!-- Subcontractor -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <mat-label>Subcontractor</mat-label>
              <mat-select [(ngModel)]="selectedUser.subcontractor">
                <mat-option value="None">None</mat-option>
                <mat-option *ngFor="let contractor of subcontractors"
                  [value]="contractor.name">{{contractor.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- sysAdmin
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.sysAdmin">System Admin</mat-checkbox>
            </div> -->

          <mat-radio-group [(ngModel)]="selectedUser.userType">
            <mat-radio-button [value]="'basic'" (click)="setUserType('basic')"> Basic User </mat-radio-button>
            <mat-radio-button [value]="'se'" (click)="setUserType('se')"> Site Engineer </mat-radio-button>
            <mat-radio-button [value]="'installer'" (click)="setUserType('installer')"> Installer </mat-radio-button>
            <mat-radio-button [value]="'advanced'" (click)="setUserType('advanced')"> Advanced </mat-radio-button>
          </mat-radio-group>

          <div *ngIf="selectedUser.userType != 'basic'">
            <!-- Admin -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.isAdmin">Admin</mat-checkbox>
            </div>
            <!-- Tokens -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.generateTokens">Can Generate v-Keys
              </mat-checkbox>
            </div>
            <!-- Can Configure -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.canConfigure">Can Configure
              </mat-checkbox>
            </div>
            <!-- Can Handover -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.canHandover">Can Handover
              </mat-checkbox>
            </div>
            <!-- Can Commission -->
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-checkbox [(ngModel)]="selectedUser.canCommission">Can Commission
              </mat-checkbox>
            </div>
          </div>

          <!-- Cellphone -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline">
              <mat-label>Cellphone</mat-label>
              <input matInput [(ngModel)]="selectedUser.cellphone">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div>
        <!-- Sites -->
        <label>Sites</label>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="editPanel" style="height: 40vh; max-height: 40vh; overflow-y: scroll; width: 20vw; margin: 8px;">
            <div *ngFor="let site of selectedUser.sites">
              <div>
                <div>{{site.siteName}} ({{site.siteID}})</div>
                <mat-divider></mat-divider>
              </div>
            </div>
            <button mat-mini-fab
              style="background-color: lightgreen; color:black; position: absolute; right: 25px; top: 54px;"
              (click)="addingUserSites = !addingUserSites">
              <mat-icon>edit</mat-icon>
            </button>
          </div>

          <app-edit-user-sites *ngIf="addingUserSites" [siteList]="siteList" [activeSites]="selectedUser.sites"
            (close)="addingUserSites = false;"
            (savedSites)="selectedUser.sites=$event; addingUserSites = false;"></app-edit-user-sites>

        </div>
        <div fxLayout="column">
          <button mat-raised-button color="warn" style="margin: 8px; width: 50%" (click)="lockingUser = true"
          *ngIf="!selectedUser.blocked"><mat-icon>lock</mat-icon>Lock User</button>
        <label style="color: red;" *ngIf="selectedUser.blocked">*User locked: {{selectedUser.blockedReason}}</label>
        <button mat-raised-button color="primary" style="margin: 8px; width: 50%" (click)="resettingDevice = true"
          *ngIf="canApprove"><mat-icon>app_blocking</mat-icon>Reset Devices</button>
        </div>
        
      </div>


    </div>

    <div fxLayout="column" fxLayoutAlign="space-evenly center">
      <button mat-raised-button style="background-color: lightgreen; color:black; width: 25vw; margin: 4px;"
        (click)="resetPassword()">
        <mat-icon>restart_alt</mat-icon>Reset Password
      </button>
      <button style="margin: 4px; width: 25vw;" mat-raised-button color="warn" (click)="deleteUser()">
        <mat-icon>delete</mat-icon> Delete User
      </button>
      <button style="margin: 4px; width: 25vw;" mat-raised-button color="primary" (click)="updateUser()"
        [disabled]="validateData()">
        <mat-icon>save</mat-icon> Update User
      </button>
    </div>


  </div>
</div>

<!-- Edit User Group -->
<div *ngIf="editingUserGroup"
  style="margin:auto; position:absolute; width: 80vw; right: 0; left: 0; top:0;bottom:0; border:2px solid white; z-index: 3011; height: fit-content;"
  cdkDrag>
  <app-edit-user-group [selectedUserGroup]="selectedUserGroup" [allUsers]="users"
    (close)="editingUserGroup = false; getUserGroups();"></app-edit-user-group>
</div>

<!-- Add User Group -->
<div *ngIf="addingUserGroup"
  style="position: absolute; width:fit-content; left: 0; right: 0; top: 0; bottom:0; margin: auto; height: fit-content; border:2px solid white; z-index: 3011;"
  cdkDrag>
  <app-add-user-group (close)="addingUserGroup = false; getUserGroups();"></app-add-user-group>
</div>

<!-- User Locking -->
<mat-card fxLayout="column" fxLayoutAlign="center center" *ngIf="lockingUser == true"
  style="position: absolute; width:fit-content; left: 0; right: 0; top: 0; bottom:0; margin: auto; height: fit-content; border:2px solid white; z-index: 3011;">
  <h2>Please supply a reason for locking this user:</h2>
  <label>({{selectedUser.email}}) {{selectedUser.name}} {{selectedUser.surname}}</label>
  <mat-form-field appearance="outline">
    <mat-label>Lock Reason</mat-label>
    <input matInput [(ngModel)]="selectedUser.blockedReason">
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="lockUser()"
    style="width: 60%; margin: 8px;"><mat-icon>lock</mat-icon> Lock User</button>
  <button mat-raised-button color="warn" (click)="lockingUser = false"
    style="width: 60%; margin: 8px;"><mat-icon>cancel</mat-icon> Cancel</button>
</mat-card>

<!-- Device Reset -->
<mat-card fxLayout="column" fxLayoutAlign="center center" *ngIf="resettingDevice == true"
  style="position: absolute; width:fit-content; left: 0; right: 0; top: 0; bottom:0; margin: auto; height: fit-content; border:2px solid white; z-index: 3011;">
  <h2>Are you sure you wish to reset devices for the user:</h2>
  <label>({{selectedUser.email}}) {{selectedUser.name}} {{selectedUser.surname}}</label>
  
  <button mat-raised-button color="primary" (click)="resetDevices()"
    style="width: 60%; margin: 8px;"><mat-icon>app_blocking</mat-icon> Continue Reset</button>
  <button mat-raised-button color="warn" (click)="resettingDevice = false"
    style="width: 60%; margin: 8px;"><mat-icon>cancel</mat-icon> Cancel</button>
</mat-card>