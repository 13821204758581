import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-bypass',
  templateUrl: './bypass.component.html',
  styleUrls: ['./bypass.component.scss']
})
export class BypassComponent implements OnInit {

  siteID: any;

  myCharacteristic: any;

  counterValue  : any = 0;
  clickable: boolean = true;
  isConnected = false;
  myContent: string = '';

  constructor(private webreq: WebRequestsService,  
    public btService: BluetoothService, 
    private snackBar: MatSnackBar, 
    public loader: LoaderService,
    public notify: NotificationsService) { }

  ngOnInit(): void {
    this.siteID = localStorage.getItem('siteID');
  }

  StopBluetooth() {
    console.log("Stopping Service ...")
    if (this.btService.myReadCharacteristic) {
      this.btService.myReadCharacteristic.stopNotifications()
        .then((data: any) => {
          console.log('> Notifications stopped');
          this.btService.myReadCharacteristic.removeEventListener('characteristicvaluechanged', this.handleNotifications);
          if(this.btService.myDevice.gatt.connected) {
            console.log("Disconnecting from:")
            console.log(this.btService.myDevice)
            this.btService.myDevice.gatt.disconnect();
            this.snackBar.open('Disconnected...', 'Ok', {duration:3000})
            navigator.vibrate(200);
          }
          this.isConnected=false;
        })
        .catch((err: any) => {
          console.log('Argh! ' + err);
        });
    }
  }

  Connect() {
    this.loader.isLoading = true;
    // this.clickable = false;
    this.btService.connectDevice().then(conn => {
      if(conn) {
        this.isConnected = true;
        this.btService.myReadCharacteristic.startNotifications().then(()=> {
          console.log("Listening...")
          this.loader.isLoading = false;
          this.btService.myReadCharacteristic.addEventListener('characteristicvaluechanged', (event: any) => this.handleNotifications(event))
          navigator.vibrate(300);
        });
        // this.writeAction('time');
      }
    }).catch(err => {
      this.notify.openMessage("Connection Cancelled", "error");
    }).finally(()=> {
      this.loader.isLoading = false;
    });
  }

  handleNotifications(event: any) {
    let utf8decoder = new TextDecoder();
    let value = event.target.value;
    let a = [];
    let b;
    // Convert raw data bytes to hex values just for the sake of showing something.
    // In the "real" world, you'd use data.getUint8, data.getUint16 or even
    // TextDecoder to process raw data bytes.
    for (let i = 0; i < value.byteLength; i++) {
      a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
    }
    b = (utf8decoder.decode(value));
    // console.log('> ' + a.join(' '));
    // Handle Response Here
    console.log('Text Value > ' + b);
    if(b[0] == '0' || b[0] == '1' || b[0] == '2' || b[0] == '3' || b[0] == '4' || b[0] == '5' || b[0] == '7' || b[0] == '8' || b[0] == '9' || b[0] == ':' || b[0]==';' || b[0]=='<' || b[0]=='=') {
      this.notify.openMessage(b[0], 'success');
      this.clickable = true;
      this.loader.isLoading = false;
    }
    this.counterValue = utf8decoder.decode(value);
  }

  sendContent() {
    this.loader.isLoading = true;
    this.clickable = false;
    var enc = new TextEncoder(); // Check need
    var myValue1 = enc.encode(this.myContent + "\n"); // Check need
    this.clickable = true;
    this.loader.isLoading = false;
    this.myContent = "";
    return this.btService.myWriteCharacteristic.writeValue(myValue1);
  }
  setTime() {
    this.loader.isLoading = true;
    this.clickable = false;
    var enc = new TextEncoder(); // Check need
    var myValue1 = enc.encode("*;1638280801" + "\n"); // Check need
    this.clickable = true;
    this.loader.isLoading = false;
    this.myContent = "";
    return this.btService.myWriteCharacteristic.writeValue(myValue1);
  }

  open() {
    this.loader.isLoading = true;
    this.clickable = false;
    var enc = new TextEncoder(); // Check need
    var myValue1 = enc.encode("3iz/gGY6/ew5F1mD9T2Ss9d2sTbSKM8n2ASsjA==" + "\n"); // Check need
    this.clickable = true;
    this.loader.isLoading = false;
    this.myContent = "";
    return this.btService.myWriteCharacteristic.writeValue(myValue1);
  }
  
  close() {
    this.loader.isLoading = true;
    this.clickable = false;
    var enc = new TextEncoder(); // Check need
    var myValue1 = enc.encode("0jK8GHFz7WiBmKCc4nexGs8s2g37mVP4jp90kg==" + "\n"); // Check need
    this.clickable = true;
    this.loader.isLoading = false;
    this.myContent = "";
    return this.btService.myWriteCharacteristic.writeValue(myValue1);
  }
}
