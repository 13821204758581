import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  @Output() close = new EventEmitter();

  @Input() clientList: any = [];
  @Input() subcontractors: any = [];

  selectedUser: any = {};
  addingUserClients: boolean = false;

  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {
    
  }

  closing() {
    this.close.emit(null);
  }

  removeSiteFromUser(site:any) {
    const index = this.selectedUser.sites.indexOf(site);
    if(index>=0) {
      this.selectedUser.sites.splice(index, 1);
    }
  }

  removeClientFromUser(client:any) {
    const index = this.selectedUser.clientdb.indexOf(client);
    if(index>=0) {
      this.selectedUser.clientdb.splice(index, 1);
    }
  }

  addUser() {
    // this.selectedUser.clientdb = [];
    let alphabet = 'abcdefghijklmnopqrstuvwxyz';
    let isValid = true;

    console.log(this.selectedUser)

   

    if(isValid == true) {
      // if(this.selectedUser.idNumber.length != 13 )
      this.selectedUser.clientdb = localStorage.getItem('clientdb');
      this.webReq.addUser(this.selectedUser).subscribe((res:any) => {
        alert(res.message);
        if(res.message.includes('User already exists')){
          this.close.emit(null)
        } else {
          this.close.emit(this.selectedUser.email);
        }
      });
    }
  }

  getSubcontractors() {
    this.webReq.getSubcontractors().subscribe(sc => {
      this.subcontractors = sc;
    })
  }

  setUserType(type:string) {
    if(type == 'basic') {
      this.selectedUser.userType = 'basic';
      this.selectedUser.isAdmin = false;
      this.selectedUser.generateTokens = false;
      this.selectedUser.canConfigure = false;
      this.selectedUser.canCommission = false;
      this.selectedUser.canHandover = false;
    } else if(type == 'se') {
      this.selectedUser.userType = 'se';
      this.selectedUser.isAdmin = false;
      this.selectedUser.generateTokens = false;
      this.selectedUser.canConfigure = true;
      this.selectedUser.canCommission = true;
      this.selectedUser.canHandover = true;
    } else if (type == 'installer') {
      this.selectedUser.userType = 'installer';
      this.selectedUser.isAdmin = false;
      this.selectedUser.generateTokens = false;
      this.selectedUser.canConfigure = true;
      this.selectedUser.canCommission = true;
      this.selectedUser.canHandover = true;
    } else if (type == 'advanced') {
      this.selectedUser.userType = 'advanced';
      this.selectedUser.isAdmin = true;
      this.selectedUser.generateTokens = true;
      this.selectedUser.canConfigure = true;
      this.selectedUser.canCommission = true;
      this.selectedUser.canHandover = true;
    }
  }

  validateData(){
    const emailRegex  = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");

    
    let emailValid = emailRegex.test(this.selectedUser.email);

    if(!emailValid || this.selectedUser.subcontractor == null || this.selectedUser.subcontractor == undefined || this.selectedUser.subcontractor == '' || this.selectedUser.cellphone == '' || (this.selectedUser.idNumber == undefined || this.selectedUser.idNumber.length < 5)) {
      return true;
    } else{
      return false
    } 
    
  }
}
