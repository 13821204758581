// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: "https://192.168.3.120:5000",
  // baseUrl: "https://783d08884886.sn.mynetname.net:32768",
  // baseUrl: "https://783d08884886.sn.mynetname.net:32769", // This is prod
  baseUrl: "https://783d08884886.sn.mynetname.net:32766", // This is dev
  // baseUrl: "https://192.168.1.253", // This is the new server for testing!!!
  // baseUrl: "https://192.168.3.120:8443",
  wsUrl: "wss://783d08884886.sn.mynetname.net:32766/call", // This is dev
  // wsUrl: "wss://783d08884886.sn.mynetname.net:32769/call", // This is prod



  firebase: {
    apiKey: "AIzaSyAAFH-Kd0NGKuElADyiSNzGLUQ09pKpB9E",
  authDomain: "prism-v2-df011.firebaseapp.com",
  projectId: "prism-v2-df011",
  storageBucket: "prism-v2-df011.appspot.com",
  messagingSenderId: "894592159783",
  appId: "1:894592159783:web:eb81651f591d37f6957e02",
  measurementId: "G-Q2DGVCWQZ5"
  },

  ftpConfig: {
    host: 'www.myprism.co.za',
    user: 'myprickgpg_0',
    password: 'F9h607D1c8f104',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
