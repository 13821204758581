import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() description = '';
  @Input() link: string = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  navToLink() {
    if(this.link.includes('https://myprism.co.za')){
      window.open(this.link, "_blank");
    } else {
      this.router.navigate(['/'+this.link]);
    }
  }


}
