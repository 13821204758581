import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-add-site-group',
  templateUrl: './add-site-group.component.html',
  styleUrls: ['./add-site-group.component.scss']
})
export class AddSiteGroupComponent implements OnInit {

  @Input() selectedSiteGroup: any = {};
  @Output() close = new EventEmitter();
  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
  }

  addSiteGroup() {
    this.webReq.addSiteGroup(this.selectedSiteGroup).subscribe((result:any) => {
      this.notify.openMessage(result.message, result.success == true ? 'success' : 'error', 3000);
      this.close.emit();
    });
  }

  closing() {
    this.close.emit();
  }


}
