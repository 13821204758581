import { Component, OnInit, ViewChild } from '@angular/core';
import { DayPilot, DayPilotCalendarComponent, DayPilotMonthComponent, DayPilotNavigatorComponent } from '@daypilot/daypilot-lite-angular';
import { memoryUsage } from 'process';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss', './test_theme.css']
})
export class SchedulesComponent implements OnInit {

  myEvent: any = {};
  siteGroup: any;

  public fromDate:any  = new Date(Date.now() + 119*60000).toISOString().slice(0, 16); // toISO returns GMT Time (-2 hours)
  public toDate:any = new Date(Date.now() + 150*60000).toISOString().slice(0, 16);
  public minDate: any = new Date(Date.now() + 118*60000).toISOString().slice(0, 16);

  @ViewChild("day") day!: DayPilotCalendarComponent;
  @ViewChild("week") week!: DayPilotCalendarComponent;
  @ViewChild("month") month!: DayPilotMonthComponent;
  @ViewChild("navigator") nav!: DayPilotNavigatorComponent;

  creatingEvent = false;

  users: any = [];
  sites: any = [];
  reasons: any = [];
  schedules: any = [];
  eventObjects: any = [];
  isEditEvent: boolean = false;
  selectedEvent: any;

  public isSiteGroup: boolean = false;
  public tokensSet = false;


  configDay: DayPilot.CalendarConfig = {
    theme: "calendar_green",
    onTimeRangeSelected: args => {
      alert("Date selected: " + args.start + " to " + args.end)
    },
    onEventClicked: args => {
      // this.isEditEvent = true;
      this.setMyEvent(args.e.data);
    }
  };

  configMonth: DayPilot.MonthConfig = {
    theme: "calendar_green",
    eventMoveHandling: 'Disabled',
    onTimeRangeSelected: args => {
      this.creatingEvent = true; 
      this.setCustomSchedule({from:args.start, to: args.end.addHours(-6)})
    },
    onEventClicked: args => {
      this.setMyEvent(args.e.data);
    }
  };

  configWeek: DayPilot.CalendarConfig = {
    viewType: "Week",
    onEventClicked: args => {
      this.setMyEvent(args.e.data);
    }
  };

  events: DayPilot.EventData[] = [];

  date = DayPilot.Date.today();

  configNavigator: DayPilot.NavigatorConfig = {
    showMonths: 3,
    cellWidth: 20,
    cellHeight: 20,
    onVisibleRangeChanged: args => {
      // this.loadEvents();
    }
  };

  constructor(public webReq: WebRequestsService, public loader: LoaderService, public notifier: NotificationsService) {
    // this.viewWeek();
    this.viewMonth();
   }

  async ngOnInit() {
    // this.loadEvents();
    this.setDefaultEvent();
    this.getReasons();
    this.getSites();
    this.getUsers();
    this.getSchedules();
    this.loader.isLoading = false;
  }

  setMyEvent(data) {
    let tempEvent;

    tempEvent = this.schedules.filter(x=>x._id == data.id)[0];
    
    this.myEvent.from = new Date(new Date(tempEvent.start).setHours(new Date(tempEvent.start).getHours()+2)).toISOString().slice(0,16);
    this.myEvent.to = new Date(new Date(tempEvent.end).setHours(new Date(tempEvent.end).getHours()+2)).toISOString().slice(0,16);
    this.myEvent.user = this.users.filter(x=>x.email == tempEvent.email)[0];
    this.myEvent.site = this.sites.filter(x=> x.siteID == tempEvent.siteID)[0];
    this.myEvent._id = tempEvent._id;
    this.myEvent.reason = tempEvent.reason;
    this.myEvent.downloaded = tempEvent.downloaded;
    this.creatingEvent = true;
  }

  getUsers() {
    this.webReq.getUsers().subscribe(users => {
      this.users = users;
    })
  }

  getSites() {
    this.webReq.getSitesAll().subscribe(sites => {
      this.sites = sites;
    })
  }

  getReasons() {
    this.webReq.getReasons().subscribe(reasons => {
      this.reasons = reasons.reasons;
    })
  }
  

  changeDate(event:DayPilot.Date) {
    this.configDay.startDate = event;
    this.configWeek.startDate = event;
    this.configMonth.startDate = event;
  }

  async onTimeRangeSelected(args) {
    const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
  }

  timeRangeSelected(event) {
  }
  
  selectTomorrow() {
    this.date = DayPilot.Date.today().addDays(1);
  }

  viewDay():void {
    this.configNavigator.selectMode = "Day";
    this.configDay.visible = true;
    this.configWeek.visible = false;
    this.configMonth.visible = false;
  }

  viewWeek():void {
    this.configNavigator.selectMode = "Week";
    this.configDay.visible = false;
    this.configWeek.visible = true;
    this.configMonth.visible = false;
  }

  viewMonth():void {
    this.configNavigator.selectMode = "Month";
    this.configDay.visible = false;
    this.configWeek.visible = false;
    this.configMonth.visible = true;
  }

  setDefaultEvent() {
    let evt = new DayPilot.Event({
      start: DayPilot.Date.now(),
      end: DayPilot.Date.today().addDays(1),
      id: DayPilot.guid(),
      text: "Test"
    })
    // let evtData = new DayPilot.Event();
    this.events.push(evt.data)
  }

  setDefaultEvent1(customData) {
    let evt = new DayPilot.Event({
      start: customData.from,
      end: customData.to,
      id: DayPilot.guid(),
      text: "Test"
    })
    // let evtData = new DayPilot.Event();
    this.events.push(evt.data)
  }

  generateSystemTokens(site:any) {
    this.getTokensNew(site);
  }
  
  getTokensNew(site:any) {
    let siteArray = [];
    this.loader.isLoading = true;
    let pArray = [];

    if(this.isSiteGroup == true) {
      this.siteGroup.forEach((group:any) => {
        group.sites.forEach((site:any) => {
        pArray.push(this.webReq.generateSystemTokensNew(site,[[1,0],[0,0],[0,1],[0,2],[3,0],[2,0], [4,0]],new Date(this.myEvent.from).getTime(), new Date(this.myEvent.to).getTime(),this.myEvent.user.username, this.myEvent.user._id, this.myEvent.reason).toPromise()); 
        });
      });
    } else {
      siteArray.push(this.myEvent.site);
      // site.forEach(siteID => {
      siteArray.forEach(siteID => {

        if(siteID.door2 == true && (siteID.door1 == undefined || siteID.door1 == false)) {
          pArray.push(this.webReq.generateSystemTokensNew(siteID,[[1,0],[5,0],[5,1],[5,2],[3,0],[2,0], [4,0]],new Date(this.myEvent.from).getTime(), new Date(this.myEvent.to).getTime(),this.myEvent.user.username, this.myEvent.user._id, this.myEvent.reason).toPromise()); 
  
           } else if(siteID.door1 == true && siteID.door2 == true) {
          pArray.push(this.webReq.generateSystemTokensNew(siteID,[[1,0],[0,0],[0,1],[0,2], [5,0], [5,1], [5,2],[3,0],[2,0], [4,0]],new Date(this.myEvent.from).getTime(), new Date(this.myEvent.to).getTime(),this.myEvent.user.username, this.myEvent.user._id, this.myEvent.reason).toPromise()); 
           }
            else {
            pArray.push(this.webReq.generateSystemTokensNew(siteID,[[1,0],[0,0],[0,1],[0,2],[3,0],[2,0], [4,0]],new Date(this.myEvent.from).getTime(), new Date(this.myEvent.to).getTime(),this.myEvent.user.username, this.myEvent.user._id, this.myEvent.reason).toPromise()); 
           }

        // pArray.push(this.webReq.generateSystemTokensNew(siteID,[[1,0],[0,0],[0,1],[0,2],[3,0],[2,0],[4,0]],new Date(this.myEvent.from).getTime(), new Date(this.myEvent.to).getTime(),this.myEvent.user.username, this.myEvent.user._id, this.myEvent.reason).toPromise());
      });
    }

    Promise.all(pArray).then(values => {  // This allows for multiple sites for token generation
      this.tokensSet = true;
      this.loader.isLoading = false;
      this.notifier.openMessage('v-Keys generated', 'success');
      this.addEvent();
    }).catch(err => {
      this.loader.isLoading = false;
      this.notifier.openMessage('Failed to generate v-Keys', 'error');
      console.warn(err)
    })
  }

  addEvent() {
    let evt = new DayPilot.Event({
      start:  new Date(this.myEvent.from).toISOString(),
      end: new Date(this.myEvent.to).toISOString(),
      id: null,
      text: "User: " + this.myEvent.user.username + " " + this.myEvent.user.email + " \nSite: " + this.myEvent.site.siteName + " " +this.myEvent.site.siteID 
    })

    let webObj = {
      start:  new Date(this.myEvent.from).toISOString(),
      end: new Date(this.myEvent.to).toISOString(),
      username: this.myEvent.user.username,
      userID: this.myEvent.user.userID,
      email: this.myEvent.user.email,
      cellphone: this.myEvent.user.cellphone,
      site: this.myEvent.site.siteName,
      siteID: this.myEvent.site.siteID,
      reason: this.myEvent.reason,
      name: this.myEvent.user.name,
      surname: this.myEvent.user.surname,
      startEpoch: new Date(this.myEvent.from).getTime(),
      endEpoch: new Date(this.myEvent.to).getTime()
    }

    // this.events.push(evt.data);

    this.webReq.addSchedule(webObj).subscribe(res => {
      this.getSchedules();
      this.creatingEvent = false;
    });
  }

  getSchedules() {
    this.webReq.getSchedules().subscribe(res => {
      this.schedules = res;
      this.events = [];
      this.schedules.forEach(sched => {


        let evt = new DayPilot.Event({
          start: sched.start,
          end: sched.end,
          text: `Name: ${sched.name} ${sched.surname} \nEmail: ${sched.email} \nCellphone: ${sched.cellphone} \nSite: ${sched.site} ${sched.siteID} \nReason: ${sched.reason} \nKeys downloaded: ${sched.downloaded ? 'Yes' : 'No'}`,
          id: sched._id,
          backColor: sched.downloaded ? "orange" : "#e0ded5",
        })

      
        this.events.push(evt.data)

      });

    })
  }

  setDefaultSchedule() {
    this.myEvent.from = new Date(Date.now() + 119*60000).toISOString().slice(0, 16);
    this.myEvent.to = new Date(Date.now() + 150*60000).toISOString().slice(0, 16);
    this.myEvent.user = null;
    this.myEvent.reason = null;
    this.myEvent._id = "";
    this.myEvent.downloaded = false;
  }
  setCustomSchedule(obj) {
    // this.myEvent.from = new Date(Date.now() + 119*60000).toISOString().slice(0, 16);
    this.myEvent.from = obj.from;
    // this.myEvent.to = new Date(Date.now() + 150*60000).toISOString().slice(0, 16);
    this.myEvent.to = obj.to;
    this.myEvent.user = null;
    this.myEvent.reason = null;
    this.myEvent._id = "";
    this.myEvent.downloaded = false;
  }

  removeSchedule(sched) {
    this.webReq.removeSchedule(sched).subscribe(res => {
      this.getSchedules();
      this.creatingEvent = false;
    })
  }
}
