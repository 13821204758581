import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  public username: string = "";
  public email:string = "";

  @Output() close = new EventEmitter();

  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
  }

  resetPassword() {
    this.webReq.resetPassword({username: this.username, email: this.email}).subscribe(res => {
      if (res.success == true) {
        this.notify.openMessage('Check email to reset password','success', 3000);
        this.closing();
      } else {
        this.notify.openMessage('Contact Administrator', 'error', 3000);
      }
    });
  }
  closing() {
    this.close.emit('cancel');
  }

}
