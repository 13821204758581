<div fxFlex fxLayoutAlign="center center" fxLayout="column">
  <app-loader></app-loader>
  <mat-card class="mat-elevation-z16" style="height: 75vh; width: 85vw;"
  fxLayoutAlign="space-around center" fxLayout="column">
<button mat-mini-fab style="position: absolute; top: 8px; left:8px; background-color: transparent;" (click)="close()"><mat-icon>arrow_back</mat-icon></button>
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Enter Content</mat-label>
            <input matInput [(ngModel)]="myContent"/>
          </mat-form-field>
          <div (click)="sendContent()" class="block-button mat-elevation-z16" fxLayout="column"
          fxLayoutAlign="center center">
          <!-- <mat-spinner *ngIf="doorSensor.state == 3" class="button-spinner" diameter="25"></mat-spinner> -->
          <mat-icon style="color:darkblue">send</mat-icon>
          <label>Send</label>
        </div>
        <a (click)="openHelp('3.4')" style="position: absolute; bottom: 0px; right: 0px; margin: 4px;"><mat-icon>help_outline</mat-icon></a>

  </mat-card>
</div>