<!-- <p style="color: black;">testing works!</p> -->
<div fxFlex fxLayoutAlign="center center" fxLayout="column">
    <app-loader></app-loader>
    <mat-card class="mat-elevation-z16" style="height: 65vh; width: 85vw;" fxLayoutAlign="center center">
      <mat-card-content fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxLayoutAlign="space-around stretch" fxLayout="column">
          <button mat-fab (click)="Connect()" color="primary" style="margin: 4px; text-align: center !important; width: 100px; height: 100px; box-shadow: 0px 0px 4px 2px gray;" 
          *ngIf="isConnected == false">
            <mat-icon>bluetooth_connected</mat-icon> Connect
          </button>
          <!-- <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="outline" *ngIf="isConnected == true">
              <mat-label>Enter Content</mat-label>
              <input matInput [(ngModel)]="myContent"/>
            </mat-form-field>
            <button mat-mini-fab (click)="sendContent()" color="primary" *ngIf="isConnected == true" fxLayoutAlign="center center" style="margin-left: 10px; margin-bottom: 14px;">
              <mat-icon>send</mat-icon>
            </button>
          </div> -->

          <button mat-raised-button  style="margin: 4px; text-align: start !important;" *ngIf="isConnected" color="primary" (click)="setTime()"> Set Time Token </button>
          <button mat-raised-button  style="margin: 4px; text-align: start !important;" *ngIf="isConnected" color="accent" (click)="open()"> Open </button>
          <button mat-raised-button  style="margin: 4px; text-align: start !important;" *ngIf="isConnected" color="warn" (click)="close()"> Lock </button>
          <button mat-raised-button  style="margin: 4px; text-align: start !important;" *ngIf="isConnected" (click)="StopBluetooth()" color="warn" style="margin: 4px;">
            <mat-icon>bluetooth_disabled</mat-icon> Stop Bluetooth
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>