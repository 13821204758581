<mat-stepper orientation="horizontal" style="background-color: transparent;" linear=true>
    <mat-step>
        <ng-template matStepLabel>Select Reason</ng-template>
        <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <!-- User Count: {{users.length}}, Filtered Users: {{filteredUsers.length}} -->
            <mat-form-field appearance="outline">
                <mat-label>Reason</mat-label>
                <mat-select [(ngModel)]="reason">
                    <mat-option *ngFor="let reason of reasonList" [value]="reason">
                        {{reason}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-raised-button matStepperNext class="nextButton" color="primary">Next</button>
            </div>
        </div>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel>Select User</ng-template>
        <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <!-- User Count: {{users.length}}, Filtered Users: {{filteredUsers.length}} -->
            <mat-form-field appearance="outline">
                <mat-label>User</mat-label>
                <mat-select [(ngModel)]="username">
                    <mat-form-field appearance="outline">
                        <mat-label>Filter</mat-label>
                        <input matInput type="text" (keyup)="filterUsers($event)" [(ngModel)]="userNameFilter">
                    </mat-form-field>

                    <!-- <mat-option *ngFor="let user of users" (click)="getUserSites(user)" [value]="user"
                        [hidden]="filteredUsers.length != 0">
                        {{user.username}}
                    </mat-option> -->
                    <mat-option *ngFor="let user of filteredUsers" (click)="getUserSites(user)" [value]="user"
                        [hidden]="filteredUsers.length == 0 || userNameFilter == ''">
                        {{user.username}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-raised-button matStepperNext class="nextButton" color="primary"
                    [disabled]="username == undefined || username.length < 1">Next</button>
            </div>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Select Site</ng-template>
        <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="isSiteGroup" style="margin-top: 8px;">
                <mat-radio-button color="primary" [value]="false" style="margin:4px;"> Site </mat-radio-button>
                <mat-radio-button color="primary" [value]="true" style="margin:4px;"> Group </mat-radio-button>
            </mat-radio-group>

            <mat-form-field appearance="outline" *ngIf="!isSiteGroup">
                <mat-label>Site</mat-label>
                <mat-select [(ngModel)]="siteID" multiple>
                    <mat-form-field appearance="outline">
                        <mat-label>Filter</mat-label>
                        <input matInput type="text" (keyup)="filterSites($event)" [(ngModel)]="siteFilter">
                    </mat-form-field>
                    <mat-option *ngFor="let site of filteredSites" [value]="site">{{site.siteName}}
                        {{site.secondaryController == true ? '2 Door' : '1 Door'}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="isSiteGroup">
                <mat-label>Group</mat-label>
                <mat-select [(ngModel)]="siteGroup" multiple>
                    <mat-form-field appearance="outline">
                        <mat-label>Filter</mat-label>
                        <input matInput type="text" (keyup)="filterSiteGroups($event)" [(ngModel)]="siteGroupFilter">
                    </mat-form-field>
                    <mat-option *ngFor="let group of filteredSiteGroups" [value]="group">{{group.groupName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div>
                <div *ngFor="let site of siteID">
                    <div *ngIf="site.secondaryController"
                        style="border: 1px solid grey; border-radius: 5px; padding: 8px; margin: 4px;">
                        {{site.siteID}} {{site.siteName}}
                        <div fxLayout="column">
                            <mat-checkbox [(ngModel)]="site.door1">Door 1</mat-checkbox>
                            <mat-checkbox [(ngModel)]="site.door2">Door 2</mat-checkbox>
                        </div>
                        <label style="color:red; font-size: smaller;">*If no door is selected, keys will work for only
                            door 1</label>

                    </div>

                </div>
            </div>

            <div>
                <button mat-raised-button matStepperNext class="nextButton" color="primary">Next</button>
            </div>
        </div>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel>Valid From</ng-template>
        <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <mat-form-field appearance="outline">
                <mat-label>From</mat-label>
                <input matInput [(ngModel)]="fromDate" [min]="minDate" type="datetime-local">
            </mat-form-field>
            <div>
                <button mat-raised-button matStepperNext class="nextButton" color="primary">Next</button>
            </div>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Valid To</ng-template>
        <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <mat-form-field appearance="outline">
                <mat-label>To</mat-label>
                <input matInput [(ngModel)]="toDate" [min]="fromDate" type="datetime-local">
            </mat-form-field>
            <!-- <div>
                        <button mat-raised-button matStepperNext color="primary">Next</button>
                    </div> -->
            <button mat-raised-button color="primary" class="nextButton" (click)="generateSystemTokens(siteID)"
                [disabled]="siteID == undefined || siteID.length < 1">
                <mat-icon>sms</mat-icon> Generate v-Key
            </button>
        </div>

    </mat-step>

</mat-stepper>





<!-- </div> -->
<!-- </div> -->