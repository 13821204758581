import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BluetoothCore, BrowserWebBluetooth } from '@manekinekko/angular-web-bluetooth';
import { Subject } from 'rxjs';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from '../../services/web-requests.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // ol: any;
  // map: any;

  confSubject: Subject<any> = new Subject();
  listenType = 0; // Listen types: [0:normal,1:version,2:config]
  isConfig: boolean = false;
  isConnected: boolean = false;
  deviceState = 0;
  isDiagnostics: boolean = false;
  isUnlinking: boolean = false;
  serviceUUID: string = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
  writeUUID: string = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
  readUUID: string = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';

  allSites: any = [];
  filteredSites: any = [];
  allUsers: any = [];
  filteredUsers: any = [];
  allUserGroups: any = [];
  filteredUserGroups: any = [];
  allSiteGroups: any = [];
  filteredSiteGroups: any = [];
  allSubcontractors:any = [];
  filteredSubcontractors:any = []; 

  allowedSites: any = [];

  selectedClient: any = {};
  tempClient: any = {};
  selectedSite: any = {};
  tempSite: any = {};
  selectedUser: any = {};
  tempUser: any = {};
  selectedUserGroup: any = {};
  selectedSiteGroup: any = {};

  addingClient: boolean = false;
  addingSite: boolean = false;
  addingUsers: boolean = false;
  addingUserClients: boolean = false;
  addingUserSites: boolean = false;
  addingUserGroup: boolean = false;
  editingUserGroup: boolean = false;
  addingSiteGroup: boolean = false;
  editingSiteGroup: boolean = false;
  addingBatch: boolean = false;
  editSiteLocation: boolean = false;
  addingSubcontractor: boolean = false;
  editingSubcontractor: boolean = false;

  manualLinking: boolean = false;
  manualLinkingStep = 1;

  showMap:boolean = false;


  sites = [];



  // selectedSite:any;
  username: any = "";
  password: any;
  user: any;
  token: any = "";
  showToken = false;
  myCharacteristic: any;
  public counterValue: any;
  showCounter = false;
  testValue: string = "";

  isAdmin: boolean = false;

  constructor(private router: Router,
    private webReq: WebRequestsService,
    public blt: BrowserWebBluetooth,
    public loader: LoaderService,
    public snackBar: MatSnackBar,
    public notify: NotificationsService,
    public btService: BluetoothService) { }

  ngOnInit(): void {
    // location.reload()
    if(localStorage.getItem('admin') == 'true') {
      this.isAdmin = true;
    }
    this.loader.isLoading = true;
    this.username = localStorage.getItem('username');
    this.password = localStorage.getItem('password');
    this.user = localStorage.getItem('username');
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    if (this.username == null || isLoggedIn == null || isLoggedIn == 'false') {
      this.router.navigate(['/login']);
    }
    this.getSites();
    this.counterValue = "Test";

    this.selectedClient = null;
    this.selectedSite = null;
    this.selectedUser = null;

    this.getSitesAndUsers();



  }

  applySitesFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredSites = this.allSites.filter(x => x.siteName.toLowerCase().includes(filterValue) || x.siteID.toString().includes(filterValue));
    if(filterValue == "") {
      this.filteredSites = this.allSites;
    }
  }

  applyUsersFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredUsers = this.allUsers.filter(x => x.username.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredUsers = this.allUsers;
    }
  }

  applySiteGroupFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredSiteGroups = this.allSiteGroups.filter(x => x.groupName.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredSiteGroups = this.allSiteGroups;
    }
  }

  applyUserGroupFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredUserGroups = this.allUserGroups.filter(x => x.groupname.toLowerCase().includes(filterValue));
    if(filterValue == "") {
      this.filteredUserGroups = this.allUserGroups;
    }
  }

  getSites() {
    this.loader.isLoading = true;
    this.webReq.getSites(this.user).subscribe(data => {
      this.sites = data
      this.loader.isLoading = false;
    }, err => {
      this.loader.isLoading = false;
      this.notify.openMessage('Failed to get sites', 'error')
    });
  }

  getSitesAndUsers() {
    // this.loader.isLoading = true;
    this.webReq.getSitesAll().subscribe(sites => {
      this.allSites = sites;
      this.filteredSites = this.allSites;
      this.webReq.getUsers().subscribe(users => {
        this.allUsers = users;
        this.filteredUsers = this.allUsers;
        this.webReq.getUserGroups().subscribe(userGroups => {
          this.allUserGroups = userGroups.userGroups;
          this.filteredUserGroups = this.allUserGroups;
          this.webReq.getSiteGroups().subscribe(siteGroups => {
            this.allSiteGroups = siteGroups.siteGroups;
            this.filteredSiteGroups = this.allSiteGroups;
            this.webReq.getSubcontractors().subscribe(subContractors => {
              this.allSubcontractors = subContractors;
              this.filteredSubcontractors = this.allSubcontractors; 
            })
          })
        })
      });
    });
  }

  getUsers() {

  }

  generateToken() {
    var userToken = {
      'username': this.username, 'password': this.password, 'siteID': this.selectedSite[0]
    }
    // console.log(this.selectedSite[1])

    //Fix this
    this.webReq.generateToken(this.selectedSite, 0, [0, 1, 2]).subscribe(data => {
      this.token = data.token;
      this.showToken = true;
      localStorage.setItem('token', this.token)
      this.router.navigate(['sidenavnew/page-two'])
    });
  }

  SelectSite() {

    localStorage.setItem('siteID', this.selectedSite[0])
    this.router.navigate(['sidenavnew/page-two'])
  }

  navToTokens() {
    this.router.navigate(['/token-tools'])
  }

  navToAdmin() {
    this.router.navigate(['/admin-home'])
  }

  deleteUser() {
    this.loader.isLoading = true;
    this.webReq.globalDeleteUser(this.selectedUser).subscribe(res => {
      if (res.success != false) {
        this.loader.isLoading = false;
        this.notify.openMessage(res.message, 'success');
        this.selectedUser = null;
        this.getSitesAndUsers();

      } else {
        this.loader.isLoading = false;
        this.notify.openMessage("User not deleted", 'error');
      }
    }, err => {
      this.loader.isLoading = false;
      this.notify.openMessage("Server error", "error");
    });
  }
  updateUser() { // Should be able to update username, cellphone, sites
    this.webReq.globalUpdateUser(this.selectedUser).subscribe(res => {
      alert(res.message);
      this.getSitesAndUsers();
    });
  }
  removeSiteFromUser(site: any) {
    const index = this.selectedUser.sites.indexOf(site);
    if (index >= 0) {
      this.selectedUser.sites.splice(index, 1);
    }
  }
  resetPassword() {
    this.webReq.resetPassword(this.selectedUser).subscribe(res => {
      // this.loader.isLoading = false;
      alert('User Password has been reset');
    }, err => {
      this.loader.isLoading = false;
      alert('Failed to reset user`s password');
    })
  }

  updateSite() {

  }

  addSite() {
    // this.webReq.commissionSite(this.siteID, this.siteKey).subscribe((result: any) => {
    //   this.notify.openMessage(result.message, result.success == true ? 'success' : 'error', 3000);
    //   this.close.emit();
    // })
  }

  renameSite() {
    this.webReq.renameSite(this.selectedSite).subscribe(result => {
      // this.getSites();
      this.selectedSite = null;
      this.notify.openMessage(result.message, result.success==true?'success':'error', 3000);
      this.getSitesAndUsers();
    })
  }

  deleteSite() {
    this.webReq.deleteSite(this.selectedSite._id, this.selectedSite.siteName).subscribe(result => {
      // this.getSites();
      // this.isDeleting = false;
      this.selectedSite = null;
      this.getSitesAndUsers();
      this.notify.openMessage(result.message, result.success == true ? 'success' : 'error', 3000);
    });
  }

  setAllowedSites(user: any) {
    this.allowedSites = [];
    console.log('setting allowed sites')
    user.clientdb.forEach((client: any) => {
      var tempList = this.allSites.filter((x: any) => x.clientdb == client);
      tempList.forEach((site: any) => {
        this.allowedSites.push(site);
        console.log('Added allowed site: ' + JSON.stringify(site))
      });
    });
  }

  userOfSite(user:any) {
    if(user.sites != null) {
      if (user.sites.filter((x:any) => x._id == this.selectedSite._id).length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkSelectedSiteUsers() {
    var siteUsers = [];
    console.log("Selected site ID: " + this.selectedSite._id)
    this.allUsers.forEach((user:any) => {
      if(user.sites != null) {
        var filteredSites = user.sites.filter((x:any)=> x._id == this.selectedSite._id)
        console.log("Filtered sites for " + user.username + ": " + JSON.stringify(filteredSites))  
      }
      
    });
  }

  manualLink() {
    if(this.isUnlinking) {
      this.selectedSite.device = {linked:false, serialnumber: "0"};
    } else {
      this.selectedSite.device = {linked:true, serialnumber: this.selectedSite.device.serialnumber};
    }
    this.renameSite();
    this.manualLinking = false;
    this.manualLinkingStep =1;
    this.isUnlinking = false;
  }

  startLinkingDevice() {
    // show popup asking to access device

    this.loader.isLoading = true;
    this.isUnlinking = false;

    var actualID = this.selectedSite.siteID;
    this.selectedSite.siteID = 10000;
    this.webReq.generateTokenNew(this.selectedSite, [[1,0],[0,0], [0,1],[0,2],[3,0],[2,0]], new Date(Date.now()).getTime(), new Date(Date.now()).getTime() + 300000).subscribe((tokens: {"tokens":[],"from":any,"to":any}) => {
      console.log(tokens);
      this.selectedSite.siteID = actualID;
      tokens.tokens.forEach(tokenObj => {
        if(tokenObj[0] == 3) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_diag",tokenObj[2]);
        }
        if(tokenObj[0] == 0) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_" + tokenObj[1], tokenObj[2]);
        }
        if(tokenObj[0] == 1) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_time",tokenObj[2]);
        }
        if(tokenObj[0]==2) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_conf", tokenObj[2])
        }
      });

      //Tokens are set...continue to connect
      this.Connect();

    });

  }

  startUnlinkingDevice() {
    this.loader.isLoading =true;
    // var actualID = this.selectedSite.siteID;
    // this.selectedSite.siteID = 10000;
    this.isUnlinking = true;
    this.webReq.generateTokenNew(this.selectedSite, [[1,0],[0,0], [0,1],[0,2],[3,0],[2,0]], new Date(Date.now()).getTime(), new Date(Date.now()).getTime() + 300000).subscribe((tokens: {"tokens":[],"from":any,"to":any}) => {
      console.log(tokens);
      // this.selectedSite.siteID = actualID;
      tokens.tokens.forEach(tokenObj => {
        if(tokenObj[0] == 3) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_diag",tokenObj[2]);
        }
        if(tokenObj[0] == 0) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_" + tokenObj[1], tokenObj[2]);
        }
        if(tokenObj[0] == 1) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_time",tokenObj[2]);
        }
        if(tokenObj[0]==2) {
          localStorage.setItem("t_" + this.selectedSite.siteName + "_conf", tokenObj[2])
        }
      });

      //Tokens are set...continue to connect
      this.Connect();

    });
  }

  StopBluetooth() {
    console.log("Stopping Service ...")
    if (this.btService.myReadCharacteristic) {
      this.btService.myReadCharacteristic.stopNotifications()
        .then((data: any) => {
          console.log('> Notifications stopped');
          this.showCounter = false;
          this.btService.myReadCharacteristic.removeEventListener('characteristicvaluechanged', this.handleNotifications);
          if (this.btService.myDevice.gatt.connected) {
            console.log("Disconnecting from:")
            console.log(this.btService.myDevice)
            this.btService.myDevice.gatt.disconnect();
            this.snackBar.open('Disconnected...', 'Ok', { duration: 3000 })
            localStorage.removeItem("selectedBTSite");
            navigator.vibrate(200);
          }
          // this.isConnected = false;
          // this.siteSelected = false;
        })
        .catch((err: any) => {
          console.log('Argh! ' + err);
        });
    }
  }

  handleNotifications(event: any) {
    // console.log("Recieved BT Message. Listen Type: " + this.listenType)
    // console.log("Config: " + this.isConfig + "\nDiagnostics: " + this.isDiagnostics)
    let utf8decoder = new TextDecoder();
    let value = event.target.value;
    let a = [];
    let b;
    // Convert raw data bytes to hex values just for the sake of showing something.
    // In the "real" world, you'd use data.getUint8, data.getUint16 or even
    // TextDecoder to process raw data bytes.
    for (let i = 0; i < value.byteLength; i++) {
      a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
    }
    b = (utf8decoder.decode(value));
    // console.log('> ' + a.join(' '));
    // Handle Response Here
    console.log('Text Value From BT > ' + b);
    // console.log('Substring: ' + b.substring(0, 2));

    if (!this.isConfig) { // These are general action responses
      if (b == '0' || b == '1' || b == '2' || b == '3' || b == '4' || b == '5' ||
        b == '7' || b == '8' || b == '9' || b == ':' || b == ';' || b == '<' ||
        b == '=' || b == '>' || b == '13' || b == '11' || b == '10' || b == '12' || b == '14') {
        console.log()
        this.notify.openMessage(b, 'success', 3);
        // this.clickable = true;
        this.loader.isLoading = false;
      } else {
        if (b.length > 6) {
          this.notify.openMessage(b, 'info', 10);
        }
      }
      this.counterValue = utf8decoder.decode(value);
    } else if (this.isConfig) {
      // alert(b + ' Device State: ' + this.deviceState)
      if(b == '1') {
        this.loader.isLoading = false;
        this.manualLinking = false;
        alert('Failed to ' + (this.isUnlinking == true ? 'unlink' : 'link'))
      } else if(b == '8'){
        this.loader.isLoading = false;
        this.manualLinking = false;

        alert('Failed to ' + (this.isUnlinking == true ? 'unlink' : 'link') + ", ensure site ID's are correct");
      } else if(b== '0') {
        // this.deviceState++;
        if(this.isUnlinking) {
          this.selectedSite.device = {linked:false, serialnumber: "0"};
        } else {
          this.selectedSite.device = {linked:true, serialnumber: "0"};
        }
        if(this.deviceState == 3){
          this.webReq.renameSite(this.selectedSite).subscribe((res:any) => {
            if (res.success == true) {
              this.manualLinking = false;
              alert(this.isUnlinking == true ? 'Device Unlinked' : 'Device Linked')
              this.loader.isLoading = false;

            }
            else {
              this.manualLinking = false;
              this.loader.isLoading = false;
              alert((this.isUnlinking == true ? 'Device Unlinked' : 'Device Linked') + " but failed to update database");
            }
          });
        } else if(this.deviceState == 4) {
          this.StopBluetooth();
          alert('Device config saved');
        }
        
      }
      this.confSubject.next({ value: b, listenType: this.listenType });
    }

  }

  Connect() {
    // this.loader.isLoading = true;
    // this.clickable = false;
    this.btService.connectDevice().then(conn => {
      if (conn) {
        this.isConnected = true;
        this.deviceState = 1;
        this.btService.myReadCharacteristic.startNotifications().then(() => {
          console.log("Listening...")
          // this.loader.isLoading = false;
          this.btService.myReadCharacteristic.addEventListener('characteristicvaluechanged', (event: any) => this.handleNotifications(event))
          navigator.vibrate(300);
        });
        this.writeAction('time');
        setTimeout(() => {
          this.isConfig = true;
          console.log('Will now enter config')
          this.writeAction('conf');
          this.deviceState = 2;
          setTimeout(() => {
            console.log('Will now set ID')
            this.deviceState = 3;
            if(this.isUnlinking) {
              this.writeAction('3;10000');
            } else {
              this.writeAction('3;' + this.selectedSite.siteID);
            }

            setTimeout(()=>{
              this.deviceState = 4;
              this.writeAction('98');
            }, 2500)
          }, 2500)
        }, 2500) 
      }
    }).catch(err => {
      this.loader.isLoading = false;
      this.notify.openMessage("Connection Cancelled", "error", 10);
    }).finally(() => {
      // this.loader.isLoading = false;
    });
  }

  writeAction(tokenID: string) {
    // if (!this.isDiagnostics && !this.isConfig && tokenID != 'conf' && tokenID != '?') {
    //   this.loader.isLoading = true;
    // }
    // if (tokenID != 'time') {
    //   this.clickable = false;
    // }
    var enc = new TextEncoder();
    var myValue;
    if (tokenID == 'time') {
      let date = Math.floor(new Date().getTime() / 1000.0)
      myValue = "*;" + date;
    } else if (tokenID.includes('*;')) {
      myValue = tokenID;
    } else if (tokenID == '99') {
      myValue = tokenID;
    } else if (tokenID == '?') {
      myValue = tokenID;
    } else if (this.isConfig) {
      if (tokenID == 'conf') {
        myValue = localStorage.getItem("t_" + this.selectedSite.siteName + "_conf");
      } else {
        myValue = tokenID;
      }
    }
    else {
      myValue = localStorage.getItem("t_" + localStorage.getItem("selectedBTSite") + "_" + tokenID);
    }
    if (myValue != null) {
      var myValue1 = enc.encode(myValue + "\n");
      // this.clickable = true;
      if(myValue != '?') {
        // this.logger.addLog(tokenID, this.conn.online,localStorage.getItem("selectedBTSite")); // Don't log status check
      }
      // console.log("about to write: " + myValue1);
      setTimeout(() => {
        return this.btService.myWriteCharacteristic.writeValue(myValue1);
      }, 500);
    }
    else {
      // this.clickable = true;

      console.log("about to write: Nothing");
      this.notify.openMessage('Please get tokens', 'info', 5);
      this.router.navigate(['/token-tools'])
      // return this.btService.myWriteCharacteristic.writeValue(enc.encode(""));
    }
  }

  doEventAction(event: any) {
    console.log("Event: " + JSON.stringify(event));
    if (this.isDiagnostics) {
      this.writeAction(event);
    } else if (this.isConfig) {
      this.listenType = event.listenType;
      this.writeAction(event.code);
    }
  }

  enterConfig() {
    this.isConfig = true;
    this.listenType = 1;
    this.writeAction('conf');
  }
  
}
