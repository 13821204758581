import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { ConnectivityService } from './services/connectivity.service';
import { WebRequestsService } from './services/web-requests.service';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mtn-control';
  loggedIn = true;
  constructor(private router: Router, public auth: AuthService, public conn: ConnectivityService, 
    public webReq: WebRequestsService, public notifications: NotificationsService) {

  }
  ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // var url = "http://app.myprism.co.za/";
      // this.router.navigate(['app.myprism.co.za'])
      window.location.href = 'https://app.myprism.co.za'
    }
    this.getHandoverReqs();
    this.getCurrentAlarms();
    this.getKeyRequests();
  } 

  navToTokens() {
    // console.log("Should Navigate")
    this.router.navigate(['/home'])
  }

  navToBluetooth() {
    // console.log("Should Navigate")
    this.router.navigateByUrl('/bluetooth')
    this.router.navigate(['/bluetooth'])
  }

  getHandoverReqs() {
    this.webReq.getHandoverRequests().subscribe(res => {
      // console.log(res)
      this.notifications.currentHandovers = res.length;
    })
  }

  getCurrentAlarms() {
    this.webReq.getCurrentAlarms().subscribe(res => {
      // console.log(res)
      this.notifications.currentAlarms = res.length;
    })
  }

  getKeyRequests() {
    this.webReq.getKeyRequests(1,0).subscribe(res => {
      this.notifications.currentKeyRequests = res.count[0]['COUNT(*)'];
    })
  }
}
