<div class="mainContainer">
    <div class="headerText">
        Dashboards
    </div>
    <div fxLayout="row">
        <app-menu-item icon="dashboard" label="System Analysis" description="Main dashboard for auditing and tracking" link="dashboard"></app-menu-item>
        <app-menu-item icon="summarize" label="Operational Summaries" description="Operational dashboard for sites overview" link="prism-dashboard"></app-menu-item>
        <app-menu-item icon="query_stats" label="Diagnostic Reports" description="View Diagnostic Report documents" link="site-reports"></app-menu-item>
        <app-menu-item icon="notification_important" [matBadge]="notifications.currentAlarms" matBadgeColor="warn"  [matBadgeHidden]="notifications.currentAlarms == 0"  label="Alarms" description="View and handle currently active alarms" link="alarms"></app-menu-item>
        <app-menu-item icon="notifications" label="Alarm history" description="View historical data of alarms" link="alarms-history"></app-menu-item>
    </div>
    
    <hr>
    
    <div class="headerText">
        Users
    </div>
    <div fxLayout="row">
        <app-menu-item icon="person" label="User Management" description="Manage User details, allowed sites and access" link="user-management"></app-menu-item>
        <app-menu-item icon="group_add" label="Sub Contractors" description="Manage Subcontractor Details" link="subcontractor-management"></app-menu-item>
        <app-menu-item icon="person_off" label="Locked Users" description="View and manage currently locked users" link="blocked-users" *ngIf="isAdmin"></app-menu-item>
    </div>
    
    <hr>
    
    <div class="headerText">
        Sites
    </div>
    <div fxLayout="row">
        <app-menu-item icon="domain" label="Site Management" description="Manage Site details and configurations" link="site-management"></app-menu-item>
        <app-menu-item icon="extension" label="Config Templates" description="Manage Configuration Templates for easy config on controllers" link="config-templates"></app-menu-item>
        <app-menu-item icon="handshake" [matBadge]="notifications.currentHandovers" matBadgeColor="warn" [matBadgeHidden]="notifications.currentHandovers == 0" label="Handover Requests" description="Manage sites which are requested for handover" link="handover-requests"></app-menu-item>
        <app-menu-item icon="handshake" label="Handovers" description="Manage Handover site details and groupings" link="handover-management"></app-menu-item>
    </div>
    
    <hr>
    
    <div class="headerText">
        Access
    </div>
    <div fxLayout="row">
        <app-menu-item icon="shield" label="V-Keys" description="Create V-Keys for site access" link="token-tools"></app-menu-item>
        <app-menu-item icon="shield" [matBadge]="notifications.currentKeyRequests" matBadgeColor="warn" [matBadgeHidden]="notifications.currentKeyRequests == 0" label="V-Key Requests" description="Approve V-Key Requests for site access" link="key-requests"></app-menu-item>
        <app-menu-item icon="schedule" label="Schedules" description="Create Scheduled access to sites" link="schedules"></app-menu-item>
    </div>
    
    <hr>
    
    <div class="headerText">
        Other Services
    </div>
    <div fxLayout="row">
        <app-menu-item icon="support_agent" label="Support" description="Customer Support link" link="https://myprism.co.za/support/"></app-menu-item>
        <app-menu-item icon="key" label="Change Password" description="Change your user password" link="change-password"></app-menu-item>
    </div>
</div>



<img class="bg-img" src="../../assets/prism.png">