import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/services/loader.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  public logs: any;
  public btLogs: any;
  public errLogs: any;
  public successLogs: any;
  public showLogs: boolean = false;
  public users: any = [];
  public from: any;
  public to: any;
  public user: string = "";
  public searchFilter: string = "";

  allBtLogs: any =[];

  BTdataSource = new MatTableDataSource<any>(this.allBtLogs);
  displayedColumns: string[] = ['user', 'action', 'time', 'site', 'siteID'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Output() close = new EventEmitter();
  
  constructor(public webreq: WebRequestsService, public loader: LoaderService) { }

  ngAfterViewInit() {
    this.BTdataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.loader.isLoading = false;

    this.webreq.getUsers().subscribe(users => {
      this.users = users;
      console.log(users);
      this.loader.isLoading = false;
    }, err => { console.log("Failed to get users"); this.loader.isLoading = false; });

  }

  async getLogs() {
    return new Promise((resolve, reject) => {
      this.webreq.getLogs().subscribe(logs => {
        // this.logs = logs;
        // this.btLogs = logs.btLogs;
        resolve(logs)
      }, err => {
        this.loader.isLoading = false;
        console.log("Failed to get logs");
      })
    })
  }

  getBtLogs() {
    this.getLogs().then((logs: any) => {
      this.btLogs = logs.btLog;
      this.errLogs = logs.errLog;
      this.successLogs = logs.successLog;
      this.loader.isLoading = false;
    });
  }

  findLogs(from: any, to: any, user: any) {
    this.webreq.getBTLogs(from, to, user).subscribe(loggies => {
      console.log(loggies);
      let tempLogs: any[] = [];
      loggies.forEach((mylog: any) => {
        tempLogs.push(mylog.user + ", " + mylog.action + ", " + mylog.time.toString('YYYY/MM/DD') + "\n");
      });
      // this.showLogs = true;
      this.logs = tempLogs;
      this.allBtLogs = tempLogs
      this.BTdataSource.data = loggies;
    }, err => {
      console.log(err);
    });
  }

  filterResults() {
    this.BTdataSource.data = this.BTdataSource.data.filter(x => x.action.includes(this.searchFilter));
  }
  applyFilter() {
    // let filterValue = this.searchFilter.trim(); // Remove whitespace
    // filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches    
    this.BTdataSource.filter = this.searchFilter;
  }

  closing() {
    this.close.emit('cancel');
  }
}
