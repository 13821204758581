import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-manage-subcontractors',
  templateUrl: './manage-subcontractors.component.html',
  styleUrls: ['./manage-subcontractors.component.scss']
})
export class ManageSubcontractorsComponent implements OnInit {

  displayedColumns: string[] = ['name', 'contactEmail', 'contactNumber', 'address'];
  dataSource = new  MatTableDataSource();
  addingSubcontractor = false;
  subcontractors = [];
  selectedSubcontractor = null;
  filterValue = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  
  constructor(public loader: LoaderService, public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
    this.getSubcontractors();
  }

  getSubcontractors() {
    this.webReq.getSubcontractors().subscribe(sc => {
      this.subcontractors = sc;
      this.dataSource.data = this.subcontractors;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.data = this.subcontractors.filter((x: any) => 
    x.name.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.contactNumber.toLowerCase().includes(filterValue.toLowerCase()) ||
    x.address.toLowerCase().includes(filterValue.toLowerCase()) || 
    x.contactEmail.toString().includes(filterValue.toLowerCase())
    );
  }

}
