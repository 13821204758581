<mat-card class="mat-elevation-z8" style="width: 30vw;">
    <div fxLayout="column">
        <h2>User</h2>
        <div>
            <label>Details for User:</label>
            <div fxLayout="column">
                <!-- Email -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput [(ngModel)]="selectedUser.email">
                    </mat-form-field>
                </div>
                <!-- Username -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Username</mat-label>
                        <input matInput [(ngModel)]="selectedUser.username">
                    </mat-form-field>
                </div>
                <!-- Name -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput [(ngModel)]="selectedUser.name">
                    </mat-form-field>
                </div>
                <!-- Surname -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Surname</mat-label>
                        <input matInput [(ngModel)]="selectedUser.surname">
                    </mat-form-field>
                </div>
                  <!-- ID -->
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>ID Number</mat-label>
                        <input matInput [(ngModel)]="selectedUser.idNumber">
                    </mat-form-field>
                </div>

                <!-- Subcontactor -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Sub-Contractor</mat-label>
                        <mat-select [(ngModel)]="selectedUser.subcontractor">
                            <mat-option value="None">None</mat-option>
                            <mat-option *ngFor="let contractor of subcontractors"
                                [value]="contractor.name">{{contractor.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-radio-group [(ngModel)]="selectedUser.userType">
                    <mat-radio-button [value]="'basic'" (click)="setUserType('basic')"> Basic User </mat-radio-button>
                    <mat-radio-button [value]="'se'" (click)="setUserType('se')"> Site Engineer </mat-radio-button>
                    <mat-radio-button [value]="'installer'" (click)="setUserType('installer')"> Installer
                    </mat-radio-button>
                    <mat-radio-button [value]="'advanced'" (click)="setUserType('advanced')"> Advanced
                    </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="selectedUser.userType != 'basic'">
                    <!-- Admin -->
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-checkbox [(ngModel)]="selectedUser.isAdmin">Admin</mat-checkbox>
                    </div>
                    <!-- System Admin -->
                    <!-- <div fxLayout="row" fxLayoutAlign="start center">
    <mat-checkbox [(ngModel)]="selectedUser.sysAdmin">System Admin</mat-checkbox>
</div> -->
                    <!-- Tokens -->
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-checkbox [(ngModel)]="selectedUser.generateTokens">Can Generate v-Keys</mat-checkbox>
                    </div>
                    <!-- Can Configure -->
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-checkbox [(ngModel)]="selectedUser.canConfigure">Can Configure</mat-checkbox>
                    </div>

                    <!-- Can Handover -->
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-checkbox [(ngModel)]="selectedUser.canHandover">Can Handover Sites</mat-checkbox>
                    </div>

                    <!-- Can Commission -->
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-checkbox [(ngModel)]="selectedUser.canCommission">Can Commission</mat-checkbox>
                    </div>
                </div>

                <!-- Cellphone -->
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-form-field appearance="outline">
                        <mat-label>Cellphone</mat-label>
                        <input matInput [(ngModel)]="selectedUser.cellphone">
                    </mat-form-field>
                </div>


                <button style="margin: 4px;" mat-raised-button color="primary" (click)="addUser()" [disabled]="validateData()">
                    <mat-icon>save</mat-icon> Create User
                </button>
                <button style="margin: 4px;" mat-raised-button color="warn" (click)="closing()">
                    <mat-icon>cancel</mat-icon> Cancel
                </button>
            </div>
        </div>
    </div>
</mat-card>