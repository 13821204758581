import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BluetoothService } from 'src/app/services/bluetooth.service';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoggingService } from 'src/app/services/logging.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
// import { TestingComponent } from '../testing/testing.component';

@Component({
  selector: 'app-bluetooth',
  templateUrl: './bluetooth.component.html',
  styleUrls: ['./bluetooth.component.scss']
})
export class BluetoothComponent implements OnInit {

  testSubject:Subject<any> = new Subject();
  @Output() testResult = new EventEmitter();
  siteSelected : boolean = false;
  clickable : boolean = true;
  username: any;
  password: any;
  siteID: any;
  user = {};
  myCharacteristic: any;
  public counterValue: any;
  showCounter = false;
  testValue: string = "";
  tempToken: any;
  funcToken: any;
  serviceUUID: string = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
  writeUUID: string = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
  readUUID: string = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';
  myDevice: any;
  lastToggle: string = "~B0"

  public isDebugging = false;
  public isDiagnostics = false;
  public isConnected = false;

  constructor(private webreq: WebRequestsService, 
    public btService: BluetoothService, 
    private snackBar: MatSnackBar, 
    public loader: LoaderService,
    public notify: NotificationsService,
    public logger: LoggingService,
    public router: Router,
    public conn: ConnectivityService) { }

  server: any;
  service: any;

  ngOnInit(): void {
    localStorage.removeItem("selectedBTSite");
    this.username = localStorage.getItem('username');
    this.password = localStorage.getItem('password');
    this.siteID = localStorage.getItem('siteID')
    this.user = { 'username': this.username, 'password': this.password }
    // this.getSites();
    this.counterValue = "Test"
  }

  TestBluetooth() {
    this.loader.isLoading = true;
    this.btService.getReadService().then(characteristic => {
      console.log("Will now subscribe characteristic")
      this.myCharacteristic = characteristic;
      return this.myCharacteristic.startNotifications().then(() => {
        console.log("Listening...")
        this.loader.isLoading = false;
        this.myCharacteristic.addEventListener('characteristicvaluechanged', (event: any) => this.handleNotifications(event))
      });
    }).then(value => {
      console.log("Notifications Started...")
      this.showCounter = true;
    }).catch((err: any) => {
      console.error(err)
      alert("Error: " + err)
    });
  }

  GetTrustedDevices() {
    navigator.bluetooth.getDevices().then(devices => {
      console.log('Devices: ' + devices.length)
      if (devices.length > 0) {
        this.myDevice = devices[0]
      }
    })
  }

  StopBluetooth() {
    console.log("Stopping Service ...")
    if (this.btService.myReadCharacteristic) {
      this.btService.myReadCharacteristic.stopNotifications()
        .then((data: any) => {
          console.log('> Notifications stopped');
          this.showCounter = false;
          this.btService.myReadCharacteristic.removeEventListener('characteristicvaluechanged', this.handleNotifications);
          if(this.btService.myDevice.gatt.connected) {
            console.log("Disconnecting from:")
            console.log(this.btService.myDevice)
            this.btService.myDevice.gatt.disconnect();
            this.snackBar.open('Disconnected...', 'Ok', {duration:3000})
            localStorage.removeItem("selectedBTSite");
            navigator.vibrate(200);
          }
          this.isConnected=false;
          this.siteSelected = false;
        })
        .catch((err: any) => {
          console.log('Argh! ' + err);
        });
    }
  }

  handleNotifications(event: any) {
    let utf8decoder = new TextDecoder();
    let value = event.target.value;
    let a = [];
    let b;
    // Convert raw data bytes to hex values just for the sake of showing something.
    // In the "real" world, you'd use data.getUint8, data.getUint16 or even
    // TextDecoder to process raw data bytes.
    for (let i = 0; i < value.byteLength; i++) {
      a.push('0x' + ('00' + value.getUint8(i).toString(16)).slice(-2));
    }
    b = (utf8decoder.decode(value));
    // console.log('> ' + a.join(' '));
    // Handle Response Here
    console.log('Text Value > ' + b);
    console.log('Substring: ' + b.substring(0,2));
    if (!this.isDiagnostics) {
      if(b[0] == '0' || b[0] == '1' || b[0] == '2' || b[0] == '3' || b[0] == '4' || b[0] == '5' || b[0] == '7' || b[0] == '8' || b[0] == '9' || b[0] == ':' || b[0]==';' || b[0]=='<' || b[0]=='=' || b[0]=='>' || b.substring(0,1)=='13' || b.substring(0,)=='11') {
        console.log()
        this.notify.openMessage(b[0], 'success', 3);
        this.clickable = true;
        this.loader.isLoading = false;
      } else {
        if(b.length > 6){
          this.notify.openMessage(b, 'info', 10);
        }
      }
      this.counterValue = utf8decoder.decode(value);
    } else {
      if(b[0]=='0') {
        // OK
        this.loader.isLoading = false;
        this.testSubject.next(true);
      }
      else if (b[0] == '1') {
        // ERR
        this.loader.isLoading = false;
        this.testSubject.next(false);
      }
    }
    
  }

  writeAction(tokenID:string) {
    if (!this.isDiagnostics) {
      this.loader.isLoading = true;
    }
    if(tokenID != 'time') {
      this.clickable = false;
    }
    var enc = new TextEncoder();
    var myValue;
    if(tokenID == 'time') {
      let date = Math.floor(new Date().getTime()/1000.0)
      myValue = "*;" + date;
    } else if(tokenID.includes('*;')) {
      myValue = tokenID;
    }
    else {
      myValue = localStorage.getItem("t_"+ localStorage.getItem("selectedBTSite") + "_"+tokenID);
    }
    if(myValue != null) {
      var myValue1 = enc.encode(myValue + "\n");
      this.clickable = true;
      this.logger.addLog(tokenID,this.conn.online);
      // console.log("about to write: " + myValue1);
      setTimeout(()=> {
        return this.btService.myWriteCharacteristic.writeValue(myValue1);
      }, 500);
    }
    else {
      this.clickable = true;

      console.log("about to write: Nothing");
      this.notify.openMessage('Please get tokens', 'info', 5);
      this.router.navigate(['/token-tools'])
      // return this.btService.myWriteCharacteristic.writeValue(enc.encode(""));
    }
  }

  doEventAction(event:any) {
    console.log("Event: " + event);
    this.writeAction(event);
  }

  Connect() {
    this.loader.isLoading = true;
    // this.clickable = false;
    this.btService.connectDevice().then(conn => {
      if(conn) {
        this.isConnected = true;
        this.btService.myReadCharacteristic.startNotifications().then(()=> {
          console.log("Listening...")
          this.loader.isLoading = false;
          this.btService.myReadCharacteristic.addEventListener('characteristicvaluechanged', (event: any) => this.handleNotifications(event))
          navigator.vibrate(300);
        });
        this.writeAction('time');
      }
    }).catch(err => {
      this.notify.openMessage("Connection Cancelled", "error", 10);
    }).finally(()=> {
      this.loader.isLoading = false;
    });
  }


  TestService() {
    this.btService.connectDevice().then(server => {
      console.log("Server Connected: " + server)
    })
  }

  ToggleLED(){
    let command = "";
    if (this.lastToggle == "~B0") {
      command = "~B1"
      this.lastToggle = "~B1"
    } else {
      command = "~B0"
      this.lastToggle = "~B0"
    }
    // this.clickable = false;
    this.btService.getWriteService().then(service=> {
      var enc = new TextEncoder();
      console.log("Encoded toggle command: " + enc.encode(command + "\n"))
      service.writeValue(enc.encode(command + "\n"))
    })
  }

  TryBTLog(action:any) {

  }

  enterDiagnostics() {
    this.writeAction('diag');
    this.isDiagnostics = true;
  }

  openHelp(link:string) {
    window.open('https://wernerg123.github.io/prism-v2-docs/#'+link, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }
}
