<div class="myHeader">
    <mat-icon>schedule</mat-icon> Scheduled Site Visits
  </div>

<div class="container">
    <div class="navigator">
      <daypilot-navigator [config]="configNavigator" [events]="events" [(date)]="date" (dateChange)="changeDate($event)" #navigator></daypilot-navigator>
    </div>
    <div class="content">
      <div class="buttons">
      <button class="cbutton" (click)="viewDay()" [class]="this.configNavigator.selectMode == 'Day' ? 'selected' : ''">Day</button>
      <button class="cbutton" (click)="viewWeek()" [class]="this.configNavigator.selectMode == 'Week' ? 'selected' : ''">Week</button>
      <button class="cbutton" (click)="viewMonth()" [class]="this.configNavigator.selectMode == 'Month' ? 'selected' : ''">Month</button>
      </div>

      <daypilot-calendar [config]="configDay" [events]="events" #day></daypilot-calendar>
      <daypilot-calendar [config]="configWeek" [events]="events" #week></daypilot-calendar>
      <daypilot-month [config]="configMonth" [events]="events" #month (onTimeRangeSelected)="timeRangeSelected($event)"></daypilot-month>
    </div>
  </div>

  <button mat-raised-button class="createSchedButton" (click)="creatingEvent = true; setDefaultSchedule()"><mat-icon>event</mat-icon> Create Schedule</button>
  <div class="backdrop" (click)="creatingEvent = false;" *ngIf="creatingEvent"
  style="position: fixed; width: 100vw; height: 100vh; top: 0; z-index: 3004; background-color: rgba(100,100,100,0.5);">
</div>

<div *ngIf="creatingEvent" class="scheduleCard" fxLayout="column" fxLayoutAlign="space-evenly center">
    <h2 class="scheduleText">Schedule</h2>
    <label class="scheduleID">{{myEvent._id}}</label>
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>From</mat-label>
        <input matInput [(ngModel)]="myEvent.from" type="datetime-local">
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>To</mat-label>
        <input matInput [(ngModel)]="myEvent.to" type="datetime-local">
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>User</mat-label>
        <mat-select [(ngModel)]="myEvent.user" >
            <mat-option *ngFor="let user of users" [value]="user">{{user.username}} ({{user.email}})</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Site</mat-label>
        <mat-select [(ngModel)]="myEvent.site">
            <mat-option *ngFor="let site of sites" [value]="site">{{site.siteName}} ({{site.siteID}})</mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="myEvent.site?.secondaryController" fxLayout="column">
        <mat-checkbox [(ngModel)]="myEvent.site.door1">Door 1</mat-checkbox>
        <mat-checkbox [(ngModel)]="myEvent.site.door2">Door 2</mat-checkbox>
    </div>

    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Reason</mat-label>
        <mat-select [(ngModel)]="myEvent.reason">
            <mat-option *ngFor="let reason of reasons" [value]="reason">{{reason}}</mat-option>
        </mat-select>
    </mat-form-field>

    <label>Keys Downloaded: {{myEvent.downloaded ? "Yes" : "No"}}</label>

    <button mat-raised-button (click)="generateSystemTokens(myEvent.site)" *ngIf="myEvent._id == null || myEvent._id == '' || myEvent._id == undefined" color="primary"><mat-icon>check</mat-icon> Create Access Schedule</button>
    <button mat-raised-button (click)="removeSchedule(myEvent)" *ngIf="myEvent._id != null && myEvent._id != '' && myEvent._id != undefined" color="warn" [disabled]="myEvent.downloaded"><mat-icon>cancel</mat-icon> Delete Access Schedule</button>
</div>
<app-loader></app-loader>