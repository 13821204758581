import { AfterContentChecked, AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
// import { EventEmitter } from 'stream';

@Component({
  selector: 'app-site-select',
  templateUrl: './site-select.component.html',
  styleUrls: ['./site-select.component.scss']
})
export class SiteSelectComponent implements OnInit,AfterContentChecked {

  @Output() btSite = new EventEmitter();
  @Input() currentState: boolean = false;
  public sites: any;
  public selectedSite: any;
  public availableSites: Set<string> = new Set();
  public siteAvailability : any[] = [];
  public noTokens: boolean = false;

  constructor(public adminService: AdminService, 
    public loader: LoaderService, 
    public webReq: WebRequestsService, 
    public snackBar: MatSnackBar,
    public router: Router) { }

  ngOnInit(): void {
    this.getSites();
  }

  ngAfterContentChecked(): void {
    this.checkSites();
  }

  getSites() {
    let tempSites = new Array<string>();
    let tempDetails:any[] = [];
    this.siteAvailability = [];

    
    for (var key in localStorage) {
      if (key.includes("t_")&& !key.includes("_time") && !key.includes("_diag") && !key.includes("_from") && !key.includes("_to")) {
        tempSites.push(key.substring(2, key.length - 2));
      }
    }

    for (var key in localStorage) {
      if (key.includes("_from")) {
        tempDetails.push({"sitename":key.substring(2, key.length - 5), "from":localStorage.getItem(key)});
      }
    }
    for (var key in localStorage) {
      if (key.includes("_to")) {
        var temp = tempDetails.filter((x:any)=> x.sitename == key.substring(2, key.length - 3))[0] 
        temp = {"sitename": temp.sitename, "from":temp.from, "to": localStorage.getItem(key)} 
        tempDetails.forEach(detail => {
          if(detail.sitename == temp.sitename && detail.from == temp.from) {
            this.siteAvailability.push(temp);
          }
        });;
      }
    }
    this.availableSites = new Set(tempSites);
    this.siteAvailability.forEach(element => {
      let datenow = new Date().getTime();
      if (element.from > datenow || element.to < datenow) {
       this.availableSites.delete(element.sitename); 
      }
    });
  }

  checkSites() {
    if (this.availableSites.size == 0) {
      this.noTokens = true;
    } else if (this.availableSites.size == 1) {
      var values = this.availableSites.values();
      var obj = values.next();
      var chosen = obj.value;
      this.confirmSite(chosen);
    }
  }

  confirmSite(mySite: string) {
    localStorage.setItem("selectedBTSite", mySite);
    this.btSite.emit("close");
  }

  goToGetTokens() {
    this.router.navigate(['/token-tools']);
  }

}
