import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public user = {};
  public email: string = "";
  public password: string = "";
  public pwdCred = { id: "werner", name: "Werner", password: "test" }
  public showDBSelect = false;
  public clients: any[] = [];
  public selectedClient = "";
  public resetPassword: boolean = false;

  public requestAccount: boolean = false;
  public requirements: string = "";
  public newEmail: string = "";
  public inputType = 'password';

  public jwtData: any = {};

  constructor(private router: Router,
    private webReq: WebRequestsService,
    private auth: AuthService,
    public loader: LoaderService,
    public snackBar: MatSnackBar,
    public notifier: NotificationsService) { }

  ngOnInit(): void {
    this.loader.isLoading = false;
  }

  toggleInputType() {
    if(this.inputType == 'password') {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }
  
  login(email: string | undefined, password: string | undefined, clientdb: string) {
    this.loader.isLoading = true;

    this.user = {
      'username': this.email,
      'password': this.password,
      'clientdb': this.selectedClient
    }
    if (this.selectedClient == "") {
      this.user = {
        'username': this.email,
        'password': this.password,
        'clientdb': clientdb
      }
    }
    if (this.password != "" && this.email != "") {
      this.webReq.login(this.user).subscribe(data => {
        // console.log(data)
        if (data.success == true) {
          if (data.loggedIn == true) {
            localStorage.setItem('username', data.username.toLowerCase());
            if (this.selectedClient != "")
              localStorage.setItem('clientdb', this.selectedClient);
            else
              localStorage.setItem('clientdb', clientdb)
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('email', data.email);
            if (data.admin == true) {
              localStorage.setItem('admin', 'true')
            } else {
              localStorage.setItem('admin', 'false')
            }
            if(data.canApproveUsers == true) {
              localStorage.setItem('canApproveUsers', 'true')
            } else {
              localStorage.removeItem('canApproveUsers');
            }
            if (data.generateTokens == true) {
              localStorage.setItem('generateTokens', 'true');
            } else {
              localStorage.setItem('generateTokens', 'false')
            }
            this.auth.isLoggedIn = true;
            this.loader.isLoading = false;
            // this.registerCredential();                            // Uncomment for auto login
            this.router.navigate([''], { replaceUrl: true });
          }
        }
        else {
          this.loader.isLoading = false;
          this.snackBar.open('Invalid Credentials', 'Ok', { duration: 3000 })
        }
      }, err => {
        this.loader.isLoading = false;
        this.snackBar.open('Invalid Credentials', 'Ok', { duration: 3000 })
      });
    } else {
      this.loader.isLoading = false;
    }

  }


  mongoLogin() {
    this.loader.isLoading = true;
    this.user = {
      'username': this.email,
      'password': this.password,
    }
    this.webReq.login(this.user).subscribe(data => {
      console.log(data);
      if (data.success == true) {
        localStorage.setItem('access_token', data.access_token)
        this.jwtData = jwt_decode(data.access_token);

        localStorage.setItem('username', this.jwtData.username.toLowerCase());
        localStorage.setItem('email', this.jwtData.email);
        localStorage.setItem('isLoggedIn', 'true');
        if (this.jwtData.isAdmin == true) {
          localStorage.setItem('admin', 'true')
        } else {
          localStorage.setItem('admin', 'false')
        }
        if(this.jwtData.canApproveUsers == true) {
          localStorage.setItem('canApproveUsers', 'true')
        } else {
          localStorage.removeItem('canApproveUsers');
        }
        if (this.jwtData.generateTokens == true) {
          localStorage.setItem('generateTokens', 'true');
        } else {
          localStorage.setItem('generateTokens', 'false')
        }
        this.auth.isLoggedIn = true;
        this.loader.isLoading = false;

        if (this.jwtData.clientdb.length > 1) {
          this.clients = this.jwtData.clientdb;
          this.showDBSelect = true;
        } else if (this.jwtData.clientdb.length == 1) {
          this.clients = this.jwtData.clientdb;
          this.selectedClient = this.jwtData.clientdb[0];
          localStorage.setItem('clientdb', this.selectedClient);

          this.router.navigate([''], { replaceUrl: true });

        }
      } else {
        this.loader.isLoading = false;
        this.notifier.openMessage('Invalid Credentials', 'error');
      }
    }, err => {
      this.loader.isLoading = false;
      this.notifier.openMessage('Try Again Later', 'error');
    });
  }
  continue() {
    localStorage.setItem('clientdb', this.selectedClient);
    this.router.navigate([''], { replaceUrl: true });
  }

  goToFlags() {
    // navigator.tabs.create({url:"chrome://flags/#enable-experimental-web-platform-features"});
  }
  ChromeFlags() {
    window.open("chrome://flags/#enable-experimental-web-platform-features");
  }

  storeCred() {
    let myCred = new PasswordCredential({ id: this.email, password: this.password, name: this.email, iconURL: '' });
    navigator.credentials.store(myCred)
  }

  registerCredential() {
    // debugger
    try {
      navigator.credentials.create({
        publicKey: {
          rp: {
            id: 'https://web.myprism.co.za/',
            // id: 'localhost',
            // id: '192.168.1.166',
            name: 'test'
          },
          user: {
            id: new Uint8Array(16),
            name: this.email,
            displayName: this.email
          },
          challenge: new Uint8Array(16),
          pubKeyCredParams: [
            {
              type: 'public-key',
              alg: -7
            }
          ]
        }
      }).then(c => {
        alert('Credential registered');
      }).catch(ex => {
        alert(ex);
      });
    }
    catch (ex) {
      console.log(ex);
    }

  }

  sendRequestAccount() {
    this.webReq.requestNewAccount(this.newEmail, this.requirements).subscribe(res => {
      alert(res.message);
      this.requestAccount = false;
    });
  }

}
