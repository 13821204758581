<!-- <app-loader></app-loader> -->
<mat-progress-bar *ngIf="loader.isLoading" mode="indeterminate" style="position: absolute; top: 7vh;"></mat-progress-bar>
<div *ngIf="hoReady" class="chart-container" style="position: absolute; left: 0; top: 10vh; animation: fade-in 1s;">
    <canvasjs-chart [options]="handedOverChartOptions" [styles]="{width: '400px', height: '360px'}"></canvasjs-chart>
</div>

<div *ngIf="rcReady" class="chart-container" style="position: absolute; left: 400px; top: 10vh; animation: fade-in 1s;">
    <canvasjs-chart [options]="reasonsChartOptions" [styles]="{width: '30vw', height: '40vh'}"></canvasjs-chart>
</div>

<div *ngIf="sacReady" class="chart-container" style="position: absolute; right: 5vw; top: 10vh; animation: fade-in 1s;">
    <canvasjs-chart [options]="siteAccessCountChartOptions" [styles]="{width: '80vh', height: '60vh'}"></canvasjs-chart>
</div>

<div fxLayout="column" style="font-family:'Times New Roman',sans-serif; font-size: 2rem; color: white; border: 2px solid darkgray; border-radius: 5px; padding: 8px;
        box-shadow: 0px 0px 2px 2px white; margin: 8px;
         animation: fade-in 1s; position: absolute; left: 5vh; bottom: 18vh">


    <table>
        <tr>
            <td>
                <label style="margin: 4px; width:100%" class="chart-container" [matTooltip]="getStatusSites('Error:')"
                    matTooltipPosition="right" (click)="showStatusSites('Error:')">
                    Sites currently in error state:
                </label>
            </td>
            <td>
                <i style="color: red;">{{errorStates}}</i>
            </td>
        </tr>
        <tr>
            <td>
                <label style="margin: 4px; width:100%" class="chart-container"
                    [matTooltip]="getStatusSites('Open Lock')" matTooltipPosition="right"
                    (click)="showStatusSites('Open Lock')">
                    Sites currently open:
                </label>
            </td>
            <td>
                <i style="color: red; ">{{sitesOpen}}</i>
            </td>
        </tr>
        <tr>
            <td>
                <label style="margin: 4px; width:100%" class="chart-container"
                    [matTooltip]="getStatusSites('Close Lock')" matTooltipPosition="right"
                    (click)="showStatusSites('Close Lock')">
                    Sites currently locked:
                </label>
            </td>
            <td>
                <i style="color: orange;">{{sitesClosed}}</i>
            </td>
        </tr>
        <tr>
            <td>
                <label style="margin: 4px; width:100%" class="chart-container"
                    [matTooltip]="getStatusSites('Stand down success')" matTooltipPosition="right"
                    (click)="showStatusSites('Stand down success')">
                    Sites currently stood down:
                </label>
            </td>
            <td>
                <i style="color: limegreen; ">{{sitesStoodDown}}</i>
            </td>
        </tr>
        <tr>
            <td>
                <label style="margin: 4px; width:100%" class="chart-container"
                    matTooltip="The times that your sites were most accessed" matTooltipPosition="right">
                    Busiest Access Hours:
                </label>
            </td>
            <td>
                <i style="color:orange; ">{{busiestHours.hour}}:00-{{busiestHours.hour+1}}:00</i>

            </td>
        </tr>
        <tr>
            <td>
                <label style="margin: 4px; width:100%" class="chart-container"
                    matTooltip="Days your sites are most frequently accessed" matTooltipPosition="below">
                    Busiest Day:
                </label>
            </td>
            <td>
                <i style="color: orange; ">{{busiestDay}}s</i>
            </td>
        </tr>
        <tr>
            <td>
                <label style="margin: 4px; width:100%" class="chart-container"
                    matTooltip="These are sites which were handed over but there is no device recorded to the site. This might cause 3rd party systems to behave incorrectly."
                    matTooltipPosition="below">
                    Sites Handed Over but not Linked:
                </label>
            </td>
            <td>
                <i style="color: red; ">{{handedOverUnlinked}}</i>
            </td>
        </tr>
        <tr>
            <td>
                <label style="margin: 4px; width:100%" class="chart-container"
                    matTooltip="These are sites which have devices linked to them but have not been handed over to 3rd party systems"
                    matTooltipPosition="below">
                    Sites Linked but not Handed Over:
                </label>
            </td>
            <td>
                <i style="color: orange;">{{linkedNotHandedOver}}</i>
            </td>
        </tr>
        <tr>
            <td>
                <label style="margin: 4px; width:100%" class="chart-container"
                    matTooltip="These are sites created in the system but have no devices linked"
                    matTooltipPosition="below">
                    Sites not linked and not Handed Over:
                </label>
            </td>
            <td>
                <i style="color: red;">{{unlinkedNotHandedOver}}</i>
            </td>
        </tr>
    </table>
</div>


<div class="backdrop" *ngIf="showStatusTable" (click)="showStatusTable = false"></div>
<mat-card *ngIf="showStatusTable"
    style="z-index: 3010; width: 95vw; left: 0;right: 0; margin-left: auto; margin-right: auto; top: 5vh; height: 80vh; max-height: 70vh; overflow: auto;">
    <!-- <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSourceCurrent" class="mat-elevation-z8" style="width: 100%;"> -->
    <table mat-table matSort [dataSource]="dataSourceCurrent" class="mat-elevation-z8" style="width: 100%;">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
                ID
            </th>
            <td mat-cell *matCellDef="let element">{{element.idoperational_logs}}</td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
                Last known action/response
            </th>
            <td mat-cell *matCellDef="let element">{{element.action}}</td>
        </ng-container>

        <ng-container matColumnDef="siteID">
            <th mat-header-cell *matHeaderCellDef>
                SiteID
            </th>
            <td mat-cell *matCellDef="let element">{{element.siteID}}</td>
        </ng-container>

        <ng-container matColumnDef="site">
            <th mat-header-cell *matHeaderCellDef>
                Site Name
            </th>
            <td mat-cell *matCellDef="let element">{{element.site}}</td>
        </ng-container>

        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>
                Time
            </th>
            <td mat-cell *matCellDef="let element">{{element.time}}</td>
        </ng-container>

        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef>
                User
            </th>
            <td mat-cell *matCellDef="let element">{{element.user}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsCurrent"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCurrent;"></tr>
    </table>
    <mat-paginator #myPaginator [pageSizeOptions]="[5, 10, 20, 30, 50]" [pageSize]="10" showFirstLastButtons
        aria-label="Select page of statusses" style="position:fixed;bottom: 10vh; width: 95vw;">
    </mat-paginator>

    <button mat-raised-button color="primary" style="position: absolute; left: 4px; bottom: 4px" (click)="exportDataSet()"><mat-icon>download</mat-icon> Export</button>

</mat-card>