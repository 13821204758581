import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-manage-handovers',
  templateUrl: './manage-handovers.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
  styleUrls: ['./manage-handovers.component.scss']
})
export class ManageHandoversComponent implements OnInit {

  displayedColumns: string[] = ['groupID', 'groupName', 'handoverCount', 'expand', 'delete'];
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  dataSource = new MatTableDataSource();
  addingSiteGroup = false;
  siteGroups = [];
  selectedSiteGroup = null;
  selectedHandover = null;
  removingDevice = false;
  expandedElement: any | null;
  removingGroup = false;
  addingDevice = false;
  availableDevices: any[] = [];
  availableDevicesFiltered: any[] = [];
  deviceFilter = "";
  deviceToRemove: any;
  devicesToAdd: any;
  filterValue = "";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public loader: LoaderService, public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
    this.getSites();
  }

  getSites() {
    this.webReq.getSiteGroups().subscribe(groups => {
      this.siteGroups = groups.siteGroups;
      this.dataSource.data = this.siteGroups;
      this.dataSource.sort = this.sort;
      console.log("Data set to:")
      console.log(this.siteGroups)
      this.dataSource.paginator = this.paginator;
    });
  }

  getDataForAdd() {
    this.webReq.getSitesAll().subscribe(results => {
      let tempSites = results;
      // tempSites.forEach((availableSite:any) => {
      this.selectedSiteGroup.sites.forEach((currentSite: any) => {
        tempSites = tempSites.filter((x: any) => x.siteID != currentSite.siteID)
      });
      // });
      this.availableDevices = tempSites;
      this.availableDevicesFiltered = tempSites;
      this.addingDevice = true;
    })
  }

  handoverDevice() {
    this.loader.isLoading = true;
    let deviceToAdd = {
      DeviceID: this.devicesToAdd.siteID.toString(),
      DeviceName: this.devicesToAdd.siteName,
      SiteName: this.selectedSiteGroup.groupName,
      SiteID: this.selectedSiteGroup.groupID
    }
    this.webReq.iCryptoAddDevice(deviceToAdd).subscribe(addRes => {
      // If success add this device to the group on our DB
      if (addRes.DeviceID == deviceToAdd.DeviceID) {

        this.webReq.handoverDevice(this.devicesToAdd).subscribe(handoverRes => {
          if (handoverRes.success == true) {
            this.selectedSiteGroup.sites.push(this.devicesToAdd);
            this.webReq.updateSiteGroup(this.selectedSiteGroup).subscribe(results => {
              if (results.success == true) {
                this.addingDevice = false;
                this.selectedSiteGroup = null;
                this.availableDevices = [];
                this.loader.isLoading = false;

                this.notify.openMessage('Device Handed Over', 'success', 3);
              } else {
                this.loader.isLoading = false;

                this.notify.openMessage('Failed to add device', 'error', 3);
              }
            });
          } else {
            this.loader.isLoading = false;

            this.notify.openMessage('Device update failed', 'error', 3);
          }
        })




      } else {
        this.loader.isLoading = false;

        this.notify.openMessage('Handover failed', 'error', 3);
      }
    });
  }

  removeDevices() {
    this.loader.isLoading = true;

    let deviceToDeleteObj = {
      DeviceID: this.deviceToRemove.siteID.toString(),
      DeviceName: this.deviceToRemove.siteName,
      SiteName: this.selectedSiteGroup.groupName,
      SiteID: this.selectedSiteGroup.groupID
    }

    this.webReq.iCryptoDeleteDevice(deviceToDeleteObj).subscribe(delRes => {  // TODO : ensure delete success before removing handedover device!

      // if (delRes.status == null || delRes.status == undefined) {
      // console.log(delRes)
      this.webReq.takeBackDevice(this.deviceToRemove).subscribe(remResult => {
        if (remResult.success == true) {
          this.selectedSiteGroup.sites = this.selectedSiteGroup.sites.filter((x: any) => x.siteID != this.deviceToRemove.siteID);
          this.webReq.updateSiteGroup(this.selectedSiteGroup).subscribe(result => {
            if (result.success == true) {
              this.removingDevice = false;
              this.selectedSiteGroup = null;
              this.loader.isLoading = false;

              this.notify.openMessage('Device removed', 'success', 3);
            } else {
              this.loader.isLoading = false;

              this.notify.openMessage('Failed to remove', 'error', 3);
            }
          });
        } else {
          this.loader.isLoading = false;

          this.notify.openMessage('Failed to take back device', 'error', 3);
        }
      });
      // } else if(delRes.status == "error") {
      //   this.loader.isLoading = false;

      //   this.notify.openMessage('Failed to decomission', 'error', 3);
      // }

    });

  }

  removeGroup() {
    this.webReq.deleteSiteGroup(this.selectedSiteGroup).subscribe(result => {
      console.log(result)
      if (result.success == true) {
        this.removingGroup = false;
        this.notify.openMessage('Group deleted', 'success', 3);
        this.webReq.getSiteGroups().subscribe(results => {
          this.dataSource.data = results.siteGroups;
          this.dataSource.paginator = this.paginator;

        });
      } else {
        this.notify.openMessage('Failed to delete', 'error', 3);
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.data = this.siteGroups.filter((x: any) => x.groupName.toLowerCase().includes(filterValue.toLowerCase()) || x.groupID.toString().includes(filterValue.toLowerCase()));
  }

  applyDeviceFilter(event: Event) {
    console.log(this.availableDevices)
    this.availableDevicesFiltered = this.availableDevices.filter(x => x.siteID.toString().includes(this.deviceFilter) || x.siteName.toLowerCase().includes(this.deviceFilter.toLocaleLowerCase()))
  }
}
