import { Injectable } from '@angular/core';
import { WebRequestsService } from './web-requests.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  public allUsers: any[] = [];
  public selectedUser: any;
  public selectedSite: any;
  public negativeSites: any[] = [];
  public positiveSites: any[] = [];
  public allSites: any[] = [];
  public temp: any;

  constructor(private webreq: WebRequestsService) { }

  public getNegativeSites(username: any) {
    this.webreq.getSitesAll().subscribe((allSites: any) => {

      this.allSites = allSites;
      this.negativeSites = [];
      // console.log("Total Sites: " + this.allSites.length);
      // console.log(this.allSites.toString());
      this.webreq.getSites(username).subscribe(sites => {
        if (sites.success == false) {
          this.positiveSites = [];
        } else {
          this.positiveSites = sites;
          // console.log("Positive sites: " + this.positiveSites.length)
        }

        this.allSites.forEach(mainSite => {
          let found = false;

          this.positiveSites.forEach(site => {
            if (site[1] == mainSite[1]) {
              found = true;
            }
          });

          if (found == false) {
            this.negativeSites.push(mainSite);
          }
        });


      });
    });

  }

  public addUserToSite() {
    return new Promise((resolve, reject) => {
      this.webreq.addUserToSite(this.selectedUser.username, this.selectedSite.siteID).subscribe(res => {
        // console.log(res);
        resolve(res);
      });
    })

  }
}
