// import { temporaryAllocator } from '@angular/compiler/src/render3/view/util';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingPageComponent } from 'src/app/landing-page/landing-page.component';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-sidenavnew',
  templateUrl: './sidenavnew.component.html',
  styleUrls: ['./sidenavnew.component.scss']
})
export class SidenavnewComponent implements OnInit {

  public username: string = "";
  public admin: string = "user";
  public clientdb: any = "";
  public sysadmin = false;
  public canGenerate = false;
  public sitesString: any = "";
  public siteList: any = [];
  public showSites = false;
  public canBypass = false;
  public online = true;
  public isAdmin: boolean = false;
  showHoverMenu = false;
  // showHoverMenu = true;
  public canApprove = false;

  constructor(private router: Router, public webReq: WebRequestsService, public conn: ConnectivityService, public notifications: NotificationsService) { }

  ngOnInit(): void {

    let canApproveObj = localStorage.getItem('canApproveUsers');
    let isAdminObj = localStorage.getItem('admin');

    if (canApproveObj == 'true') {
      this.canApprove = true
    }
    if (isAdminObj == 'true') {
      this.isAdmin = true;
    }

    var tempName = localStorage.getItem('username')
    if (tempName != null) {
      this.username = tempName;
      if (this.username == "sysadmin") {
        this.sysadmin = true;
        this.canBypass = true;
      } else if (this.username == "Stanley") {
        this.canBypass = true;
      }
    }
    if (localStorage.getItem('admin') == 'true') {
      this.admin = "admin"
      this.isAdmin = true;
    } else {
      this.admin = "user"
      this.isAdmin = false;
    }
    if (localStorage.getItem('generateTokens') == 'true') {
      this.canGenerate = true;
    }
    this.clientdb = localStorage.getItem('clientdb');
  }

  checkSites() {
    if (this.sysadmin == true) {
      this.webReq.getSitesAll().subscribe(data => {
        // console.log(data);
        this.siteList = data;
        this.showSites = true;
      })
    }
  }

  openManual() {
    window.open('https://wernerg123.github.io/prism-v2-docs/', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }

  openMenu() {
    if (this.showHoverMenu) {
      this.showHoverMenu = false;
    } else {
      this.showHoverMenu = true;
    }
  }

  navToHome() {
    this.showHoverMenu = false;
    this.router.navigate(['/']);
  }
  navToDashboard() {
    this.showHoverMenu = false;
    this.router.navigate(['/dashboard'])
  }

  navToSites() {
    this.showHoverMenu = false;
    this.router.navigate(['/site-management'])
  }

  navToUsers() {
    this.showHoverMenu = false;
    this.router.navigate(['/user-management'])
  }

  navToVKeys() {
    this.showHoverMenu = false;
    this.router.navigate(['/token-tools'])
  }

  navToAlarms() {
    this.showHoverMenu = false;
    this.router.navigate(['/alarms'])
  }

  navToChangePwd() {
    this.showHoverMenu = false;
    this.router.navigate(['/change-password'])
  }

  navToSubcontractors() {
    this.showHoverMenu = false;
    this.router.navigate(['/subcontractor-management'])
  }

  navToHandovers() {
    this.showHoverMenu = false;
    this.router.navigate(['/handover-management'])
  }

  navToPrismDashboard() {
    this.showHoverMenu = false;
    this.router.navigate(['/prism-dashboard'])
  }

  navToSupport() {
    this.showHoverMenu = false;
    window.open('https://myprism.co.za/support', '_blank')
  }

  navToSchedules() {
    this.showHoverMenu = false;
    this.router.navigate(['/schedules']);
  }

  navToDiagnosticReports() {
    this.showHoverMenu = false;
    this.router.navigate(['/site-reports']);
  }

  navToAlarmHistory() {
    this.showHoverMenu = false;
    this.router.navigate(['/alarms-history'])
  }

  navToConfigTemplates() {
    this.showHoverMenu = false;
    this.router.navigate(['/config-templates'])
  }

  navToHandoverReqs() {
    this.showHoverMenu = false;
    this.router.navigate(['/handover-requests'])
  }

  navToKeyRequests() {
    this.showHoverMenu = false;
    this.router.navigate(['/key-requests']);
  }

  navToBlockedUsers() {
    this.showHoverMenu = false;
    this.router.navigate(['/blocked-users']);
  }

  logout() {
    this.router.navigate(['/login']);
  }

}
