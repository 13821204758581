import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-edit-site-group',
  templateUrl: './edit-site-group.component.html',
  styleUrls: ['./edit-site-group.component.scss']
})
export class EditSiteGroupComponent implements OnInit {
  @Input() selectedSiteGroup: any = {};
  @Input() allSites: any = [];
  @Output() close = new EventEmitter();
  

  currentSites: any = [];
  currentNoneSites: any = [];

  selectedToAdd: any = [];
  selectedToRemove: any = [];

  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
    this.getCurrentSites();
    this.getCurrentNoneSites();
  }

  getCurrentSites() {
    this.currentSites = this.selectedSiteGroup.sites;
  }
  getCurrentNoneSites() {
    var foundInCurrent = false;
    this.allSites.forEach((allSite:any) => {
      this.currentSites.forEach((currentSite:any) => {
        if (allSite._id == currentSite._id) {
          foundInCurrent = true;
        }
      });
      if(foundInCurrent == false) {
        this.currentNoneSites.push(allSite);
      } else {
        foundInCurrent = false;
      }
    });
  }

  closing() {
    this.close.emit();
  }

  updateSiteGroup() {
    this.selectedSiteGroup.sites = this.currentSites;

    console.log(this.selectedSiteGroup)
    this.webReq.updateSiteGroup(this.selectedSiteGroup).subscribe((res:any) => {
      if (res.success == true) {
        this.notify.openMessage(res.message, res.success == true ? 'success' : 'error', 3000);
        this.close.emit();
      }
    })
  }

  deleteSiteGroup() {
    this.webReq.deleteSiteGroup(this.selectedSiteGroup).subscribe((res:any) => {
      if(res.success == true) {
        this.notify.openMessage(res.message, res.success == true ? 'success' : 'error', 3000);
        this.close.emit();
      } else {
        this.notify.openMessage(res.message, res.success == true ? 'success' : 'error', 3000);
      }
    })
  }

  removeSiteFromGroup(site:any) {

  }

  checkNoneSite(mySite:any, value: any) {
    var found = false;
    if(value == true) {
      this.selectedToAdd.forEach((site:any) => {
        if (mySite._id == site._id) {
          found = true;
        }
      });
      if(found == false) {
        this.selectedToAdd.push(mySite);
      }
    }
    else {
      this.selectedToAdd = this.selectedToAdd.filter((x:any) => x._id != mySite._id);
    }
  }

  checkCurrentSite(mySite:any, value: any) {
    var found = false;
    if(value == true) {
      this.selectedToRemove.forEach((site:any) => {
        if (mySite._id == site._id) {
          found = true;
        }
      });
      if(found == false) {
        this.selectedToRemove.push(mySite);
      }
    }
    else {
      this.selectedToRemove = this.selectedToRemove.filter((x:any) => x._id != mySite._id);
    }
  }

  addCurrentSites() {
    var found = false;
    this.selectedToAdd.forEach((siteToAdd:any) => {
      this.currentSites.forEach((currentSite:any) => {
        if(siteToAdd._id == currentSite._id) {
          found = true;
        }
      });
      if (found == false) {
        this.currentSites.push(siteToAdd);
        this.currentNoneSites = this.currentNoneSites.filter((x:any) => x._id != siteToAdd._id);
      }
    });
    this.selectedToAdd = [];
  }

  removeCurrentSites() {
    var found = false;
    this.selectedToRemove.forEach((siteToRemove:any) => {
      this.currentNoneSites.forEach((currentNoneSite:any) => {
        if(siteToRemove._id == currentNoneSite._id) {
          found = true;
        }
      });
      if (found == false) {
        this.currentNoneSites.push(siteToRemove);
        this.currentSites = this.currentSites.filter((x:any) => x._id != siteToRemove._id);
      }
    });
    this.selectedToRemove = [];
  }
}
