<div class="myHeader">
    <mat-icon>notification_important</mat-icon> Alarm Management
  </div>
<div fxLayout="row" fxLayoutAlign="space-between start">

    <div style="position: absolute; top: 15vh; width: 100vw; height: 80vh; max-height: 72vh; overflow-y: scroll" >
        <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSourceCurrent" class="mat-elevation-z8" style="width: 100%;">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>
                    ID
                </th>
                <td mat-cell *matCellDef="let element">{{element.alarmUUID}}</td>
            </ng-container>

            <ng-container matColumnDef="priority">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by alarm priority"> Priority </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': getPriorityColor(element.priority), 'border':'1px solid white'}" >
                    {{element.priority}}
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by alarm type"> Type </th>
                <td mat-cell *matCellDef="let element">{{element._type}}</td>
            </ng-container>

            <ng-container matColumnDef="siteID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by site ID"> Site ID </th>
                <td mat-cell *matCellDef="let element">{{element.siteID}}</td>
            </ng-container>

            <ng-container matColumnDef="siteName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by site name"> Site Name </th>
                <td mat-cell *matCellDef="let element">{{element.siteName}}</td>
            </ng-container>

            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by time"> Time </th>
                <td mat-cell *matCellDef="let element">{{element.time | date:"dd/MM/yy HH:mm"}}</td>
            </ng-container>

            <ng-container matColumnDef="acknowledged">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by acknowledged"> Acknowledged </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="element.acknowledged == 1" style="color: lime">check</mat-icon>
                    <mat-icon *ngIf="element.acknowledged == 0" style="color: red;">close</mat-icon>
               
                </td>
            </ng-container>

            <ng-container matColumnDef="acknowledgedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by acknowledgedby"> Acknowledged By </th>
                <td mat-cell *matCellDef="let element">{{element.acknowledgedBy}}</td>
            </ng-container>

            <ng-container matColumnDef="acknowledgedTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by acknowledged time"> Acknowledged Time </th>
                <td mat-cell *matCellDef="let element">{{element.acknowledgedTime | date:"dd/MM/yy HH:mm"}}</td>
            </ng-container>

            <ng-container matColumnDef="acknowledge">
                <th mat-header-cell *matHeaderCellDef> Acknowledge </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-mini-fab *ngIf="element.acknowledged == 0 || element.acknowledged == undefined"
                        (click)="acknowledgeAlarm(element)"><mat-icon>waving_hand</mat-icon></button>
                    <!-- <button mat-mini-fab *ngIf="element.acknowledged == 1"
                        style="background-color: lime;"><mat-icon>waving_hand</mat-icon></button> -->
                </td>
            </ng-container>

            <ng-container matColumnDef="resolve">
                <th mat-header-cell *matHeaderCellDef> Resolve </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-mini-fab
                        *ngIf="element.acknowledged == 0 || element.acknowledged == undefined"><mat-icon>checkmark</mat-icon></button>
                    <button mat-mini-fab *ngIf="element.acknowledged == 1" style="background-color: orange;"
                        (click)="selectedAlarm = element; startResolve = true"><mat-icon>checkmark</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsCurrent"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCurrent;"></tr>
        </table>
        <mat-icon style="color:lime; position: fixed; bottom: 8vh; left: 1vw; z-index: 2002;" *ngIf="ws.isConnected == true">swap_horiz</mat-icon>
        <mat-icon style="color:red; position: fixed; bottom: 8vh; left: 1vw; z-index: 2002;" *ngIf="ws.isConnected == false">swap_horiz</mat-icon>
        <mat-paginator [pageSizeOptions]="[5, 10, 20, 30, 50]" [pageSize]="10" showFirstLastButtons aria-label="Select page of alarms" style="position:fixed;bottom: 6vh; width: 100%;">
        </mat-paginator>

    </div>
    
    <div *ngIf="startResolve">
        <div id="overlay"
        style="position: fixed; top: 0px;left:0px; height: 100vw; width: 100vw; background-color: gray; opacity: 0.5; z-index: 2000"> </div>
        <mat-card
            style="position: absolute; width: 50vw; height: 60vh; z-index: 2005; top: 20vh; left: 25vw; border: 1px solid white;"
            fxLayout="column">
            <button mat-mini-fab style="position: absolute; right: 8px; top: 8px;"
                (click)="selectedAlarm ={}; startResolve=false"><mat-icon>close</mat-icon></button>
            <h1>Resolve Alarm</h1>
            <h2>{{selectedAlarm.alarmUUID}}</h2>

            <label>Alarm Description: {{selectedAlarm._type}}</label>
            <label>Alarm Time: {{selectedAlarm.time}}</label>
            <label>Acknowledged by: {{selectedAlarm.acknowledgedBy}}</label>
            <label>Aknowledged on: {{selectedAlarm.acknowledgedTime}}</label>

            <mat-form-field appearance="outline">
                <mat-label>Comment</mat-label>
                <textarea matInput [(ngModel)]="resolveComment"></textarea>
            </mat-form-field>

            <button mat-raised-button style="background-color: lime; color: black" (click)="resolveAlarm()">Resolve</button>

        </mat-card>
    </div>
    
   
</div>