import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications.service';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-edit-user-group',
  templateUrl: './edit-user-group.component.html',
  styleUrls: ['./edit-user-group.component.scss']
})
export class EditUserGroupComponent implements OnInit {
  @Input() selectedUserGroup: any = {};
  @Input() allUsers: any = [];
  @Output() close = new EventEmitter();
  

  currentUsers: any = [];
  currentNoneUsers: any = [];

  selectedToAdd: any = [];
  selectedToRemove: any = [];

  constructor(public webReq: WebRequestsService, public notify: NotificationsService) { }

  ngOnInit(): void {
    this.getCurrentUsers();
    this.getCurrentNoneUsers();
  }

  getCurrentUsers() {
    this.currentUsers = this.selectedUserGroup.users;
  }
  getCurrentNoneUsers() {
    var foundInCurrent = false;
    this.allUsers.forEach((allUser:any) => {
      this.currentUsers.forEach((currentUser:any) => {
        if (allUser._id == currentUser._id) {
          foundInCurrent = true;
        }
      });
      if(foundInCurrent == false) {
        this.currentNoneUsers.push(allUser);
      } else {
        foundInCurrent = false;
      }
    });
  }

  closing() {
    this.close.emit();
  }

  updateUserGroup() {
    this.selectedUserGroup.users = this.currentUsers;

    console.log(this.selectedUserGroup)
    this.webReq.updateUserGroup(this.selectedUserGroup).subscribe((res:any) => {
      if (res.success == true) {
        this.notify.openMessage(res.message, res.success == true ? 'success' : 'error', 3000);
        this.close.emit();
      } else {
        this.notify.openMessage(res.message, res.success == true ? 'success' : 'error', 3000);
      }
    })
  }

  deleteUserGroup() {
    this.webReq.deleteUserGroup(this.selectedUserGroup).subscribe((res:any) => {
      if (res.success == true) {
        this.notify.openMessage(res.message, res.success == true ? 'success' : 'error', 3000);
        this.close.emit();
      } else {
        this.notify.openMessage(res.message, res.success == true ? 'success' : 'error', 3000);

      }
    })
  }

  removeUserFromGroup(user:any) {

  }

  checkNoneUser(myUser:any, value: any) {
    var found = false;
    if(value == true) {
      this.selectedToAdd.forEach((user:any) => {
        if (myUser._id == user._id) {
          found = true;
        }
      });
      if(found == false) {
        this.selectedToAdd.push(myUser);
      }
    }
    else {
      this.selectedToAdd = this.selectedToAdd.filter((x:any) => x._id != myUser._id);
    }
  }

  checkCurrentUser(myUser:any, value: any) {
    var found = false;
    if(value == true) {
      this.selectedToRemove.forEach((user:any) => {
        if (myUser._id == user._id) {
          found = true;
        }
      });
      if(found == false) {
        this.selectedToRemove.push(myUser);
      }
    }
    else {
      this.selectedToRemove = this.selectedToRemove.filter((x:any) => x._id != myUser._id);
    }
  }

  addCurrentUsers() {
    var found = false;
    this.selectedToAdd.forEach((userToAdd:any) => {
      this.currentUsers.forEach((currentUser:any) => {
        if(userToAdd._id == currentUser._id) {
          found = true;
        }
      });
      if (found == false) {
        this.currentUsers.push(userToAdd);
        this.currentNoneUsers = this.currentNoneUsers.filter((x:any) => x._id != userToAdd._id);
      }
    });
    this.selectedToAdd = [];
  }

  removeCurrentUsers() {
    var found = false;
    this.selectedToRemove.forEach((userToRemove:any) => {
      this.currentNoneUsers.forEach((currentNoneUser:any) => {
        if(userToRemove._id == currentNoneUser._id) {
          found = true;
        }
      });
      if (found == false) {
        this.currentNoneUsers.push(userToRemove);
        this.currentUsers = this.currentUsers.filter((x:any) => x._id != userToRemove._id);
      }
    });
    this.selectedToRemove = [];
  }

}
