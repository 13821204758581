<app-loader></app-loader>
<div class="myHeader">
    <mat-icon>shield</mat-icon> v-Key Requests
  </div>
<div style="position: absolute; top: 15vh; width: 100vw; height: 80vh; max-height: 72vh; overflow-y: scroll">
    
    <table mat-table matSort [dataSource]="currentRequests"
        class="mat-elevation-z8" style="width: 100%;">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Name
            </th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="surname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Surname
            </th>
            <td mat-cell *matCellDef="let element">{{element.surname}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Email
            </th>
            <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>
        
        <ng-container matColumnDef="cell">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Cell
            </th>
            <td mat-cell *matCellDef="let element">{{element.cell}}</td>
        </ng-container>

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                ID
            </th>
            <td mat-cell *matCellDef="let element">{{element.id_number}}</td>
        </ng-container>

        <ng-container matColumnDef="faceImg">
            <th mat-header-cell *matHeaderCellDef>
                Face
            </th>
            <td mat-cell *matCellDef="let element"> <img (mouseenter)="showEnlargedImage($event, element.img_face)" (mouseleave)="hideEnlargedImage()" style="width:100px; height:100px;" [src]="element.img_face"></td>
        </ng-container>

        <ng-container matColumnDef="idImg">
            <th mat-header-cell *matHeaderCellDef>
                ID Img
            </th>
            <td mat-cell *matCellDef="let element"> <img (mouseenter)="showEnlargedImage($event, element.img_ID)" (mouseleave)="hideEnlargedImage()" style="width:100px; height:100px;" [src]="element.img_ID"></td>
        </ng-container>

        <ng-container matColumnDef="refImg">
            <th mat-header-cell *matHeaderCellDef>
                Ref Img
            </th>
            <!-- <td mat-cell *matCellDef="let element"> <img [src]="getImgUrl(element.img_REF.data)"></td> -->
            <td mat-cell *matCellDef="let element"> <img (mouseenter)="showEnlargedImage($event, element.img_REF)" (mouseleave)="hideEnlargedImage()" style="width:100px; height:100px;" [src]="element.img_REF"></td>
        </ng-container>

        <ng-container matColumnDef="siteID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Site ID
            </th>
            <td mat-cell *matCellDef="let element">{{element.siteID}}</td>
        </ng-container>

        <ng-container matColumnDef="siteName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Site Name
            </th>
            <td mat-cell *matCellDef="let element">{{element.siteName}}</td>
        </ng-container>

        <ng-container matColumnDef="support">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Support
            </th>
            <td mat-cell *matCellDef="let element">{{element.support}}</td>
        </ng-container>

        <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> 
                From
            </th>
            <td mat-cell *matCellDef="let element">{{element.fromDate}}</td>
        </ng-container>

        <ng-container matColumnDef="to">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                To
            </th>
            <td mat-cell *matCellDef="let element">{{element.toDate}}</td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>
                Decline
            </th>
            <td mat-cell *matCellDef="let element"  (click)="selectedItem = element; isDelete = true"><mat-icon>cancel</mat-icon></td>
        </ng-container>

        <ng-container matColumnDef="accept">
            <th mat-header-cell *matHeaderCellDef>
                Accept
            </th>
            <td mat-cell *matCellDef="let element"><mat-icon (click)="selectedItem = element; isAccept = true">check</mat-icon></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsCurrent"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCurrent;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20, 30, 50]" (page)="logPageEvent($event)" [length]="length" [pageSize]="pageSize"  showFirstLastButtons aria-label="Select page of alarms" style="position:fixed;bottom: 6vh; width: 100%;">
    </mat-paginator>
</div>

<div class="backdrop" *ngIf="isAccept || isDelete"></div>
<mat-card *ngIf="selectedItem != null && (isAccept || isDelete)" style="position: absolute; z-index: 3011; left:0; right:0; top:0;bottom:0;margin:auto;width:30vw;height: fit-content; border: 1px solid white">
<mat-card-header>
    <mat-card-title>{{isAccept ? 'Accept' : 'Delete'}} Key Request</mat-card-title>
</mat-card-header>
<mat-card-content>
    <div fxLayout="column" style="width: 100%">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">Name</label> <div></div> <strong style="width: 40%">{{selectedItem.name}}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">Surname</label> <div></div><strong style="width: 40%">{{selectedItem.surname}}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">ID</label><div></div> <strong style="width: 40%">{{selectedItem.id_number}}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">Email</label><div></div> <strong style="width: 40%">{{selectedItem.email}}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">SiteID</label> <div></div><strong style="width: 40%">{{selectedItem.siteID}}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">Site Name</label> <div></div><strong style="width: 40%">{{selectedItem.siteName}}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">Door 1</label><div></div> <strong style="width: 40%">{{selectedItem.door1}}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">Door 2</label><div></div> <strong style="width: 40%">{{selectedItem.door2}}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">From</label> <div></div><strong style="width: 40%">{{selectedItem.fromDate}}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label style="width: 40%">To</label><div></div> <strong style="width: 40%">{{selectedItem.toDate}}</strong>
        </div>
        <div *ngIf="isDelete">
            <mat-form-field appearance="outline">
                <mat-label>Comment</mat-label>
                <input matInput [(ngModel)]="selectedItem.comment">
            </mat-form-field>
        </div>
    </div>
</mat-card-content>
<mat-card-actions fxLayout="row" fxLayoutAlign="space-evenly center">
    <button mat-raised-button color="primary" *ngIf="isAccept" (click)="accept();"><mat-icon>check</mat-icon> Accept</button>
    <button mat-raised-button color="warn" *ngIf="isDelete" (click)="deleteKeyRequest();"><mat-icon>cancel</mat-icon> Decline</button>
    <button mat-raised-button color="accent" (click)="isAccept = false; isDelete = false; selectedItem = null"><mat-icon>cancel</mat-icon> Cancel</button>
</mat-card-actions>
</mat-card>


<div *ngIf="enlargedImageUrl" class="enlarged-image" [style.left.px]="enlargedImagePositionX" [style.top.px]="enlargedImagePositionY">
    <img [src]="enlargedImageUrl">
  </div>