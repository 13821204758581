import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { config } from 'process';
import { WebRequestsService } from 'src/app/services/web-requests.service';

@Component({
  selector: 'app-add-edit-config',
  templateUrl: './add-edit-config.component.html',
  styleUrls: ['./add-edit-config.component.scss']
})
export class AddEditConfigComponent implements OnInit {

  @ViewChild('myTemplateCard') templateCard: ElementRef;
  @Output() close = new EventEmitter();
  @Input() isAdd: boolean = true;
  @Input() mytemplate: any = {
    name: "",
    description: "",
    useTimer: false,
    invertActuator: false,
    maglockToggle: false,
    actuatorTimeout: 10,
    maxCurrent: 1.1,
    actSpeed: 12,
    useDoorContact: true,
    actLength: 50,
    currentWifiState: 0,

    door1AutoLock: false,
    door2AutoLock: false,

    sensorType: 1,
    use2Sensors: false,
    useTamperRelay: false,
    useDCtoInhibit: false,
    dcIsNO: false,
    ssid: "",
    password: "",

    secondaryPresent: false,
    tpmPresent: false,
    secondarySensorType: 0,
    secondaryUse2Sensors: false,
    tpm1ID: '0',
    tpm2ID: '0'

  };

  someValue = 1;

  constructor(public webReq: WebRequestsService) { }

  ngOnInit(): void {

    if (this.isAdd) {
      this.mytemplate = {
        name: "",
        description: "",
        useTimer: false,
        invertActuator: false,
        maglockToggle: false,
        actuatorTimeout: 10,
        maxCurrent: 1.1,
        actSpeed: 12,
        useDoorContact: true,
        actLength: 50,
        currentWifiState: 0,

        door1AutoLock: false,
        door2AutoLock: false,

        sensorType: 1,
        use2Sensors: false,
        useTamperRelay: false,
        useDCtoInhibit: false,
        dcIsNO: false,
        ssid: "",
        password: "",

        secondaryPresent: false,
        tpmPresent: false,
        secondarySensorType: '0',
        secondaryUse2Sensors: false,
        tpm1ID: '0',
        tpm2ID: '0'
      };
    }
  }

  addTemplate() {

  }

  editTemplate() {

  }

  saveConfig() {
    let wifiDetails = {
      ssid: this.mytemplate.ssid,
      password: this.mytemplate.password
    }
    if (this.mytemplate.sensorType != 2) {
      this.mytemplate.use2Sensors = false;
    }
    let configString = "96;0;" + this.setUseDoorContactValue() + ";" + "xxxx" + ";" + this.setInvertActuatorValue()
      + ";" + this.mytemplate.actuatorTimeout + ";" + parseFloat(this.mytemplate.maxCurrent).toFixed(2) + ";" + this.setToggleMagLockValue() + ";" + this.mytemplate.actLength + ";" + this.mytemplate.actSpeed + ";" + (this.mytemplate.currentWifiState == true ? "1" : "0");
    console.info("Config String to save: " + configString)
    let configString2 = "96;1;" + this.mytemplate.sensorType + ";" + (this.mytemplate.use2Sensors ? '1' : '0') + ";" + (this.mytemplate.useTimer ? '1' : '0') + ";" + (this.mytemplate.useTamperRelay ? '1' : '0') + ";"
      + (this.mytemplate.useDCtoInhibit ? '1' : '0') + ";" + (this.mytemplate.dcIsNO ? '1' : '0') + ";" + this.getAutoLockVals();
    console.info("Config string 2 to save: " + configString2)
    let configString3 = "96;2;" + (this.mytemplate.secondaryPresent ? '1' : '0') + ";" + (this.mytemplate.tpmPresent ? '1' : '0') + ";" + this.mytemplate.tpm1ID + ";" + this.mytemplate.tpm2ID + ";" + this.mytemplate.secondarySensorType + ";" + (this.mytemplate.secondaryUse2Sensors ? '1' : '0')
    console.info("Config string 3 to save: " + configString3)
    this.mytemplate.wifiDetails = wifiDetails;
    this.mytemplate.configString1 = configString;
    this.mytemplate.configString2 = configString2;
    this.mytemplate.configString3 = configString3;
    this.mytemplate.clientdb = localStorage.getItem('clientdb');
    console.log(this.mytemplate)
    if (this.isAdd) {
      this.webReq.saveConfigTemplate(this.mytemplate).subscribe(res => {
        console.log(res);
        this.closing()
      })
    } else {
      this.webReq.updateConfigTemplate(this.mytemplate).subscribe(res => {
        console.log(res);
        this.closing()
      })
    }

  }

  getAutoLockVals() {
    if(this.mytemplate.door1AutoLock && this.mytemplate.door2AutoLock) {
      return '3';
    } else if(this.mytemplate.door1AutoLock && !this.mytemplate.door2AutoLock) {
      return '1'
    } else if(!this.mytemplate.door1AutoLock && this.mytemplate.door2AutoLock) {
      return '2';
    } else if(!this.mytemplate.door1AutoLock && !this.mytemplate.door2AutoLock) {
      return '0';
    } else {
      return '0';
    }
  }

  setUseDoorContactValue() {
    if (this.mytemplate.useDoorContact) {
      return "1";
    } else {
      return "0";
    }
  }

  setInvertActuatorValue() {
    if (this.mytemplate.invertActuator) {
      return "1";
    } else {
      return "0";
    }
  }
  setToggleMagLockValue() {
    if (this.mytemplate.maglockToggle) {
      return "1";
    } else {
      return "0";
    }
  }

  closing() {
    this.close.emit();
  }

  checkBG() {
    return this.mytemplate.color;
  }

  deleteConfig() {
    this.webReq.deleteConfigTemplate({ "_id": this.mytemplate._id }).subscribe(res => {
      console.log(res)
      this.closing();
    })
  }
}
