<!-- <div class="carousel-container" fxLayout="row">
    <div class="container" *ngFor="let menuItem of menuItems; let i=index"
        [ngClass]="selectedIndex > i ? {'container-active to-right': selectedIndex === i} : {'container-active to-left': selectedIndex === i}"
        (mouseenter)="selectItem(i)">
        <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center">
            <mat-icon fxLayoutAlign="center center">{{menuItem.icon}}</mat-icon>
            {{menuItem.label}}
        </div>
    </div>
    <div class="carousel-dot-container">
        <span class="dot" *ngFor="let dot of menuItems; let i = index" 
        [ngClass]="{'active': selectedIndex === i}"
        (click)="selectItem(i)"
        (mouseenter)="selectItem(i)">
            
        </span>
    </div>
</div> -->

<div class="carousel-container">
    <div class="container" *ngFor="let menuItem of menuItems; let i=index">
        <!-- [ngClass]=" {'container-active': selectedIndex === i}"> -->
        <div class="btn" fxLayout="column" fxLayoutAlign="space-evenly center">
            <mat-icon fxLayoutAlign="center center">{{menuItem.icon}}</mat-icon>
            {{menuItem.label}}
        </div>
    </div>
    <!-- <div class="carousel-dot-container">
        <span class="dot" *ngFor="let dot of menuItems; let i = index" 
        [ngClass]="{'active': selectedIndex === i}"
        (click)="selectItem(i)"
        (mouseenter)="selectItem(i)">
            
        </span>
    </div> -->
</div>