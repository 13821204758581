import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { MatButtonModule } from '@angular/material/button';
// import { LoginComponent } from './login/loginOld.component';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './pages/home/home.component';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
// import { SidenavComponent } from './sidenav/sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { BluetoothComponent } from './pages/bluetooth/bluetooth.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderModule } from './components/loader/loader.module';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { SiteSelectComponent } from './pages/bluetooth/site-select/site-select.component';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TestingComponent } from './pages/testing/testing.component';
import { DiagnosticsComponent } from './pages/diagnostics/diagnostics.component';
import { TokensComponent } from './pages/token-tools/tokens/tokens.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { GenerateSystemTokensComponent } from './pages/token-tools/generate-system-tokens/generate-system-tokens.component';
// import { PasswordUpdateComponent } from './components/password-update/password-update.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { WebcamModule } from 'ngx-webcam';
import { MyTokensComponent } from './pages/my-tokens/my-tokens.component';
import { MatTableModule } from '@angular/material/table';
import { AuthInterceptorInterceptor } from './interceptors/auth-interceptor.interceptor';
import { AddUserComponent } from './components/popups/add-user/add-user.component';
import { AddSiteComponent } from './components/popups/add-site/add-site.component';
import { TokenAdminComponent } from './pages/token-admin/token-admin.component';
import { LogsModule } from './pages/logs/logs.module';
import { AddUserGroupComponent } from './components/popups/add-user-group/add-user-group.component';
import { AddSiteGroupComponent } from './components/popups/add-site-group/add-site-group.component';
import { DragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { EditUserGroupComponent } from './components/popups/edit-user-group/edit-user-group.component';
import { EditSiteGroupComponent } from './components/popups/edit-site-group/edit-site-group.component';
import { BatchImportsComponent } from './components/batch-imports/batch-imports.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MapSelectorComponent } from './components/map-selector/map-selector.component';
import { MatRadioModule } from '@angular/material/radio';
import { EditUserSitesComponent } from './components/popups/edit-user-sites/edit-user-sites.component';
import { EditSubcontractorComponent } from './components/popups/edit-subcontractor/edit-subcontractor.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SiteManagementComponent } from './pages/site-management/site-management.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { HoverMenuComponent } from './components/hover-menu/hover-menu.component';
import { ManageSubcontractorsComponent } from './pages/manage-subcontractors/manage-subcontractors.component';
import { ManageHandoversComponent } from './pages/manage-handovers/manage-handovers.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { AltDashboardComponent } from './pages/alt-dashboard/alt-dashboard.component';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { SchedulesComponent } from './components/schedules/schedules.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatTreeModule} from '@angular/material/tree';
import {MatBadgeModule} from '@angular/material/badge';

import { DayPilot, DayPilotModule } from "@daypilot/daypilot-lite-angular";
import { AlarmHistoryComponent } from './pages/alarm-history/alarm-history.component';
import { ConfigTemplatesComponent } from './pages/config-templates/config-templates.component';
import { AddEditConfigComponent } from './pages/config-templates/add-edit-config/add-edit-config.component';
import { HandoverRequestsComponent } from './pages/handover-requests/handover-requests.component';
import { SiteReportsComponent } from './pages/site-reports/site-reports.component';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CarouselMenuComponent } from './components/carousel-menu/carousel-menu.component';
import { CoverageMapComponent } from './components/coverage-map/coverage-map.component';
import { KeyRequestsComponent } from './pages/key-requests/key-requests.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BlockedUsersComponent } from './pages/blocked-users/blocked-users.component';
import { MegaMenuComponent } from './landing-page/mega-menu/mega-menu.component';
import { MenuItemComponent } from './landing-page/mega-menu/menu-item/menu-item.component';
import { SiteUsersComponent } from './pages/site-management/site-users/site-users.component';

@NgModule({
  declarations: [
    AppComponent,
    // LoginComponent,
    HomeComponent,
    // SidenavComponent,
    BluetoothComponent,
    TestingComponent,
    DiagnosticsComponent,
    NotificationsComponent,
    // TokensComponent,    
    SplashScreenComponent,
    SiteSelectComponent,
    MyTokensComponent,
    AddUserComponent,
    AddSiteComponent,
    TokenAdminComponent,
    GenerateSystemTokensComponent,
    AddUserGroupComponent,
    AddSiteGroupComponent,
    EditUserGroupComponent,
    EditSiteGroupComponent,
    BatchImportsComponent,
    MapSelectorComponent,
    EditUserSitesComponent,
    EditSubcontractorComponent,
    LandingPageComponent,
    SiteManagementComponent,
    UserManagementComponent,
    HoverMenuComponent,
    ManageSubcontractorsComponent,
    ManageHandoversComponent,
    AltDashboardComponent,
    SchedulesComponent,
    AlarmHistoryComponent,
    ConfigTemplatesComponent,
    AddEditConfigComponent,
    HandoverRequestsComponent,
    SiteReportsComponent,
    CoverageMapComponent,
    KeyRequestsComponent,
    BlockedUsersComponent,
    MegaMenuComponent,
    MenuItemComponent,
    SiteUsersComponent,
    // CarouselMenuComponent,
    // DashboardComponent
    // PasswordUpdateComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    WebBluetoothModule.forRoot({
      enableTracing: true // or false, this will enable logs in the browser's console
    }),
    ZXingScannerModule,
    FlexLayoutModule,
    FlexModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    HttpClientModule,
    FormsModule,
    MatSelectModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatDividerModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCheckboxModule,
    LoaderModule,
    MatStepperModule,
    MatExpansionModule,
    MatTableModule,
    MatChipsModule,
    WebcamModule,
    MatTabsModule,
    MatPaginatorModule,
    DragDropModule,
    MatTooltipModule,
    MatRadioModule,
    MatMenuModule,
    MatSortModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatTreeModule,
    MatBadgeModule,
    // PdfViewerModule,
    NgxExtendedPdfViewerModule,
    DayPilotModule,
    CanvasJSAngularChartsModule,
    // LogsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideFirebaseApp(() => initializeApp({}))
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
