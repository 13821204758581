import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BluetoothService {

  // enum RETURN_CODES
  // {
  //     OK,
  //     ERROR,
  //     PACKET_TOO_LONG,
  //     INVALID_CRC,
  //     VALID_CRC,
  //     INVALID_TOKEN,    
  //     VALID_TOKEN,
  //     FUTURE_TOKEN,
  // };

  // enum FUNC_CODES
  // {
  //     NORMAL,
  //     SET_TIME,
  //     ENROLL,
  // };


  serviceUUID: string = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
  writeUUID: string = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
  readUUID: string = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';
  public myDevice: any;
  public tokenSet: boolean;
  public myGattServer: any;
  public myGattService: any;
  public myReadCharacteristic: any;
  public myWriteCharacteristic: any;

  constructor() {
    this.tokenSet = false;
  }

  bluetoothNameFilters = [{name: "Prism"}, {name: "!Prism"}, {namePrefix: "Prism"}, {namePrefix: "prism"}, {namePrefix: "!Prism"}, {namePrefix: "PRISM"}]

  getDevices(): Promise<BluetoothRemoteGATTServer> {
    return new Promise<BluetoothRemoteGATTServer>((res, rej) => {
      navigator.bluetooth.requestDevice({
        // filters:this.bluetoothNameFilters
        filters: [{ services: [this.serviceUUID] }],
        // acceptAllDevices: true,
        // optionalServices: [this.serviceUUID]
      }).then((devices: any) => {
        console.log("Connecting...")
        this.myDevice = devices;
        res(devices.gatt.connect())
      }).catch(err => {
        rej(err);
      })
    })
  }

  getDevice() {
    return new Promise<BluetoothRemoteGATTServer>((res, rej) => {
      navigator.bluetooth.getDevices().then((devices: any) => {
        if (devices.length > 0) {
          console.log("Auto Connecting...");
          this.myDevice = devices[0];
          this.myGattServer = devices[0].gatt;
          console.log(this.myGattServer)
          res(devices[0].gatt.connect());
        } else {
          rej(false);
        }
      }).catch(err => {
        rej(err)
      })
    })
  }

  connectDevice() {
    return new Promise<BluetoothRemoteGATTServer>((res, rej) => {
      this.getDevices().then(server => {
        this.myGattServer = server
        this.getPrimaryService().then(service => {
          this.myGattService = service;
          this.getReadService().then(read => {
            this.myReadCharacteristic = read;
            this.getWriteService().then(write => {
              this.myWriteCharacteristic = write;
              res(server)
            });
          });
        });
      }).catch(err => {
        console.log("Could not connect...")
        rej(err)
      });
    });
  }

  getPrimaryService() {
    return new Promise<BluetoothRemoteGATTService>((res, rej) => {
      this.myGattServer.getPrimaryService(this.serviceUUID).then((service: BluetoothRemoteGATTService) => {
        this.myGattService = service;
        res(service);
      });
    });
  }

  getReadService() {
    return new Promise<BluetoothRemoteGATTCharacteristic>((res, rej) => {
      this.myGattService.getCharacteristic(this.readUUID).then((reader: BluetoothRemoteGATTCharacteristic) => {
        console.log("Read service: ");
        console.log(reader);
        res(reader);
      });
    });
  }

  getWriteService() {
    return new Promise<BluetoothRemoteGATTCharacteristic>((res, rej) => {
      this.myGattService.getCharacteristic(this.writeUUID).then((writer: BluetoothRemoteGATTCharacteristic) => {
        res(writer);
      });
    });
  }

  setToken() {

  }

}
